require('./Calendar.scss');
require('./_Project/Calendar_Project.scss');

const b = require('app/www/libs/b')('calendar');

const _max = require('lodash/max');

const React = require('react');
const PropTypes = require('prop-types');

const {getPeriod, isPeriod} = require('app/common/libs/periods');

const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');

const Icon = require('app/www/components/blocks/Icon/Icon');
const Scroll = require('app/www/components/blocks/Scroll/Scroll');
const Ua = require('app/www/components/blocks/Ua/Ua');

const DAYS_IN_WEEK = 7;
const FIRST_DAY_INDEX = 0;
const CURRENT_WEEK_FIRST_DAY_INDEX = FIRST_DAY_INDEX + DAYS_IN_WEEK;
const CURRENT_WEEK_LAST_DAY_INDEX = 15;
const WEEKEND_INDEXES = [6, 0];

class Calendar extends React.PureComponent {

    static get contextTypes() {
        return {
            router: PropTypes.object,
        };
    }

    static get defaultProps() {
        return {
            mods: {
                channel: false,
            },
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            animate: false,
        };

        this._prepare();

        this._calculateWidth = this._calculateWidth.bind(this);
        this._onClickPrev = this._onClickPrev.bind(this);
        this._onClickNext = this._onClickNext.bind(this);
        this._onScroll = this._onScroll.bind(this);
        this._onClick = this._onClick.bind(this);
    }

    componentDidMount() {
        this._saveSizes();
        this._toSelectedlWeek();
    }

    render() {
        const {
            req,
            mods,
            days,
            schedules,
            isSportProject,
        } = this.props;

        const moment = req.lib('moment');
        const isMainPage = mods && mods.main;
        const monday = moment.getRangedDate('start', 'week', moment.getCalendarDate());
        const nextMonday = moment.getDeltaDate('add', monday, DAYS_IN_WEEK, 'days');

        const isViewInIframeKinopoiskhd =
        req.lib('urlBuilder').isViewInIframeKinopoiskhd;

        const schedulesFinish = schedules.map(schedule => moment.lib()(schedule.finish).valueOf());
        let scheduleLastDay = moment.getRangedDate('start', 'day', _max(schedulesFinish));

        if (moment.is('same', nextMonday, scheduleLastDay) && isMainPage) {
            scheduleLastDay = moment.getDeltaDate('subtract', scheduleLastDay, 1, 'days');
        }

        const iconProps = {
            mods: {
                arrow_dark: isViewInIframeKinopoiskhd,
                arrow_centered: true,
                arrow_centered_dark: isViewInIframeKinopoiskhd,
            },
        };

        const scrollProps = {
            width: this.state.width,
            animate: this.state.animate,
            touch: Ua.isTouch,
        };

        this._daysDOM = [];

        const children = this._days.map((day, index) => {
            let noSchedule = !schedules.length || moment.is('after', day.moment, scheduleLastDay);
            const wrapperProps = {
                className: b('item-wrapper', {
                    disabled: noSchedule,
                    dark: isViewInIframeKinopoiskhd,
                    disabled_dark: noSchedule && isViewInIframeKinopoiskhd,
                }),
                ref: ref => (this._daysDOM[index] = ref),
            };
            let itemProps;
            let Tag = 'a';

            if (isSportProject) {
                noSchedule = noSchedule || !days.includes(day.date);
            }

            if (noSchedule) {
                Tag = 'i';
                itemProps = {
                    className: b('item', {
                        selected: day.isSelected,
                        dark: isViewInIframeKinopoiskhd,
                        selected_dark: isViewInIframeKinopoiskhd && day.isSelected,
                    }),
                };
            } else {
                wrapperProps.onClick = day.onClick;
                itemProps = {
                    className: b('item', {
                        past: day.isPast,
                        weekend: day.isWeekend,
                        selected: day.isSelected,
                        dark: isViewInIframeKinopoiskhd,
                        dark_weekend: day.isWeekend && isViewInIframeKinopoiskhd,
                        selected_dark: isViewInIframeKinopoiskhd && day.isSelected,
                    }),
                    href: day.url,
                };
            }

            return (
                <div key={day.title} {...wrapperProps}>
                    <Tag {...itemProps}>
                        {day.title}
                    </Tag>
                </div>
            );
        });

        mods.dark = isViewInIframeKinopoiskhd;

        return (
            <div className={b(mods)}>
                {(this.state.prev || isViewInIframeKinopoiskhd) &&
                    <span
                        className={b('ctrl', {
                            prev: true,
                            prev_dark: isViewInIframeKinopoiskhd,
                            dark: isViewInIframeKinopoiskhd,
                            invis: !this.state.prev,
                        })}
                        onClick={this._onClickPrev}
                    >
                        <Icon {...iconProps} />
                    </span>
                }
                {(this.state.next || isViewInIframeKinopoiskhd) &&
                    <span
                        className={b('ctrl', {
                            next: true,
                            next_dark: isViewInIframeKinopoiskhd,
                            dark: isViewInIframeKinopoiskhd,
                            invis: !this.state.next,
                        })}
                        onClick={this._onClickNext}
                    >
                        <Icon {...iconProps} />
                    </span>
                }
                {this.state.mounted
                    ? <Scroll
                        className={b('scroll_dark')}
                        ref={elem => (this._scroll = elem)}
                        onScroll={this._onScroll}
                        {...scrollProps}
                    >
                        {children}
                    </Scroll>
                    : [
                        <div key={1} className={b('hidden')}>
                            {children}
                        </div>,
                        <div key={2} className={b('fake-items')}>
                            {this._getFakeChildren(children)}
                        </div>,
                    ]
                }
            </div>
        );
    }

    /**
     * @private
     */
    _prepare() {
        const {
            req,
            i18n,
            isSimple,
            selectedDate,
        } = this.props;

        this._hasPosition = false;

        const moment = req.lib('moment');
        const selectedDay = moment.getCalendarDate(selectedDate);
        const today = moment.getCalendarDate();
        const monday = moment.getRangedDate('start', 'week', today);
        const sunday = moment.getDeltaDate('add', monday, DAYS_IN_WEEK - 1, 'days');
        const firstDay = moment.getDeltaDate('subtract', monday, DAYS_IN_WEEK, 'days');
        const lastDay = moment.getDeltaDate('add', sunday, DAYS_IN_WEEK, 'days');
        let day = firstDay;

        this._days = [];

        while (!moment.is('after', day, lastDay)) {
            const properties = {
                moment: day,
                isPast: moment.is('before', day, today),
                isWeekend: WEEKEND_INDEXES.includes(day.day()),
                isSelected: moment.is('same', day, selectedDay),
            };

            const nextDay = moment.getDeltaDate('add', day, 1, 'days');

            if (moment.is('same', nextDay, today)) {
                properties.title = i18n.get('calendar.yesterday');
            }

            if (moment.is('same', day, today)) {
                if (!isSimple) {
                    this._pushPeriodNow(day, properties);
                }
                this._pushPeriodAllDay(day, properties);
            } else {
                this._pushDay(day, properties);
            }

            day = nextDay;
        }

        this._currentWeekLastDayIndex = isSimple ? CURRENT_WEEK_LAST_DAY_INDEX - 1 : CURRENT_WEEK_LAST_DAY_INDEX;
        this._lastDayIndex = this._currentWeekLastDayIndex + DAYS_IN_WEEK;
    }

    /**
     * Добавляем сегодняшний день с периодом now
     * @param {Object} day день
     * @param {Object} properties свойства дня
     * @private
     */
    _pushPeriodNow(day, properties) {
        const {i18n, selectedPeriod} = this.props;
        const period = getPeriod('now').alias;
        const isSelected = properties.isSelected && (!selectedPeriod || isPeriod(period, selectedPeriod));

        properties = Object.assign({}, properties, {
            title: i18n.get('calendar.now'),
            isSelected,
            period,
        });

        return this._pushDay(day, properties);
    }

    /**
     * Добавляем сегодняшний день с периодом all-day
     * @param {Object} day день
     * @param {Object} properties свойства дня
     * @private
     */
    _pushPeriodAllDay(day, properties) {
        const {i18n, selectedPeriod, isSimple} = this.props;
        const period = getPeriod('all-day').alias;
        const isSelected = properties.isSelected && (isSimple || isPeriod(period, selectedPeriod));

        properties = Object.assign({}, properties, {
            title: i18n.get('calendar.all-day'),
            isSelected,
            period,
        });

        return this._pushDay(day, properties);
    }

    /**
     * Добавляем день
     * @param {Object} day день
     * @param {Object} properties свойства дня
     * @private
     */
    _pushDay(day, properties) {
        const index = this._days.length;
        const urlBuilder = this.props.req.lib('urlBuilder');
        const query = {
            date: day,
            kinopoisk_channel: urlBuilder.isViewInIframeKinopoiskhd,
        };

        const {isSimple} = this.props;

        if (!isSimple) {
            query.period = properties.period || getPeriod('all-day').alias;
        }

        if (properties.isSelected) {
            this._selectedDayIndex = index;
        }

        this._days.push(Object.assign({}, {
            title: day.format('dd, D'),
            date: day.format('YYYY-MM-DD'),
            url: urlBuilder.buildIndexQuery(query),
            onClick: (evt) => this._onClick(evt, query, index),
        }, properties));
    }

    /**
     * Сохраняем размеры и координаты
     * @private
     */
    _saveSizes() {
        this._prevWeekWidth = this._daysDOM.slice(FIRST_DAY_INDEX, CURRENT_WEEK_FIRST_DAY_INDEX).reduce(this._calculateWidth, 0);
        this._currentWeekWidth = this._daysDOM.slice(CURRENT_WEEK_FIRST_DAY_INDEX, this._currentWeekLastDayIndex).reduce(this._calculateWidth, 0);
        this._nextWeekWidth = this._daysDOM.slice(this._currentWeekLastDayIndex, this._lastDayIndex).reduce(this._calculateWidth, 0);

        this._prevWeek = 0;
        this._currentWeek = this._prevWeekWidth;
        this._nextWeek = this._prevWeekWidth + this._currentWeekWidth;
        this._contentWidth = this._prevWeekWidth + this._currentWeekWidth + this._nextWeekWidth;
    }

    /**
     * Суммируем ширину
     * @param {Number} width ширина предыдущих дней
     * @param {Object} element React.Element дня недели
     * @returns {Number}
     * @private
     */
    _calculateWidth(width, element) {
        return width + element.offsetWidth;
    }

    /**
     * Получаем дни для первичного отображения
     * @param {Object[]} children все дни
     * @returns {Object[]}
     * @private
     */
    _getFakeChildren(children) {
        const {isChannel} = this.props;
        let first;
        let last;

        if (this._selectedDayIndex < CURRENT_WEEK_FIRST_DAY_INDEX) {
            first = FIRST_DAY_INDEX;
            last = CURRENT_WEEK_FIRST_DAY_INDEX;
        } else if (this._selectedDayIndex >= this._currentWeekLastDayIndex) {
            first = this._currentWeekLastDayIndex;
        } else {
            first = CURRENT_WEEK_FIRST_DAY_INDEX;
            last = this._currentWeekLastDayIndex;
        }

        if (Ua.isTouch && isChannel) {
            return children.slice(this._selectedDayIndex, this._lastDayIndex);
        }

        return children.slice(first, last);
    }

    /**
     * Прокручиваем календарь к неделе с выбранным днем
     * @private
     */
    _toSelectedlWeek() {
        const {isChannel} = this.props;
        let weekState;
        let offsetX;

        if (this._selectedDayIndex < CURRENT_WEEK_FIRST_DAY_INDEX) {
            weekState = this._prevWeekState;
            offsetX = this._prevWeek;
        } else if (this._selectedDayIndex >= this._currentWeekLastDayIndex) {
            weekState = this._nextWeekState;
            offsetX = this._nextWeek;
        } else {
            weekState = this._currentWeekState;
            offsetX = this._currentWeek;
        }

        if (Ua.isTouch && isChannel) {
            this._currentWidth = this._daysDOM.slice(0, this._selectedDayIndex).reduce(this._calculateWidth, 0);
            offsetX = this._currentWidth;
            weekState = {};
        }

        this.setState(Object.assign({}, weekState, {
            mounted: true,
        }), () => {
            this._scroll.to(offsetX);

            this.setState({
                animate: true,
            });
        });
    }

    /**
     * @returns {Object}
     * @private
     */
    get _prevWeekState() {
        return {
            width: this._prevWeekWidth,
            prev: false,
            next: true,
        };
    }

    /**
     * @returns {Object}
     * @private
     */
    get _nextWeekState() {
        return {
            width: this._nextWeekWidth,
            prev: true,
            next: false,
        };
    }

    /**
     * @returns {Object}
     * @private
     */
    get _currentWeekState() {
        return {
            width: this._currentWeekWidth,
            prev: true,
            next: true,
        };
    }

    /**
     * @private
     */
    _onClickPrev() {
        this._scroll.to(!this.state.next
            ? this._currentWeek
            : this._prevWeek
        );
    }

    /**
     * @private
     */
    _onClickNext() {
        if (this.state.prev) {
            this.setState(
                this._nextWeekState,
                () => {
                    this._scroll.to(this._nextWeek);
                }
            );
        } else {
            this._scroll.to(this._currentWeek);
        }
    }

    /**
     * @private
     */
    _onScroll(data) {
        const {isChannel} = this.props;
        if (!this._hasPosition) {
            this._hasPosition = true;
            return;
        }

        if (Ua.isTouch && isChannel) {
            return;
        }

        if (data.isStart) {
            this.setState(this._prevWeekState);
        } else if (data.position === this._nextWeek || this._contentWidth - data.position <= this._currentWeekWidth) {
            this.setState(this._nextWeekState);
        } else if (!this.state.prev || !this.state.next) {
            this.setState(this._currentWeekState);
        }
    }

    /**
     * @param {Event} evt
     * @param {Object} query
     * @param {Number} index индекс выбранного дня
     * @private
     */
    _onClick(evt, query, index) {
        const {req, isChannel, onChange, isAsyncRouting} = this.props;
        const {router} = this.context;
        const {route, history} = router;
        const urlBuilder = req.lib('urlBuilder');
        const location = route.location;
        const daySelected = this._days.filter(day => day.isSelected)[0];

        if (daySelected) {
            daySelected.isSelected = false;
        }

        this._days[index].isSelected = true;

        if (isAsyncRouting) {
            evt.preventDefault();

            history.push(`${location.pathname}${urlBuilder.buildQuery(query)}`);

            this.setState({
                switch: !this.state.switch,
            });
        }

        if (Ua.isTouch && isChannel) {
            this._selectedDayIndex = index;
            this._toSelectedlWeek();
        }

        onChange(evt, query);
    }

}

Calendar.propTypes = {
    mods: PropTypes.shape({
        channel: PropTypes.bool,
        main: PropTypes.bool,
    }),
    schedules: PropTypes.array.isRequired,
    selectedDate: PropTypes.string,
    selectedPeriod: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

module.exports = withReq(withI18n(Calendar));
