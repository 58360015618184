require('./Scroll.scss');

const b = require('app/www/libs/b')('scroll', true);

const _ = {
    isEqual: require('lodash/isEqual'),
};

const nextTick = require('app/www/libs/nextTick');

const React = require('react');
const PropTypes = require('prop-types');
const ResizeListener = require('app/www/components/blocks/ResizeListener/ResizeListener');

class Scroll extends React.PureComponent {

    constructor(props) {
        super(props);

        const {autoLoad, children} = this.props;

        this.state = Object.assign({children}, autoLoad && {
            itemsRendered: autoLoad.itemsRendered,
        });

        this._saveSizes = this._saveSizes.bind(this);
        this._onScroll = this._onScroll.bind(this);
        this._callback = this._callback.bind(this);
    }

    componentDidMount() {
        this._minPosition = 0;
        this._position = this._minPosition;

        nextTick(() => {
            this._saveSizes();
        });
    }

    static getDerivedStateFromProps(props, state) {
        const {autoLoad, children} = props;

        if (!_.isEqual(children, state.children)) {
            return Object.assign({children}, autoLoad && {
                itemsRendered: autoLoad.itemsRendered,
            });
        }

        return null;
    }

    componentDidUpdate(prevProps) {
        if (!_.isEqual(prevProps, this.props)) {
            this._saveSizes();
        }
    }

    render() {
        const {
            width,
            touch,
            render,
            autoLoad,
            className,
        } = this.props;

        const {
            children,
            itemsRendered,
        } = this.state;

        const style = width ? {width} : {};

        let content = children;

        if (autoLoad) {
            content = content.slice(0, itemsRendered);
        }

        if (render) {
            content = content.map(render);
        }

        const resizeListenerProps = {
            callback: this._saveSizes,
        };

        return (
            <div
                className={b.mix(className, {touch})}
                style={style}
            >
                <div
                    ref={ref => (this._wrapper = ref)}
                    className={b('wrapper')}
                    onScroll={this._onScroll}
                >
                    <div
                        ref={ref => (this._content = ref)}
                        className={b('content')}
                    >
                        {content}
                    </div>
                </div>
                <ResizeListener {...resizeListenerProps} />
            </div>
        );
    }

    /**
     * Скроллим на указанную величину
     * @param {Number} [leftPosition] позиция прокрутки от начала
     */
    to(leftPosition) {
        const {animate} = this.props;

        this._to = true;
        this._position = typeof leftPosition !== 'undefined' ? leftPosition : this._position;

        this._wrapper.scroll({
            top: 0,
            left: this._position,
            behavior: animate ? 'smooth' : 'auto',
        });
    }

    /**
     * Скроллим влево
     * или на указанную величину,
     * или на ширину компонента,
     * или в начало
     */
    toLeft() {
        const {scrollWidth, scrollOffset} = this.props;

        const position = this._position - (scrollWidth || this._wrapperWidth + scrollOffset);
        this._position = position >= this._minPosition ? position : this._minPosition;

        this.to(this._position);
    }

    /**
     * Скроллим вправо
     * или на указанную величину,
     * или на ширину компонента,
     * или в конец
     */
    toRight() {
        const {scrollWidth, scrollOffset} = this.props;

        const position = this._position + (scrollWidth || this._wrapperWidth + scrollOffset);
        this._position = position <= this._maxPosition ? position : this._maxPosition;

        this.to(this._position);
    }

    /**
     * Сохраняем необходимые для расчетов размеры
     * @private
     */
    _saveSizes() {
        this._position = this._wrapper.scrollLeft;
        this._wrapperWidth = this.props.width || this._wrapper.offsetWidth;
        this._contentWidth = this._content.offsetWidth;
        this._maxPosition = this._contentWidth - this._wrapperWidth;

        this._onScroll();
    }

    /**
     * Обрабатываем события нативного и js скролла
     * @private
     */
    _onScroll() {
        if (!this._to) {
            this._position = this._wrapper.scrollLeft;
            this._callback();
        } else if (this._wrapper.scrollLeft === this._position) {
            this._to = false;
            this._callback();
        }
    }

    /**
     * Подгружаем следующий чанк элементов
     * Рассчитываем и передаем в callback параметры
     * @private
     */
    _callback() {
        this._autoLoad();

        this.props.onScroll({
            position: this._position,
            isStart: this._position === this._minPosition,
            isEnd: this._position === this._maxPosition,
        });
    }

    /**
     * Подгружаем следующий чанк элементов
     * @private
     */
    _autoLoad() {
        const {autoLoad} = this.props;
        const {itemsRendered, children} = this.state;

        if (autoLoad && itemsRendered < children.length) {
            const maxPosition = (itemsRendered - autoLoad.itemsRemained) * autoLoad.itemWidth - this._wrapperWidth;

            if (this._position > maxPosition) {
                this.setState({
                    itemsRendered: itemsRendered + autoLoad.itemsRendered,
                }, this._saveSizes);
            }
        }
    }

}

Scroll.defaultProps = {
    scrollOffset: 0,
    animate: true,
    touch: false,
    onScroll: () => {},
};

Scroll.propTypes = {
    width: PropTypes.number,
    scrollWidth: PropTypes.number,
    scrollOffset: PropTypes.number,
    animate: PropTypes.bool,
    touch: PropTypes.bool,
    render: PropTypes.func,
    autoLoad: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            itemsRendered: PropTypes.number.isRequired,
            itemsRemained: PropTypes.number.isRequired,
            itemWidth: PropTypes.number.isRequired,
        }),
    ]),
    onScroll: PropTypes.func,
};

module.exports = Scroll;
