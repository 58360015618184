require('./Select.scss');

const b = require('app/www/libs/b')('select');

const React = require('react');
const Icon = require('app/www/components/blocks/Icon/Icon');
const PropTypes = require('prop-types');
const Dropdown = require('app/www/components/blocks/Dropdown/Dropdown');
const Popup = require('app/www/components/blocks/Popup/Popup');
const Ua = require('app/www/components/blocks/Ua/Ua');

class Select extends React.PureComponent {

    static get defaultProps() {
        return {
            popupProps: {},
            onChange: () => {},
        };
    }

    constructor(props) {
        super(props);

        const {
            options,
            mods,
        } = this.props;

        let checkedItems = options.filter(option => option.attrs.selected).map(option => ({
            id: option.attrs.id,
            value: option.attrs.value,
            name: option.content,
        }));

        this.state = {
            visible: false,
            disabled: mods.disabled,
            checked: checkedItems,
        };

        this._onClick = this._onClick.bind(this);
        this._onChange = this._onChange.bind(this);
        this._onChangeMultiple = this._onChangeMultiple.bind(this);
        this._select = this._select.bind(this);
    }

    static getDerivedStateFromProps(props) {
        const {
            options,
            mods,
        } = props;

        let checkedItems = options.filter(option => option.attrs.selected).map(option => ({
            id: option.attrs.id,
            value: option.attrs.value,
            name: option.content,
        }));

        return {
            disabled: mods.disabled,
            checked: checkedItems,
        };
    }

    render() {
        const {
            mods,
            attrs,
            icon,
            popupProps,
            options,
            format,
            multiple,
            className,
            children,
        } = this.props;

        const {disabled, checked} = this.state;

        Object.assign(mods, {
            arrow: !mods['no-arrow'],
            disabled,
        });


        let buttonTitle = children;

        if (!buttonTitle) {
            if (typeof format === 'function') {
                buttonTitle = format(checked.map(o => o.name));
            } else if (typeof format === 'string') {
                buttonTitle = format.replace('{selected}', checked.map(o => o.name).join(','));
            } else {
                buttonTitle = checked.map(o => o.name).join(',');
            }
        }

        const dropdownProps = {
            buttonProps: {
                mods,
                icon,
                attrs,
                className,
                children: [
                    buttonTitle,
                    Ua.isMobile && (
                        <select
                            className={b('control')}
                            onClick={evt => evt.stopPropagation()}
                            onChange={this._onChange}
                            multiple={multiple}
                            ref={ref => (this._multiSelect = ref)}
                        >
                            {options.map((option, index) => {
                                return (
                                    <option
                                        key={index}
                                        value={option.attrs.value}
                                        disabled={option.attrs.disabled}
                                        selected={this._isItemSelected(option.attrs.id)}
                                    >
                                        {option.content}
                                    </option>
                                );
                            })}
                        </select>
                    ),
                ],
            },
            popupProps: {
                mods: Object.assign({}, popupProps.mods, {
                    theme: 'normal',
                }),
                directions: 'bottom-left',
            },
        };

        return (
            <Dropdown
                ref={elem => (this.dropdown = elem)}
                {...dropdownProps}
            >
                {!Ua.isMobile && (
                    <div>
                        <ul className={b(mods)}>
                            {options.map(option =>
                                option.separator ? this._renderSeparator(option) : this._renderItem(option)
                            )}
                        </ul>
                    </div>
                )}
            </Dropdown>
        );
    }

    /**
     * @param {Object} option
     * @private
     */
    _renderItem(option) {
        const selected = this._isItemSelected(option.attrs.id);

        return (
            <li
                key={option.attrs.value}
                className={b('item', {
                    selected,
                    disabled: option.attrs.disabled,
                })}
                onClick={!option.attrs.disabled ? this._onClick(option.attrs.id, option.attrs.value, option.content) : () => {}}
            >
                {selected && (
                    <Icon mods={{tick: true}} />
                )}
                {option.content}
            </li>
        );
    }

    /**
     * @param {Object} option
     * @private
     */
    _renderSeparator(option) {
        return (
            <div key={option.attrs.value} className={b('item-separator')}>
                {option.content}
            </div>
        );
    }

    /**
     * @param {Number} itemId идентификатор
     * @returns {Boolean}
     * @private
     */
    _isItemSelected(itemId) {
        return this.state.checked.some(i => i.id === itemId);
    }

    /**
     * @param {Number|String} id идентификатор
     * @param {Number|String} value значение
     * @param {String} name название
     * @returns {function()}
     * @private
     */
    _onClick(id, value, name) {
        return () => {
            this.dropdown.close();
            this._select(id, value, name);
        };
    }

    /**
     * @param {Object} evt
     * @private
     */
    _onChange(evt) {
        const {multiple} = this.props;

        if (multiple) {
            this._onChangeMultiple();
        } else {
            const {
                attrs: {id, value},
                content: name,
            } = this.props.options[evt.target.selectedIndex];

            this._select(id, value, name);
        }
    }

    /**
     * @private
     */
    _onChangeMultiple() {
        const {options} = this.props;
        const selectedOptions = this._multiSelect.selectedOptions;
        const newChecked = [];

        for (let i = 0; i < selectedOptions.length; i++) {
            const selectedItem = options.filter(item => String(item.attrs.value) === selectedOptions[i].value)[0];

            if (selectedItem) {
                const {
                    attrs: {id, value},
                    content: name,
                } = selectedItem;

                const itemChecked = {id, value, name};

                newChecked.push(itemChecked);
            }
        }

        this.setState({
            checked: newChecked,
        });

        this.props.onChange(newChecked.map(option => option.value));
    }

    /**
     * @param {Number|String} id идентификатор
     * @param {Number|String} value значение
     * @param {String} name название
     * @private
     */
    _select(id, value, name) {
        const {multiple} = this.props;
        const {checked} = this.state;

        const item = {id, value, name};

        if (multiple) {
            let newChecked;

            if (this._isItemSelected(id)) {
                newChecked = checked.filter(option => option.id !== id);
            } else {
                newChecked = [].concat(checked, item);
            }

            this.setState({
                checked: newChecked,
            });

            this.props.onChange(newChecked.map(option => option.value));
        } else {
            this.setState({
                checked: [item],
            });

            this.props.onChange(item.value);
        }
    }
}

Select.propTypes = {
    mods: PropTypes.shape({
        disabled: PropTypes.bool,
        size: PropTypes.string.isRequired,
        theme: PropTypes.string.isRequired,
    }).isRequired,
    attrs: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        tabIndex: PropTypes.number,
    }),
    popupProps: PropTypes.shape({
        mods: Popup.propTypes.mods,
    }),
    options: PropTypes.arrayOf(PropTypes.shape({
        attrs: PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.number,
                PropTypes.string,
            ]),
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]).isRequired,
            selected: PropTypes.bool,
            disabled: PropTypes.bool,
        }),
        content: PropTypes.string,
    })).isRequired,
    multiple: PropTypes.bool,
    format: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.string,
    ]),
    onChange: PropTypes.func,
};

module.exports = Select;
