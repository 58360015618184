require('./Teaser_Hd.scss');

const b = require('app/www/libs/b')('teaser_hd');

const React = require('react');
const PropTypes = require('prop-types');

const Metrika = require('app/www/libs/client/metrika');
const nextTick = require('app/www/libs/nextTick');

const Button = require('app/www/components/blocks/Button/Button');
const Link = require('app/www/components/blocks/Link/Link');
const Ua = require('app/www/components/blocks/Ua/Ua');

const COOKIE_NAME = require('app/www/constants/cookies')['TEASER_HD_DISPLAY'];
const DELAY_OPENING = 100;

class Teaser_Hd extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            hidden: false,
        };

        this._onClickClose = this._onClickClose.bind(this);
    }

    componentDidMount() {
        const {
            campaign,
        } = this.props;
        const cookieUniqueName = `${COOKIE_NAME}_${campaign}`;
        const isClient = typeof window !== 'undefined';
        this._isHidden = false;

        if (isClient) {
            this._isHidden = document.cookie.includes(cookieUniqueName);

            nextTick(() => {
                if (
                    !Ua.isLayoutColumnsLarge &&
                    !this._isHidden
                ) {
                    this._sendMetrika('show');

                    setTimeout(this.setState({
                        visible: true,
                    }), DELAY_OPENING);
                }
            });
        }
    }

    render() {
        const {
            url,
            title,
            video,
            buttonText,
            buttonColor,
            buttonTextColor,
            description,
        } = this.props;

        const {visible, hidden} = this.state;

        const style = {};

        if (buttonColor) {
            style.color = buttonTextColor;
        }

        if (buttonTextColor) {
            style.backgroundColor = buttonColor;
        }

        const linkProps = {
            url: url.value,
            attrs: {
                target: '_blank',
            },
            onClick: () => this._sendMetrika('click'),
        };

        const buttonProps = {
            mods: {
                theme: 'orange',
            },
            url: url.value,
            attrs: {
                target: '_blank',
                style,
            },
            onClick: () => this._sendMetrika('click'),
        };

        const videoProps = {
            src: video,
            autoPlay: 'autoplay',
            muted: 'muted',
            width: 300,
            height: 120,
            loop: 'loop',
        };

        return (
            <div className={b({visible, hidden})}>
                <div className={b('info')}>
                    <div className={b('info-text')}>
                        <div className={b('title')}>{title}</div>
                        <div className={b('description')}>{description}</div>
                    </div>
                    <Button className={b('button')} {...buttonProps} >
                        {buttonText}
                    </Button>
                </div>
                <i className={b('close')} onClick={this._onClickClose} />
                <Link className={b('link')} {...linkProps} />
                <video className={b('video')} {...videoProps} />
            </div>
        );
    }

    /**
     * Отправляем данные в метрику
     * @param {String} action show|click
     * @private
     */
    _sendMetrika(action) {
        const {campaign} = this.props;

        Metrika.trackParams('TEASER', {
            campaign,
            action,
        });
    }

    /**
     * @private
     */
    _onClickClose() {
        this.setState({
            hidden: true,
        });

        this._sendMetrika('close');

        const {
            campaign,
        } = this.props;
        const cookieUniqueName = `${COOKIE_NAME}_${campaign}`;
        const isClient = typeof window !== 'undefined';

        if (isClient) {
            document.cookie = `${cookieUniqueName}=1; path=/; expires=0}`;
        }
    }
}

Teaser_Hd.propTypes = {
    url: PropTypes.object,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    description: PropTypes.string,
    campaign: PropTypes.string.isRequired,
};

module.exports = Teaser_Hd;
