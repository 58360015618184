const b = require('app/www/libs/b')('grid-channel');

const React = require('react');
const PropTypes = require('prop-types');
const ChannelLogo = require('app/www/components/blocks/ChannelLogo/ChannelLogo');

const GridDndChannel = (props) => {
    const channelLogoProps = {
        className: b('logo'),
        mods: {
            size: 'm',
        },
        logo: props.logo,
    };

    return (
        <div className={b()}>
            <ChannelLogo {...channelLogoProps} />
            <h2 className={b('title')}>
                {props.title}
            </h2>
        </div>
    );
};

GridDndChannel.propTypes = {
    logo: ChannelLogo.propTypes.logo,
    title: PropTypes.string.isRequired,
};

module.exports = GridDndChannel;
