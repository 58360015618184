const React = require('react');
const ReqContext = React.createContext();

module.exports = {
    ReqContext,
    withReq: Component => props => (
        <ReqContext.Consumer>
            {req => <Component {...props} req={req} />}
        </ReqContext.Consumer>
    ),
    withReqRef: Component => React.forwardRef((props, ref) => (
        <ReqContext.Consumer>
            {req => <Component {...props} ref={ref} req={req} />}
        </ReqContext.Consumer>
    )),
};
