const PAGES = {
    INDEX: /^\/\d*\/?(\?.*?)?$/,
    CHANNEL: /(^\/(\d+\/)?channel\/)([\w-]+-)?(\d+)\/?$/,
    CHANNEL_OLD: /(^\/(\d+\/)?channels\/)([\w-]+-)?(\d+)\/?$/,
    STREAM: /(^\/(\d+\/)?channel\/)(([\w-]+-)?(\d+)\/?)?stream$/,
    STREAM_OLD: /(^\/(\d+\/)?channels\/)(([\w-]+-)?(\d+)\/?)?stream$/,
    PROGRAM: /(^\/(\d+\/)?program\/)([\w-]+-)?(\d+)\/?/,
    FAVORITES: /^\/(\d+\/)?my\/favorites\/?$/,
    LISTS: /^\/(\d+\/)?lists\//,
    SEARCH: /^\/(\d+\/)?search$/,
    SPECIAL: /^\/special\//,
    ONLINE: /^\/online/,
    SPORT: /^\/(\d+\/)?sport\//,
};

function escape(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

/**
 * Форматироваие числа: пробелы между тысячными разрядами
 * 1234567 => 1 234 567
 * @param {Number|String} str
 * @returns {String}
 */
function formatHighDigits(str) {
    return String(str).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

module.exports = {
    PAGES,
    escape,
    formatHighDigits,
};
