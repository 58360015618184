const React = require('react');
const PropTypes = require('prop-types');

class PageVisibilityAware extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onHidden = this._onHidden.bind(this);
        this._getPrefix = this._getPrefix.bind(this);
    }

    componentDidMount() {
        if (this._getPrefix()) {
            this.evtName = `${this._getPrefix().replace(/[H|h]idden/, '')}visibilitychange`;
            document.addEventListener(this.evtName, this._onHidden, false);
        }
    }

    componentWillUnmount() {
        document.removeEventListener(this.evtName, this._onHidden, false);
    }

    render() {
        return <span />;
    }

    /**
     * Добавляет префикс
     * @private
     */
    _getPrefix() {
        const prefixes = ['webkit', 'moz', 'ms', 'o'];

        if ('hidden' in document) {
            return 'hidden';
        }

        for (let i = 0; i < prefixes.length; i++) {
            if ((`${prefixes[i]}Hidden`) in document) {
                return `${prefixes[i]}Hidden`;
            }
        }

        return null;
    }

    _onHidden() {
        const {callback} = this.props;
        const type = this._getPrefix();

        callback(document[type]);
    }

}

PageVisibilityAware.propTypes = {
    callback: PropTypes.func,
};

module.exports = PageVisibilityAware;
