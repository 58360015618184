require('./Select_Calendar.scss');

const b = require('app/www/libs/b')('select');

const _ = {
    get: require('lodash/get'),
    capitalize: require('lodash/capitalize'),
};

const React = require('react');
const PropTypes = require('prop-types');

const {getPeriod, isPeriod} = require('app/common/libs/periods');

const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');

const Select = require('app/www/components/blocks/Select/Select');
const Ua = require('app/www/components/blocks/Ua/Ua');

const DAYS_IN_WEEK = 7;

class Select_Calendar extends React.PureComponent {

    static get contextTypes() {
        return {
            router: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            mounted: false,
            animate: false,
        };

        this._prepare();
        this._changeDay = this._changeDay.bind(this);
    }

    render() {
        const selectProps = {
            className: b('calendar'),
            mods: {
                'filters-calendar': true,
                size: Ua.isTouchPhone ? 'm' : 's',
                theme: 'normal',
            },
            options: this._days,
            onChange: this._changeDay,
        };

        return (
            <Select {...selectProps} />
        );
    }

    /**
     * @private
     */
    _prepare() {
        const {
            req,
            i18n,
            selectedDate,
        } = this.props;

        this._hasPosition = false;

        const moment = req.lib('moment');
        const selectedDay = moment.getCalendarDate(selectedDate);
        const today = moment.getCalendarDate();
        const monday = moment.getRangedDate('start', 'week', today);
        const sunday = moment.getDeltaDate('add', monday, DAYS_IN_WEEK - 1, 'days');
        const firstDay = moment.getDeltaDate('subtract', monday, DAYS_IN_WEEK, 'days');
        const lastDay = moment.getDeltaDate('add', sunday, DAYS_IN_WEEK, 'days');
        let day = firstDay;

        this._days = [];

        while (!moment.is('after', day, lastDay)) {
            const properties = {
                selected: moment.is('same', day, selectedDay),
            };
            const nextDay = moment.getDeltaDate('add', day, 1, 'days');

            if (moment.is('same', day, today)) {
                this._pushPeriodNow(day, properties);
                this._pushPeriodAllDay(day, properties);
            } else {
                let name;

                if (moment.is('same', nextDay, today)) {
                    name = i18n.get('calendar.yesterday');
                }

                this._pushDay(day, properties, name);
            }

            day = nextDay;
        }
    }

    /**
     * Добавляем сегодняшний день с периодом now
     * @param {Object} day день
     * @param {Object} properties свойства дня
     * @private
     */
    _pushPeriodNow(day, properties) {
        const {i18n, selectedPeriod} = this.props;
        const period = getPeriod('now').alias;
        const selected = properties.selected && (!selectedPeriod || isPeriod(period, selectedPeriod));
        const name = i18n.get('calendar.now');

        properties = Object.assign({}, properties, {
            id: period,
            selected,
            value: period,
            period,
        });

        return this._pushDay(day, properties, name);
    }

    /**
     * Добавляем сегодняшний день с периодом all-day
     * @param {Object} day день
     * @param {Object} properties свойства дня
     * @private
     */
    _pushPeriodAllDay(day, properties) {
        const {i18n, selectedPeriod} = this.props;
        const period = getPeriod('all-day').alias;
        const selected = properties.selected && isPeriod(period, selectedPeriod);
        const name = i18n.get('calendar.all-day');

        properties = Object.assign({}, properties, {
            id: period,
            selected,
            value: period,
            period,
        });

        return this._pushDay(day, properties, name);
    }

    /**
     * Добавляем день
     * @param {Object} day день
     * @param {Object} properties свойства дня
     * @param {String} [name] если есть название дня
     * @private
     */
    _pushDay(day, properties, name) {
        const content = name || _.capitalize(day.format('dddd, D'));

        this._days.push({
            content,
            attrs: Object.assign({}, {
                id: day.format('YYYY-MM-DD'),
                date: day.format('YYYY-MM-DD'),
                selected: properties.selected,
                value: day.format('YYYY-MM-DD'),
            }, properties),
        });
    }

    /**
     * @param {String} value индекс выбранного дня
     * @private
     */
    _changeDay(value) {
        const {req, onChange, isAsyncRouting} = this.props;
        const {router} = this.context;
        const {route, history} = router;
        const urlBuilder = req.lib('urlBuilder');
        const location = route.location;
        const daySelected = this._days.filter(day => day.attrs.selected)[0];
        const dayIsSelect = this._days.filter(day => day.attrs.value === value)[0];
        const query = {};

        query.period = _.get(dayIsSelect, 'attrs.period', getPeriod('all-day').alias);

        if (daySelected) {
            daySelected.attrs.selected = false;
        }

        if (dayIsSelect) {
            query.date = dayIsSelect.attrs.date;
            dayIsSelect.attrs.selected = true;
        }

        if (isAsyncRouting) {
            history.push(`${location.pathname}${urlBuilder.buildQuery(query)}`);

            this.setState({
                switch: !this.state.switch,
            });
        }

        onChange(query);
    }
}

Select_Calendar.defaultProps = {
    mods: {
        channel: false,
    },
    onChange: () => {},
};

Select_Calendar.propTypes = {
    mods: PropTypes.shape({
        channel: PropTypes.bool,
        main: PropTypes.bool,
    }),
    selectedDate: PropTypes.string,
    selectedPeriod: PropTypes.string,
    onChange: PropTypes.func.isRequired,
};

module.exports = withReq(withI18n(Select_Calendar));
