require('./RecommendedBottomItem.scss');

const b = require('app/www/libs/b')('recommended-bottom-item');

const React = require('react');
const PropTypes = require('prop-types');

const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');

const Icon = require('app/www/components/blocks/Icon/Icon');
const Image = require('app/www/components/blocks/Image/Image');
const Link = require('app/www/components/blocks/Link/Link');
const Metrika = require('app/www/libs/client/metrika');

class RecommendedBottomItem extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
        };

        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onClick = this._onClick.bind(this);
    }

    render() {
        const {
            id,
            req,
            url,
            i18n,
            image,
            isLive,
            title,
            start,
            channel,
        } = this.props;

        const linkProps = {
            url: url,
        };

        const imageProps = {
            mods: {
                size: 'l',
                background: true,
            },
            sizes: image.sizes,
        };

        const iconLiveProps = {
            mods: {
                hovered: this.state.hovered,
                live: true,
                size: 'm',
            },
        };

        const moment = req.lib('moment');
        const date = moment.getFormattedDate('dd', start, i18n);
        const time = moment.getFormattedDate('HH:mm', start);

        return (
            <Link
                key={id}
                className={b()}
                onMouseLeave={this._onMouseLeave}
                onMouseEnter={this._onMouseEnter}
                onClick={this._onClick}
                {...linkProps}
            >
                <Image className={b('image')} {...imageProps}/>
                <p className={b('title')}>
                    {isLive && <Icon className={b('live')} {...iconLiveProps} />}
                    {` ${title}`}
                </p>
                <p className={b('meta')}>
                    <span className={b('date')}>
                        {date},
                    </span>
                    {time}
                    <span className={b('bull')} >
                        &bull;
                    </span>
                    {channel}
                </p>
            </Link>
        );
    }

    /**
     * @private
     */
    _onMouseEnter() {
        this.setState({
            hovered: true,
        });
    }

    /**
     * @private
     */
    _onMouseLeave() {
        this.setState({
            hovered: false,
        });
    }

    /**
     * @private
     */
    _onClick() {
        const {genre, source} = this.props;

        Metrika.trackParams('TV_RECOMMENDED', {
            type: source,
            genre: genre || '',
        });
    }

}

RecommendedBottomItem.propTypes = {
    image: PropTypes.shape({
        sizes: PropTypes.object.isRequired,
    }).isRequired,
    isLive: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    channel: PropTypes.string.isRequired,
    genre: PropTypes.string,
    source: PropTypes.string,
};

module.exports = withReq(withI18n(RecommendedBottomItem));
