require('./Tableau.scss');

const b = require('app/www/libs/b')('tableau');

const _ = {
    mapValues: require('lodash/mapValues'),
    merge: require('lodash/merge'),
    toPairs: require('lodash/toPairs'),
};

const React = require('react');
const PropTypes = require('prop-types');
const Global = require('app/www/components/blocks/Global/Global');
const Popup = require('app/www/components/blocks/Popup/Popup');

const HOST = 'https://yastatic.net';
const PATH = '/tableau/tableau.html';

class Tableau extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this._onLoad = this._onLoad.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    componentDidMount() {
        const {owner} = this.props;

        owner.addEventListener('mouseenter', this._onMouseEnter);
        owner.addEventListener('mouseleave', this._onMouseLeave);
    }

    render() {
        if (!this.state.popup) {
            return null;
        }

        const {
            device,
            width,
            height,
            owner,
            popup,
        } = this.props;

        const tableauMods = {device};

        const popupProps = _.merge({}, popup, {
            owner,
            mods: {
                theme: 'normal',
            },
            directions: 'bottom-left',
            tail: {
                offset: {
                    left: owner.offsetWidth / 2,
                },
            },
        });

        const iframeAttrs = {
            src: this._buildUrl(),
            frameBorder: 0,
            allowTransparency: true,
            scrolling: 'no',
            style: {
                width: `${width}px`,
                height: `${height}px`,
            },
        };

        return (
            <Popup
                ref={ref => (this._popup = ref)}
                className={b(tableauMods)}
                onMouseLeave={this._onMouseLeave}
                {...popupProps}
            >
                <iframe
                    ref={ref => (this.iframe = ref)}
                    className={b('content')}
                    onLoad={this._onLoad}
                    {...iframeAttrs}
                />
            </Popup>
        );
    }

    /**
     * Строим урл для iframe
     * @returns {String}
     * @private
     */
    _buildUrl() {
        const {path} = this.props;

        return `${HOST}${path || this._buildPath()}`;
    }

    /**
     * Строим path для iframe исходя из параметров
     * @returns {String}
     * @private
     */
    _buildPath() {
        const {device, target} = this.props;

        const params = _.mapValues(
            {
                'service-id': Global.id,
                preset: Global.tld,
                lang: Global.lang,
                domain: Global.tld,
                device: device,
                target: target,
            },
            encodeURIComponent
        );

        const query = _.toPairs(params)
            .map(param => param.join('='))
            .join('&');

        return `${PATH}?${query}`;
    }

    /**
     * @private
     */
    _onLoad() {
        this._hasIframeLoaded = true;
    }

    /**
     * Устанавливаем текст поискового запроса
     * Он будет подставлен в ссылки на сервисы
     * @param {String} text текст поискового запроса
     */
    setSearchText(text) {
        const message = JSON.stringify({
            fnName: 'updateUrls',
            fnArgs: [
                {
                    text: text,
                    serviceId: Global.id,
                },
            ],
        });

        this._hasIframeLoaded // eslint-disable-line no-unused-expressions
            ? this.iframe.contentWindow.postMessage(message, '*')
            : this.iframe.addEventListener('load', () => {
                this.iframe.contentWindow.postMessage(message, '*');
            });
    }

    /**
     * @private
     */
    _onMouseEnter() {
        if (!this.state.popup) {
            this.setState({
                popup: true,
            });
        } else {
            this._popup.open();

            const message = JSON.stringify({fnName: 'open'});
            this.iframe.contentWindow.postMessage(message, '*');
        }
    }

    /**
     * @param {Object} evt
     * @private
     */
    _onMouseLeave(evt) {
        if (!this._popup) {
            return;
        }

        const target = evt.relatedTarget;
        const hasClosest = target && Boolean(target.closest);

        if (!hasClosest || !target.closest(`.${b()}`)) {
            this._popup.close();
        }
    }

}

Tableau.defaultProps = {
    device: 'desktop',
    width: 370,
    height: 500,
    popup: {},
};

Tableau.propTypes = {
    path: PropTypes.string,
    device: PropTypes.oneOf(['desktop', 'touch-pad', 'touch-phone']),
    target: PropTypes.oneOf(['blank']),
    popup: PropTypes.object,
    width: PropTypes.number,
    height: PropTypes.number,
    owner: Popup.propTypes.owner,
};

module.exports = Tableau;
