const dataProvider = require('app/www/dataProviders/client/schedule');
const {
    SCHEDULE_GET,
    SCHEDULE_PROGRAM_ONLINE,
    SCHEDULE_CHUNK_RECEIVED,
    SCHEDULE_PROGRAM_TYPES_CHANGED,
} = require('app/www/constants/reducer-schedule');
const {userSettingsFallback} = require('app/www/actions/userSettingsFallback');

function getSchedule(params) {
    return dispatch => {
        return dataProvider.getSchedule(params).then(response => {
            dispatch({
                type: SCHEDULE_GET,
                payload: response,
            });

            dispatch(
                userSettingsFallback(response)
            );
        });
    };
}

/**
 * Получаем расписание, состоящее из передач идущих в данный момент
 * @param {Object} params
 */
function getOnlineSchedule(params) {
    return dispatch => {
        return dataProvider.getOnlineSchedule(params).then(response => {
            dispatch({
                type: SCHEDULE_PROGRAM_ONLINE,
                payload: response,
            });

            dispatch(
                userSettingsFallback(response)
            );
        });
    };
}

/**
 * Получаем чанк на главной странице
 * @param {Object} params
 */
function getChunk(params) {
    return dispatch => {
        return dataProvider.getChunk(params).then(chunk => {
            dispatch({
                type: SCHEDULE_CHUNK_RECEIVED,
                payload: chunk,
            });

            dispatch(
                userSettingsFallback(chunk)
            );
        });
    };
}

/**
 * Получаем чанк с нужной программой на главной странице
 * @param params
 */
function getChunksByProgramType(params) {
    return dispatch => {
        return dataProvider.getChunksByProgramType(params).then(chunk => {
            dispatch({
                type: SCHEDULE_CHUNK_RECEIVED,
                payload: chunk,
            });

            dispatch(
                userSettingsFallback(chunk)
            );
        });
    };
}

/**
 * Нажали на кнопку `programType` в фильтре
 * @param {String} programTypesAlias
 */
function changeProgramTypes(programTypesAlias) {
    return {
        type: SCHEDULE_PROGRAM_TYPES_CHANGED,
        payload: programTypesAlias,
    };
}

module.exports = {
    getSchedule,
    getChunk,
    getOnlineSchedule,
    getChunksByProgramType,
    changeProgramTypes,
};
