require('./GridEvent.scss');

const b = require('app/www/libs/b')('grid-event');

const React = require('react');
const PropTypes = require('prop-types');

const Metrika = require('app/www/libs/client/metrika');

const {withReq} = require('app/www/components/contexts/Req');

const Button_WannaSee = require('app/www/components/blocks/Button/_WannaSee/Button_WannaSee');
const Icon = require('app/www/components/blocks/Icon/Icon');
const Link = require('app/www/components/blocks/Link/Link');
const Ua = require('app/www/components/blocks/Ua/Ua');

class GridEvent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {};
        this._isSerpApp = this.props.req.lib('urlBuilder').isSerpApp;

        this._onClick = this._onClick.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    render() {
        const {
            req,
            event,
            onClick,
            selectedProgramTypes,
        } = this.props;

        const {
            id,
            url,
            live,
            title,
            start,
            program,
            activeType,
            hasFinished,
        } = event;

        const hasProgramType = selectedProgramTypes.length;

        if (Ua.isTouchPhone && hasProgramType && !activeType) {
            return null;
        }

        const iconLiveProps = {
            className: b('live'),
            mods: {
                hovered: id,
                live: true,
                size: 's',
            },
        };

        const buttonWannaSeeProps = {
            className: b('item-icon', {'wanna-see': true}),
            mods: {
                text: true,
                'wanna-see': true,
            },
            programId: program.id,
            isFavorite: program.favourite,
        };

        const linkProps = {
            url,
            onMouseEnter: this._onMouseEnter,
            onMouseLeave: this._onMouseLeave,
            onClick,
        };

        return (
            <li
                key={id}
                className={b({
                    match: Boolean(activeType),
                    type: !Ua.isTouchPhone && activeType,
                    past: hasFinished,
                })}
                onClick={this._onClick}
            >
                <Link className={b('item-link')} {...linkProps}>
                    <time className={b('item-time')}>
                        {req.lib('moment').getFormattedDate('HH:mm', start)}
                    </time>
                    <h3 className={b('item-title')}>
                        <div className={b('item-title-wrap')}>{title}</div>
                        <div className={b('icons-wrapper')}>
                            <span className={b('icons-divider')} />
                            {live && <Icon {...iconLiveProps} />}
                            {!this._isSerpApp && (
                                <Button_WannaSee {...buttonWannaSeeProps} />
                            )}
                        </div>
                    </h3>
                </Link>
            </li>
        );
    }

    /**
     * Передаем в обработчик объект evt, id события и id программы
     * @private
     */
    _onMouseEnter(evt) {
        const {
            id,
            program,
        } = this.props.event;

        if (!Ua.isTouchPhone && !this._isSerpApp) {
            this.props.onMouseEnter(evt, id, program.coId);
        }
    }

    /**
     * @param {Number} eventId id события
     * @returns {Object}
     * @private
     */
    _onMouseLeave(evt) {
        if (!Ua.isTouchPhone) {
            this.props.onMouseLeave(evt);
        }
    }

    /**
     * @private
     */
    _onClick() {
        const {
            id,
            program,
        } = this.props.event;

        Metrika.trackParams('TV_EVENT', {
            genre: program.type.alias,
            programId: id,
        });
    }
}

GridEvent.defaultProps = {
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onClick: () => {},
};

GridEvent.propTypes = {
    event: PropTypes.shape({
        url: PropTypes.string,
        title: PropTypes.string,
        start: PropTypes.string,
        live: PropTypes.bool,
        hasFinished: PropTypes.bool,
        program: PropTypes.shape({
            id: PropTypes.number,
            favourite: PropTypes.bool,
        }),
    }).isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
};

module.exports = withReq(GridEvent);
