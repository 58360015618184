const {createStore, applyMiddleware, compose} = require('redux');
const thunkMiddleware = require('redux-thunk').default;
const {promiseMiddleware} = require('redux-promise');
const {createLogger} = require('redux-logger');
const env = require('app/common/libs/env');
const reducers = require('app/www/reducers');

const isClient = typeof window !== 'undefined';

function configureStore(initialState) {
    const middlewares = [
        thunkMiddleware,
        promiseMiddleware,
        env.IS_DEVELOP && createLogger(),
    ].filter(Boolean);

    const storeInstance = createStore(
        reducers,
        initialState,
        compose(
            applyMiddleware.apply(applyMiddleware, middlewares),
            isClient && window.devToolsExtension ? window.devToolsExtension() : f => f
        )
    );

    if (module.hot) {
        module.hot.accept('app/www/reducers', () => {
            const nextRootReducer = require('app/www/reducers');
            storeInstance.replaceReducer(nextRootReducer);
        });
    }

    return storeInstance;
}

module.exports = configureStore;
