const dataProvider = require('app/www/dataProviders/client/reminders');
const dataProviderFulfill = require('app/www/dataProviders/client/event');
const {
    REMINDER_ADD,
    REMINDER_DEL,
    REMINDER_ADD_SESSION,
    REMINDER_DEL_SESSION,
    REMINDER_OPEN_POPUP,
    REMINDER_CLOSE_POPUP,
    REMINDERS_UPDATE,
    REMINDER_FUL_FILLED,
} = require('app/www/constants/reducerReminders');
const {userSettingsFallback} = require('app/www/actions/userSettingsFallback');
const {asyncActionError} = require('app/www/actions/asyncActionError');

const cookies = require('app/www/constants/sessionCookies');

function add(eventId) {
    return dispatch => {
        return dataProvider.add(eventId).then(success => {
            dispatch({
                type: REMINDER_ADD,
                payload: {
                    eventId,
                    success,
                },
            });
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

function del(eventId) {
    return dispatch => {
        return dataProvider.del(eventId).then(success => {
            dispatch({
                type: REMINDER_DEL,
                payload: {
                    eventId,
                    success,
                },
            });
        })
        .catch(error => {
            dispatch(asyncActionError(error));
        });
    };
}


function fulfilled(eventIds) {
    return dispatch => {
        return dataProviderFulfill.getEvents(eventIds).then(response => {
            dispatch({
                type: REMINDER_FUL_FILLED,
                payload: response.events,
            });

            dispatch(
                userSettingsFallback(response)
            );
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

/**
 * Сохраняем напоминалку в сесионную cookie, чтобы при логине пользователя или подтверждении телефона её выставить в базу
 * @param {Number} eventId
 * @returns {Object}
 */
function addSession(eventId) {
    document.cookie = `${cookies.REMINDER}=add-${eventId}; path=/;`;

    return {
        type: REMINDER_ADD_SESSION,
        payload: {
            eventId,
            success: document.cookie.includes(cookies.REMINDER),
        },
    };
}

/**
 * Удаляем напоминалку из сесионной куки
 * @param {Number} eventId
 * @returns {Object}
 */
function delSession(eventId) {
    if (document.cookie.includes(cookies.REMINDER)) {
        document.cookie = `${cookies.REMINDER}=del-${eventId}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    return {
        type: REMINDER_DEL_SESSION,
        payload: {
            eventId,
            success: !document.cookie.includes(cookies.REMINDER),
        },
    };
}

function openRemindersPopup() {
    return {
        type: REMINDER_OPEN_POPUP,
        payload: true,
    };
}

function closeRemindersPopup() {
    return {
        type: REMINDER_CLOSE_POPUP,
        payload: false,
    };
}

function update(eventIds) {
    return {
        type: REMINDERS_UPDATE,
        payload: {
            eventIds,
        },
    };
}

module.exports = {
    add,
    del,
    fulfilled,
    addSession,
    delSession,
    openRemindersPopup,
    closeRemindersPopup,
    update,
};
