const {ON_AIR_VH_GET} = require('app/www/constants/reducerOnAir');

function reducer_OnAir(state = {}, action) {
    switch (action.type) {
        case ON_AIR_VH_GET:
            state = Object.assign({}, action.payload);
            break;
    }

    return state;
}

module.exports = reducer_OnAir;
