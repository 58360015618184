require('./MenuVert.scss');

const b = require('app/www/libs/b')('menu-vert');
const _ = {
    flattenDeep: require('lodash/flattenDeep'),
};

const React = require('react');

const MenuVert = (props) => {
    const children = _.flattenDeep(props.children).filter(Boolean);

    return (
        <div className={b()}>
            <ul className={b('items')}>
                {children.map((item, index) =>
                    item !== 'separator' && (
                        <li
                            key={index}
                            className={b('item', {separator: children[index + 1] === 'separator'})}
                        >
                            {item}
                        </li>
                    )
                )}
            </ul>
        </div>
    );
};

module.exports = MenuVert;
