const {SCHEDULE_SETTINGS_CHANGED} = require('app/www/constants/reducerScheduleSettings');

function reducer_ScheduleSettings(state, action) {
    switch (action.type) {
        case SCHEDULE_SETTINGS_CHANGED:
            return Object.assign({}, state, action.payload);
        default:
            return state || {};
    }
}

module.exports = reducer_ScheduleSettings;
