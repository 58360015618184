const _get = require('lodash/get');
const _trimStart = require('lodash/trimStart');
const path = require('path');
const config = require('app/common/libs/configLoader');
const {APP, NODE_PATH} = require('app/common/libs/env');
const {ADV_TYPE_ALL} = require('app/www/constants/adv-type');

/**
 * Получаем значение из конфига по ключу
 * @param {String} key ключ
 * @param {*} [defaultValue] дефолтное значение
 * @returns {*} Значение из конфига или дефолтное значение. Или undefine, если значение все-равно не найдено
 */
function get(key, defaultValue) {
    return _get(config, key, defaultValue);
}

const methods = {

    toJSON() {
        return config;
    },

    /**
     * Получаем значение из объекта `app`
     * @param {String} key
     * @returns {*}
     */
    getApp(key) {
        return get(`app.${key}`, null);
    },

    getBackend(key) {
        return get(`backends.${key}`, null);
    },

    getAppPort() {
        return methods.getApp('port');
    },

    get appLogger() {
        return methods.getApp('logger');
    },

    /**
     * Урлы контроллера
     * @param {String} key
     * @param {Boolean} [isClient] На клиенте конструкции вида `([\\d]+)` не нужны, убираем
     * @returns {*}
     */
    getControllerRoute(key, isClient) {
        const route = methods.getApp(`routes.${key}`);

        return (typeof route === 'string' && isClient)
            ? _trimStart(route.replace('([\\d]+)', '').replace('([\\d]+)', ''))
            : route;
    },

    /**
     * Получаем хост бекенда
     * @returns {String}
     */
    get backendHost() {
        return methods.getBackend('tv');
    },

    /**
     * Получаем хост бекенда
     * @returns {String}
     */
    get backendVersion() {
        return 'v8';
    },

    get geobaseHost() {
        return methods.getBackend('geobase');
    },

    get uatraitsHost() {
        return methods.getBackend('uatraits');
    },

    get langdetectHost() {
        return methods.getBackend('langdetect');
    },

    /**
     * @returns {String}
     */
    get blackboxHost() {
        return methods.getBackend('blackbox');
    },

    getBuildDir() {
        return path.join(NODE_PATH, '.build');
    },

    /**
     * Полный путь до CSS/JS-ок на static-сервере
     * @returns {String}
     */
    getStaticUrl() {
        return methods.getBackend('static').url;
    },

    /**
     * Полный путь до физических файлов на сервере
     * @returns {String}
     */
    getStaticDir() {
        return path.join(NODE_PATH, methods.getBackend('static').dir);
    },

    get staticPublicPath() {
        return methods.getBackend('static').public;
    },

    getStaticAssetsDir() {
        return path.join(methods.getStaticDir(), 'assets');
    },

    /**
     * @param {String} [tld]
     * @returns {String}
     */
    getPassportHost(tld) {
        tld = tld || 'ru';
        return methods.getBackend('passport').replace('{tld}', tld);
    },

    /**
     * @param {String} [tld]
     * @returns {String}
     */
    getLangHost(tld) {
        tld = tld || 'ru';
        return methods.getBackend('lang').replace('{tld}', tld);
    },

    /**
     * @returns {String}
     */
    getSocialHost() {
        return methods.getBackend('social');
    },

    /**
     * @returns {String}
     */
    get phoneHost() {
        return methods.getBackend('phone');
    },

    /**
     * @returns {String}
     */
    get serviceId() {
        return 'tv';
    },

    /**
     * @param {String} tld
     * @param {String} lang
     * @param {Number} regionId
     * @returns {String}
     */
    getSuggestHost(tld, lang, regionId) {
        const isSuggestUK = methods.isUkraineLang(lang) && methods.isUkraineRegion(regionId);
        const langId = isSuggestUK ? 'uk' : 'ru';
        const locale = isSuggestUK ? '-uk' : '';

        return methods.getBackend('suggest')
            .replace('{tld}', tld)
            .replace('{lang}', langId)
            .replace('{locale}', locale);
    },

    /**
     * @param {String} tld
     * @returns {String}
     */
    getTuneHost(tld) {
        return methods.getBackend('tune').replace('{tld}', tld);
    },

    /**
     * @returns {String}
     */
    getAvatarsHost() {
        return methods.getBackend('avatars');
    },

    /**
     * @returns {String}
     */
    get bunkerHost() {
        return methods.getBackend('bunker');
    },

    /**
     * Соль для генерации ключа X-Uid для хождения в бекенд tv
     * @returns {String|Number}
     */
    get apiSalt() {
        return get('api.salt');
    },

    /**
     * @returns {Object}
     */
    get apiHeaders() {
        return get('api.headers');
    },

    /**
     * @returns {String}
     */
    get apiClientId() {
        return get('api.client-id');
    },

    /**
     * Возвращает Id блока рекламы с учетом эксперимента
     * @param {String} key название ключа в конфиге
     * @param {String} [group] - название группы для эксперимента
     * @param {Object} [advMap] - advMap объект ключ id рекламы
     * @returns {String|Array}
     */
    getAdv(key, group = ADV_TYPE_ALL, advMap = get('adv')) {
        const value = advMap[key];

        if (typeof value === 'string' || Array.isArray(value)) {
            return value;
        }

        return value[group];
    },

    /**
     * @returns {Object}
     */
    getAdvMap() {
        return get('adv');
    },

    /**
     * @returns {Object}
     */
    getAdFoxMap() {
        return get('adFox');
    },

    /**
     * @returns {Object}
     */
    getI18n(key) {
        return get(`i18n.${key}`);
    },

    /**
     * @returns {Object}
     */
    getMetrika(key = APP) {
        return get(`metrika.${key}`);
    },

    /**
     * Получаем константы для direct
     * @param {String} key
     * @returns {*}
     */
    getDirect(key) {
        return get(`direct.${key}`);
    },

    /**
     * Количество каналов на странице в расписании
     * @returns {Number}
     * @link https://st.yandex-team.ru/TVFRONT-2425
     * @link https://st.yandex-team.ru/TVFRONT-2918
     */
    getChannelLimit(...args) {
        return get('channelLimit').apply(null, args);
    },

    /**
     * Соль для формирования секретного ключа
     * @returns {String}
     */
    getSecretKeySalt() {
        return methods.getApp('secretKeySalt');
    },

    /**
     * Regions
     * @param {String} key
     * @returns {Number}
     */
    getRegionIdByName(key) {
        return get(`regions.${key}`);
    },

    /**
     * Показывать ли информацию об ошибке
     * @returns {Boolean}
     */
    get showErrors() {
        return methods.getApp('showErrors') || false;
    },

    /**
     * id русского языка
     * @returns {String}
     */
    get russianLang() {
        return get('russia.langId');
    },

    /**
     * tld России
     * @returns {String}
     */
    get russianTld() {
        return get('russia.tld');
    },

    /**
     * Определение региона Украины
     * @param {Number} regionId
     * @returns {Boolean}
     */
    isUkraineRegion(regionId) {
        return get('ukraine.regionId') === Number(regionId);
    },

    /**
     * id украинского языка
     * @returns {String}
     */
    get ukraineLang() {
        return get('ukraine.langId');
    },

    /**
     * Определение языка Украины
     * @param {String} langId
     * @returns {Boolean}
     */
    isUkraineLang(langId) {
        return methods.ukraineLang === langId;
    },

    /**
     * id kazakhstan языка
     * @returns {String}
     */
    get kazakhstanLang() {
        return get('kazakhstan.langId');
    },

    /**
     * tld kazakhstan
     * @returns {String}
     */
    get kazakhstanTld() {
        return get('kazakhstan.tld');
    },

    /**
     * Определение региона Kazakhstan
     * @param {Number} regionId
     * @returns {Boolean}
     */
    isKazakhstanRegion(regionId) {
        return get('kazakhstan.regionId') === Number(regionId);
    },

    /**
     * Определение языка kazakhstan
     * @param {String} langId
     * @returns {Boolean}
     */
    isKazakhstanLang(langId) {
        return methods.kazakhstanLang === langId;
    },

    /**
     * Определение tld Kazakhstan
     * @param {String} tld
     * @returns {Boolean}
     */
    isKazakhstanTld(tld) {
        return methods.kazakhstanTld === tld || tld === 'kk';
    },

    /**
     * id uzbekistan языка
     * @returns {String}
     */
    get uzbekistanLang() {
        return get('uzbekistan.langId');
    },

    /**
     * tld uzbekistan
     * @returns {String}
     */
    get uzbekistanTld() {
        return get('uzbekistan.tld');
    },

    /**
     * Определение региона uzbekistan
     * @param {Number} regionId
     * @returns {Boolean}
     */
    isUzbekistanRegion(regionId) {
        return get('uzbekistan.regionId') === Number(regionId);
    },

    /**
     * Определение языка uzbekistan
     * @param {String} langId
     * @returns {Boolean}
     */
    isUzbekistanLang(langId) {
        return methods.uzbekistanLang === langId;
    },

    /**
     * Определение tld uzbekistan
     * @param {String} tld
     * @returns {Boolean}
     */
    isUzbekistanTld(tld) {
        return methods.uzbekistanTld === tld;
    },

    getService(key) {
        return get(`services.${key}`, null);
    },

    /**
     * tld Украины
     * @returns {String}
     */
    get ukraineTld() {
        return get('ukraine.tld');
    },

    /**
     * Region id Moscow
     * @returns {Number}
     */
    get moscow() {
        return get('regions.moscow');
    },

    /**
     * Определение tld Украины
     * @param {String} tld
     * @returns {Boolean}
     */
    isUkraineTld(tld) {
        return methods.ukraineTld === tld;
    },

    /**
     * @param {string} tld
     * @return {string}
     */
    getMailApiHost(tld) {
        return methods.getService('mail.host').replace('$tld', tld);
    },

    /**
     * @param {string} tld
     * @param {string} [service]
     * @return {string}
     */
    getMailApi(tld, service = methods.serviceId) {
        const hostname = methods.getMailApiHost(tld);
        const pathname = methods.getService('mail.pathname').replace('$caller', service);

        return `${hostname}${pathname}`;
    },

    /**
     * @returns {Object}
     */
    get myCookie() {
        return get('myCookie');
    },

    /**
     * @returns {String}
     */
    get broadcastingPlayerHost() {
        return get('broadcastingPlayer.host');
    },

    /**
     * @returns {String}
     */
    broadcastingPlayerParam(key) {
        return get(`broadcastingPlayer.params.${key}`);
    },

    /**
     * Параметр use_test для саджеста
     * При use_test=1 ссылки ведут на testing.tv.yandex.ru
     * @returns {1|undefined}
     */
    get suggestUseTest() {
        return get('suggestUseTest');
    },

    /**
     * @returns {String}
     */
    get rumCounterHost() {
        return get('rumCounter.host');
    },

    /**
     * @returns {String}
     */
    get hdUrl() {
        return get('hd');
    },

};

module.exports = methods;
