require('../../Icon/_Size/Icon_Size_S.scss');
require('../../Icon/_FavoriteChannel/_Size/Icon_FavoriteChannel_Size_S.scss');

const _merge = require('lodash/merge');
const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');
const {addFavoriteChannel, delFavoriteChannel} = require('app/www/actions/favoriteChannels');

const nextTick = require('app/www/libs/nextTick');
const Metrika = require('app/www/libs/client/metrika');

const {withI18n} = require('app/www/components/contexts/I18n');

const Button = require('app/www/components/blocks/Button/Button');
const ButtonWrapper = require('app/www/components/blocks/ButtonWrapper/ButtonWrapper');
const Tooltip = require('app/www/components/blocks/Tooltip/Tooltip');
const Ua = require('app/www/components/blocks/Ua/Ua');

const TOOLTIP = require('app/www/constants/tooltip');

class Button_FavoriteChannel extends React.PureComponent {

    static get defaultProps() {
        return {
            tooltip: {
                mods: {
                    theme: 'normal',
                    size: 'xs',
                },
                to: 'top',
            },
            icon: {
                'favorite-channel': true,
                active: false,
            },
            onChange: () => {},
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            isFavorite: props.isFavorite,
            tooltip: false,
        };

        this._onClick = this._onClick.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._openTooltip = this._openTooltip.bind(this);
    }

    static getDerivedStateFromProps(props) {
        return {
            isFavorite: props.isFavorite,
        };
    }

    render() {
        const {i18n, mods, icon} = this.props;
        const tooltipProps = Object.assign({}, this.props.tooltip, {
            children: !this.state.isFavorite
                ? i18n.get('button.favoriteChannel.tooltip.add')
                : i18n.get('button.favoriteChannel.tooltip.del'),
        });

        const buttonProps = _merge({}, this.props, {
            onClick: this._onClick,
            onMouseEnter: this._onMouseEnter,
            onMouseLeave: this._onMouseLeave,
            mods: Object.assign({}, mods, {
                'favorite-channel': true,
                active: this.state.isFavorite,
            }),
            icon: Object.assign({}, icon, {
                'favorite-channel': true,
                active: this.state.isFavorite,
            }),
            children: this.state.isFavorite
                ? i18n.get('button.favoriteChannel.button.del')
                : i18n.get('button.favoriteChannel.button.add'),
        });

        return (
            <ButtonWrapper>
                <Button
                    {...buttonProps}
                    ref={elem => (this.button = elem)}
                />
                {this.state.tooltip &&
                    <Tooltip
                        {...tooltipProps}
                        ref={elem => (this.tooltip = elem)}
                        owner={this.button.node}
                    />
                }
            </ButtonWrapper>
        );
    }

    _onClick() {
        const {isFavorite} = this.state;

        this._closeTooltip();

        this.props[isFavorite ? 'dispatchDel' : 'dispatchAdd']();
        this.props.onChange(!isFavorite);

        Metrika.trackParams('LIKES_FOR_CHANNELS', {
            channelId: this.props.channelId,
            isFavorite,
        });
    }

    _onMouseEnter() {
        this._timeout = setTimeout(this._openTooltip, TOOLTIP.DELAY);
    }

    _onMouseLeave() {
        clearTimeout(this._timeout);
        this._closeTooltip();
    }

    _openTooltip() {
        if (Ua.isTouch) {
            return;
        }

        this.setState({
            tooltip: true,
        });

        nextTick(() => this.tooltip.open());
    }

    _closeTooltip() {
        if (Ua.isTouch) {
            return;
        }

        if (this.tooltip) {
            this.tooltip.close();
        }
    }

}

Button_FavoriteChannel.propTypes = {
    channelId: PropTypes.number.isRequired,
    mods: Button.propTypes.mods,
    tooltip: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            mods: Tooltip.propTypes.mods,
            to: PropTypes.string,
        }),
    ]),
    icon: PropTypes.shape({
        'favorite-channel': PropTypes.bool,
        active: PropTypes.bool,
    }),
    onChange: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        isFavorite: state.favoriteChannels.includes(ownProps.channelId),
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatchAdd: () => dispatch(addFavoriteChannel(ownProps.channelId)),
        dispatchDel: () => dispatch(delFavoriteChannel(ownProps.channelId)),
    };
}

module.exports = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withI18n(
        Button_FavoriteChannel
    )
);
