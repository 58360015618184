/**
 * Кроссбраузерный nextTick
 * @returns {Object}
 * @private
 */
module.exports = (() => {
    const isClient = typeof window !== 'undefined';
    const canSetImmediate = isClient && window.setImmediate;
    const canPost = isClient && window.postMessage;

    if (canSetImmediate) {
        return f => {
            return window.setImmediate(f);
        };
    }

    if (canPost) {
        const queue = [];

        window.addEventListener('message', evt => {
            const source = evt.source;

            if ((source === window || source === null) && evt.data === 'process-tick') {
                evt.stopPropagation();

                if (queue.length > 0) {
                    const fn = queue.shift();

                    fn();
                }
            }
        }, true);

        return fn => {
            queue.push(fn);

            window.postMessage('process-tick', '*');
        };
    }

    return fn => {
        setTimeout(fn, 0);
    };
})();
