const _ = {
    has: require('lodash/has'),
    flatten: require('lodash/flatten'),
    isEmpty: require('lodash/isEmpty'),
};
const React = require('react');
const PropTypes = require('prop-types');

const Global = require('app/www/components/blocks/Global/Global');
const NoScript = require('app/www/components/blocks/NoScript/NoScript');

class Metrika extends React.PureComponent {

    constructor(props) {
        super(props);

        if (props.isExecutable) {
            Metrika.props = Object.assign({}, this.props, {
                params: this._params,
            });

            Metrika.queue = {
                params: [],
                reachGoal: [],
                hit: [],
            };
        }
    }

    render() {
        if (Metrika.hasLoaded) {
            this._counter = this._counter || new Ya.Metrika(this._params);

            if (this.props.isExecutable && !Metrika.hasCounter) {
                this._setCounter();

                if (this.props.trackInitialHashParams) {
                    this._trackParamsFromHash();
                }

                if (this.props.trackParamsOnHashChange) {
                    window.addEventListener('hashchange', () => this._trackParamsFromHash);
                }

                if (this.props.hitOnPopstate) {
                    window.addEventListener('popchange', () => Metrika.hit);
                }
            }
        }

        const divAttrs = {
            style: {
                display: 'none',
            },
        };

        const imgAttrs = {
            src: `//mc.yandex.ru/watch/${this._params.id}`,
            alt: '',
        };

        return (
            <NoScript {...divAttrs}>
                <img {...imgAttrs} />
            </NoScript>
        );
    }

    /**
     * Если мы не передадим в `props` параметры метрики — попробуем получить из `global`
     * @returns {Object}
     * @private
     */
    get _params() {
        return !_.isEmpty(this.props.params)
            ? this.props.params
            : Global.metrika;
    }

    /**
     * Передаем все незагруженые `params` в параметры счетчика и логгируем их, инициализируем счетчик
     * Выполняем все `hit` и `reachGoal`
     * @private
     */
    _setCounter() {
        Metrika.counter = this._counter;

        // Выполняем все `reachGoal` из очереди
        // А параметры только логгируем, т.к. мы их передали в `params` нового объекта метрики
        ['params', 'reachGoal'].forEach(method => {
            Metrika.queue[method].forEach(data => {
                switch (method) {
                    case 'hit':
                    case 'reachGoal':
                    case 'params':
                        execute(method, data);
                        break;
                }
            });
        });

        this.props.onLoad(Metrika.counter);
    }

    /**
     * Выполняем параметры из хеша, если это возможно
     * @private
     */
    _trackParamsFromHash() {
        const hash = location.hash;
        let paramsFromHash;

        if (hash) {
            try {
                paramsFromHash = JSON.parse(decodeURIComponent(
                    hash.match(/metrika=([^&]+)/)[1]
                ));

                if (window.history && window.history.replaceState) {
                    window.history.replaceState('', document.title, location.pathname + location.search);
                } else {
                    document.location.hash = '';
                }
            } catch (ex) {}
        }

        if (paramsFromHash) {
            paramsFromHash = _.flatten([paramsFromHash]);
            paramsFromHash.forEach(params => {
                Metrika.params(params);
            });
        }
    }

    /**
     * @returns {boolean}
     * @private
     */
    static get _isClient() {
        return typeof window !== 'undefined';
    }

    /**
     * Returns metrika object
     * @return {Function}
     */
    static get counter() {
        return window[`yaCounter${Metrika.props.params.id}`];
    }

    /**
     * @param {Function} counter Ya.Metrika class
     */
    static set counter(counter) {
        window[`yaCounter${Metrika.props.params.id}`] = counter;
    }

    /**
     * @returns {Boolean}
     */
    static get hasLoaded() {
        return Metrika._isClient && _.has(window, 'Ya.Metrika');
    }

    /**
     * @returns {Boolean}
     */
    static get hasCounter() {
        return Metrika.hasLoaded && Boolean(Metrika.counter);
    }

    /**
     * @param {Object} params
     * @return {Boolean}
     * @link https://yandex.ru/support/metrika/objects/params-method.xml
     */
    static params(params) {
        return execute('params', params);
    }

    /**
     * @param {String} [url] текущей страницы
     * @param {Object} options
     * @return {Boolean}
     * @link https://yandex.ru/support/metrika/objects/hit.xml
     */
    static hit(url = document.location.pathname, options) {
        return execute('hit', [
            url, Object.assign({
                title: document.title,
                referer: document.referrer,
            }, options),
        ]);
    }

    /**
     * @param {String} target
     * @return {Boolean}
     * @link https://yandex.ru/support/metrika/objects/reachgoal.xml
     */
    static reachGoal(target) {
        return execute('reachGoal', target);
    }

    /**
     * Формируем строку вида `#metrika={"hello": "world"}` при data={hello: 'world'}
     * @param {*} data
     * @returns {string}
     */
    static makeHash(data) {
        return `#metrika=${JSON.stringify(data)}`;
    }

}

/**
 * Выполняем метод если метрика загружена или добавляем в очередь на выполнение
 * @param {String} method params|reachGoal|hit
 * @param {*} params
 * @return {Boolean}
 */
function execute(method, params) {
    if (Metrika.hasCounter) {
        Metrika.counter[method].apply(null, _.flatten([params]));
        log(method, params);

        return true;
    }
    switch (method) {
        case 'params':
        case 'reachGoal':
        case 'hit':
            Metrika.queue[method].push(params);
            break;
    }

    return false;

}

/**
 * Logging metrika params, hits, goals if enabled
 * @param {String} method params|reachGoal|hit
 * @param {*} data
 */
function log(method, data) {
    if (Metrika.props.log) {
        console.log(`Metrika ${Metrika.props.params.id}:`, `[${method}]`, data); // eslint-disable-line no-console
    }
}

Metrika.defaultProps = {
    params: {},
    log: false,
    trackInitialHashParams: false,
    trackParamsOnHashChange: false,
    hitOnPopstate: false,
    isExecutable: true,
    onLoad: () => {},
    onError: () => {},
};

Metrika.propTypes = {
    params: PropTypes.object,
    log: PropTypes.bool,
    trackInitialHashParams: PropTypes.bool,
    trackParamsOnHashChange: PropTypes.bool,
    hitOnPopstate: PropTypes.bool,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

module.exports = Metrika;
