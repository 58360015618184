module.exports = {
    main: {
        base: '/:regionId(\\d+)?',
        api: {
            base: '/api/:regionId(\\d+)',
            chunk: '/api/:regionId(\\d+)/main/chunk',
            onlineSchedule: '/api/:regionId(\\d+)/main/onlineSchedule',
            chunksByProgramType: '/api/:regionId(\\d+)/main/chunksByProgramType',
        },
    },
    event: {
        api: {
            base: '/api/:regionId(\\d+)/event',
            all: '/api/:regionId(\\d+)/events',
        },
    },
    program: {
        api: {
            base: '/api/:regionId(\\d+)/program/:programId(\\d+)',
        },
        // @todo to:aleksbasko https://st.yandex-team.ru/TVFRONT-6848
        base: '/:regionId(\\d+)?/program/(:programTitle([\\0-9A-Za-zА-ЯЁIЇҐЄЂЃЀЅЍЈЉЊЋЌЎӘҒҚҢӨҰҮҺа-яёіїґєђѓѐѕѝјљњћќўәғқңөұүһ]+-))?:programId(\\d+)',
        event: '/:regionId(\\d+)?/program/(:programTitle([\\0-9A-Za-zА-ЯЁIЇҐЄЂЃЀЅЍЈЉЊЋЌЎӘҒҚҢӨҰҮҺа-яёіїґєђѓѐѕѝјљњћќўәғқңөұүһ]+-))?:programId(\\d+)/event/:eventId(\\d+)',
    },
    recommended: {
        api: {
            now: '/api/:regionId(\\d+)/recommended/now',
            editorial: '/api/:regionId(\\d+)/recommended/editorial',
            provider: '/api/:regionId(\\d+)/recommended/provider',
        },
    },
    onAir: {
        api: {
            vh: '/api/:regionId(\\d+)/vhOnAir',
            tv: '/api/:regionId(\\d+)/onAir',
            vhr: '/api/:regionId(\\d+)/RecommendedVhOnAir',
        },
    },
    channel: {
        api: {
            schedule: '/api/:regionId(\\d+)/channel/(:channelEngName([\\w-]+-))?:channelId(\\d+)/schedule',
            favoriteChannels: '/api/:regionId(\\d+)/channels',
        },
        base: '/:regionId(\\d+)?/channel/(:channelEngName([\\w-]+-))?:channelId(\\d+)',
        baseOld: '/:regionId(\\d+)?/channels/(:channelEngName([\\w-]+-))?:channelId(\\d+)',
        list: '/:regionId(\\d+)?/channels',
    },
    stream: {
        base: '/:regionId(\\d+)?/channel/(:channelEngName([\\w-]+-))?:channelId(\\d+)?/stream',
        base_old: '/:regionId(\\d+)?/channels/(:channelEngName([\\w-]+-))?:channelId(\\d+)?/stream',
    },
    online: {
        base: '/online',
    },
    player: {
        base: '/:regionId(\\d+)?/channels/:channelId(\\d+)?/player',
    },
    search: {
        base: '/:regionId(\\d+)?/search',
        api: {
            base: '/api/:regionId(\\d+)?/search',
        },
    },
    tune: {
        base: '/:regionId(\\d+)?/tune',
    },
    user: {
        base: '/user',
        api: {
            phone: '/api/:regionId(\\d+)/user/phone',
            favoriteChannels: '/api/:regionId(\\d+)/user/favoriteChannels',
            reminders: '/api/:regionId(\\d+)/user/reminders',
            wannaSee: '/api/:regionId(\\d+)?/user/wanna-see',
            remindersSettingsPopup: '/api/:regionId(\\d+)?/user/remindersSettingsPopup',
            scheduleSettings: '/api/:regionId(\\d+)/user/scheduleSettings',
        },
    },
    favorites: {
        base: '/:regionId(\\d+)?/my/favorites',
        api: {
            base: '/api/:regionId(\\d+)?/my/favorites',
        },
    },
    sk: {
        api: {
            base: '/api/sk',
        },
    },
    regular: {
        base: '/:regionId(\\d+)?/lists/:urlSegment',
    },
    specials: {
        base: '/special/:special',
    },
    templateProject: {
        base: '/:regionId(\\d+)?/sport/:urlSegment',
        api: {
            base: '/api/:regionId(\\d+)?/sport/:urlSegment',
        },
    },
    sport: {
        api: {
            event: '/api/sport/event/:eventId',
        },
    },
    ott: {
        api: {
            sections: '/api/ott/sections',
        },
    },
    broadcast: {
        base: '/broadcast.xml',
    },
    static: {
        assetlinks: '/.well-known/assetlinks.json',
        robots: '/robots.txt',
        favicons: /^\/([^/]+)\.(png|ico)$/,
        homescreen: /^\/([^/]+)(x\.png|\.json|\.js)$/,
        avatarsFast: '/tv-message-fast/',
        avatarsMds: '/tv-message-mds/',
        root: /^\/google(.*)\.html$/,
    },
};
