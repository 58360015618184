require('../Placeholder.scss');
require('./Placeholder_Grid.scss');

const _ = {
    range: require('lodash/range'),
};

const b = require('app/www/libs/b')('placeholder', true);

const React = require('react');
const PropTypes = require('prop-types');

const COUNT_PLACEHOLDER_GRID = 3;
const COUNT_LINES_ITERATION = 2;

const Placeholder_Grid = (props) => {
    const {
        mods,
        count = COUNT_PLACEHOLDER_GRID,
        className,
    } = props;

    const items = _.range(count);

    return (
        <ul>
            {items.map(item => {
                return (
                    <li className={b.mix(className, mods)} key={item}>
                        <div className={b('header-line')} />
                        {_.range(COUNT_LINES_ITERATION).map(index => {
                            return (
                                <div key={index}>
                                    <div className={b('long-line')} />
                                    <div className={b('short-line')} />
                                </div>
                            );
                        })}
                        <div className={b('long-line')} />
                    </li>
                );
            })}
        </ul>
    );
};

Placeholder_Grid.propTypes = {
    count: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
};

module.exports = Placeholder_Grid;
