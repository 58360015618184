const React = require('react');
const ExperimentsContext = React.createContext();

module.exports = {
    ExperimentsContext,
    withExperiments: Component => props => (
        <ExperimentsContext.Consumer>
            {experiments => <Component {...props} experiments={experiments} />}
        </ExperimentsContext.Consumer>
    ),
};
