const {
    SUPER_BANNER_OPEN,
} = require('app/www/constants/superBanner');

function reducer_SuperBanner(state, action) {
    switch (action.type) {
        case SUPER_BANNER_OPEN:
            state = action.payload;
            break;
        default:
            state = state || false;
            break;
    }

    return state;
}

module.exports = reducer_SuperBanner;
