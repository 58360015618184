const dataProvider = require('app/www/dataProviders/client/favoriteChannels');
const {
    FAVORITE_CHANNEL_ADD,
    FAVORITE_CHANNEL_DEL,
    FAVORITE_CHANNELS_SAVE,
    FAVORITE_CHANNELS_UPDATE,
} = require('app/www/constants/reducerFavoriteChannels');
const {asyncActionError} = require('app/www/actions/asyncActionError');

function addFavoriteChannel(channelId) {
    return dispatch => {
        dataProvider.add(channelId).then(channelIds => {
            dispatch({
                type: FAVORITE_CHANNEL_ADD,
                payload: channelIds,
            });
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

function delFavoriteChannel(channelId) {
    return dispatch => {
        dataProvider.del(channelId).then(channelIds => {
            dispatch({
                type: FAVORITE_CHANNEL_DEL,
                payload: channelIds,
            });
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

function saveFavoriteChannels(channelIds) {
    return dispatch => {
        dataProvider.save(channelIds).then(channelsIds => {
            dispatch({
                type: FAVORITE_CHANNELS_SAVE,
                payload: channelsIds,
            });
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

function updateFavoriteChannels(channelIds) {
    return dispatch => {
        dispatch({
            type: FAVORITE_CHANNELS_UPDATE,
            payload: channelIds,
        });
    };
}

module.exports = {
    addFavoriteChannel,
    delFavoriteChannel,
    saveFavoriteChannels,
    updateFavoriteChannels,
};
