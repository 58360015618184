require('./CountryFlag.scss');
require('./_S16/CountryFlag_S16_By.scss');
require('./_S16/CountryFlag_S16_En.scss');
require('./_S16/CountryFlag_S16_Kz.scss');
require('./_S16/CountryFlag_S16_Ru.scss');
require('./_S16/CountryFlag_S16_Ua.scss');
require('./_S32/CountryFlag_S32_By.scss');
require('./_S32/CountryFlag_S32_En.scss');
require('./_S32/CountryFlag_S32_Kz.scss');
require('./_S32/CountryFlag_S32_Ru.scss');
require('./_S32/CountryFlag_S32_Ua.scss');
require('./_S64/CountryFlag_S64_By.scss');
require('./_S64/CountryFlag_S64_En.scss');
require('./_S64/CountryFlag_S64_Kz.scss');
require('./_S64/CountryFlag_S64_Ru.scss');
require('./_S64/CountryFlag_S64_Ua.scss');

const b = require('app/www/libs/b')('country-flag');

const React = require('react');
const PropTypes = require('prop-types');

const CountryFlag = (props) => {
    return <span className={b(props.mods)} />;
};

CountryFlag.propTypes = {
    mods: PropTypes.shape({
        s16: PropTypes.oneOf(['ru', 'ua', 'by', 'kz', 'en']),
        s32: PropTypes.oneOf(['ru', 'ua', 'by', 'kz', 'en']),
        s64: PropTypes.oneOf(['ru', 'ua', 'by', 'kz', 'en']),
    }).isRequired,
};

module.exports = CountryFlag;
