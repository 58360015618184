const {IS_DEVELOP} = require('app/common/libs/env');

const Promise = require('bluebird');
Promise.config({
    warnings: IS_DEVELOP,
    longStackTraces: IS_DEVELOP,
    cancellation: IS_DEVELOP,
    monitoring: IS_DEVELOP,
});

module.exports = Promise;
