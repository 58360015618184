require('./GridEvents.scss');

const b = require('app/www/libs/b')('grid-events');

const React = require('react');
const PropTypes = require('prop-types');

const {withReqRef} = require('app/www/components/contexts/Req');
const {withI18nRef} = require('app/www/components/contexts/I18n');

const GridEvent = require('app/www/components/blocks/GridEvent/GridEvent');

const MIN_PAST_EVENTS_TO_HIDE = 4;

class GridEvents extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showPast: props.events.every(event => event.hasFinished),
        };

        this._showPastEvents = this._showPastEvents.bind(this);
        this._generateListItem = this._generateListItem.bind(this);
    }

    static getDerivedStateFromProps(props) {
        if (props.events.filter(event => event.hasFinished).length < MIN_PAST_EVENTS_TO_HIDE) {
            return {
                hiddenEvents: [],
                shownEvents: props.events,
                isOpened: true,
                offset: null,
            };
        }

        return {
            hiddenEvents: props.events.filter((event, index, events) => event.hasFinished && !(index < events.length && !events[index].hasFinished)),
            shownEvents: props.events.filter((event, index, events) => !event.hasFinished || (event.hasFinished && index < events.length && !events[index].hasFinished)),
        };
    }

    render() {
        const {
            req,
            i18n,
            events,
            messages,
            hasFinished,
            selectedDate,
        } = this.props;

        const {
            offsetPast,
            showPast,
            shownEvents,
            hiddenEvents,
        } = this.state;

        const moment = req.lib('moment');
        const selectedDay = moment.getCalendarDate(selectedDate);
        const today = moment.getCalendarDate();
        const isToday = moment.is('same', today, selectedDay);

        const pastListStyle = offsetPast ? {
            marginTop: `-${offsetPast}px`,
        } : {};

        if (!events.length) {
            const message = hasFinished
                ? messages.noEvents
                : messages.broadcastGap;

            return (
                <div className={b({splash: true})}>
                    {message}
                </div>
            );
        }

        const modsPast = {
            past: true,
            opened: showPast,
        };

        return (
            <div className={b()}>
                {isToday && hiddenEvents.length > 0 && shownEvents.length > 0 && (
                    <div className={b('button', {open: showPast})} onClick={this._showPastEvents}>
                        <span className={b('button-text')}>
                            {i18n.get(`channel-schedule.${showPast ? 'hide-' : ''}past-events`)}
                        </span>
                    </div>
                )}
                <ul className={b('list')}>
                    <div
                        className={b('hidden-list', modsPast)}
                        ref={ref => (this._pastList = ref)}
                        style={pastListStyle}
                    >
                        {hiddenEvents.map(this._generateListItem)}
                    </div>
                    {shownEvents.map(this._generateListItem)}
                </ul>
            </div>
        );
    }

    /**
     * Раскрываем расписание прошедших сеансов
     * @private
     */
    _showPastEvents() {
        const {showPast} = this.state;

        this.setState({
            showPast: !showPast,
            offsetPast: this._pastList ? this._pastList.clientHeight : 0,
        });
    }

    /**
     * @param {Object} event объект события
     * @returns {Object}
     * @private
     */
    _generateListItem(event) {
        const {
            onClick,
            onMouseEnter,
            onMouseLeave,
            selectedProgramTypes,
        } = this.props;

        const eventProps = {
            event,
            onClick,
            onMouseEnter,
            onMouseLeave,
            selectedProgramTypes,
        };

        return <GridEvent key={event.id} {...eventProps} />;
    }
}

GridEvents.propTypes = {
    events: PropTypes.array.isRequired,
    selectedProgramTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
    hasFinished: PropTypes.bool.isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    messages: PropTypes.object,
    onClick: PropTypes.func,
};

module.exports = withReqRef(withI18nRef(GridEvents));
