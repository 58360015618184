require('./Dropdown.scss');

const React = require('react');
const PropTypes = require('prop-types');

const Button = require('app/www/components/blocks/Button/Button');
const Popup = require('app/www/components/blocks/Popup/Popup');

class Dropdown extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this._onClick = this._onClick.bind(this);
        this._handleOpenPopup = this._handleOpenPopup.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    render() {
        const {buttonProps, popupProps, children} = this.props;

        const dropdownProps = Object.assign({}, children && {
            onClick: this._onClick,
        });

        if (buttonProps.mods.disabled) {
            popupProps.isOpen = false;
        }

        if (buttonProps.mods.arrow) {
            buttonProps.mods.arrow = this.state.isOpen ? 'up' : 'down';
        }

        return (
            <div
                className="dropdown"
                {...dropdownProps}
            >
                <Button
                    ref={elem => (this.button = elem)}
                    {...buttonProps}
                />
                {this.state.popup &&
                    <Popup
                        ref={elem => (this.popup = elem)}
                        owner={this.button.node}
                        isOpen={this._handleOpenPopup}
                        {...popupProps}
                    >
                        {children}
                    </Popup>
                }
            </div>
        );
    }

    open() {
        this.popup.open();
    }

    close() {
        if (this.popup) {
            this.popup.close();
        }
    }

    /**
     * @private
     */
    _onClick() {
        let isButtonDisabled = this.props.buttonProps.mods.disabled;

        if (isButtonDisabled) {
            return false;
        }

        if (!this.state.popup) {
            this.setState({
                popup: true,
            });
        } else {
            this.popup.toggle();
        }
    }

    /**
     * Состояние видимости попапа
     * @param {Boolean} isOpen видимость попапа
     * @private
     */
    _handleOpenPopup(isOpen) {
        this.setState({
            isOpen: isOpen,
        });
    }

}

Dropdown.propTypes = {
    buttonProps: PropTypes.shape(
        Button.propTypes
    ),
    popupProps: PropTypes.shape(
        Popup.PropTypes
    ),
};

module.exports = Dropdown;
