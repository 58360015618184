const {saveScheduleSettings} = require('app/www/dataProviders/client/scheduleSettings');
const {SCHEDULE_SETTINGS_CHANGED} = require('app/www/constants/reducerScheduleSettings');
const {asyncActionError} = require('app/www/actions/asyncActionError');

function save(params) {
    return dispatch => {
        return saveScheduleSettings(params).then(scheduleSettings => {
            dispatch({
                type: SCHEDULE_SETTINGS_CHANGED,
                payload: Object.assign({provider: null}, scheduleSettings),
            });
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

/**
 * Сохранение настроек сетки
 * @param {String} scheduleTypeAlias алиас жанра сетки
 */
function saveScheduleTypeAlias(scheduleTypeAlias) {
    return save({scheduleTypeAlias});
}

/**
 * Сохранение пакетов провайдера
 * @param {Object[]} packages пакеты провайдера
 * @property {Number} packages[0].id id пакета
 * @property {String} packages[0].title название пакета
 */
function saveProvider(packages) {
    return save({packages});
}

module.exports = {
    saveScheduleTypeAlias,
    saveProvider,
};
