require('./Header.scss');

const b = require('app/www/libs/b')('header');

const React = require('react');
const PropTypes = require('prop-types');

const Metrika = require('app/www/libs/client/metrika');

const {withReqRef} = require('app/www/components/contexts/Req');
const {withI18nRef} = require('app/www/components/contexts/I18n');

const Button_Project = require('app/www/components/blocks/Button/_Project/Button_Project');
const Button_WannaSeeCount = require('app/www/components/blocks/Button/_WannaSeeCount/ButtonWannaSeeCount');
const Global = require('app/www/components/blocks/Global/Global');
const HeaderMenu = require('app/www/components/blocks/HeaderMenu/HeaderMenu');
const Logo_Service = require('app/www/components/blocks/Logo/_Service/Logo_Service');
const ResizeListener = require('app/www/components/blocks/ResizeListener/ResizeListener');
const SearchForm = require('app/www/components/blocks/SearchForm/SearchForm');
const Tableau = require('app/www/components/blocks/Tableau/Tableau');
const Ua = require('app/www/components/blocks/Ua/Ua');
const User_Menu = require('app/www/components/blocks/User/_Menu/User_Menu');

class Header extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = Object.assign({
            searchFocused: false,
        }, Ua.displayWidth && (
            this._initialState
        ));

        this._onResize = this._onResize.bind(this);
        this._onSearchInputFocus = this._onSearchInputFocus.bind(this);
        this._onSearchInputBlur = this._onSearchInputBlur.bind(this);
    }

    componentDidMount() {
        this.setState(Object.assign({
            tableau: !(Ua.isTouch || Ua.isMobile),
        }, this._initialState));

        this._tableauOwner = this.logo.main;
        this._html = document.documentElement;

        const {project} = this.props;

        if (project) {
            this._sendMetrikaEntryPoint('show');
        }
    }

    render() {
        const {
            req,
            i18n,
            project,
            searchText,
        } = this.props;
        const {
            isLogged,
            avatarId,
            name: userName,
        } = Global.user;

        const {
            tableau,
            isMobile,
            searchFocused,
        } = this.state;
        const urlBuilder = req.lib('urlBuilder');

        const logoProps = {
            url: urlBuilder.getIndexUrl({
                date: null,
                genre: null,
                grid: null,
                period: null,
            }),
        };

        const tableauProps = {
            target: 'blank',
        };

        const searchProps = {
            action: urlBuilder.getSearchUrl(),
            buttonName: i18n.get('button.search'),
            suggestProps: {
                onBlur: this._onSearchInputBlur,
                onFocus: this._onSearchInputFocus,
                url: Global.suggestHost,
                focused: Ua.isLayoutColumnsSmall,
                params: Object.assign({
                    v: 4,
                    uil: Global.lang,
                    lr: Global.regionId,
                    count_channels: 4,
                    count_programs: 4,
                    sn: 50, // https://st.yandex-team.ru/TVFRONT-3304
                }, Global.suggestUseTest && {
                    use_test: Global.suggestUseTest,
                }),
                searchText,
                placeholder: i18n.get('search-form.placeholder'),
                isMobile,
                onOpen: value => this._sendMetrikaSuggest('show', value),
                onClose: () => this._sendMetrikaSuggest('show'),
                onClickItem: (value, number) => this._sendMetrikaSuggest('click', value, number),
            },
            isMobile,
            buttonCloseLabel: i18n.get('button.close'),
        };

        const buttonProjectProps = project && {
            attrs: {
                tabIndex: 1,
            },
            mods: {
                project: true,
                theme: 'ghost',
                size: 'm',
                icon: 'bright',
            },
            icon: {
                project: true,
            },
            url: project.url,
            style: project.style,
            onClick: () => this._sendMetrikaEntryPoint('click'),
        };

        const buttonWannaSeeProps = {
            attrs: {
                tabIndex: 3,
            },
            mods: {
                theme: 'ghost',
                size: 'm',
                icon: 'bright',
            },
            fetch: true,
            url: urlBuilder.getWannaSeeUrl(),
        };

        const UserMenuProps = {
            items: [
                'mail',
                'mail-compose',
                'disk',
                'separator',
                'tune',
                'passport',
                'logout',
            ],
            userProps: {
                isLogged,
                avatarId,
            },
            caller: Global.getCaller,
        };

        const resizeListenerProps = {
            callback: this._onResize,
        };

        const HeaderMenuProps = {
            userProps: {
                isLogged,
                avatarId,
                userName,
            },
        };

        return (
            <header className={b()}>
                <div className={b('logo')}>
                    <Logo_Service
                        ref={elem => (this.logo = elem)}
                        {...logoProps}
                    />
                    {tableau && (
                        <Tableau
                            owner={this._tableauOwner}
                            {...tableauProps}
                        />
                    )}
                </div>
                <div className={b('middle')}>
                    <div className={b('search', {focused: searchFocused})}>
                        <div className={b('search-wrapper')}>
                            <SearchForm {...searchProps} />
                        </div>
                    </div>
                </div>
                <nav className={b('nav')}>
                    {project && (
                        <div className={b('nav-button', {project: true})}>
                            <Button_Project {...buttonProjectProps}>
                                {project.text}
                            </Button_Project>
                        </div>
                    )}

                    {!isMobile && (
                        <div className={b('nav-button', {'wanna-see': true})}>
                            <Button_WannaSeeCount {...buttonWannaSeeProps}>
                                {i18n.get('button.wannaSee.button')}
                            </Button_WannaSeeCount>
                        </div>
                    )}

                    <div className={b('nav-user')}>
                        {!isMobile && (
                            <User_Menu {...UserMenuProps} />
                        )}
                        {isMobile && (
                            <HeaderMenu {...HeaderMenuProps} />
                        )}
                    </div>
                    <ResizeListener {...resizeListenerProps} />
                </nav>
            </header>
        );
    }

    /**
     * @returns {Object}
     * @private
     */
    get _initialState() {
        return {
            isMobile: Ua.isTouchPhone,
        };
    }

    /**
     * Отправляем данные точки входа в метрику
     * @param {String} action show|click
     * @private
     */
    _sendMetrikaEntryPoint(action) {
        const {campaign} = this.props.project;

        Metrika.trackParams('ENTRY_POINT', {
            control: 'button',
            campaign,
            action,
        });
    }

    /**
     * Отправляем данные саджеста в метрику
     * @param {String} action show|click
     * @param {String} [value] значение в текстовом поле
     * @param {Number} [number] порядковый номер элемента
     * @private
     */
    _sendMetrikaSuggest(action, value, number) {
        Metrika.trackParams('SUGGEST', {
            isClick: action === 'click',
            value,
            number,
        });
    }

    /**
     * @private
     */
    _onResize() {
        this.setState(this._initialState);
    }

    /**
     * @private
     */
    _onSearchInputFocus() {
        this.setState({
            searchFocused: true,
        });
    }

    /**
     * @private
     */
    _onSearchInputBlur() {
        this.setState({
            searchFocused: false,
        });
    }
}

Header.propTypes = {
    searchText: PropTypes.string,
    project: PropTypes.shape({
        campaign: PropTypes.string.isRequired,
        url: PropTypes.string,
        text: PropTypes.string,
    }),
};

module.exports = withReqRef(withI18nRef(Header));
