require('./Carousel.scss');
require('./_Gallery/Carousel_Gallery.scss');
require('./_Lists/Carousel_Lists.scss');
require('./_Sport/Carousel_Sport.scss');
require('./_Episodes/Carousel_Episodes.scss');
require('./_Recommended/Carousel_Recommended.scss');

const b = require('app/www/libs/b')('carousel');

const React = require('react');
const PropTypes = require('prop-types');

const HotkeyListener = require('app/www/components/blocks/HotkeyListener/HotkeyListener');
const Scroll = require('app/www/components/blocks/Scroll/Scroll');
const Ua = require('app/www/components/blocks/Ua/Ua');

const {RIGHT, LEFT} = require('app/www/components/blocks/HotkeyListener/HotkeyListener.const');

class Carousel extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            prev: false,
            next: props.hasNext,
        };

        this._onClickPrev = this._onClickPrev.bind(this);
        this._onClickNext = this._onClickNext.bind(this);
        this._onScroll = this._onScroll.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        return {
            next: state.next && props.hasNext,
        };
    }

    render() {
        const {
            mods,
            width,
            render,
            animate,
            hotKeys,
            autoLoad,
            children,
            scrollWidth,
            scrollOffset,
            forceControls,
        } = this.props;

        const {
            prev,
            next,
        } = this.state;

        const scrollProps = {
            width,
            render,
            animate,
            autoLoad,
            scrollWidth,
            scrollOffset,
            touch: Ua.isTouch && !forceControls,
        };

        const hotkeyListenerPrevProps = {
            keyCode: LEFT,
            callback: this._onClickPrev,
        };

        const hotkeyListenerNextProps = {
            keyCode: RIGHT,
            callback: this._onClickNext,
        };

        return (
            <section className={b(mods)}>
                {!Ua.isTouch || forceControls
                    ? [
                        <span
                            key="prev"
                            className={b('ctrl', {prev: true, disabled: !prev})}
                            onClick={this._onClickPrev}
                        />,
                        <span
                            key="next"
                            className={b('ctrl', {next: true, disabled: !next})}
                            onClick={this._onClickNext}
                        />,
                    ]
                    : <span />
                }
                <Scroll
                    ref={elem => (this._scroll = elem)}
                    onScroll={this._onScroll}
                    {...scrollProps}
                >
                    {children}
                </Scroll>
                {hotKeys && <HotkeyListener {...hotkeyListenerPrevProps} />}
                {hotKeys && <HotkeyListener {...hotkeyListenerNextProps} />}
            </section>
        );
    }

    /**
     * Ширина контента
     */
    get contentWidth() {
        return this._scroll._contentWidth;
    }

    /**
     * Прокручиваем на указанную величину
     * @param {Number} position позиция прокрутки от начала
     */
    scroll(position) {
        this._scroll.to(position);
    }

    /**
     * Прокручивам на один экран назад
     */
    prev() {
        this._onClickPrev();
    }

    /**
     * Прокручивам на один экран вперед
     */
    next() {
        this._onClickNext();
    }

    /**
     * @param {Object} [evt]
     * @private
     */
    _onClickPrev(evt) {
        const {prev} = this.state;

        if (evt) {
            evt.stopPropagation();
        }

        if (prev) {
            this._scroll.toLeft();
            this.props.onPrevClick();
        }
    }

    /**
     * @param {Object} [evt]
     * @private
     */
    _onClickNext(evt) {
        const {next} = this.state;

        if (evt) {
            evt.stopPropagation();
        }

        if (next) {
            this._scroll.toRight();
            this.props.onNextClick();
        }
    }

    /**
     * @private
     */
    _onScroll(data) {
        const {hasNext} = this.props;

        this.setState({
            prev: !data.isStart,
            next: !data.isEnd && hasNext,
        });

        this.props.onScroll(data.position);
    }

}

Carousel.defaultProps = {
    animate: true,
    hasNext: true,
    hotKeys: false,
    onNextClick: () => {},
    onPrevClick: () => {},
    onScroll: () => {},
};

Carousel.propTypes = {
    width: Scroll.propTypes.width,
    scrollWidth: Scroll.propTypes.scrollWidth,
    scrollOffset: Scroll.propTypes.scrollOffset,
    animate: Scroll.propTypes.animate,
    touch: Scroll.propTypes.touch,
    render: Scroll.propTypes.render,
    autoLoad: Scroll.propTypes.autoLoad,
    forceControls: PropTypes.bool,
    hotKeys: PropTypes.bool,
    onNextClick: PropTypes.func,
    onPrevClick: PropTypes.func,
    onScroll: PropTypes.func,
    hasNext: PropTypes.bool,
};

module.exports = Carousel;
