module.exports = {
    /**
     * @returns {String}
     */
    get key() {
        return this._sk.key;
    },

    /**
     * @returns {Number}
     */
    get expire() {
        return this._sk.expire;
    },

    /**
     * @param {Object} sk
     * @param {String} sk.key ключ
     * @param {Number} sk.expire timestamp когда закончится ключ
     */
    set(sk) {
        this._sk = sk;
    },
};
