require('./ChannelsGridReload.scss');

const b = require('app/www/libs/b')('channels-grid-reload', true);

const {withI18nRef} = require('app/www/components/contexts/I18n');

const React = require('react');
const PropTypes = require('prop-types');

class ChannelsGridReload extends React.PureComponent {

    constructor(props) {
        super(props);

        const {
            isMy,
            panelReloadVisible,
        } = props;

        this.state = {
            type: isMy ? 'reload' : 'sort',
            visible: panelReloadVisible,
        };

        this._onClick = this._onClick.bind(this);
    }

    static getDerivedStateFromProps(props) {
        const {
            isMy,
            panelReloadVisible,
        } = props;

        return {
            type: isMy ? 'reload' : 'sort',
            visible: panelReloadVisible,
            loading: false,
        };
    }

    render() {
        const {i18n} = this.props;
        const {type} = this.state;

        const text = i18n.get(`channels-grid-reload.${type}`);

        return (
            <div className={b.mix('notification', this.state)}
                onClick={this._onClick}
            >
                <span className={b('text')}>
                    {text}
                </span>
                <span className={b('button')}>
                    {i18n.get('channels-grid-reload.sort-button')}
                </span>
            </div>
        );
    }

    /**
     * @private
     */
    _onClick() {
        this.setState({
            loading: true,
        });

        window.scrollTo(0, 0);

        this.props.onClick();
    }
}

ChannelsGridReload.defaultProps = {
    onClick: () => {},
};

ChannelsGridReload.propTypes = {
    isMy: PropTypes.bool,
    text: PropTypes.string,
    panelReloadVisible: PropTypes.bool,
    onClick: PropTypes.func,
};

module.exports = withI18nRef(ChannelsGridReload);
