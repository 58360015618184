require('./Footer.scss');

const b = require('app/www/libs/b')('footer');

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');
const _ = {
    throttle: require('lodash/throttle'),
};

const Metrika = require('app/www/libs/client/metrika');

const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');

const Button = require('app/www/components/blocks/Button/Button');
const Copyright = require('app/www/components/blocks/Copyright/Copyright');
const Global = require('app/www/components/blocks/Global/Global');
const LangSwitcher = require('app/www/components/blocks/LangSwitcher/LangSwitcher');
const Link = require('app/www/components/blocks/Link/Link');
const ListsCarousel = require('app/www/components/blocks/ListsCarousel/ListsCarousel');
const Producers = require('app/www/components/blocks/Producers/Producers');
const Ua = require('app/www/components/blocks/Ua/Ua');

const METRIKA_NAME = {
    HELP: 'help',
    FEEDBACK: 'feedback',
    KP: 'kinopoisk',
    ADV: 'adv',
    TERM: 'termsofuse',
    UP: 'up',
    SHOW: 'show',
};

const isClient = typeof window !== 'undefined';

class Footer extends React.PureComponent {
    constructor(props) {
        super(props);

        this._onScroll = this._onScroll.bind(this);
        this._onClick = this._onClick.bind(this);
        this._onScroll = _.throttle(this._onScroll, 200);
        this._scrollToTop = this._scrollToTop.bind(this);
        this._changeVersion = this._changeVersion.bind(this);

        this.state = {
            depth: 0,
            isVisible: false,
            wasShowFooter: false,
        };
    }

    componentDidMount() {
        if (Global.isUkraine) {
            const param = Global.lang === 'uk' ? 'Ua' : 'Ru';
            Metrika.trackParams('LANG_SWITCHER', param);
        }

        if (isClient) {
            window.addEventListener('scroll', this._onScroll);
            document.addEventListener('touchmove', this._onScroll);
        }
    }

    componentDidUpdate() {
        const {
            isVisible,
            wasShowFooter,
        } = this.state;
        if (isVisible && !wasShowFooter) {
            this.setState({
                wasShowFooter: true,
            });
            Metrika.trackParams('FOOTER', {
                footerScroll: METRIKA_NAME.SHOW,
            });
        }
    }

    render() {
        const {
            i18n,
            lists,
            rating,
            isMain,
        } = this.props;

        const {isVisible} = this.state;

        const hasLists = lists.length > 0;

        const listsCarouselProps = {lists};

        const langSwitcherProps = {
            directions: 'top',
            options: [
                {
                    lang: {
                        code: 'ua',
                    },
                    selected: Global.lang === 'uk',
                },
                {
                    lang: {
                        code: 'ru',
                    },
                    selected: Global.lang === 'ru',
                },
            ],
        };

        const helpLinkProps = {
            url: Global.supportUrl,
        };

        const feedbackLinkProps = {
            url: Global.feedbackUrl,
        };

        const versionMobileLinkProps = {
            url: this._changeVersion('toMobile'),
        };

        const versionDesktopLinkProps = {
            url: this._changeVersion(),
        };

        const kinopoiskLinkProps = {
            url: Global.kinopoiskFooterUrl,
        };

        const advertiseLinkProps = {
            url: Global.advertiseUrl,
        };

        const termsofuseLinkProps = {
            url: Global.termsofuseUrl,
        };

        const mods = {
            lists: hasLists,
            fixed: !Ua.isTouchPhone && isMain && isVisible,
            visible: isVisible,
        };

        const buttonProps = {
            mods: {
                theme: 'clear',
                size: 'xs',
                'footer-up': true,
            },
            onClick: this._scrollToTop,
        };

        return (
            <footer className={b(mods)}>
                {hasLists && [
                    <div key="title" className={b('title')}>
                        {i18n.get('footer.title')}
                    </div>,
                    <div key="carousel" className={b('carousel')}>
                        <ListsCarousel {...listsCarouselProps} />
                    </div>,
                ]}
                <div className={b('divider')}>
                    <div className={b('groups')}>
                        {rating && rating.length !== 0 && (
                            <span className={b('rating')}>{rating}</span>
                        )}
                        <div className={b('group', {left: true})}>
                            {Global.isUkraine && (
                                <LangSwitcher {...langSwitcherProps}>
                                    {Global.lang}
                                </LangSwitcher>
                            )}
                            {Ua.isPhoneHasViewDesktop && (
                                <Link {...versionMobileLinkProps}>
                                    {i18n.get('footer.version')}
                                </Link>
                            )}
                            {Ua.isTouchPhone && (
                                <Link {...versionDesktopLinkProps}>
                                    {i18n.get('header-menu.desktopVersion')}
                                </Link>
                            )}
                            <Link {...helpLinkProps} onClick={this._onClick(METRIKA_NAME.HELP)}>
                                {i18n.get('footer.help')}
                            </Link>
                            <Link className={b('adaptive-link')} {...feedbackLinkProps} onClick={this._onClick(METRIKA_NAME.FEEDBACK)}>
                                {i18n.get('footer.feedback')}
                            </Link>
                            <Link className={b('adaptive-link')} {...kinopoiskLinkProps} onClick={this._onClick(METRIKA_NAME.KP)}>
                                {i18n.get('footer.kinopoisk')}
                            </Link>
                        </div>
                        <div className={b('group', {right: true})}>
                            <Link {...advertiseLinkProps} onClick={this._onClick(METRIKA_NAME.ADV)}>
                                {i18n.get('footer.advertise')}
                            </Link>
                            <Link {...termsofuseLinkProps} onClick={this._onClick(METRIKA_NAME.TERM)}>
                                {i18n.get('footer.termsofuse')}
                            </Link>
                            <Producers />
                            <div className={b('copyright', {desktop: true})}>
                                <Copyright />
                            </div>
                            {!Ua.isTouchPhone && (
                                <Button {...buttonProps} />
                            )}
                        </div>
                        <div className={b('copyright', {touch: true})}>
                            <Copyright />
                        </div>
                    </div>
                </div>
            </footer>
        );
    }

    /**
     * Урл изменения версии
     * @returns {String}
     * @private
     */
    _changeVersion(toMobile) {
        const urlBuilder = this.props.req.lib('urlBuilder');
        const retpath = encodeURIComponent(urlBuilder.appUrls.www);
        const crc = Global.crc;
        const version = toMobile ? 0 : 1;

        return `${Global.yandexUrl}/portal/set/my?block=44&param=${version}&retpath=${retpath}&sk=${crc}`;
    }

    /**
     * Всегда отслеживаем скрол
     * на всех страницах
     * @private
     */
    _onScroll() {

        const {
            depth,
        } = this.state;

        if (Ua.isRobot) {
            return;
        }

        const {innerHeight, pageYOffset} = window;

        const deepScroll = Math.floor(pageYOffset / innerHeight);

        if (deepScroll > depth) {

            this.setState({
                depth: deepScroll,
            });

            Metrika.trackParams('SCROLL', {
                deepScroll,
            });
        }

        const {wasShowFooter} = this.state;
        const showFooter = (document.body.scrollHeight === (innerHeight + pageYOffset)) && !wasShowFooter && !Ua.isTouchPhone;

        if (showFooter) {
            this.setState({
                wasShowFooter: true,
            });

            Metrika.trackParams('FOOTER', {
                footerScroll: 'show',
            });
        }

        const scrollOffsetOnePage = innerHeight <= pageYOffset;

        if (scrollOffsetOnePage && this.props.isMain && !Ua.isTouchPhone) {
            this.setState({
                isVisible: true,
            });
        } else {
            this.setState({
                isVisible: false,
            });
        }
    }

    /**
     * Скролим в верх страницы
     * @private
     */
    _scrollToTop() {
        const scrollSpeed = 2;
        let scrollAnimation;
        let isScrolling;

        const scrollPage = (toTop) => {
            window.scrollTo(0, Math.floor(toTop - toTop / (scrollSpeed * 2)));
            if (toTop <= 0) {
                clearInterval(scrollAnimation);
                isScrolling = false;
            }
        };
        if (!isScrolling) {
            isScrolling = true;
            clearInterval(scrollAnimation);
            scrollAnimation = setInterval(() => {
                const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

                scrollPage(scrollTop);
            }, scrollSpeed);
        }

        Metrika.trackParams('FOOTER', {
            footerClick: true,
            button: METRIKA_NAME.UP,
        });
    }

    /**
     * Отправляем метрику по клику
     * @private
     */
    _onClick(button) {
        return () => {
            Metrika.trackParams('FOOTER', {
                footerClick: true,
                button: button,
            });
        };
    }
}

Footer.propTypes = {
    lists: PropTypes.array,
    rating: PropTypes.string,
};

function mapStateToProps(state) {
    return {
        lists: state.entryPoints.lists,
        rating: state.special.rating,
    };
}

module.exports = connect(
    mapStateToProps,
    () => {return {};}
)(
    withReq(withI18n(
        Footer
    ))
);
