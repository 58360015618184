const b = require('app/www/libs/b')('image', true);

const React = require('react');
const PropTypes = require('prop-types');

let SIZES = {
    xs: 80,
    s: 80,
    m: 80,
    l: 120,
    xl: 160,
};

const Image_Logo = (props) => {
    const {
        className,
        sizes,
        mods,
    } = props;

    const attrs = sizes[SIZES[mods.size]] || sizes[SIZES['xl']];

    return (
        <figure className={b.mix(className, mods)}>
            <img className={b('img')} {...attrs} />
        </figure>
    );
};

Image_Logo.defaultProps = {
    sizes: {},
    mods: {},
};

Image_Logo.propTypes = {
    sizes: PropTypes.object,
    mods: PropTypes.shape({
        size: PropTypes.oneOf([
            'xs',
            's',
            'm',
            'l',
            'xl',
        ]),
    }),
};

module.exports = Image_Logo;
