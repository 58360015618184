require('./ButtonWannaSeeCount.scss');
require('../../Icon/Icon.scss');
require('../../Icon/_WannaSee/Icon_WannaSee.scss');

const b = require('app/www/libs/b')('button_wanna-see-count');

const React = require('react');
const PropTypes = require('prop-types');
const _isEmpty = require('lodash/isEmpty');

const {connect} = require('react-redux');
const actions = require('app/www/actions/wannaSee');

const Button = require('app/www/components/blocks/Button/Button');
const Metrika = require('app/www/libs/client/metrika');

class Button_WannaSeeCount extends React.PureComponent {

    static get defaultProps() {
        return {
            stat: {
                count: 0,
                finish: 0,
            },
            icon: {
                'wanna-see': true,
            },
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            stat: this.props.stat,
        };

        if (typeof window !== 'undefined' && this.props.fetch) {
            this.props.dispatchUpdate();
        }
    }

    static getDerivedStateFromProps(props) {
        if (props.stat.count > 0) {
            Metrika.reachGoal('MYTP_NOT_EMPTY');
        }

        return props;
    }

    render() {
        const {children} = this.props;
        const count = this.state.stat.count;
        const buttonProps = Object.assign({}, this.props);

        buttonProps.mods['wanna-see-count'] = true;

        return (
            <Button {...buttonProps}>
                <span className={b('label-text')} key="button-1">{children}</span>
                <span className={b('counter', {visible: count > 0})} key="button-2">
                    {count}
                </span>
            </Button>
        );
    }

}

Button_WannaSeeCount.propTypes = {
    stat: PropTypes.shape({
        count: PropTypes.number,
        finish: PropTypes.number,
    }),
    mods: Button.propTypes.mods,
    fetch: PropTypes.bool,
};

function mapStateToProps(state, ownProps) {
    return {
        stat: !_isEmpty(state.wannaSee.count) ? state.wannaSee.count : ownProps.stat,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatchUpdate: () => dispatch(actions.update()),
    };
}

module.exports = connect(
    mapStateToProps,
    mapDispatchToProps
)(Button_WannaSeeCount);
