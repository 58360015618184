const dataProvider = require('app/www/dataProviders/client/event');
const {EVENT_GET, EVENTS_GET} = require('app/www/constants/event');
const {userSettingsFallback} = require('app/www/actions/userSettingsFallback');

function getEvent(eventId, programId) {
    return dispatch => {
        dataProvider.getEvent(eventId, programId).then(event => {
            dispatch({
                type: EVENT_GET,
                payload: event,
            });

            dispatch(
                userSettingsFallback(event)
            );
        });
    };
}

function getEvents(eventIds) {
    return dispatch => {
        dataProvider.getEvents(eventIds).then(events => {
            dispatch({
                type: EVENTS_GET,
                payload: events,
            });
        });
    };
}

module.exports = {
    getEvent,
    getEvents,
};
