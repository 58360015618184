require('./Ticker.scss');

const b = require('app/www/libs/b')('tableau', true);

const React = require('react');
const PropTypes = require('prop-types');

const Ticker = (props) => {
    const {className, count, maxCount, url} = props;

    const Tag = url ? 'a' : 'span';
    const value = count > maxCount ? `${maxCount}+` : count;

    const tagAttrs = {
        href: url,
    };

    return (
        <div className={b.mix(className)}>
            <Tag className={b('count')} {...tagAttrs}>
                <span className={b('value')}>
                    {value}
                </span>
            </Tag>
        </div>
    );
};

Ticker.defaultProps = {
    count: 0,
    maxCount: 99,
};

Ticker.propTypes = {
    count: PropTypes.number,
    maxCount: PropTypes.number,
    url: PropTypes.string,
};

module.exports = Ticker;
