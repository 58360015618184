const b = require('app/www/libs/b')('adv-manager');

const _ = {
    remove: require('lodash/remove'),
};

const React = require('react');
const PropTypes = require('prop-types');
const randomString = require('app/www/libs/randomString');

const Global = require('app/www/components/blocks/Global/Global');

const CONTEXT_URL = '//yandex.ru/ads/system/context.js';

class AdvManagerBase extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onLoad = this._onLoad.bind(this);
        this._onError = this._onError.bind(this);
        this._loadScript = this._loadScript.bind(this);
        this._scriptId = Global.advScriptId;
    }

    componentDidMount() {
        this._renderTo = randomString();
        this.adv.id = this._renderTo;

        this._loadScript();
    }

    componentWillUnmount() {
        ((w, d, n) => {
            w[n] = w[n] || [];

            _.remove(w[n], f => f.renderTo === this._renderTo);

            if (d.removeEventListener) {
                w.removeEventListener('load', this._createScript);
            } else {
                w.detachEvent('onload', this._createScript);
            }
        })(window, document, 'yandexContextAsyncCallbacks');
    }

    render() {
        return (
            <div
                className={b(this.props.mods)}
                ref={ref => (this.adv = ref)}
            />
        );
    }

    /**
     * Грузим скрипт direct
     * @private
     */
    _loadScript() {
        const {
            blockId,
            index,
            statId,
        } = this.props;

        let subPage;
        let sent = false;
        const startTime = Ya.Rum.getTime();
        const tryToMakeSubPage = () => {
            if (subPage) {
                return true;
            } else if (Ya.Rum.makeSubPage) {
                subPage = Ya.Rum.makeSubPage('155', startTime); // direct
            }
            if (subPage) {
                Ya.Rum.sendTTI(false, startTime, subPage);
                return true;
            }
        };

        const blockSettings = {
            blockId,
            renderTo: this._renderTo,
            pageNumber: index,
            async: true,
            onRender: data => {
                if (!sent && tryToMakeSubPage()) {
                    let timeMarkName = '2046'; // render
                    if (data.product === 'direct') {
                        timeMarkName += '.155'; // direct
                    } else if (data.product === 'rtb') {
                        timeMarkName += '.3431'; // rtb
                    }
                    // время от начала загрузки рекламы
                    Ya.Rum.sendTimeMark(timeMarkName, Ya.Rum.getTime(), false, subPage);
                    /*
                        Время от навигации
                        ВАЖНО:
                        Изначально счетчик задумывался для проверки рекламного кода.
                        Если нужно знать время появления рекламы от начала навигации, нужно раскомментить строку с sendDelta.
                        Это увеличивает объем данных, поэтому не нужно этим злоупотреблять.
                    */
                    // Ya.Rum.sendDelta(timeMarkName, Ya.Rum.getTime(), subPage);
                    // защита от повторного вызова onRender
                    sent = true;
                }
                this._onLoad(data.product);
            },
            cspNonce: Global.nonce,
        };

        if (statId) {
            blockSettings.statId = statId;
        }

        ((w, d, n) => {
            // Тут нужно синхронизировать моменты инициализации кода директа и RUM
            // если RUM еще не доступен, то пробуем создать счетчик позже
            tryToMakeSubPage();

            if (tryToMakeSubPage()) {
                // время от начала загрузки рекламы
                Ya.Rum.sendTimeMark('1724', Ya.Rum.getTime(), false, subPage); // load
            }

            const f = () => {
                // https://wiki.yandex-team.ru/jandekskontekst/partnercode/onrender/
                Ya.Context.AdvManager.render(blockSettings, this._onError);
            };
            f.renderTo = this._renderTo;

            w[n] = w[n] || [];
            w[n].push(f);

            this._createScript = () => {
                const ls = document.getElementById(this._scriptId);
                if (ls) {
                    return;
                }
                const t = d.getElementsByTagName('script')[0];
                const s = d.createElement('script');
                s.src = CONTEXT_URL;
                s.type = 'text/javascript';
                s.onerror = () => this._onError();
                s.onload = () => {};
                s.async = true;
                s.id = this._scriptId;
                t.parentNode.insertBefore(s, t);
            };

            if (d.readyState === 'complete') {
                this._createScript();
            } else if (d.addEventListener) {
                w.addEventListener('load', this._createScript);
            } else {
                w.attachEvent('onload', this._createScript);
            }
        })(window, document, 'yandexContextAsyncCallbacks');
    }

    /**
     * @param {String} product - тип(direct, rtb, stripe = Промо-полоска)
     * @private
     */
    _onLoad(product) {
        this.props.onLoad(product);
    }

    /**
     * @private
     */
    _onError() {
        this.props.onError();
    }
}

AdvManagerBase.defaultProps = {
    onLoad: () => {},
    onError: () => {},
};

AdvManagerBase.propTypes = {
    blockId: PropTypes.string,
    index: PropTypes.number,
    statId: PropTypes.number,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

module.exports = AdvManagerBase;
