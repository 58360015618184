const fetch = require('app/www/libs/client/fetch');
const secretKeyStore = require('app/www/libs/client/secretKeyStore');
const RESOURCE = require('app/configs/_routes').sk.api.base;

module.exports = {
    update() {
        return fetch(RESOURCE, null, {
            method: 'get',
        }).then(data => {
            secretKeyStore.set(data.sk);
        });
    },
};
