const dataProvider = require('app/www/dataProviders/client/wanna-see');
const {
    FAVORITE_PROGRAM_ADD,
    FAVORITE_PROGRAM_DEL,
    FAVORITE_PROGRAM_ADD_SESSION,
    FAVORITE_PROGRAM_DEL_SESSION,
    FAVORITE_PROGRAMS_UPDATED,
    FAVORITE_PROGRAMS_UPDATED_EXTERNAL,
} = require('app/www/constants/reducerWannaSee');
const {userSettingsFallback} = require('app/www/actions/userSettingsFallback');
const {asyncActionError} = require('app/www/actions/asyncActionError');

const cookies = require('app/www/constants/sessionCookies');

function add(programId) {
    return dispatch => {
        return dataProvider.add(programId)
            .then(success =>
                dispatch({
                    type: FAVORITE_PROGRAM_ADD,
                    payload: {
                        programId,
                        success,
                    },
                })
            )
            .then(() => dispatch(update()))
            .catch(error => dispatch(asyncActionError(error)));
    };
}

function del(programId) {
    return dispatch => {
        return dataProvider.del(programId).then(success =>
            dispatch({
                type: FAVORITE_PROGRAM_DEL,
                payload: {
                    programId,
                    success,
                },
            })
        )
        .then(() => dispatch(update()))
        .catch(error => dispatch(asyncActionError(error)));
    };
}

function addSession(programId) {
    document.cookie = `${cookies['WANNA-SEE']}=add-${programId}; path=/;`;

    return {
        type: FAVORITE_PROGRAM_ADD_SESSION,
        payload: {
            programId,
            success: document.cookie.includes(cookies['WANNA-SEE']),
        },
    };
}

function delSession(programId) {
    if (document.cookie.includes(cookies['WANNA-SEE'])) {
        document.cookie = `${cookies['WANNA-SEE']}=del-${programId}; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
    }

    return {
        type: FAVORITE_PROGRAM_DEL_SESSION,
        payload: {
            programId,
            success: !document.cookie.includes(cookies['WANNA-SEE']),
        },
    };
}

/**
 * Получаем количество событий ХП и время их окончания
 */
function update() {
    return dispatch => {
        return dataProvider.update().then(response => {
            dispatch({
                type: FAVORITE_PROGRAMS_UPDATED,
                payload: response,
            });

            dispatch(
                userSettingsFallback(response)
            );
        });
    };
}

function updateExternal(programIds) {
    return {
        type: FAVORITE_PROGRAMS_UPDATED_EXTERNAL,
        payload: {
            items: programIds,
        },
    };
}

module.exports = {
    add,
    del,
    addSession,
    delSession,
    update,
    updateExternal,
};
