require('./Button.scss');
require('./_Arrow/Button_Arrow_Down.scss');
require('./_Arrow/Button_Arrow_Up.scss');
require('./_Disabled/Button_Disabled.scss');
require('./_FooterArrow/Footer_Arrow.scss');
require('./_Icon/Button_Icon_Bright.scss');
require('./_Icon/Button_Icon_Dim.scss');
require('./_OnlyIcon/Button_OnlyIcon.scss');
require('./_Pin/Button_Pin_Circle.scss');
require('./_Pin/Button_Pin_Brick-Round.scss');
require('./_Size/Button_Size_XS.scss');
require('./_Size/Button_Size_S.scss');
require('./_Size/Button_Size_M.scss');
require('./_Size/Button_Size_L.scss');
require('./_Size/Button_Size_XL.scss');
require('./_Size/Button_Size_2XL.scss');
require('./_Theme/Button_Theme_Action.scss');
require('./_Theme/Button_Theme_Active.scss');
require('./_Theme/Button_Theme_Checked.scss');
require('./_Theme/Button_Theme_Clear.scss');
require('./_Theme/Button_Theme_Ghost.scss');
require('./_Theme/Button_Theme_Grey.scss');
require('./_Theme/Button_Theme_Orange.scss');
require('./_Theme/Button_Theme_Normal.scss');
require('./_Theme/Button_Theme_Pseudo.scss');
require('./_Theme/Button_Theme_White.scss');
require('./_Touch/Button_Touch.scss');
require('./_HeaderMenu/Button_HeaderMenu.scss');

const b = require('app/www/libs/b')('button', true);

const React = require('react');
const PropTypes = require('prop-types');
const Icon = require('app/www/components/blocks/Icon/Icon');

class Button extends React.PureComponent {

    constructor(props) {
        super(props);

        const {mods} = this.props;

        this.state = {
            pressed: mods.pressed,
            disabled: mods.disabled,
            arrow: mods.arrow,
        };

        this._onClick = this._onClick.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onMouseDown = this._onMouseDown.bind(this);
        this._onMouseUp = this._onMouseUp.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
    }

    static getDerivedStateFromProps(props) {
        const {mods} = props;

        return {
            arrow: mods.arrow,
            disabled: mods.disabled,
        };
    }

    render() {
        const {
            attrs,
            url,
            children,
            icon,
            mods,
            className,
        } = this.props;
        const state = this.state;

        const buttonMods = Object.assign({}, mods, state);

        let Tag = 'button';
        const iconProps = !icon ? null : {
            mods: Object.assign(icon, {
                hovered: state.hovered,
            }),
        };
        const iconTag = icon ? <Icon className={b('icon')} {...iconProps} /> : null;

        if (url) {
            Tag = 'a';
            attrs.href = url;
        }

        const label = children && <span className={b('label')}>{children}</span>;

        if (state.disabled) {
            return (
                <Tag
                    ref={elem => (this._node = elem)}
                    className={b.mix(className, buttonMods)}
                    {...attrs}
                >
                    {iconTag}
                    {label}
                </Tag>
            );
        }

        return (
            <Tag
                ref={elem => (this._node = elem)}
                className={b.mix(className, buttonMods)}
                onClick={this._onClick}
                onFocus={this._onFocus}
                onBlur={this._onBlur}
                onMouseDown={this._onMouseDown}
                onMouseUp={this._onMouseUp}
                onMouseEnter={this._onMouseEnter}
                onMouseLeave={this._onMouseLeave}
                {...attrs}
            >
                {iconTag}
                {label}
            </Tag>
        );
    }

    get node() {
        return this._node;
    }

    /**
     * Обработчик события
     * @private
     */
    _onClick(evt) {
        if (this.state.arrow) {
            this.setState({
                arrow: this.state.arrow === 'down' ? 'up' : 'down',
            });
        }

        this.props.onClick(evt);
    }

    /**
     * @protected
     */
    _onFocus() {
        this.setState({
            focused: true,
        });

        return this._event('onFocus');
    }

    /**
     * @protected
     */
    _onBlur() {
        this.setState({
            focused: false,
        });

        return this._event('onBlur');
    }

    /**
     * @protected
     */
    _onMouseDown() {
        this.setState({
            pressed: true,
            focused: true,
        });

        return this._event('onMouseDown');
    }

    /**
     * @protected
     */
    _onMouseUp() {
        this.setState({
            pressed: false,
            focused: false,
        });

        return this._event('onMouseUp');
    }

    /**
     * @protected
     */
    _onMouseLeave(evt) {
        this.setState({
            pressed: false,
            hovered: false,
        });

        return this._event('onMouseLeave', evt);
    }

    /**
     * @protected
     */
    _onMouseEnter(evt) {
        this.setState({
            hovered: true,
        });

        return this._event('onMouseEnter', evt);
    }

    _event(name, evt) {
        if (this.props[name] && !this.state.disabled) {
            return this.props[name](evt);
        }

        return false;
    }
}

Button.defaultProps = {
    attrs: {
        type: 'button',
    },
    onClick: () => {},
};

Button.propTypes = {
    mods: PropTypes.shape({
        theme: PropTypes.string,
        icon: PropTypes.string,
        size: PropTypes.string,
        disabled: PropTypes.bool,
        pressed: PropTypes.bool,
    }),
    attrs: PropTypes.shape({
        type: PropTypes.string,
        tabIndex: PropTypes.number,
    }),
    url: PropTypes.string,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

module.exports = Button;
