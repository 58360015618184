require('./ProgramMeta.scss');

const b = require('app/www/libs/b')('program-meta');

const React = require('react');
const PropTypes = require('prop-types');

const ProgramMeta = (props) => {
    const {type, age} = props;

    return type || age > 0
        ? (
            <div className={b()}>
                {type && <span className={b('type')}>{type}</span>}
                {age > 0 && <span className={b('age')}>{age}+</span>}
            </div>
        )
        : <div />;
};

ProgramMeta.propTypes = {
    type: PropTypes.string,
    age: PropTypes.number,
};

module.exports = ProgramMeta;
