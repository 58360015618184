const {
    ASYNC_REQUEST_ERROR,
} = require('app/www/constants/async-request-error');

/**
 * Все асинхронный запросы нужно оборачивать в эту функцию,
 * для логгирования ошибок и вывода их в UI
 * @param {Object} error
 * @param {Object} error.status статус код
 * @return {Object}
 */
function asyncActionError(error) {
    if (typeof error === 'object' && error.status) {
        return {
            type: ASYNC_REQUEST_ERROR,
            payload: error,
        };
    }
}

module.exports = {
    asyncActionError,
};
