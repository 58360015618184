require('./Button_Auth.scss');

const b = require('app/www/libs/b')('button_auth');

const React = require('react');
const PropTypes = require('prop-types');

const {withI18nRef} = require('app/www/components/contexts/I18n');

const Button = require('app/www/components/blocks/Button/Button');
const Global = require('app/www/components/blocks/Global/Global');
const Icon = require('app/www/components/blocks/Icon/Icon');
const Modal = require('app/www/components/blocks/Modal/Modal');

class Button_Auth extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this._onClick = this._onClick.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onClickCancel = this._onClickCancel.bind(this);
    }

    render() {
        const {i18n} = this.props;
        const url = `${Global.passportUrl}/auth?origin=${Global.id}&retpath=${Global.retpath}`;

        const buttonProps = Object.assign({}, this.props, {
            url,
            onClick: this._onClick,
            onMouseEnter: this._onMouseEnter,
            onMouseLeave: this._onMouseLeave,
        });

        const modalProps = {
            owner: this.node,
        };

        const iconWannaSeeProps = {
            mods: {
                size: 'm',
                'wanna-see': true,
            },
        };

        const iconFavoriteChannelProps = {
            mods: {
                size: 'm',
                'favorite-channel': true,
            },
        };

        const buttonEnterProps = {
            mods: {
                theme: 'action',
                size: 'l',
            },
            url,
        };

        const buttonCancelProps = {
            mods: {
                theme: 'clear',
                size: 'l',
            },
            onClick: this._onClickCancel,
        };

        return [
            <Button
                key="button"
                ref={ref => (this._button = ref)}
                {...buttonProps}
            />,
            this.state.modal && (
                <Modal
                    key="modal"
                    ref={ref => (this._modal = ref)}
                    {...modalProps}
                >
                    <div className={b('modal-content')}>
                        <div className={b('modal-title')}>
                            {i18n.get('button.auth.modal-title')}
                        </div>
                        <div className={b('modal-list')}>
                            <div className={b('modal-item')}>
                                <Icon className={b('modal-icon')} {...iconWannaSeeProps} />
                                {i18n.get('button.auth.modal-wanna-see')}
                            </div>
                            <div className={b('modal-item')}>
                                <Icon className={b('modal-icon')} {...iconFavoriteChannelProps} />
                                {i18n.get('button.auth.modal-favorite-channel')}
                            </div>
                        </div>
                        <Button className={b('modal-button')} {...buttonEnterProps}>
                            {i18n.get('button.auth.modal-button-enter')}
                        </Button>
                        <Button className={b('modal-button')} {...buttonCancelProps}>
                            {i18n.get('button.auth.modal-button-cancel')}
                        </Button>
                    </div>
                </Modal>
            ),
        ];
    }

    /**
     * @returns {Object}
     */
    get node() {
        return this._button && this._button.node;
    }

    /**
     * Выполняем функцию-обработчик для кнопки
     * Показываем модальное окно авторизации
     * @param {Object} evt
     * @private
     */
    _onClick(evt) {
        if (!this.props.hasModal) {
            return;
        }

        evt.preventDefault();
        evt.stopPropagation();

        this._event('onClick', evt);

        if (!this.state.modal) {
            this.setState({
                modal: true,
            });
        } else {
            this._modal.open();
        }
    }

    /**
     * @private
     */
    _onMouseLeave(evt) {
        evt.stopPropagation();

        this._event('onMouseLeave', evt);
    }

    /**
     * @private
     */
    _onMouseEnter(evt) {
        evt.stopPropagation();

        this._event('onMouseEnter', evt);
    }

    /**
     * @private
     */
    _onClickCancel() {
        this._modal.close();
    }

    /**
     * @param {String} name
     * @param {Object} evt
     * @private
     */
    _event(name, evt) {
        const handler = this.props[name];

        if (handler) {
            handler(evt);
        }
    }
}

Button_Auth.defaultProps = {
    hasModal: true,
    onClick: () => {},
};

Button_Auth.propTypes = Object.assign({
    hasModal: PropTypes.bool,
}, Button.propTypes);


module.exports = withI18nRef(Button_Auth);
