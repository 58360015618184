const b = require('app/www/libs/b')('image', true);

const React = require('react');
const PropTypes = require('prop-types');

const Image = (props) => {
    const {className, mods} = props;

    return <img className={b.mix(className, mods)} {...props.attrs} />;
};

Image.propTypes = {
    mods: PropTypes.shape({
        type: PropTypes.string,
    }),
    attrs: PropTypes.shape({
        src: PropTypes.string,
        title: PropTypes.string,
        width: PropTypes.number,
        height: PropTypes.number,
    }).isRequired,
};

module.exports = Image;
