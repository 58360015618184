require('./Suggest.scss');

const b = require('app/www/libs/b')('suggest');

const _ = {
    get: require('lodash/get'),
};

const {parse, stringify} = require('querystring');
const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const fetch = require('app/www/libs/fetch');

const Input = require('app/www/components/blocks/Input/Input');
const Popup = require('app/www/components/blocks/Popup/Popup');
const SuggestItem = require('app/www/components/blocks/SuggestItem/SuggestItem');

class Suggest extends React.PureComponent {

    constructor(props) {
        super(props);

        const {searchText} = this.props;

        this.state = {
            focused: false,
            popup: false,
            value: searchText ? decodeURIComponent(searchText) : '',
            content: '',
        };

        this._getSuggest = this._getSuggest.bind(this);
        this._bemjsonParse = this._bemjsonParse.bind(this);
        this._getUrl = this._getUrl.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }


    static getDerivedStateFromProps(nextProps) {
        return {
            focused: nextProps.focused,
        };
    }

    render() {
        const {
            placeholder,
            disabled,
            isMobile,
            superBanner,
        } = this.props;

        const hasSuperBanner = superBanner && isMobile;
        const {value, popup, content, focused} = this.state;

        const popupProps = {
            directions: 'bottom-left',
            offset: {
                top: 20,
                left: 0,
            },
            mods: {
                theme: 'normal',
                'suggest-mobile': isMobile,
                suggest: true,
            },
            hasOverlay: isMobile,
            hasSuperBanner,
            isMobileSuggest: isMobile,
        };

        const inputProps = {
            focused,
            mods: {
                pin: 'round-clear',
                size: 'm',
                theme: 'normal',
                type: 'search',
            },
            attrs: {
                name: 'text',
                type: 'text',
                placeholder: placeholder,
                autoComplete: 'off',
                value,
            },
        };

        return (
            <div className={b()}>
                <Input
                    ref={elem => (this._input = elem)}
                    onFocus={this._onFocus}
                    onBlur={this._onBlur}
                    onInput={this._getSuggest}
                    onKeyDown={this._onKeyDown}
                    {...inputProps}
                />
                {popup && !disabled && (
                    <Popup
                        ref={elem => (this._popup = elem)}
                        owner={this._input.node}
                        {...popupProps}
                    >
                        <div
                            className={b('wrapper')}
                            onMouseEnter={this._onMouseEnter}
                        >
                            {content}
                        </div>
                    </Popup>
                )}
            </div>
        );
    }

    /**
     * Сохраняем в state данные, показываем попап
     * @param {Object} [data]
     */
    open(data) {
        const {popup} = this.state;

        if (data) {
            const {onOpen} = this.props;
            const {value} = this._input.node;
            this._bemjson = this._bemjsonParse(data);

            this.setState({
                popup: true,
                content: this._createItems(this._bemjson).content,
            });

            onOpen(value);
        }

        if (this._itemsLength && popup) {
            this._popup.open();
        }

    }

    /**
     * Скрываем попап
     */
    close() {
        const {onSelectItem, onClose} = this.props;
        const {popup} = this.state;

        onSelectItem();

        if (popup) {
            this._popup.close();
        }

        onClose();
    }

    /**
     * Делаем запрос к ручке, показываем/скрываем попап
     * @param {String} value значение поля input
     * @private
     */
    _getSuggest(value) {
        this.setState({
            value,
        });

        if (this.props.disabled) {
            return f => f;
        }

        fetch(this._getUrl(value), {
            method: 'get',
            mode: 'cors',
        }).then(data => {
            this._selectedItemNumber = 0;
            this._itemsLength = data[1].length;

            if (this._itemsLength) {
                this.open(data);
            } else {
                this.close();
            }
        }).catch(console.error);
    }

    /**
     * Возвращает хост саджеста c GET параметрами
     * @param {String} value значение поле input
     * @return {String}
     * @private
     */
    _getUrl(value) {
        let {url, params} = this.props;

        url = url.split('?');

        params = Object.assign(
            parse(url[1] || ''),
            params, {
                part: value,
            });

        return `${url[0]}?${stringify(params)}`;
    }

    /**
     * Создает разметку из приведенных данных
     * @param {Object} data ответ саджеста
     * @return {Object}
     * @private
     */
    _createItems(data = {}) {
        const {onSelectItem, onClickItem} = this.props;

        let itemsLength = 0;
        let value = '';

        const content = Object.keys(data).map((type, typeIndex) => {
            const group = (
                <div key={type} className={b('group')}>
                    <div className={b('title')}>{type}</div>
                    <ul className={b('items')}>
                        {data[type].map((item, itemIndex) => {
                            const bemjson = item.data.bemjson[0];
                            const bemjsonContent = bemjson.content;

                            const isLast = typeIndex === Object.keys(data).length - 1;
                            const itemNumber = ++itemIndex + itemsLength;
                            const isSelected = !isLast && itemNumber === this._selectedItemNumber;

                            if (isSelected) {
                                value = item.name;
                                onSelectItem(item.data.url);
                            }

                            const suggestItemProps = {
                                title: item.name,
                                url: item.data.url,
                                text: _.get(bemjsonContent, '[2].content', ''),
                                img: bemjson.png || bemjsonContent[0].src,
                                mods: {
                                    hovered: isSelected,
                                    'with-info': bemjson.elem === 'info',
                                },
                                onClick: () => onClickItem(this._input.node.value, itemNumber),
                            };

                            return (
                                <SuggestItem key={item.data.url} {...suggestItemProps} />
                            );
                        })}
                    </ul>
                </div>
            );

            itemsLength += data[type].length;

            return group;
        });

        return {
            content,
            value,
        };
    }

    /**
     * Разбивает ответ на группы по полю label
     * @param {Object} data ответ саджеста
     * @return {Object}
     * @private
     */
    _bemjsonParse(data) {
        const categories = {};
        let items = [];

        data[1].reduce((previous, current) => {
            if (current[2].label !== previous) {
                items = [];
            }

            items.push({
                name: current[1],
                data: current[2],
            });

            categories[current[2].label] = items;

            return current[2].label;
        }, data[1][0][2].label);

        return categories;
    }

    /**
     * @param {Object} evt
     * @private
     */
    _onKeyDown(evt) {
        if (['ArrowDown', 'ArrowUp'].includes(evt.key)) {
            evt.preventDefault();

            const {onSelectItem} = this.props;

            if (evt.key === 'ArrowDown') {
                this._selectedItemNumber++;

                if (this._selectedItemNumber > this._itemsLength - 1) {
                    this._selectedItemNumber = 0;
                    onSelectItem();
                }
            }

            if (evt.key === 'ArrowUp') {
                this._selectedItemNumber--;

                if (this._selectedItemNumber < 1) {
                    this._selectedItemNumber = this._itemsLength;
                    onSelectItem();
                }
            }

            this.setState(
                this._createItems(this._bemjson)
            );
        }
    }

    /**
     * @private
     */
    _onMouseEnter() {
        if (this._selectedItemNumber) {
            this._selectedItemNumber = 0;
            this.props.onSelectItem();

            this.setState(
                this._createItems(this._bemjson)
            );
        }
    }

    /**
     * @private
     */
    _onFocus() {
        this.props.onFocus();
        this.open();
    }

    /**
     * @private
     */
    _onBlur() {
        this.props.onBlur();
    }

}

function mapStateToProps(state) {
    return {
        superBanner: state.superBanner,
    };
}

Suggest.defaultProps = {
    onOpen: () => {},
    onClose: () => {},
    onClickItem: () => {},
    onBlur: () => {},
    onFocus: () => {},
};

Suggest.propTypes = {
    url: PropTypes.string.isRequired,
    params: PropTypes.object.isRequired,
    placeholder: PropTypes.string,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
    onSelectItem: PropTypes.func,
    onClickItem: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    isMobile: PropTypes.bool,
};

module.exports = connect(
    mapStateToProps
)(Suggest);

