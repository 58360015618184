require('./Tooltip.scss');
require('./_Size/Tooltip_Size_XS.scss');
require('./_Size/Tooltip_Size_S.scss');
require('./_Size/Tooltip_Size_M.scss');
require('./_Size/Tooltip_Size_L.scss');
require('./_Theme/Tooltip_Theme_Error.scss');
require('./_Theme/Tooltip_Theme_Normal.scss');
require('./_Theme/Tooltip_Theme_Success.scss');

const b = require('app/www/libs/b')('tooltip');

const React = require('react');
const PropTypes = require('prop-types');
const Popup = require('app/www/components/blocks/Popup/Popup');
const Ua = require('app/www/components/blocks/Ua/Ua');

class Tooltip extends React.PureComponent {

    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
    }

    render() {
        if (Ua.isTouch) {
            return <span />;
        }

        const {
            mods,
            owner,
            to,
            isAdaptive,
            children,
        } = this.props;

        const popupProps = {
            owner,
            directions: to,
            tail: true,
            clickOut: false,
            isAdaptive,
        };

        return (
            <Popup
                ref={elem => (this.popup = elem)}
                className={b(mods)}
                {...popupProps}
            >
                {children}
            </Popup>
        );
    }

    open() {
        if (!Ua.isTouch) {
            this.popup.open();
        }
    }

    close() {
        if (!Ua.isTouch) {
            this.popup.close();
        }
    }

}

Tooltip.propTypes = {
    mods: PropTypes.shape({
        size: PropTypes.oneOf(['xs', 's', 'm', 'l']).isRequired,
        theme: PropTypes.oneOf(['success', 'error', 'normal']).isRequired,
    }).isRequired,
    owner: Popup.propTypes.owner,
    to: PropTypes.oneOf([
        'top',
        'right',
        'bottom',
        'left',
    ]),
    isAdaptive: PropTypes.bool,
};

module.exports = Tooltip;

