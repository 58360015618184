const b = require('app/www/libs/b')('grid');

const _ = {
    get: require('lodash/get'),
};

const React = require('react');
const PropTypes = require('prop-types');

const {withI18n} = require('app/www/components/contexts/I18n');
const {withReq} = require('app/www/components/contexts/Req');

const GridChannel = require('app/www/components/blocks/GridChannel/GridChannel');
const GridEvents = require('app/www/components/blocks/GridEvents/GridEvents');
const Link = require('app/www/components/blocks/Link/Link');
const Ua = require('app/www/components/blocks/Ua/Ua');

class GridItem extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    render() {
        const {
            req,
            i18n,
            mods,
            isNow,
            channel,
            events,
            selectedDate,
            onMouseEnter,
            onMouseLeave,
            onClick,
        } = this.props;

        const {url} = channel;
        const {
            events: eventsList,
            selectedProgramTypes = [],
        } = events;

        const eventsProgramTypes = {
            selectedDate,
        };

        eventsProgramTypes.events = eventsList.reduce((eventsWithType, event) => {
            const activeType = event.program.type.alias;

            if (selectedProgramTypes.includes(activeType)) {
                event.activeType = activeType;
                eventsWithType.push(event);
            } else {
                delete event.activeType;

                if (!Ua.isTouchPhone) {
                    eventsWithType.push(event);
                }
            }

            return eventsWithType;
        }, []);

        if (Ua.isTouchPhone && !eventsProgramTypes.events.length && selectedProgramTypes.length) {
            return null;
        }

        const isSerpApp = req.lib('urlBuilder').isSerpApp;
        const GridEventsProps = Object.assign({}, eventsProgramTypes, events);
        const GridChannelProps = Object.assign({}, channel, {
            isSerpApp,
        });
        const hasEvents = Boolean(_.get(events, 'events', []).length);

        return (
            <div className={b('item', mods)}>
                <GridChannel
                    onClick={onClick}
                    {...GridChannelProps}
                />
                <GridEvents
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={onClick}
                    {...GridEventsProps}
                />
                {isNow && Ua.isTouchPhone && hasEvents && (
                    <Link className={b('all-day')} url={url}>
                        {i18n.get('grid-events.all-day')}
                    </Link>
                )}
            </div>
        );
    }
}

GridItem.propTypes = {
    channel: PropTypes.shape(
        GridChannel.propTypes
    ).isRequired,
    events: PropTypes.shape(
        GridEvents.propTypes
    ).isRequired,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
};

module.exports = withReq(withI18n(GridItem));
