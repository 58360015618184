/**
 * Названия кук
 */

module.exports = {
    USER_DISPLAY: 'user_display',
    TEASER_DISPLAY: 'teaser_popup',
    TEASER_HD_DISPLAY: 'teaser_hd',
    SUPER_BANNER_HIDDEN: 'super_banner_hidden',
    FULLSCREEN_AD: 'fullscreen_ad',
};
