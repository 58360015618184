// @see https://wiki.yandex-team.ru/users/maritime/tv/params

const Metrika = require('app/www/components/blocks/MetrikaManager/MetrikaManager');
const urlBuilder = require('app/common/libs/urlBuilder');
const scheduleSettings = require('app/common/constants/schedule-settings');

const _ = {
    isEmpty: require('lodash/isEmpty'),
    set: require('lodash/set'),
    setWith: require('lodash/setWith'),
    capitalize: require('lodash/capitalize'),
};
const req = require('app/www/libs/client/req');

const GOALS = {
    LOGO_CLICK: 'LOGO_CLICK',
    TUTORIAL_SMS: 'TUTORIALSMS',
    TUTORIAL_LIKE: 'TUTORIALLIKE',
    UPDATE_LIST: 'UPDATE_LIST',
    UPDATE_MY_LIST: 'UPDATE_MY_LIST',
    POPUP_REMINDERS_OPEN: 'OPENSETTINGS',
    POPUP_REMINDERS_ADD_PHONE: 'ADDPHONE',
    POPUP_REMINDERS_CONFIRM_PHONE: 'PHONECONFIRM',
    POPUP_REMINDERS_NOTIFICATIONS_DISABLED: 'NOTIFICATIONSOFF',
    // https://st.yandex-team.ru/TVFRONT-1713
    ALARM_CLICK: 'ALARM_CLICK',
    // https://beta.wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#zakryvaemtune
    TUNE_CLOSE: 'FILTER_CHANNELS_CLOSE',
    // https://beta.wiki.yandex-team.ru/users/maritime/tv/params/#trailerplay
    TRAILER_START: 'TRAILER_START',
    TRAILER_50: 'TRAILER_50',
    TRAILER_90: 'TRAILER_90',
    TRAILER_FILMS_START: 'TRAILER_FILMS_START',
    TRAILER_SERIES_START: 'TRAILER_SERIES_START',
    TRAILER_SPORT_START: 'TRAILER_SPORT_START',
    TRAILER_CHILDREN_START: 'TRAILER_CHILDREN_START',
    TRAILER_INFO_START: 'TRAILER_INFO_START',
    TRAILER_AD_START: 'TRAILER_AD_START',
    TRAILER_AD_START_PREROLL: 'TRAILER_AD_START_PREROLL',
    TRAILER_AD_START_POSTROLL: 'TRAILER_AD_START_POSTROLL',
    TRAILER_AD_DONE: 'TRAILER_AD_DONE',
    TRAILER_PLAY_AFTER_AD: 'TRAILER_PLAY_AFTER_AD',
    MY_TP_NO_WITH_LOGIN: 'MY_TP_LOGGED_NO',
    MY_TP_NO_WITH_LOGIN_YES: 'MY_TP_LOGGED_NO_YES',
    ALARM_NO_WITH_LOGIN: 'ALARM_LOGGED_NO',
    ALARM_NO_WITH_LOGIN_YES: 'ALARM_LOGGED_NO_YES',
    ALARM_NO_WITH_LOGIN_YES_CONFIRM: 'ALARM_LOGGED_NO_YES_CONFIRM',
    MYTP_NOT_EMPTY: 'MYTP_NOT_EMPTY',
    // https://wiki.yandex-team.ru/users/maritime/tv/goals/#43
    REGION_POPUP_SHOW: 'REGION_POPUP_SHOW',
    REGION_POPUP_YES: 'REGION_POPUP_YES',
    TEASER_IS_NOT_BOUNCE: 'TEASER_IS_NOT_BOUNCE',
    VIDEO_START: 'VIDEO_START',
    VIDEO_50: 'VIDEO_50',
    VIDEO_90: 'VIDEO_90',
    VIDEO_AD_START: 'VIDEO_AD_START',
    VIDEO_AD_DONE: 'VIDEO_AD_DONE',
    TV_ONLINE_PLAYER_START: 'TV_ONLINE_PLAYER_START',
    TV_ONLINE_PLAYER_30SECONDS: 'TV_ONLINE_PLAYER_30SECONDS',
    TV_ONLINE_PLAYER_3MINUTES: 'TV_ONLINE_PLAYER_3MINUTES',
    ONLINE_PLAYER_NOT_BOUNCE: 'ONLINE_PLAYER_NOT_BOUNCE',
    BRANDING_SHOW: 'BRANDING_SHOW',
    BANNER_SHOW: 'BANNER_SHOW',
    BRANDING_CLICK: 'BRANDING_CLICK',
    BANNER_CLICK: 'BANNER_CLICK',
};

const PARAMS = {
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#loggedin
    LOGGED: 'LOGGED',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#gridtype
    GRID_TYPE: 'GRID_TYPE',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#refererschannels
    REFERERS_CHANNELS: 'REFERERS_CHANNELS',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#likesforchannels
    LIKES_FOR_CHANNELS: 'LIKES_FOR_CHANNELS',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#mychannelssources
    MY_CHANNELS_SOURCES: 'MY_CHANNELS_SOURCES',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#mychannelslist
    MY_CHANNELS_LIST: 'MY_CHANNELS_LIST',
    CHOOSE_CHANNELS: 'CHOOSE_CHANNELS',
    WANNA_SEE: 'WANNA_SEE',
    GENRE_CHOOSE: 'GENRE_CHOOSE',
    REMINDER_CHOOSE: 'REMINDER_CHOOSE',
    PROVIDERS_CHOOSE: 'PROVIDERS_CHOOSE',
    CHANNEL_TITLE: 'CHANNEL_TITLE',
    TV_EVENT: 'TV_EVENT',
    TV_RECOMMENDED: 'TV_RECOMMENDED',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#ad
    RTB_ADVMANAGER_RESPONSE: 'RTB_ADVMANAGER_RESPONSE',
    BALLOON: 'BALLOON',
    BALLOON_SHOW: 'BALLOON_SHOW',
    BALLOON_CLICK: 'BALLOON_CLICK',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#genresmain
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#genreschannelpage
    GRID_GENRES: 'GRID_GENRES',
    REMINDERS_POPUP_TOGGLE: 'REMINDERS_POPUP_TOGGLE',
    LANG_SWITCHER: 'LANG_SWITCHER',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#providers
    GRID_PROVIDER: 'GRID_PROVIDER',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#region
    CHANGE_REGION_TRACK: 'CHANGE_REGION_TRACK',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#region
    CHANGE_REGION_COMPARE: 'CHANGE_REGION_COMPARE',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#tune
    REFERER_TUNE: 'REFERER_TUNE',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#serp
    REFERER_SERP: 'REFERER_SERP',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#serptune
    SERP_TUNE_SHOW: 'SERP_TUNE_SHOW',
    SERP_TUNE_CLICK: 'SERP_TUNE_CLICK',
    // https://wiki.yandex-team.ru/users/maritime/tvtouch/metrics/#referersprograms
    REFERERS_PROGRAMS: 'REFERERS_PROGRAMS',
    // https://st.yandex-team.ru/TVFRONT-2647
    EMBEDDED_SHOW: 'EMBEDDED_SHOW',
    EMBEDDED_CLICK: 'EMBEDDED_CLICK',
    // https://beta.wiki.yandex-team.ru/users/maritime/tv/params/#trailers
    TRAILERS: 'TRAILERS',
    // https://beta.wiki.yandex-team.ru/users/maritime/tv/params/#onlinepopup
    ONLINE_POPUP: 'ONLINE_POPUP',
    // https://beta.wiki.yandex-team.ru/users/maritime/tv/params/#playertabs
    PLAYER_TABS: 'PLAYER_TABS',
    // https://wiki.yandex-team.ru/users/maritime/tv/params/#29
    REGION_POPUP: 'REGION_POPUP',
    // https://st.yandex-team.ru/TVFRONT-2400#1448627092000
    EXPERIMENTS: 'EXPERIMENTS',
    // https://st.yandex-team.ru/TVFRONT-3627
    ENTRY_POINT: 'ENTRY_POINT',
    // https://st.yandex-team.ru/TVFRONT-2588
    PROJECT_ENTRY_POINT: 'PROJECT_ENTRY_POINT',
    // https://st.yandex-team.ru/TVFRONT-2505
    SUGGEST: 'SUGGEST',
    ADV_BLOKED: 'ADV_BLOKED',
    // https://st.yandex-team.ru/KPAT-241
    PROGRAM_PAGE: 'PROGRAM_PAGE',
    SEARCH_PAGE: 'SEARCH_PAGE',
    CHANNEL_TYPES: 'CHANNEL_TYPES',
    ONLINE_SPECIAL: 'ONLINE_SPECIAL',
    TRAILERS_SPECIAL: 'TRAILERS_SPECIAL',
    VIDEOS_SPECIAL: 'VIDEOS_SPECIAL',
    LIVE: 'LIVE',
    TEASER: 'TEASER',
    MY_PAGE: 'MY_PAGE',
    LISTS: 'LISTS',
    VOD: 'VOD',
    BRANDING: 'BRANDING',
    PROJECT_SEARCH: 'PROJECT_SEARCH',
    SCROLL: 'SCROLL',
    FOOTER: 'FOOTER',
};

/**
 * Есть логин на Яндексе?
 * @private
 * @return {Boolean}
 */
function userHasYandexLogin() {
    return 'L' in req.cookies;
}

/**
 * Когда есть логин на Яндексе
 * Если у человека есть логин на яндекс, переопределяется название целей
 * @link https://st.yandex-team.ru/TVFRONT-1718
 * @link https://st.yandex-team.ru/TVFRONT-1764
 * @private
 */
function thereIsLoginYandex() {
    if (userHasYandexLogin()) {
        GOALS.MY_TP_NO_WITH_LOGIN = 'MY_TP_NO_WITH_LOGIN';
        GOALS.MY_TP_NO_WITH_LOGIN_YES = 'MY_TP_NO_WITH_LOGIN_YES';
        GOALS.ALARM_NO_WITH_LOGIN = 'ALARM_NO_WITH_LOGIN';
        GOALS.ALARM_NO_WITH_LOGIN_YES = 'ALARM_NO_WITH_LOGIN_YES';
        GOALS.ALARM_NO_WITH_LOGIN_YES_CONFIRM = 'ALARM_NO_WITH_LOGIN_YES_CONFIRM';
    }
}

/**
 * Track metrika params
 * @param {String} action `PARAMS` map key
 * @param {Object|Object[]|Vow.promise} [data] additional data
 */
function trackParams(action, data) {
    const param = buildParams(PARAMS[action], data);

    if (param) {
        Metrika.params(param);
    }
}

/**
 * @param {String} target
 * @param {String} [prefix]
 * @link https://yandex.ru/support/metrika/objects/reachgoal.xml
 */
function reachGoal(target, prefix = '') {
    const goalsName = GOALS[target] || target;
    Metrika.reachGoal(`${prefix}${goalsName}`);
}

/**
 * @param {Object} param
 * @link https://yandex.ru/support/metrika/objects/params-method.xml
 */
function params(param) {
    Metrika.params(param);
}

/**
 * @param {String} URL текущей страницы
 * @param {Object} options
 * @see https://yandex.ru/support/metrika/objects/hit.xml
 */
function hit(url, options) {
    Metrika.hit(url, options);
}

/**
 * Строим параметры для метода метрики `params`
 * @param {String} action
 * @param {*} [data]
 * @returns {Object|Promise}
 * @example metrika.buildParams(metrika.PARAMS.LOGGED) → {"logged_in": "yes"}
 * @todo to:ixax реальные дата-провайдеры для пакетов провайдера, настроек пользователя, и т.д.
 */
function buildParams(action, data) {
    const iRouter = {};
    const iSessionStorage = {};
    const iRegion = {};

    const isTouchPhone = false;
    const isIndex = urlBuilder.isIndexUrl;
    const isChannel = urlBuilder.isChannelUrl;
    const isTune = false;
    const isSearch = urlBuilder.isSearchUrl;
    const isFavorites = urlBuilder.isFavoritesUrl;
    const isProgram = urlBuilder.isProgramUrl;
    const isLists = urlBuilder.isListsUrl;
    const isSpecial = urlBuilder.isSpecialUrl;
    const isProject = urlBuilder.isSpecialSportUrl;
    const referer = document.referrer;
    const getGridParam = urlBuilder.getGridParam;
    const isMySchedule = getGridParam === scheduleSettings.MY;

    let result;
    let param;
    let obj;
    let location;
    let root;
    let type;

    thereIsLoginYandex();

    switch (action) {
        case PARAMS.LOGGED:
            result = {
                logged_in: data
                    ? 'yes'
                    : userHasYandexLogin() ? 'no_with_login' : 'no',
            };
            break;

        case PARAMS.GRID_TYPE:
            const gridName = `${data.toUpperCase()}_channels`;
            result = {Grid_type: {}};

            result['Grid_type'][gridName] = {};

            break;

        case PARAMS.GRID_PROVIDER:
            const providerName = data.providerName;
            const packages = data.packages;
            const anyProviders = !_.isEmpty(data.providersForRegion);

            if (!providerName && !anyProviders) {
                return {Provider: 'not'};
            }

            if (anyProviders && _.isEmpty(packages)) {
                return {Provider: 'empty'};
            }

            result = {Provider: {}};
            result['Provider'][providerName] = packages.map(pack => {
                return pack.title;
            });

            break;

        // Write current region to session storage
        case PARAMS.CHANGE_REGION_TRACK:
            // result = iSessionStorage.set('this.getName()_change_region', iRegion.getName().nominative, 300);
            break;

        // Does user back to `/providers` from `tune region`?
        case PARAMS.CHANGE_REGION_COMPARE:
            const storageKey = 'this.getName()_change_region';
            if (
                ((isTouchPhone && iRouter.isProviders()) || isIndex) &&
                iRouter.getReferer().match(iRouter.getTuneRegionUrl(false)) &&
                iSessionStorage.isset(storageKey)
            ) {
                result = _.set({}, ['Region', iRegion.getName().nominative], iSessionStorage.get(storageKey));
                iSessionStorage.remove(storageKey);
            }
            break;

        case PARAMS.LIKES_FOR_CHANNELS:
            const likeParam = !data.isFavorite ? 'likes' : 'dislikes';
            let tuneParam;

            if (isIndex) {
                param = 'main';
                tuneParam = isMySchedule ? 'my' : 'other';
            } else if (isChannel) {
                param = 'channelpage';
            } else if (isProgram) {
                param = 'programpage';
            } else if (isSearch) {
                param = 'from_search';
            }

            if (param) {
                result = {Likes_for_channels: {}};
                result['Likes_for_channels'][likeParam] = {};
                result['Likes_for_channels'][likeParam][param] = {common: data.channelId};

                if (tuneParam) {
                    result['Likes_for_channels'][likeParam][param][tuneParam] = {};
                    result['Likes_for_channels'][likeParam][param][tuneParam] = data.channelId;
                }
            }

            break;

        case PARAMS.MY_CHANNELS_LIST:
            switch (data) {
                case 'dragStart':
                    obj = {drag: 'start'};
                    break;
                case 'dragStop':
                    obj = {drag: 'done'};
                    break;
                case 'moveStart':
                    obj = {move: 'start'};
                    break;
                case 'moveStop':
                    obj = {move: 'done'};
                    break;
            }

            result = {My_channels_list: obj};
            break;

        case PARAMS.CHOOSE_CHANNELS:
            const {
                type: gridType,
                favoriteChannelIds,
            } = data;


            let typeGridValue = isMySchedule ? `${gridType}_default` : 'empty';

            if (favoriteChannelIds.length) {
                typeGridValue = isMySchedule ? `${gridType}_with_my` : 'not empty';
            }

            result = {choose_channels: {}};
            result.choose_channels[gridType] = typeGridValue;
            break;

        case PARAMS.WANNA_SEE:
            let logged = data.isLogged ? 'logged-in' : 'logged-out';

            if (isProgram) {
                location = 'from_program_page';
                if (isTouchPhone) {
                    location = 'programpage';
                }
            } else if (data && data.balloon) {
                location = 'from_balloon';
            } else if (isSearch) {
                location = 'from_search';
            } else if (data && data.project) {
                location = (isTouchPhone ? 'SP_' : 'from_SP_') + data.project;
            }

            if (data.genre) {
                obj = _.set({}, [location, logged], data.genre);
            } else {
                obj = _.set({}, location, logged);
            }
            result = {MyTP_add_to: obj};
            break;

        /**
         * @param {Object} data
         * @param {String} data.master Clicked on it
         * @param {Array} data.slave has been clicked before
         */
        case PARAMS.GENRE_CHOOSE:
            let master = data.master;
            let slave = data.slave ? data.slave.slice() : [];

            if (isTouchPhone) {
                result = _.set({}, ['Genres', master], {});
            } else {
                if (master) {
                    if (slave.length) {
                        slave.splice(slave.indexOf(master), 1);
                    }
                } else {
                    master = slave.splice(0, 1)[0];
                }

                result = _.set({}, ['Genres', master], [master].concat(slave.sort()).join(' + '));
            }

            break;

        case PARAMS.REMINDER_CHOOSE:
            root = 'Notifications';
            type = data.checked ? 'on' : 'off';
            let key;

            if (data.popup) {
                key = 'from_balloon';
            } else if (isChannel) {
                key = 'from_channel_page';
            } else if (isProgram) {
                key = 'from_program_page';
            } else if (isSearch) {
                key = 'from_search';
            } else if (isFavorites) {
                key = 'from_my';
            } else if (isProject) {
                key = 'from_SP';
            } else if (isLists) {
                key = 'from_lists';
            } else {
                key = 'from_other';
            }

            result = _.set({}, [root, key], type);

            break;

        case PARAMS.PROVIDERS_CHOOSE:
            if (data !== 'empty') {
                let selectedProvider = data.selectedProvider[0].title || '';
                let selectedPackages = data.selectedProvider[0].packages || [];
                let namePackages = [];

                selectedPackages.forEach(packageItem => {
                    if (data.packagesId.indexOf(packageItem.id) !== -1) {
                        namePackages.push(packageItem.title);
                    }
                });

                result = {Provider: {}};
                result['Provider'] = {};
                result['Provider'][selectedProvider] = namePackages;
            } else {
                result = {Provider: data};
            }

            break;

        case PARAMS.CHANNEL_TITLE:
            if (isIndex) {
                obj = {from_main: data};
            } else if (isFavorites) {
                obj = 'from_my';
            } else if (isProgram) {
                obj = 'from_program_page';
            } else if (isSearch) {
                data = data || {};
                obj = _.set({}, ['from_search', data.tabId], data.channelId);
            } else if (isProject) {
                obj = {from_SP: {}};
            }

            result = {Channels: obj};
            break;

        case PARAMS.TV_EVENT:
            let from;
            data = data || {};
            root = 'Programs';
            obj = [root];

            if (data.mods) {
                if (data.mods.link) {
                    return;
                }

                type = data.mods.balloon ? 'balloon'
                    : data.mods.watchon ? 'watchon'
                    : data.mods.picture ? 'recommended' : 'usual';
            }

            if (isIndex) {
                obj.push('from_main');
                from = data.balloon ? 'from_balloon' : 'from_program_title';
            } else if (isChannel) {
                from = `from_channelpage_${type}`;
            } else if (isFavorites) {
                obj.push('from_my');
            } else if (isSearch) {
                obj = [root, 'from_search', data.tabId];
            } else if (isProject && !isTouchPhone && data.project) {
                from = 'from_SP';
            }

            if (data.param) {
                obj.push(data.param);
            } else if (from) {
                obj.push(from);

                if (data.genre) {
                    obj.push(data.genre);
                }
            }

            result = _.set({}, obj, data.programId);

            break;

        case PARAMS.TV_RECOMMENDED:
            type = data.type === 'editorial' ? 'OMG' : 'WTF';
            type = data.type === 'indexpage' ? 'now&soon' : type;

            obj = `from_${type}`;

            result = {Programs: {}};
            result['Programs'][obj] = data.genre;

            break;

        case PARAMS.RTB_ADVMANAGER_RESPONSE:
            result = _.set({}, ['ad', data.id], data.flag ? 'received' : 'missing');
            break;

        case PARAMS.BALLOON:
            result = {BALLOON: data};
            break;

        case PARAMS.BALLOON_SHOW:
            result = {balloon_show: data};
            break;

        case PARAMS.BALLOON_CLICK:
            result = {balloon_click: data};
            break;

        /**
         * @param {String} data genre title
         */
        case PARAMS.GRID_GENRES:
            if (isTouchPhone) {
                if (isIndex) {
                    result = _.set({}, ['Genres_main', data], {});
                } else if (isChannel) {
                    result = _.set({}, ['Genres_channelpage', data], {});
                }
            }
            break;

        case PARAMS.REMINDERS_POPUP_TOGGLE:
            result = {Notifications_settings: {}};
            result.Notifications_settings[data] = {};
            break;

        case PARAMS.LANG_SWITCHER:
            result = {'lang-switcher': data};
            break;

        case PARAMS.REFERER_TUNE:
            let refererIndex = iRouter.isIndex(referer);
            let refererChannel = iRouter.isChannel(referer);

            if (isChannel && data) {
                param = {channelpage: data};
            } else if (isIndex && data) {
                param = {main: data};
            } else if (iRouter.isIndex(referer) && iRouter.isFillGridParam()) {
                param = {main: 'button_fill_my_grid'};
            } else if (iRouter.isTune(referer) && iRouter.isFillGridParam()) {
                param = {tune_page: 'button_fill_my_grid'};
            } else if (isTune && !refererChannel && !refererIndex) {
                param = 'direct';
            }

            if (param) {
                result = {tune: {}};
                result.tune = param;
            }
            break;

        case PARAMS.REFERER_SERP:
            if (iRouter.isChannel(referer)) {
                param = 'channelpage';
            } else if (iRouter.isIndex(referer)) {
                param = 'main';
            } else if (iRouter.isProgram(referer)) {
                param = 'programpage';
            } else if (iRouter.isSearch(referer)) {
                param = 'searchresults';
            }

            if (param) {
                result = {SERP: {}};
                result.SERP[param] = {};
            }
            break;

        case PARAMS.SERP_TUNE_SHOW:
            result = {SERP_tune: {show: {}}};

            break;

        // @see PARAMS.REFERERS_CHANNELS
        case PARAMS.SERP_TUNE_CLICK:
            result = {SERP_tune: {click: {}}};
            break;

        case PARAMS.MY_CHANNELS_SOURCES:
            if (isIndex && isMySchedule) {
                param = data.action === 'open' ? 'main_open_my_channels_list' : 'main_update_my_channels';
            } else if (isTune) {
                param = data.action === 'open' ? 'tune_open_my_channel_list' : 'tune_update_my_channels';
            } else if (isChannel && isMySchedule) {
                param = data.action === 'open' ? 'channel_open_my_channels' : 'channel_update_my_channels';
            }

            if (!_.isEmpty(param)) {
                result = {My_channels_sources: param};
            }
            break;

        case PARAMS.REFERERS_CHANNELS:
            root = 'Referers_channels';

            if (isIndex) {
                param = 'main';
            } else if (isSearch) {
                param = 'searchresults';
            } else if (isChannel) {
                param = 'channelpage';
            } else if (isProgram) {
                param = 'programpage';
            } else if (isTune) {
                param = 'tune';
            }

            if (param) {
                if (param === 'tune') {
                    if (data) {
                        result = _.set({}, [root, 'tune', data], {});

                        // @see PARAMS.SERP_TUNE_CLICK
                        if (data === 'suggest') {
                            result = [
                                result,
                                buildParams(PARAMS.SERP_TUNE_CLICK),
                            ];
                        }

                    }
                } else {
                    result = {};

                    ['logo', 'all_day'].forEach(suffix => {
                        result[suffix] = _.set({}, [root, `${param}_${suffix}`], {});
                    });
                }
            }

            break;

        // Pass counter type in `data`. Location will detect automatically
        case PARAMS.REFERERS_PROGRAMS:
            root = 'Referers_programs';

            switch (data) {
                case 'title':
                    if (isIndex) {
                        param = 'main_programtitle';
                    } else if (isChannel) {
                        param = 'channelpage_usual';
                    }
                    break;
                case 'watchsoon':
                    if (isIndex) {
                        param = 'main_watchsoon';
                    }
                    break;
                case 'omg':
                    if (isIndex) {
                        param = 'main_OMG';
                    }
                    break;
                case 'image':
                    if (isChannel) {
                        param = 'channelpage_recommended';
                    }
                    break;
                case 'wtf':
                    if (isProgram) {
                        param = 'programpage_WTF';
                    } else if (isChannel) {
                        param = 'channelpage_WTF';
                    } else if (isSearch) {
                        param = 'emptysearchresults_WTF';
                    }
                    break;
            }

            if (data.project) {
                data.genre = data.genre || 'empty';
                result = _.set({}, [root, `from_SP_${data.project}`], data.genre);
            } else if (param) {
                result = _.set({}, [root, param], {});
            }
            break;

        case PARAMS.EMBEDDED_SHOW:
            result = _.set(
                {},
                [
                    'online_block_show',
                    data.channelTitle,
                    data.sourceId,
                    _.capitalize(data.programType || 'no_genre'),
                    data.programTitle,
                ],
                _.set({}, data.programId, data.coId)
            );
            break;

        case PARAMS.EMBEDDED_CLICK:
            result = _.set(
                {},
                [
                    'online_block_click',
                    data.type,
                    data.channelTitle,
                    data.sourceId,
                    _.capitalize(data.programType || 'no_genre'),
                    data.programTitle,
                ],
                _.set({}, data.programId, data.coId)
            );
            break;

        case PARAMS.TRAILERS:
            result = (() => {
                let channelId = data.channel.id;
                let channelTitle = data.channel.title;
                let programType = data.programType || 'no_genre';
                let res = {};

                if (!channelId) {
                    res = _.set({}, 'no_channel', {});
                } else {
                    res = _.set(
                        {},
                        channelTitle,
                        _.set({}, channelId, programType)
                    );
                }

                return res;
            })();

            result = _.set({}, 'Trailers', result);
            break;

        case PARAMS.ONLINE_POPUP:
            if (data.location === 'header') {
                location = 'from_header';
            } else if (isIndex) {
                location = data.location === 'recommended' ? 'from_videoblock' : 'from_main';
            } else if (isChannel) {
                location = 'from_channelpage';
            } else if (isProgram) {
                location = 'from_programpage';
            } else if (isSearch) {
                location = 'from_search';
            } else if (isProject) {
                location = 'from_SP';
            } else if (isLists) {
                location = 'from_lists';
            }

            if (location) {
                const version = 'new';

                result = {
                    online_popup: data.channelId
                        ? _.setWith({}, [location, data.action, data.channelId, data.programId].filter(Boolean), version, Object)
                        : _.set({}, [location, data.action], version),
                };
            }
            break;

        case PARAMS.PLAYER_TABS:
            result = {
                player_tabs: data,
            };
            break;

        case PARAMS.REGION_POPUP:
            result = _.set({}, ['Region', data.regionName], data.regionUrlName);
            break;

        case PARAMS.EXPERIMENTS:
            result = {};

            Object.keys(data)
                .forEach(k => _.set(result, ['experiments', data[k].name], data[k].group));

            break;

        case PARAMS.ENTRY_POINT:
            let page;
            root = 'entry_point';

            if (isIndex) {
                page = 'tvmain';
            } else if (isSearch) {
                page = 'tvserp';
            } else if (isChannel) {
                page = 'tvchannel';
            } else if (isProgram) {
                page = 'tvprogram';
            } else if (isFavorites) {
                page = 'tvfavorites';
            } else if (isProject) {
                page = 'tvproject';
            } else if (isLists) {
                page = 'tvlists';
            }

            result = _.set({}, [root, data.campaign, page, data.control], data.action);

            break;

        case PARAMS.PROJECT_ENTRY_POINT:
            root = data.urlSegment + (data.isClose ? '_banner_close' : '_banner_show');

            if (isIndex) {
                param = 'main';
            } else if (isSearch) {
                param = 'searchresults';
            } else if (isChannel) {
                param = 'channelpage';
            } else if (isProgram) {
                param = 'programpage';
            } else if (isFavorites) {
                param = 'favorites';
            }

            result = _.set({}, root, param);

            break;

        case PARAMS.SUGGEST:
            const {isClick, value = '', number} = data;
            const {length} = value;

            if (isClick) {
                result = {suggest_click: {}};
                result.suggest_click[length] = {};
                result.suggest_click[length][number] = value;
            } else if (value) {
                result = {suggest_show: {true: {}}};
                result.suggest_show.true[length] = value;
            } else {
                result = {suggest_show: false};
            }

            break;

        case PARAMS.ADV_BLOKED:
            const blockedStatus = data.flagSet ? 'yes' : 'no';
            result = _.set({}, ['blocked', data.blockId], blockedStatus);

            break;

        case PARAMS.PROGRAM_PAGE:
            root = PARAMS.PROGRAM_PAGE.toLowerCase();
            const eventId = data.eventId;
            const programId = data.programId;

            let path = [
                root,
                data.action,
                data.component,
            ];
            let paramValue;

            if (data.component === 'channel_blocks') {
                path = path.concat([
                    data.type,
                    data.orderIndex,
                    data.channelId,
                ]);
                paramValue = programId;
            } else {
                path = path.concat(programId);
                paramValue = String(eventId);
            }

            result = _.setWith({}, path, paramValue, Object);

            break;

        case PARAMS.CHANNEL_TYPES:
            result = _.set({}, ['channel-types', 'watch-on'], data ? 'yes' : 'no');
            break;

        case PARAMS.ONLINE_SPECIAL:
            root = PARAMS.ONLINE_SPECIAL.toLowerCase();

            result = _.setWith({}, [root, data.project], data.episodeId, Object);
            break;

        case PARAMS.TRAILERS_SPECIAL:
            root = PARAMS.TRAILERS_SPECIAL.toLowerCase();
            location = `from_${data.location}`;

            result = _.setWith({}, [root, 'trailer', location], data.trailerId, Object);
            break;

        case PARAMS.VIDEOS_SPECIAL:
            root = PARAMS.TRAILERS_SPECIAL.toLowerCase();
            location = `from_${data.location}`;

            result = _.setWith({}, [root, 'other_video', location], data.videoId, Object);
            break;

        case PARAMS.LIVE:
            root = PARAMS.LIVE.toLowerCase();
            const version = 'new';

            result = _.setWith({}, [root, data.action, data.channelId, 'from_channelpage', version], data.value, Object);
            break;

        case PARAMS.TEASER:
            root = 'popup_teaser';

            if (isIndex) {
                param = 'tvmain';
            } else if (isSearch) {
                param = 'tvserp';
            } else if (isChannel) {
                param = 'tvchannel';
            } else if (isProgram) {
                param = 'tvprogram';
            } else if (isFavorites) {
                param = 'tvfavorites';
            } else if (isProject) {
                param = 'tvproject';
            }

            result = _.setWith({}, [root, data.action, param], data.campaign);
            break;

        case PARAMS.MY_PAGE:
            root = PARAMS.MY_PAGE.toLowerCase();
            result = {[root]: {show: data}};

            break;

        case PARAMS.LISTS:
            root = PARAMS.LISTS.toLowerCase();

            if (isIndex) {
                location = 'from_main';
            } else if (isChannel) {
                location = 'from_channel';
            } else if (isProgram) {
                location = 'from_program';
            } else if (isFavorites) {
                location = 'from_my';
            } else if (isLists) {
                location = 'from_lists';
            } else if (isSpecial) {
                location = 'from_special';
            } else if (isProject) {
                location = 'from_SP';
            }

            result = _.setWith({}, [root, location, data.param], data.number, Object);

            break;

        case PARAMS.VOD:
            root = PARAMS.VOD.toLowerCase();

            if (data.param === 'video_click') {
                if (data.target.indexOf('carousel') !== -1) {
                    result = _.setWith({}, [root, data.param, 'program_page', data.programTitle, data.programType, data.programId, data.target, data.position], data.tab || 'no_tabs', Object);
                } else {
                    result = _.setWith({}, [root, data.param, 'program_page', data.programTitle, data.programType, data.programId], data.target, Object);
                }
            } else if (data.param === 'tab_click') {
                result = _.setWith({}, [root, data.param, 'program_page', data.programTitle, data.programType, data.programId], data.tab, Object);
            } else {
                result = _.setWith({}, [root, data.param, 'program_page', data.programTitle, data.programType], data.programId, Object);
            }

            break;

        case PARAMS.SEARCH_PAGE:
            result = _.setWith({}, PARAMS.SEARCH_PAGE.toLowerCase(), data.tabId, Object);

            break;

        case PARAMS.BRANDING:
            const familyId = data.familyId || 'no_familyId';

            if (isChannel) {
                location = 'tvchannel';
            } else if (isProgram) {
                location = 'tvprogram';
            }

            result = _.setWith({}, ['branding', data.action, location, data.control, familyId, data.id], data.title, Object);

            break;

        case PARAMS.PROJECT_SEARCH:
            if (isSearch) {
                const {
                    id,
                    text,
                    tabId,
                    index,
                    limit,
                    title,
                    trigger,
                    section,
                    family_Id,
                } = data;

                if (trigger === 'show') {
                    result = _.setWith({}, ['project_search', trigger], text, Object);
                } else {
                    const isAllTab = tabId === 'all';
                    const isEventSection = section === 'events';
                    const isChannelSection = section === 'channel';
                    const programs = 'programs';

                    let tab = tabId;
                    let indexInTab = index;

                    if (isAllTab) {
                        tab = `${tabId}_${section}`;
                    }

                    if (isEventSection) {
                        tab = programs;

                        if (isAllTab) {
                            tab = `${tabId}_${programs}`;
                            indexInTab = index + limit;
                        }
                    }

                    if (isChannelSection) {
                        result = _.setWith({}, ['project_search', trigger, text, tab, indexInTab, index, family_Id, id], title, Object);
                    } else {
                        result = _.setWith({}, ['project_search', trigger, text, tab, indexInTab, index, 'common', id], title, Object);
                    }
                }
            }
            break;

        case PARAMS.FOOTER:
            const {footerClick, footerScroll, button = ''} = data;

            if (footerClick) {
                result = {footer: {}};
                result.footer.click = button;
            } else if (footerScroll) {
                result = {footer: footerScroll};
            }

            break;

        case PARAMS.SCROLL:
            const {deepScroll} = data;
            let currentPage;

            if (isIndex) {
                currentPage = 'main';
            } else if (isChannel) {
                currentPage = 'channel';
            } else if (isProgram) {
                currentPage = 'program';
            } else if (isFavorites) {
                currentPage = 'favorites';
            } else if (isSearch) {
                currentPage = 'search';
            }

            if (deepScroll) {
                result = {page_scroll: {}};
                result.page_scroll[currentPage] = deepScroll;
            }

            break;

        default:
            result = data || {};
            break;
    }

    return result;
}

module.exports = {
    GOALS,
    PARAMS,
    buildParams,
    trackParams,
    reachGoal,
    params,
    hit,
};
