require('./LangSwitcher.scss');

const b = require('app/www/libs/b')('lang-switcher');

const _ = {
    upperFirst: require('lodash/upperFirst'),
};

const React = require('react');
const PropTypes = require('prop-types');

const Global = require('app/www/components/blocks/Global/Global');
const CountryFlag = require('app/www/components/blocks/CountryFlag/CountryFlag');
const Dropdown = require('app/www/components/blocks/Dropdown/Dropdown');

class LangSwitcher extends React.PureComponent {

    constructor(props) {
        super(props);

        const {options} = this.props;
        let checkedItem;

        options.forEach(option => {
            if (option.selected) {
                const {code, name} = option.lang;

                checkedItem = {
                    code: code,
                    name: _.upperFirst(name || code),
                };
            }
        });

        this.state = {
            checked: checkedItem,
        };

        this._onClick = this._onClick.bind(this);
        this._getChangeLangUrl = this._getChangeLangUrl.bind(this);
    }

    render() {
        const {
            options,
            directions,
        } = this.props;

        const {checked} = this.state;

        const countryFlagProps = {
            mods: {
                s16: checked.code,
            },
        };

        const dropdownProps = {
            buttonProps: {
                mods: {
                    theme: 'clear',
                    size: 'm',
                    'lang-switcher': true,
                },
                children: [
                    <CountryFlag key='1' {...countryFlagProps} />,
                    checked.name,
                ],
            },
            popupProps: {
                mods: {
                    theme: 'normal',
                    'lang-switcher': true,
                },
                directions: directions,
                tail: true,
            },
        };

        return (
            <Dropdown
                ref={elem => (this.dropdown = elem)}
                {...dropdownProps}
            >
                <ul className={b('list')}>
                    {options.map(option => {
                        const {code, name} = option.lang;

                        let Tag = 'span';
                        let tagAttrs = {};

                        if (code !== checked.code) {
                            Tag = 'a';
                            tagAttrs = {
                                href: this._getChangeLangUrl(code),
                            };
                        }

                        return <li
                            key={code}
                            className={b('item')}
                            onClick={this._onClick(option)}
                        >
                            <Tag
                                className={b('link')}
                                {...tagAttrs}
                            >
                                <CountryFlag mods={{s16: code}}/>
                                {_.upperFirst(name || code)}
                            </Tag>
                        </li>;
                    })}
                </ul>
            </Dropdown>
        );
    }

    _onClick(option) {
        const {code, name} = option;

        return evt => {
            evt.stopPropagation();

            this.dropdown.close();

            this.setState({
                checked: {
                    name: name || code,
                    code: code,
                },
            });
        };
    }

    /**
     * Урл смены языка
     * @param {String} tld - домен (ru, uk, en, kk, be, tt, az, tr)
     * @return {String}
     * @private
     */
    _getChangeLangUrl(tld) {
        tld = tld === 'ua' ? 'uk' : tld;
        const crc = Global.crc;
        const retpath = Global.retpath;

        return `${Global.langHost}?intl=${tld}&retpath=${retpath}&sk=${crc}`;
    }

}

LangSwitcher.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        lang: PropTypes.shape({
            code: PropTypes.string.isRequired,
            name: PropTypes.string,
        }),
        selected: PropTypes.bool,
    })),
    directions: PropTypes.oneOf(['top', 'bottom']),
};

module.exports = LangSwitcher;
