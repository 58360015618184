require('./Image.scss');

const _ = {
    isEmpty: require('lodash/isEmpty'),
};

const b = require('app/www/libs/b')('image', true);

const React = require('react');
const PropTypes = require('prop-types');

const SIZES = {
    s: 100,
    m: 160,
    l: 200,
    xl: 300,
    xxl: 400,
};

const Image = (props) => {
    const {
        className,
        sizes,
        mods,
        baseUrl,
        style,
        alt,
        onLoad,
    } = props;

    let attrs;

    if (sizes[SIZES[mods.size]]) {
        attrs = sizes[SIZES[mods.size]];
    } else if (baseUrl) {
        attrs = {
            src: baseUrl,
        };
    } else if (!_.isEmpty(sizes)) {
        attrs = sizes[Math.min.apply(null, Object.keys(sizes))];
        attrs.srcSet = Object.keys(sizes).map((key, i) => `${sizes[key].src} ${i + 1}x`);
    } else {
        attrs = {src: ''};
    }

    if (alt) {
        attrs.alt = alt;
    }

    if (style) {
        attrs.style = style;
    }

    if (onLoad) {
        attrs.onLoad = onLoad;
    }

    if (mods.background) {
        const imageStyle = {
            backgroundImage: `url('${attrs.src}')`,
        };

        return (
            <div className={b.mix(className, mods)} style={imageStyle} title={attrs.alt || ''} />
        );
    }

    return (
        <figure className={b.mix(className, mods)}>
            <img className={b('img')} {...attrs} />
        </figure>
    );
};

Image.defaultProps = {
    mods: {},
    sizes: {},
};

Image.propTypes = {
    sizes: PropTypes.object,
    baseUrl: PropTypes.string,
    mods: PropTypes.shape({
        size: PropTypes.oneOf([
            's',
            'm',
            'l',
            'xl',
            'xxl',
        ]),
        background: PropTypes.bool,
    }),
    alt: PropTypes.string,
    onLoad: PropTypes.func,
};

module.exports = Image;
