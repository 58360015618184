require('./Modal.scss');

const b = require('app/www/libs/b')('modal', true);

const React = require('react');
const ReactDOM = require('react-dom');
const PropTypes = require('prop-types');

const nextTick = require('app/www/libs/nextTick');

const Animate = require('app/www/components/blocks/Animate/Animate');
const ClickOutListener = require('app/www/components/blocks/ClickOutListener/ClickOutListener');
const HotkeyListener = require('app/www/components/blocks/HotkeyListener/HotkeyListener');

const ESC_KEY_CODE = 27;

class Modal extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            visibility: 'hidden',
        };

        const {mods} = this.props;
        this._modAnimate = mods.animate === false ? mods.animate : true;

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this._update = this._update.bind(this);
        this._remove = this._remove.bind(this);
    }

    /**
     * Вставляем и показываем модальное окно
     */
    componentDidMount() {
        this._html = document.documentElement;
        this._htmlClass = b.mod({open: true});
        this._scrollBarWidth = window.innerWidth - this._html.clientWidth;

        this._update();
        nextTick(this.open);
    }

    /**
     * Закрываем и удаляем Modal
     */
    componentWillUnmount() {
        nextTick(this._remove);
    }

    /**
     * Вставляем модальное окно в его контейнер
     * @private
     */
    render() {
        const {
            owner,
            clickOutExceptions,
            children,
            mods,
            className,
        } = this.props;

        this._insert();

        const modalMods = Object.assign({}, mods, this.state);

        const clickOutListenerProps = {
            owner: owner,
            exceptions: clickOutExceptions,
            callback: this.close,
        };

        const animateProps = {
            component: this,
            animate: this.state.animate,
        };

        const hotkeyListenerProps = {
            keyCode: ESC_KEY_CODE,
            callback: this.close,
        };

        const content = (
            <div
                className={b.mix(className, modalMods)}
                onTouchMove={evt => evt.preventDefault()}
            >
                <div className={b('under')}>
                    <div
                        ref={ref => (this._content = ref)}
                        className={b('content')}
                    >
                        <ClickOutListener {...clickOutListenerProps}>
                            {children}
                        </ClickOutListener>
                    </div>
                </div>
                {typeof this.state.animate !== 'undefined' && (
                    <Animate
                        ref={ref => (this._animate = ref)}
                        node={this._content}
                        {...animateProps}
                    />
                )}
                <HotkeyListener {...hotkeyListenerProps} />
            </div>
        );

        return ReactDOM.createPortal(content, this._container);
    }

    /**
     * Вставляем модальное окно в body
     */
    _insert() {
        if (!this._container) {
            this._container = document.createElement('div');
            document.body.appendChild(this._container);
        }
    }

    _update() {
        this.setState({
            animate: this._modAnimate,
        });
    }

    _remove() {
        this.close();
        document.body.removeChild(this._container);
    }

    open() {
        if (this._animate) {
            this._animate.open();

            this._html.classList.add(this._htmlClass);
            this._html.style.marginRight = `${this._scrollBarWidth}px`;
        }

        this.props.onOpen();
    }

    close() {
        if (this._animate) {
            this._animate.close();

            this._html.classList.remove(this._htmlClass);
            this._html.style.marginRight = 0;
        }

        this.props.onClose();
    }

}

Modal.defaultProps = {
    mods: {},
    onOpen: () => {},
    onClose: () => {},
};

Modal.propTypes = {
    mods: PropTypes.shape({
        animate: PropTypes.bool,
    }),
    owner: PropTypes.object.isRequired,
    clickOutExceptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
};

module.exports = Modal;
