require('./ErrorMessage.scss');

const b = require('app/www/libs/b')('error-message', true);

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const {IS_DEVELOP} = require('app/common/libs/env');

const {withI18n} = require('app/www/components/contexts/I18n');

class ErrorMessage extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            userSettingsFallback: 0,
        };

        this.close = this.close.bind(this);
        this._onClick = this._onClick.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const {closed} = state;
        const {status, userSettingsFallback} = props;
        const isUserSettingsFallback = state.userSettingsFallback !== props.userSettingsFallback;

        return {
            closed: false,
            userSettingsFallback,
            visible: !closed && (status || isUserSettingsFallback),
        };
    }

    render() {
        if (!this.state.visible) {
            return null;
        }

        const {
            i18n,
            status,
            userSettingsFallback,
        } = this.props;

        let message;
        const isErrorCookie = status === 418;

        if (status) {
            message = i18n.get(`error-message.${isErrorCookie ? 'cookies' : 'default'}`);

            if (IS_DEVELOP) {
                message += ` [${status}]`;
            }
        } else if (userSettingsFallback) {
            message = i18n.get('error-message.user-settings-fallback');
        }

        return (
            <div className={b.mix('notification')}>
                <div className={b('inner')} onClick={this._onClick}>
                    <span className={b('text', {cookie: isErrorCookie})}>
                        {message}
                    </span>
                    {!isErrorCookie && (
                        <span className={b('button')}>
                            {i18n.get('error-message.button')}
                        </span>
                    )}
                </div>
                <span className={b('close')} onClick={this.close}>&nbsp;</span>
            </div>
        );
    }

    /**
     * Закрываем сообщение об ошибке
     */
    close() {
        this.setState({
            closed: true,
        });
    }

    /**
     * При клике на сообщение об ошибке перезагружаем страницу
     * @private
     */
    _onClick() {
        window.location.reload();
    }
}

ErrorMessage.propTypes = {
    status: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    userSettingsFallback: PropTypes.number,
};

function mapStateToProps(state) {
    return {
        status: state.errorMessage.status,
        userSettingsFallback: state.userSettingsFallback,
    };
}

module.exports = connect(
    mapStateToProps
)(
    withI18n(
        ErrorMessage
    )
);

