const React = require('react');
const I18nContext = React.createContext();

module.exports = {
    I18nContext,
    withI18n: Component => props => (
        <I18nContext.Consumer>
            {i18n => <Component {...props} i18n={i18n} />}
        </I18nContext.Consumer>
    ),
    withI18nRef: Component => React.forwardRef((props, ref) => (
        <I18nContext.Consumer>
            {i18n => <Component {...props} i18n={i18n} ref={ref} />}
        </I18nContext.Consumer>
    )),
};
