require('./Select_Providers.scss');

const React = require('react');
const PropTypes = require('prop-types');

const {withI18n} = require('app/www/components/contexts/I18n');

const Select = require('app/www/components/blocks/Select/Select');
const Ua = require('app/www/components/blocks/Ua/Ua');

class Select_Providers extends React.PureComponent {

    constructor(props) {
        super(props);

        this._formatTitle = this._formatTitle.bind(this);
    }

    render() {
        const {
            className,
            providers,
            selectedProvider,
            onChange,
        } = this.props;

        const options = providers.map(provider => ({
            attrs: {
                id: provider.id,
                value: provider.id,
                selected: provider.id === selectedProvider.id,
            },
            content: provider.title,
        }));

        const selectProps = {
            className,
            mods: {
                size: Ua.isTouchPhone ? 'm' : 's',
                theme: 'normal',
                providers: true,
            },
            format: this._formatTitle,
            options,
            onChange,
        };

        return (
            <Select {...selectProps} />
        );
    }

    /**
     * Форматирует текст кнопки
     * @param {String[]} selectedItems массив выбранных элементов
     */
    _formatTitle(selectedItems) {
        const {i18n, providers} = this.props;

        const selectedItem = selectedItems && selectedItems[0];

        const selectedProvider = providers.filter(p => p.title === selectedItem)[0];

        if (!selectedProvider || selectedProvider.id === 0) {
            return i18n.get('filters.provider-empty-button');
        }

        return selectedItem;
    }
}

Select_Providers.propTypes = {
    providers: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        title: PropTypes.string.isRequired,
    })).isRequired,
    selectedProvider: PropTypes.shape({
        id: PropTypes.number,
    }).isRequired,
    onChange: PropTypes.func.isRequired,
};

module.exports = withI18n(Select_Providers);
