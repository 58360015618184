const React = require('react');
const PropTypes = require('prop-types');

const _ = {
    throttle: require('lodash/throttle'),
};

class ResizeListener extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onResize = _.throttle(this._onResize.bind(this), props.throttle);
    }

    componentDidMount() {
        window.addEventListener('resize', this._onResize);
        window.addEventListener('orientationchange', this._onResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize);
        window.removeEventListener('orientationchange', this._onResize);
    }

    render() {
        return null;
    }

    _onResize() {
        this.props.callback();
    }
}

ResizeListener.defaultProps = {
    throttle: 0,
};

ResizeListener.propTypes = {
    throttle: PropTypes.number,
    callback: PropTypes.func.isRequired,
};

module.exports = ResizeListener;
