const {
    FAVORITE_CHANNEL_ADD,
    FAVORITE_CHANNEL_DEL,
    FAVORITE_CHANNELS_SAVE,
    FAVORITE_CHANNELS_UPDATE,
} = require('app/www/constants/reducerFavoriteChannels');

function reducer_FavoriteChannels(state, action) {
    switch (action.type) {
        case FAVORITE_CHANNEL_ADD:
        case FAVORITE_CHANNEL_DEL:
        case FAVORITE_CHANNELS_SAVE:
            state = action.payload;
            break;
        case FAVORITE_CHANNELS_UPDATE:
            state = [].concat(state, action.payload);
            break;
        default:
            state = state || [];
            break;
    }

    return state;
}

module.exports = reducer_FavoriteChannels;
