const {
    AAB_COOKIE_NAME,
    AAB_COOKIE_ENABLED_VALUE,
} = require('app/www/constants/aab');

/**
 * Check adblock status from cookies
 * @param {object} req
 * @return {boolean}
 */
function isADBEnabled(req) {
    return req.cookies[AAB_COOKIE_NAME] === AAB_COOKIE_ENABLED_VALUE;
}

module.exports = {
    isADBEnabled,
};
