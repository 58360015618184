const fetch = require('app/www/libs/client/fetch');
const RESOURCES = require('app/configs/_routes').main.api;

/**
 * @param {String} method post|delete
 * @param {String} resource
 * @param {Object} queryParams
 * @returns {Promise}
 */
function makeRequest(method, resource, queryParams) {
    return fetch(resource, queryParams, {
        method: method,
    });
}

module.exports = {

    getSchedule(params) {
        return makeRequest('get', RESOURCES.base, params);
    },

    getOnlineSchedule(params) {
        return makeRequest('get', RESOURCES.onlineSchedule, params);
    },

    /**
     * @returns {Promise}
     */
    getChunk(params) {
        return makeRequest('get', RESOURCES.chunk, params);
    },

    /**
     * @returns {Promise}
     */
    getChunksByProgramType(params) {
        return makeRequest('get', RESOURCES.chunksByProgramType, params);
    },

};
