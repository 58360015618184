require('./Container.scss');
require('../Page/Page.scss');

const b = require('app/www/libs/b')('container');

const _ = {
    get: require('lodash/get'),
    isEmpty: require('lodash/isEmpty'),
};

const React = require('react');
const {Provider} = require('react-redux');

const configureStore = require('app/www/libs/store');
const req = require('app/www/libs/client/req');
const i18n = require('app/www/libs/client/i18n');
const Experiments = require('app/common/libs/experiments');
const secretKeyStore = require('app/www/libs/client/secretKeyStore');

const {ReqContext} = require('app/www/components/contexts/Req');
const {I18nContext} = require('app/www/components/contexts/I18n');
const {ExperimentsContext} = require('app/www/components/contexts/Experiments');

const ErrorMessage = require('app/www/components/blocks/ErrorMessage/ErrorMessage');
const Footer = require('app/www/components/blocks/Footer/Footer');
const Global = require('app/www/components/blocks/Global/Global');
const Header = require('app/www/components/blocks/Header/Header');
const Teaser = require('app/www/components/blocks/Teaser/_Program/Teaser_Program');
const Teaser_Hd = require('app/www/components/blocks/Teaser/_Hd/Teaser_Hd');
const Metrika = require('app/www/components/blocks/Metrika/Metrika');
const SuperBanner = require('app/www/components/blocks/SuperBanner/SuperBanner');
const EmersionAd = require('app/www/components/blocks/EmersionAd/EmersionAd');
const FullScreenAd = require('app/www/components/blocks/FullScreenAd/FullScreenAd');
const PageVisibilityAware = require('app/www/components/blocks/PageVisibilityAware/PageVisibilityAware');
const ResizeListener = require('app/www/components/blocks/ResizeListener/ResizeListener');
const Ua = require('app/www/components/blocks/Ua/Ua');

const BaseContainer = require('app/www/components/blocks/BaseContainer/BaseContainer');

const isClient = typeof window !== 'undefined';

class AppContainer extends BaseContainer {

    constructor(props) {
        super(props);

        if (isClient) {
            this._req = req;
            this._i18n = i18n;
            this._experiments = new Experiments(window.__EXPERIMENTS__);
        } else {
            this._req = this.props.req;
            this._i18n = this.props.i18n;
            this._experiments = this.props.experiments;
        }

        this._onLoadMetrika = this._onLoadMetrika.bind(this);
    }

    render() {
        let {
            initialState,
            globalProps,
            uaProps,
            initialSk,
            RootComponent,
            rootComponentProps,
        } = this.props;

        const store = configureStore(initialState);

        if (isClient) {
            rootComponentProps = {store};
        }

        secretKeyStore.set(initialSk);

        const urlBuilder = this._req.lib('urlBuilder');
        const isSerpApp = urlBuilder.isSerpApp;
        const isViewInIframeKinopoiskhd = urlBuilder.isViewInIframeKinopoiskhd;
        const isMain = urlBuilder.isIndexUrl;
        const isTouchPhone = _.get(uaProps, 'uatraits.isTouchPhone');
        const searchText = initialState.search && initialState.search.text;
        const headerProps = {
            project: initialState.entryPoints.common
                .filter(entry => entry.enum === 'header')
                .map(entry => ({
                    campaign: entry.campaign,
                    url: entry.url,
                    text: entry.text,
                    style: entry.style,
                }))[0],
            searchText,
        };

        const resizeListenerProps = {
            callback: this._setUserDisplayCookie,
        };

        const metrikaProps = {
            onLoad: this._onLoadMetrika,
        };

        const teaserProps = initialState.teaser;
        const teaserHDProps = initialState.teaserHd;
        const isSerpAppOrViewInIframeKinopoiskhd = isSerpApp || isViewInIframeKinopoiskhd;

        return (
            <Provider store={store}>
                <React.StrictMode>
                    <ReqContext.Provider value={this._req}>
                        <I18nContext.Provider value={this._i18n}>
                            <ExperimentsContext.Provider value={this._experiments}>
                                <div className={b({
                                    'main-phone': isMain && isTouchPhone,
                                    dark: isViewInIframeKinopoiskhd,
                                })}>
                                    <Global {...globalProps} />
                                    <Ua {...uaProps} />
                                    <FullScreenAd />
                                    {!isViewInIframeKinopoiskhd && <SuperBanner />}
                                    {!isViewInIframeKinopoiskhd && <EmersionAd />}
                                    {!isSerpAppOrViewInIframeKinopoiskhd && <Header {...headerProps} />}
                                    <RootComponent {...rootComponentProps} />
                                    {!isSerpAppOrViewInIframeKinopoiskhd && <Footer store={store} isMain={isMain}/>}
                                    <ResizeListener {...resizeListenerProps} />
                                    <Metrika {...metrikaProps} />
                                    <ErrorMessage />
                                    <PageVisibilityAware callback={this._onPageVisibilityChanged} />
                                    {!isSerpAppOrViewInIframeKinopoiskhd && !_.isEmpty(teaserProps) && <Teaser {...teaserProps} />}
                                    {!isSerpAppOrViewInIframeKinopoiskhd && !_.isEmpty(teaserHDProps) && <Teaser_Hd {...teaserHDProps} />}
                                </div>
                            </ExperimentsContext.Provider>
                        </I18nContext.Provider>
                    </ReqContext.Provider>
                </React.StrictMode>
            </Provider>
        );
    }

    /**
     * @private
     */
    _onLoadMetrika() {
        this._handleMetrikaLoad(this._experiments);
    }
}

module.exports = AppContainer;
