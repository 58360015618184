const _zipObject = require('lodash/zipObject');
const {combineReducers} = require('redux');

const REDUCERS = [
    'channel',
    'errorMessage',
    'event',
    'favoriteChannels',
    'moment',
    'online',
    'program',
    'recommendedAll',
    'recommendedEditorial',
    'recommendedNow',
    'recommendedProvider',
    'reminders',
    'remindersPopupVisible',
    'remindersSettingsPopup',
    'schedule',
    'scheduleSettings',
    'teaser',
    'teaserHd',
    'entryPoints',
    'user',
    'wannaSee',
    'regular',
    'special',
    'templateProject',
    'favorites',
    'search',
    'stream',
    'streamChannels',
    'superBanner',
    'onAir',
    'sport',
    'userSettingsFallback',
    'ott',
];

module.exports = combineReducers(
    _zipObject(
        REDUCERS,
        REDUCERS.slice().map(reducer => require(`./${reducer}`))
    )
);
