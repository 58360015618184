const {SCHEDULE_GET, FAVORITE_CHANNELS_GET} = require('app/www/constants/reducerChannel');

function reducer_Channel(state = {}, action) {
    switch (action.type) {
        case SCHEDULE_GET:
            return Object.assign({}, state, action.payload);
        case FAVORITE_CHANNELS_GET:
            return Object.assign({}, state, {
                favorites: action.payload,
            });
        default:
            return state;
    }
}

module.exports = reducer_Channel;
