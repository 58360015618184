require('./Overlay.scss');
const b = require('app/www/libs/b')('overlay');

const React = require('react');
const PropTypes = require('prop-types');

const Overlay = (props) => {
    const {hasSuperBanner} = props;

    return (
        <div onClick={props.onClick} className={b({'has-banner': hasSuperBanner})}>
            {props.children}
        </div>
    );
};

Overlay.propTypes = {
    onClick: PropTypes.func,
};

module.exports = Overlay;
