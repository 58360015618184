const fetch = require('app/www/libs/client/fetch');
const RESOURCE = require('app/configs/_routes').user.api.favoriteChannels;

/**
 * @param {String} method put|delete
 * @param {Number|Number[]} channelIds
 * @returns {Promise.<*|Array>}
 */
function makeRequest(method, channelIds) {
    return fetch(RESOURCE, null, {
        method: method,
        body: JSON.stringify({
            channelIds,
        }),
    });
}

module.exports = {

    /**
     * @param {Number} channelId
     * @returns {Promise.<*|Array>}
     */
    add(channelId) {
        return makeRequest('post', channelId);
    },

    /**
     * @param {Number} channelId
     * @returns {Promise.<*|Array>}
     */
    del(channelId) {
        return makeRequest('delete', channelId);
    },

    /**
     * @param {Number[]} channelIds
     * @returns {Promise.<*|Array>}
     */
    save(channelIds) {
        return makeRequest('put', channelIds);
    },

};
