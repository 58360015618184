require('core-js/es6/map');
require('core-js/es6/set');
require('raf/polyfill');

const React = require('react');
const {hydrate} = require('react-dom');

const BrowserRouter = require('react-router-dom').BrowserRouter;

const Container = require('app/www/components/blocks/Container/Container');

module.exports = (RootComponent) => (() => {
    document.addEventListener('DOMContentLoaded', () => {
        if (!window.__INIT_APP__) {
            return;
        }

        hydrate(
            <BrowserRouter>
                <Container
                    initialState={window.__INITIAL_STATE__}
                    initialSk={window.__INITIAL_SK__}
                    RootComponent={RootComponent}
                />
            </BrowserRouter>,
            document.getElementById('mount')
        );
    });
})();
