const _get = require('lodash/get');
const _pickBy = require('lodash/pickBy');

class Experiments {
    /**
     * @param {Object} data Данные экспериментов
     */
    constructor(data = {}) {
        this._data = data;
    }

    /**
     * Добавляет в эксперимент экстра данные
     * @param {String} name имя эксперимента
     * @param {Object} data экстра данные
     */
    setExtra(name, data) {
        const experiment = this._data[name];

        if (experiment) {
            experiment.extra = Object.assign(experiment.extra, data);
        }
    }

    /**
     * Возвращает группу по имени эксперимента
     * @param {String} name имя эксперимента
     * @return {String|null}
     */
    get(name) {
        return _get(this._data, `${name}.group`);
    }

    /**
     * Возвращает true для экспериментальной группы
     * @param {String} name - название эксперимента
     * @return {Boolean}
     */
    isExperiment(name) {
        return _get(this._data, `${name}.group`, '') === 'experiment';
    }

    /**
     * Есть ли эксперименты?
     * @return {Boolean}
     */
    get has() {
        return Object.keys(this._data).length !== 0;
    }

    /**
     * Данные для метрики
     * @return {Object}
     */
    toMetrika() {
        const obj = {};

        Object.keys(this._data).forEach(exp => {
            obj[exp] = _pickBy(this._data[exp], (val, key) => ['name', 'group'].includes(key));
        });

        return obj;
    }

    toJSON() {
        return JSON.stringify(this._data);
    }
}

module.exports = Experiments;
