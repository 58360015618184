require('./ButtonWrapper.scss');

const b = require('b_').with('button-wrapper');

const React = require('react');

module.exports = (props) => {
    return (
        <div className={b(props.mods)}>{props.children}</div>
    );
};
