const _ = require('lodash');

/**
 * @typedef {Function}
 */
class BaseModel {

    constructor(attributes = {}, req) {
        this.attributes = attributes;

        if (!_.isEmpty(req)) {
            this.req = req;
        }
    }

    /**
     * Приводим модель к объекту
     * Все вложенные массивы и объекты будут так же приведены к объекту
     * Если передать массив из списка нужных полей, то они объединятся с атрибутами
     * @param {String[]} [fields=undefined] поля, нужные из модели
     * @type {Function}
     * @returns {Object}
     */
    toJSON(fields = []) {
        fields = [].concat(fields).concat(Object.keys(this.attributes)).filter(Boolean);
        let attributes = {};

        fields.forEach(field => {
            let value = this[field] || this.attributes[field];

            if (value instanceof BaseModel) {
                value = value.toJSON();
            }

            if (typeof value === 'object' && value !== null) {
                Object.keys(value).forEach(key => {
                    value[key] = (value[key] instanceof BaseModel) ? value[key].toJSON() : value[key];
                });
            }

            if (Array.isArray(value)) {
                let newValue = [];

                value.forEach(item => {
                    newValue.push((item instanceof BaseModel) ? item.toJSON() : item);
                });

                value = newValue;
            }

            attributes[field] = value;
        });

        return attributes;
    }

    /**
     * @returns {String[]}
     */
    getFields() {
        return Object.keys(this.attributes);
    }

    /**
     * Получаем из json-а данные по ключу
     * Пустая строка, массив или объект не считается валидным значением, поэтому приравниваем к undefuned и отдаем defaultValue, если передано
     * Все непустые строки тримим
     * @param {String} key можно разбивать точками. Ex.: `key1.key2`
     * @param {*} [defaultValue=null] Если данные по ключу не получили, отдаём это значение
     * @returns {*|null} значение или null
     * @protected
     */
    _pick(key, defaultValue) {
        defaultValue = defaultValue === undefined ? null : defaultValue;
        let value = key.indexOf('.') > -1
            ? _.get(this.attributes, key, defaultValue)
            : this.attributes[key] || defaultValue;

        value = (value !== [] && value !== {} && value !== '' && value !== 0) ? value : defaultValue;

        return typeof value === 'string' ? value.trim() : value;
    }

    /**
     * Получаем приведенные к типу данные
     * Если ничего не получено — defaultValue
     * @param {String} type number|string|boolean
     * @param {String} key
     * @param {*} [defaultValue]
     * @private
     */
    _pickTyped(type, key, defaultValue) {
        let typedValue;
        const value = this._pick(key, defaultValue);

        switch (type.toLowerCase()) {
            case 'number':
                typedValue = Number(value);
                break;

            case 'string':
                typedValue = String(value);
                break;

            case 'boolean':
                typedValue = Boolean(value);
                break;

            case 'object':
                typedValue = Object(value);
                break;
        }

        return value ? typedValue : value;
    }

    /**
     * Если ключ есть — приводим его к числу, иначе 0
     * @param {String} key
     * @param {*} [defaultValue = 0]
     * @returns {Number}
     * @protected
     */
    _pickNumber(key, defaultValue) {
        defaultValue = defaultValue === undefined ? 0 : defaultValue;
        return this._pickTyped('number', key, defaultValue);
    }

    /**
     * Если ключ есть — приводим его к строке, иначе ''
     * @param {String} key
     * @param {*} [defaultValue = '']
     * @returns {String}
     * @protected
     */
    _pickString(key, defaultValue) {
        defaultValue = defaultValue || '';
        return this._pickTyped('string', key, defaultValue);
    }

    /**
     * Если ключ есть — приводим его к boolean, иначе false
     * @param {String} key
     * @param {*} [defaultValue = false]
     * @returns {Boolean}
     * @protected
     */
    _pickBoolean(key, defaultValue) {
        defaultValue = defaultValue === undefined ? false : defaultValue;
        return this._pickTyped('boolean', key, defaultValue);
    }

    /**
     * Если ключ есть — приводим его к object, иначе {}
     * @param {String} key
     * @param {*} [defaultValue = {}]
     * @returns {Object}
     * @protected
     */
    _pickObject(key, defaultValue = {}) {
        return this._pickTyped('object', key, defaultValue);
    }

    /**
     * @param {String} key
     * @param {*} value
     * @returns {BaseModel}
     * @protected
     */
    _set(key, value) {
        value = value === 'string' ? value.trim() : value;
        _.set(this.attributes, key, value);

        return this;
    }

    /**
     * @param {String} key
     * @returns {boolean}
     * @protected
     */
    _has(key) {
        return Boolean(this._pick(key));
    }

}

module.exports = BaseModel;
