require('./RecommendedOttItem.scss');

const b = require('app/www/libs/b')('recommended-ott-item');

const React = require('react');
const PropTypes = require('prop-types');

const Link = require('app/www/components/blocks/Link/Link');
const Image = require('app/www/components/blocks/Image/Image');

const Metrika = require('app/www/libs/client/metrika');

const OTT_FILM_HOST_LINK = 'https://hd.kinopoisk.ru/film/';
const OTT_SELECTION_CARD_SIZE = '672x438';

class RecommendedOttItem extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            id,
            horizontalPoster = {},
        } = this.props;

        const imagesProps = {
            baseUrl: horizontalPoster.replace('orig', OTT_SELECTION_CARD_SIZE),
            mods: {
                background: true,
            },
        };

        const linkProps = {
            url: `${OTT_FILM_HOST_LINK}${id}?from=tv&source=tv_selection`,
            onClick: () => Metrika.reachGoal(`TITLE_CLICK_${id}`),
            attrs: {
                target: '_blank',
            },
        };

        return (
            <div className={b()}>
                <Link
                    className={b('link')}
                    {...linkProps}
                >
                    {id &&
                    <div key="inner" className={b('inner')}>
                        <div key="play-control" className={b('play-control')} />
                        <Image key="image" className={b('image')} {...imagesProps} />
                    </div>
                    }
                </Link>
            </div>
        );
    }
}

RecommendedOttItem.propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    horizontalPoster: PropTypes.string,
};

module.exports = RecommendedOttItem;
