const b = require('app/www/libs/b')('logo');

const PropTypes = require('prop-types');

const React = require('react');
const Global = require('app/www/components/blocks/Global/Global');
const Logo = require('app/www/components/blocks/Logo/Logo');
const Link = require('app/www/components/blocks/Link/Link');

class Logo_Service extends React.PureComponent {

    render() {
        const {url} = this.props;

        const logoProps = {
            mods: {
                service: Global.id,
                lang: Global.lang,
            },
        };

        const linkProps = {
            url,
        };

        return (
            <Logo ref={elem => (this.logo = elem)} {...logoProps}>
                <span className={b('service')}>
                    <Link className={b('link')} {...linkProps} />
                </span>
            </Logo>
        );
    }

    /**
     * Элемент главной Яндекса
     * @returns {Object}
     */
    get main() {
        return this.logo.main;
    }
}

Logo_Service.defaultProps = {
    url: '/',
};

Logo_Service.propTypes = {
    url: PropTypes.string,
};

module.exports = Logo_Service;
