require('./Grid.scss');

const b = require('app/www/libs/b')('grid');
const bItem = require('app/www/libs/b')('recommended-item');

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const _throttle = require('lodash/throttle');
const _get = require('lodash/get');
const _range = require('lodash/range');
const {getChunk, getChunksByProgramType, getSchedule} = require('app/www/actions/schedule');
const {getEvent} = require('app/www/actions/event');

const {isPeriod} = require('app/common/libs/periods');
const {getAdv} = require('app/common/libs/config');

const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');
const {withExperiments} = require('app/www/components/contexts/Experiments');

const Balloon = require('app/www/components/blocks/Balloon/Balloon');
const Button = require('app/www/components/blocks/Button/Button');
const ChannelsGridReload = require('app/www/components/blocks/ChannelsGridReload/ChannelsGridReload');
const EmptySplash = require('app/www/components/blocks/EmptySplash/EmptySplash');
const Global = require('app/www/components/blocks/Global/Global');
const GridChunk = require('app/www/components/blocks/GridChunk/GridChunk');
const Icon = require('app/www/components/blocks/Icon/Icon');
const Link = require('app/www/components/blocks/Link/Link');
const Metrika = require('app/www/libs/client/metrika');
const Placeholder = require('app/www/components/blocks/Placeholder/_Grid/Placeholder_Grid');
const Spin = require('app/www/components/blocks/Spin/Spin');
const Ua = require('app/www/components/blocks/Ua/Ua');

const isClient = typeof window !== 'undefined';

const MAX_ITEMS_CHANNELS_IN_ROW = 4;
const HEIGHT_ADV_OF_ROWS = 3;
const HEIGHT_ADV_OF_COLUMNS = 1;
const DELAY_LOADING = 1500;

const heightAdvRow = HEIGHT_ADV_OF_ROWS % 2 === 0 ? HEIGHT_ADV_OF_ROWS : HEIGHT_ADV_OF_ROWS - 1;

const COMBO_BOX_LENGTH = (MAX_ITEMS_CHANNELS_IN_ROW - HEIGHT_ADV_OF_COLUMNS) * heightAdvRow;
const INDEX_BEFORE_BLANK = (MAX_ITEMS_CHANNELS_IN_ROW - 1) * HEIGHT_ADV_OF_ROWS;

const ADV_WIDE_FIRST_POSITION = (HEIGHT_ADV_OF_ROWS + 1) * MAX_ITEMS_CHANNELS_IN_ROW - HEIGHT_ADV_OF_ROWS;

// https://st.yandex-team.ru/TVFRONT-6028
const EXPERIMENT_NAME = 'new_main_poster';

class Grid extends React.PureComponent {

    constructor(props) {
        super(props);

        this._isSerpApp = this.props.req.lib('urlBuilder').isSerpApp;

        this._onScroll = this._onScroll.bind(this);
        this._onChangeFavorite = this._onChangeFavorite.bind(this);
        this._handlePanelReload = this._handlePanelReload.bind(this);
        this._getEmptySplashProps = this._getEmptySplashProps.bind(this);
        this._onScroll = _throttle(this._onScroll, 200);

        const {
            isEmpty,
            schedules,
            totalPages,
            currentPage,
            loadingSchedule,
        } = props;

        this.state = {
            loadingSchedule: loadingSchedule || (!isEmpty && !schedules.length),
            loadMore: false,
            loadOnScroll: currentPage + 1 < totalPages,
            currentPage: currentPage || 1,
            panelReloadVisible: false,
        };
    }

    componentDidMount() {
        const {query} = this.props.req;

        if (!Ua.isRobot) {
            this.props.dispatchGetSchedule(query);
        }

        if (isClient) {
            window.addEventListener('scroll', this._onScroll);
            document.addEventListener('touchmove', this._onScroll);
        }
    }

    // @todo @antipovs нужно переделать на getDerivedStateFromProps до переезда
    // на 17 React https://st.yandex-team.ru/TVFRONT-6369
    componentWillReceiveProps(nextProps) {
        const {
            totalPages,
            currentPage,
            loadingSchedule,
        } = nextProps;

        this.setState({
            loadMore: false,
            loadOnScroll: currentPage + 1 < totalPages,
            loadingSchedule,
        });
    }

    componentWillUnmount() {
        if (isClient) {
            window.removeEventListener('scroll', this._onScroll);
            document.removeEventListener('touchmove', this._onScroll);
        }
    }

    render() {
        const {
            req,
            i18n,
            isAll,
            store,
            isMy,
            isEmpty,
            schedules,
            totalPages,
            experiments,
            scheduleMap,
            selectedDate,
            selectedPeriod,
            isLayoutMainLarge,
            selectedProgramTypes,
            dispatchGetEvent,
            event,
        } = this.props;

        const {
            loadMore,
            loadingSchedule,
            panelReloadVisible,
        } = this.state;

        let chunks = scheduleMap.slice()
            .map(page => schedules.slice(page.offset, page.offset + page.limit))
            .filter(channels => channels.length > 0);

        const mods = {
            period: selectedPeriod,
            filtered: selectedProgramTypes.length > 0,
            mobile: Ua.isTouchPhone,
            empty: isEmpty,
        };

        const channelsGridReloadProps = {
            isMy,
            panelReloadVisible,
            onClick: this._handlePanelReload,
        };

        const advColumnProps = {
            blockId: Global.adv[isPeriod('now', selectedPeriod)
                ? 'desktop_grid_cell_now'
                : 'desktop_grid_cell_all_day'
            ],
        };

        const advKey = Ua.isTouchPhone ? '_phone' : '';
        const advWideProps = {
            blockId: getAdv(
                `desktop_grid_wide${this._isSerpApp ? '_serp_app' : advKey}`,
                experiments.get(EXPERIMENT_NAME),
                Global.adv
            ),
        };

        const balloonProps = {
            dispatchGetEvent,
            event,
            store,
        };

        let html;

        if (isEmpty) {
            html = (
                <div className={b('empty')}>
                    <EmptySplash {...this._getEmptySplashProps(isMy)} />
                </div>
            );
        } else {
            html = chunks.map((chunk, chunkId) => {
                const chunkProps = {
                    isAll,
                    chunk,
                    chunkId,
                    totalPages,
                    balloon: this.balloon,
                    onChangeFavorite: this._onChangeFavorite,
                    comboChunkOptions: {
                        chunkIds: [0],
                        indexBeforeBlank: INDEX_BEFORE_BLANK,
                        length: COMBO_BOX_LENGTH,
                        advColumn: {
                            props: advColumnProps,
                        },
                        advWide: {
                            props: advWideProps,
                            firstPosition: ADV_WIDE_FIRST_POSITION,
                            channelsInRow: MAX_ITEMS_CHANNELS_IN_ROW,
                        },
                    },
                    selectedProgramTypes,
                    isLayoutMainLarge,
                    getChunksByProgramType: this._getChunksByProgramType,
                    messages: {
                        noEvents: i18n.get('empty-splash.no-events'),
                        broadcastGap: i18n.get('empty-splash.broadcast-gap'),
                    },
                    selectedDate,
                    selectedPeriod,
                };
                return (
                    <GridChunk key={chunkId} {...chunkProps} />
                );
            });
        }

        const urlBuilder = req.lib('urlBuilder');
        let pagination = null;

        if (Ua.isRobot && totalPages) {
            pagination = _range(totalPages).map(num => {
                num = num + 1;

                return (
                    <Link
                        key={num}
                        url={urlBuilder.getIndexUrl({
                            grid: 'all',
                            page: num,
                        })}
                        className={b('pagination')}
                    >
                        {num}
                    </Link>
                );
            });
        }

        const placeholderProps = {
            className: bItem(),
            mods: {
                grid: true,
            },
        };

        if (loadingSchedule) {
            html = <Placeholder {...placeholderProps} />;
        }

        const spinProps = {
            mods: {
                size: 'm',
            },
        };

        return (
            <section className={b(mods)}>
                {pagination}
                {html}
                <ChannelsGridReload {...channelsGridReloadProps} />
                {loadMore && !Ua.isTouchPhone &&
                    <Placeholder {...placeholderProps} count="4" />
                }
                {loadMore && Ua.isTouchPhone &&
                    <div className={b('loading', {visible: true})}>
                        <Spin {...spinProps} />
                    </div>
                }

                <Balloon ref={elem => (this.balloon = elem)} {...balloonProps} />
            </section>
        );

    }

    _onScroll() {
        const {
            loadMore,
            loadOnScroll,
            loadingSchedule,
            panelReloadVisible,
        } = this.state;

        if (panelReloadVisible) {
            this.setState({
                panelReloadVisible: false,
            });
        }

        if (loadMore || !loadOnScroll || loadingSchedule || Ua.isRobot) {
            return;
        }

        const {innerHeight, pageYOffset} = window;
        const scrollOffset = document.body.scrollHeight - (innerHeight + pageYOffset);
        const bottomOffset = Ua.isTouchPhone ? innerHeight / 3 : innerHeight;

        if (scrollOffset <= bottomOffset || this.props.isMy) {
            this._onLoadMore();
        }
    }

    _onLoadMore() {
        const {dispatchGetChunk} = this.props;

        this.setState({
            loadMore: true,
        });

        //@todo https://st.yandex-team.ru/TVFRONT-6756
        if (Ua.isTouchPhone) {
            setTimeout(() => dispatchGetChunk(), DELAY_LOADING);
        } else {
            dispatchGetChunk();
        }
    }

    _onChangeFavorite() {
        this.setState({
            panelReloadVisible: !this.props.isMy,
        });
    }

    _handlePanelReload() {
        const {
            req,
            isMy,
            dispatchGetSchedule,
        } = this.props;

        dispatchGetSchedule(req.query);

        this.setState({
            panelReloadVisible: false,
        });

        Metrika.reachGoal(isMy ? 'UPDATE_MY_LIST' : 'UPDATE_LIST');
    }

    /**
     * Возвращает props для пустой страницы
     * @param {Boolean} isMy сетка из избранных
     * @return {Object}
     */
    _getEmptySplashProps(isMy) {
        const {i18n, req} = this.props;

        if (isMy) {
            const urlBuilder = req.lib('urlBuilder');
            const iconProps = {
                mods: {
                    'favorite-channel': true,
                    size: 's',
                },
            };
            const buttonProps = {
                url: urlBuilder.getIndexUrl({
                    grid: 'all',
                }),
                mods: {
                    theme: Ua.isTouchPhone ? 'grey' : 'normal',
                    size: 'm',
                },
                children: i18n.get('grid.empty.my-button'),
            };

            return {
                mods: {
                    my: true,
                },
                title: i18n.get('grid.empty.my-text'),
                description: [
                    `${i18n.get('grid.empty.my-description-start')} `,
                    <Icon key='icon' {...iconProps} />,
                    ` ${i18n.get('grid.empty.my-description-end')}`,
                ],
                button: <Button {...buttonProps} />,
            };
        }

        return {
            title: i18n.get('grid.empty.any-text'),
        };
    }
}

Grid.propTypes = {
    isMain: PropTypes.bool.isRequired,
    isMy: PropTypes.bool.isRequired,
    isAll: PropTypes.bool.isRequired,
    schedules: PropTypes.array.isRequired,
    scheduleMap: PropTypes.arrayOf(
        PropTypes.shape({
            limit: PropTypes.number.isRequired,
            offset: PropTypes.number.isRequired,
        })
    ).isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number,
    loadingSchedule: PropTypes.bool,
    selectedDate: PropTypes.string.isRequired,
    selectedPeriod: PropTypes.string.isRequired,
    selectedProgramTypes: PropTypes.array,
};

function mapStateToProps(state) {
    return {
        event: _get(state, 'event.item'),
        wannaSee: state.wannaSee,
        reminder: state.reminders,
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatchGetChunk: () => {
            const nextPage = ownProps.scheduleMap[ownProps.currentPage + 1];

            return dispatch(getChunk({
                page: ownProps.currentPage,
                date: ownProps.selectedDate,
                period: ownProps.selectedPeriod,
                offset: nextPage.offset,
                limit: nextPage.limit,
            }));
        },
        dispatchGetChunksByProgramType: programType => dispatch(getChunksByProgramType({
            date: ownProps.selectedDate,
            period: ownProps.selectedPeriod,
            programType,
        })),
        dispatchGetSchedule: query => dispatch(getSchedule(query)),
        dispatchGetEvent: (eventId, programCoId) => dispatch(getEvent(eventId, programCoId)),
    };
}

module.exports = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withReq(withI18n(withExperiments(
        Grid
    )))
);
