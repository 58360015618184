require('./User_Menu.scss');

const b = require('app/www/libs/b')('user');

const _ = {
    get: require('lodash/get'),
};
const React = require('react');
const PropTypes = require('prop-types');
const fetch = require('app/www/libs/fetch');

const {withI18n} = require('app/www/components/contexts/I18n');

const Global = require('app/www/components/blocks/Global/Global');
const Link = require('app/www/components/blocks/Link/Link');
const MenuVert = require('app/www/components/blocks/MenuVert/MenuVert');
const Popup = require('app/www/components/blocks/Popup/Popup');
const Ticker = require('app/www/components/blocks/Ticker/Ticker');
const User = require('app/www/components/blocks/User/User');

const UNREAD_MAX = 99;

class User_Menu extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this._onClick = this._onClick.bind(this);
        this._onClickLink = this._onClickLink.bind(this);
    }

    render() {
        const {
            mods,
            items,
            userProps,
            tickerProps,
            children,
        } = this.props;

        const popupProps = {
            mods: {
                theme: 'normal',
            },
            directions: 'bottom-right',
            tail: true,
        };

        return (
            <User
                ref={elem => (this._user = elem)}
                onClick={this._onClick}
                {...userProps}
            >
                {tickerProps.count > 0 && (
                    <Ticker
                        className={b('ticker')}
                        {...tickerProps}
                    />
                )}
                {this.state.popup && (
                    <Popup
                        ref={ref => (this._popup = ref)}
                        className={b('menu', mods)}
                        owner={this.user.icon}
                        {...popupProps}
                    >
                        <MenuVert>
                            {items.map((type, index) =>
                                type !== 'separator'
                                    ? (
                                        <Link key={index}
                                            className={b('link', {type})}
                                            url={this.getMenuItemUrl(type)}
                                            onClick={this._onClickLink}
                                        >
                                            {this.getMenuItemTitle(type)}
                                        </Link>
                                    )
                                    : type
                            )}
                            {children}
                        </MenuVert>
                    </Popup>
                )}
            </User>
        );
    }

    /**
     * Компонент пользователя User
     * @returns {Object}
     */
    get user() {
        return this._user;
    }

    /**
     * Получаем урл элемента меню
     * Если урл передан, берем его
     * Если нет, строим, исходя из ключа
     * @param {String|Object} item
     * @returns {String}
     */
    getMenuItemUrl(item) {
        if (item.url) {
            return item.url;
        }

        switch (item.key || item) {
            case 'mail':
                return this._getMailUrl();
            case 'mail-compose':
                return this._getMailComposeUrl();
            case 'disk':
                return this._getDiskUrl();
            case 'tune':
                return Global.getTuneUrl;
            case 'passport':
                return this._getPassportUrl();
            case 'logout':
                return this._getPassportLogoutUrl();
            default:
                return '/';
        }
    }

    /**
     * Получаем заголовок элемента меню
     * Если заголовок передан, берем его
     * Если нет, получаем по ключу из i18n
     * @param {String|Object} item
     * @returns {String}
     */
    getMenuItemTitle(item) {
        const {i18n} = this.props;
        const type = item.key || item;

        if (item === 'mail') {
            const unread = this.state.unread;

            if (unread === undefined) {
                this._updateInformer();
            }

            const key = unread ? 'letter' : 'empty';
            const unreadContent = i18n.get(
                `user-menu-update.${key}`,
                unread,
                {unread}
            );

            return <span>{unreadContent}</span>;
        }

        return item.title || i18n.get(`lego.user.${type}`);
    }

    /**
     * Урл почты
     * @returns {String}
     * @private
     */
    _getMailUrl() {
        return '//mail.yandex.ru';
    }

    /**
     * Урл нового письма
     * @returns {String}
     * @private
     */
    _getMailComposeUrl() {
        return `${this._getMailUrl()}/compose`;
    }

    /**
     * Урл диска
     * @returns {String}
     * @private
     */
    _getDiskUrl() {
        return '//disk.yandex.ru';
    }

    /**
     * Урл паспорта
     * @returns {string}
     * @private
     */
    _getPassportUrl() {
        return `${Global.passportUrl}?retpath=${Global.retpath}`;
    }

    /**
     * Урл разлогина
     * @returns {String}
     * @private
     */
    _getPassportLogoutUrl() {
        return `${Global.passportUrl}/passport?mode=logout&yu=${Global.yandexuid}&retpath=${Global.retpath}`;
    }

    /**
     * Получает количество непрочитанных писем из export-ручки.
     * @param {Boolean} update
     * @private
     */
    _updateInformer(update) {
        if (!update || !Global.mailApi) {
            return;
        }

        fetch(Global.mailApi, {
            method: 'get',
            credentials: 'include',
        }).then(response => {
            let unread = _.get(response, 'counters.unread', 0);
            unread = unread > UNREAD_MAX ? `${UNREAD_MAX}+` : unread;

            this.setState({unread});
        }).catch(console.error); // eslint-disable-line no-console
    }

    /**
     * @param {Object} evt
     * @private
     */
    _onClick(evt) {
        evt.preventDefault();

        if (!this.state.popup) {
            this.setState({
                popup: true,
            });
        } else {
            this._popup.toggle();
        }
    }

    /**
     * @param {Object} evt
     * @private
     */
    _onClickLink(evt) {
        evt.stopPropagation();
    }

}

User_Menu.defaultProps = {
    items: [],
    tickerProps: {},
};

User_Menu.propTypes = {
    mods: PropTypes.shape({
        multiauth: PropTypes.bool,
    }),
    items: PropTypes.arrayOf(
        PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.shape({
                key: PropTypes.string,
                title: PropTypes.string,
                url: PropTypes.string,
            }),
        ])
    ),
    userProps: PropTypes.shape(User.propTypes),
    tickerProps: PropTypes.shape(Ticker.propTypes),
};

module.exports = withI18n(User_Menu);
