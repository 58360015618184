require('./EmersionAd.scss');

const b = require('app/www/libs/b')('emersionAd');
const Ua = require('app/www/components/blocks/Ua/Ua');

const React = require('react');
const Global = require('app/www/components/blocks/Global/Global');
const AdFox = require('app/www/components/blocks/AdFox/AdFox');

class EmersionAd extends React.PureComponent {
    constructor(props) {
        super(props);
    }

    render() {
        const AdFoxProps = Global.adFox.emersion;

        return (
            <div className={b()}>
                {!Ua.isTouchPhone && <AdFox {...AdFoxProps} />}
            </div>
        );
    }
}

module.exports = EmersionAd;
