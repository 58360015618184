const {RECOMMENDED_NOW_GET} = require('app/www/constants/reducer-recommended');

function reducer_RecommendedNow(state, action) {
    switch (action.type) {
        case RECOMMENDED_NOW_GET:
            state = Object.assign({}, action.payload);
            break;
        default:
            state = state || {};
            break;
    }

    return state;
}

module.exports = reducer_RecommendedNow;
