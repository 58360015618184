require('./Copyright.scss');

const React = require('react');

const {withI18n} = require('app/www/components/contexts/I18n');

const Global = require('app/www/components/blocks/Global/Global');
const Link = require('app/www/components/blocks/Link/Link');

const Copyright = (props) => {
    const {i18n} = props;
    const yandexLinkProps = {
        url: Global.newYandexUrl,
    };

    return (
        <span className="copyright">
            &copy;&nbsp;
            <Link {...yandexLinkProps}>
                {i18n.get('copyright.yandex')}
            </Link>
        </span>
    );
};

module.exports = withI18n(Copyright);
