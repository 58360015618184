const _ = {
    flatten: require('lodash/flatten'),
};
const {FAVORITES_GET} = require('app/www/constants/favorites');

module.exports = function reducer_Favorites(state = {}, action) {
    switch (action.type) {
        case FAVORITES_GET:
            const hasMore = action.payload.hasMore;
            const schedule = state.schedule.slice();
            const programs = state.programs.slice();

            if (action.payload.schedule.length) {
                const programsIds = _.flatten(programs).map(event => event.program.id);
                const programsFiltered = action.payload.programs.filter(event => !programsIds.includes(event.program.id));

                schedule.push(action.payload.schedule);
                programs.push(programsFiltered);
            }

            return Object.assign({}, state, {
                schedule,
                programs,
                hasMore,
            });

        default:
            return state;
    }
};
