const {SEARCH_GET} = require('app/www/constants/search');

module.exports = function reducer_Search(state = {}, action) {
    switch (action.type) {
        case SEARCH_GET:
            const {channels, events, type, totalChannels, totalEvents} = action.payload;

            const newState = Object.assign({}, state, {
                channels: [].concat(state.channels, channels),
                events: [].concat(state.events, events),
            });

            if (type === 'channels') {
                newState.totalChannels = totalChannels;
            } else if (type === 'events') {
                newState.totalEvents = totalEvents;
            }

            return newState;

        default:
            return state;
    }
};
