require('./Select_ProgramTypes.scss');

const b = require('app/www/libs/b')('select');

const React = require('react');
const PropTypes = require('prop-types');

const Select = require('app/www/components/blocks/Select/Select');

class Select_ProgramTypes extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onChange = this._onChange.bind(this);
    }

    render() {
        const {
            className,
            programTypes,
            selectedProgramTypes,
            availableProgramTypes,
        } = this.props;

        const options = programTypes
            .filter(programType => programType.id > 0)
            .map(programType => ({
                attrs: {
                    id: programType.alias,
                    value: programType.alias,
                    disabled: !availableProgramTypes.includes(programType.alias),
                    selected: selectedProgramTypes.includes(programType.alias),
                },
                content: programType.name,
            }));

        const countSelected = options.filter(item => item.attrs.selected).length;

        const selectProps = {
            className,
            mods: {
                'program-types': true,
                'program-types-active': Boolean(selectedProgramTypes.length),
                size: 'm',
                theme: 'normal',
                'no-arrow': true,
            },
            format: this._formatTitle(countSelected),
            multiple: true,
            options,
            onChange: this._onChange,
        };

        return (
            <Select {...selectProps} />
        );
    }

    /**
     * @param {String} value алиас жанра сетки (передаётся из селекта)
     * @private
     */
    _onChange(value) {
        this.props.onChangeSelectProgramTypes(value);
    }

    /**
     * Форматирует текст кнопки
     * @param {Boolean} countSelected - сколько типов выбрано
     */
    _formatTitle(countSelected) {
        return () => {
            if (countSelected) {
                return (
                    <span className={b('program-type-title')}>
                        {countSelected}
                    </span>
                );
            }
        };
    }
}

Select_ProgramTypes.defaultProps = {
    onChangeProgramTypes: () => {},
};

Select_ProgramTypes.propTypes = {
    programTypes: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    selectedProgramTypes: PropTypes.arrayOf(
        PropTypes.string
    ).isRequired,
    onChangeProgramTypes: PropTypes.func,
};

module.exports = Select_ProgramTypes;

