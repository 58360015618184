require('./AdvManager.scss');
const b = require('app/www/libs/b')('adv-manager');

const React = require('react');
const PropTypes = require('prop-types');
const {findDOMNode} = require('react-dom');
const {isADBEnabled} = require('app/www/libs/aab');

const Metrika = require('app/www/libs/client/metrika');

const {withReq} = require('app/www/components/contexts/Req');

const AdvManagerBase = require('app/www/components/blocks/AdvManagerBase/AdvManagerBase');
const PageVisibilityAware = require('app/www/components/blocks/PageVisibilityAware/PageVisibilityAware');

const STAT_ID = 1;
const DEFAULT_MIN_HEIGHT_FOR_SHOW_EXTRA_BLOCK = 430;

class AdvManager extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            hasExtraBlock: false,
        };

        this._blockedStatus = 'no';
        this._onLoad = this._onLoad.bind(this);
        this._onError = this._onError.bind(this);
        this._addExtraBlock = this._addExtraBlock.bind(this);
        this._listenPageVisibility = this._listenPageVisibility.bind(this);
    }

    render() {
        const {
            mods,
            index,
            sticky,
            statId,
            blockId,
            extraBlockOptions,
            req,
        } = this.props;

        const isViewInIframeKinopoiskhd =
        req.lib('urlBuilder').isViewInIframeKinopoiskhd;

        if (isViewInIframeKinopoiskhd) {
            return null;
        }

        const {hasExtraBlock} = this.state;

        const commonProps = {
            index,
            statId,
            onLoad: this._onLoad,
            onError: this._onError,
            mods,
        };

        if (isADBEnabled(this.props.req)) {
            commonProps.statId = STAT_ID;
            this._blockedStatus = 'yes';
        }

        const AdvManagerBaseProps = Object.assign({}, commonProps, {
            blockId,
        });

        let content = [
            <AdvManagerBase key="base" {...AdvManagerBaseProps} />,
        ];

        if (extraBlockOptions) {
            const AdvManagerBasePropsExtra = Object.assign({}, commonProps, {
                blockId: extraBlockOptions.blockId,
                mods: {
                    extra: true,
                },
            });

            content = content.concat([
                <div key="extra" className={b('wrapper-extra', {hidden: !hasExtraBlock})}>
                    <AdvManagerBase {...AdvManagerBasePropsExtra} className={b()} />
                </div>,
                <PageVisibilityAware key="helper" callback={this._listenPageVisibility}/>,
            ]);
        }

        return (
            <div className={b('wrapper', {sticky})}>
                {content}
            </div>
        );
    }

    /**
     * @param {String} product - тип(direct, rtb, stripe = Промо-полоска)
     * @private
     */
    _onLoad(product) {
        const {
            blockId,
            onLoad,
            extraBlockOptions,
        } = this.props;

        Metrika.trackParams('RTB_ADVMANAGER_RESPONSE', {
            id: blockId,
            flag: true,
        });

        Metrika.trackParams('ADV_BLOKED', {
            blockId,
            flagSet: this._blockedStatus,
        });

        onLoad(product);

        const domNode = findDOMNode(this);

        if (extraBlockOptions
            && Number(domNode.clientHeight) <= (extraBlockOptions.minHeight || DEFAULT_MIN_HEIGHT_FOR_SHOW_EXTRA_BLOCK)
        ) {
            this._addExtraBlock();
        }
    }

    /**
     * @private
     */
    _onError() {
        const {
            onError,
            blockId,
        } = this.props;

        onError();
        Metrika.trackParams('RTB_ADVMANAGER_RESPONSE', {
            id: blockId,
            flag: false,
        });
    }

    /**
     * Дополнительный рекламный блок под "низкой" медийкой
     * @link https://st.yandex-team.ru/TVFRONT-4912
     * @private
     */
    _addExtraBlock() {
        const {blockId} = this.props.extraBlockOptions;
        this.setState({
            hasExtraBlock: true,
        });

        Metrika.trackParams('RTB_ADVMANAGER_RESPONSE', {
            id: blockId,
            flag: true,
        });

        Metrika.trackParams('ADV_BLOKED', {
            blockId,
            flagSet: this._blockedStatus,
        });
    }

    /**
     * Window in focus. Перерисовать рекламу,
     * если есть дополнительный блок
     * @param {Boolean} isHidden вкладка не активна
     * @private
     */
    _listenPageVisibility(isHidden) {
        if (!isHidden) {
            this.setState({
                hasExtraBlock: false,
            });
        }
    }

}

AdvManager.defaultProps = {
    onLoad: () => {},
    onError: () => {},
};

AdvManager.propTypes = {
    mods: PropTypes.object,
    blockId: PropTypes.string,
    index: PropTypes.number,
    statId: PropTypes.number,
    sticky: PropTypes.bool,
    extraBlockOptions: PropTypes.object,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

module.exports = withReq(AdvManager);
