const {
    SUPER_BANNER_OPEN,
} = require('app/www/constants/superBanner');


function openSuperBanner() {
    return {
        type: SUPER_BANNER_OPEN,
        payload: true,
    };
}

module.exports = {
    openSuperBanner,
};
