const React = require('react');
const ReactDOMServer = require('react-dom/server');

const NoScript = (props) => {
    const staticMarkup = ReactDOMServer.renderToStaticMarkup(
        props.children
    );

    return <noscript dangerouslySetInnerHTML={{__html: staticMarkup}} />;
};

module.exports = NoScript;
