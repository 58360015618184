module.exports = {
    id: 213,
    portalId: 213,
    pathId: 213,
    region: {
        id: 213,
        type: 6,
        parent_id: 1,
        parent: 1,
        capital_id: 0,
        chief_region: 0,
        geo_parent_id: 0,
        city_id: 213,
        name: 'Москва',
        native_name: '',
        iso_name: 'RU-MOW',
        is_main: true,
        main: true,
        en_name: 'Moscow',
        ename: 'Moscow',
        short_en_name: 'MSK',
        short_ename: 'MSK',
        bgp_name: '',
        phone_code: '495 499',
        phone_code_old: '095',
        zip_code: '',
        zip_code_old: '',
        position: 1000000000,
        population: 12506468,
        synonyms: 'Moskau, Moskva',
        latitude: 55.753215,
        lat: 55.75396,
        longitude: 37.622504,
        lon: 37.620393,
        latitude_size: 0.878654,
        spn_lat: 0.466439,
        longitude_size: 1.164423,
        spn_lon: 0.641442,
        zoom: 10,
        services: 2047,
        tzname: 'Europe/Moscow',
        timezone: 'Europe/Moscow',
        official_languages: 'ru',
        widespread_languages: 'ru',
        linguistics_list: [165, 165, 165, 165, 162, 162, 164],
        suggest_list: [],
        services_names: [
            'bs',
            'yaca',
            'weather',
            'afisha',
            'maps',
            'tv',
            'ad',
            'etrain',
            'subway',
            'delivery',
            'route',
        ],
    },
    countryId: 225,
    tzinfo: {
        offset: 10800,
        name: 'Europe/Moscow',
        abbr: 'MSK',
        dst: true,
    },
    linguistics: {
        nominative: 'Москва',
        genitive: 'Москвы',
        dative: 'Москве',
        prepositional: 'Москве',
        preposition: 'в',
        locative: '',
        directional: '',
        ablative: '',
        accusative: 'Москву',
        instrumental: 'Москвой',
    },
    parents: [
        213,
        1,
        3,
        225,
        10001,
        10000,
    ],
};
