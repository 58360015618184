const {
    REMINDERS_SETTINGS_RECEIVED,
    REMINDERS_SETTINGS_SAVE,
} = require('app/www/constants/reducerRemindersSettingsPopup');

function reducer_RemindersSettingsPopup(state = {}, action) {
    switch (action.type) {
        case REMINDERS_SETTINGS_RECEIVED:
        case REMINDERS_SETTINGS_SAVE:
            state = action.payload;
            break;
        default:
            state = state || null;
            break;
    }

    return state;
}

module.exports = reducer_RemindersSettingsPopup;
