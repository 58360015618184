require('./ProgramTypes.scss');

const b = require('app/www/libs/b')('program-types');

const React = require('react');
const PropTypes = require('prop-types');
const Button_ProgramType = require('app/www/components/blocks/Button/_ProgramType/Button_ProgramType');

const ProgramTypes = (props) => {
    const {
        programTypes,
        availableProgramTypes,
        selectedProgramTypes,
        onChangeProgramTypes,
    } = props;

    return (
        <div className={b()}>
            {programTypes
                .filter(programType => programType.id > 0)
                .map(programType => {
                    const buttonProps = {
                        programType,
                        mods: {
                            programType: programType.alias,
                            disabled: !availableProgramTypes.includes(programType.alias),
                            selected: selectedProgramTypes.includes(programType.alias),
                        },
                        onClick: onChangeProgramTypes,
                    };

                    return (
                        <Button_ProgramType key={programType.alias} {...buttonProps}>
                            {programType.name}
                        </Button_ProgramType>
                    );
                })
            }
        </div>
    );
};

ProgramTypes.propTypes = {
    programTypes: PropTypes.arrayOf(
        PropTypes.shape(Button_ProgramType.propTypes.programType)
    ).isRequired,
    availableProgramTypes: PropTypes.arrayOf(
        PropTypes.string
    ).isRequired,
    selectedProgramTypes: PropTypes.arrayOf(
        PropTypes.string
    ).isRequired,
    onChangeProgramTypes: PropTypes.func.isRequired,
};

module.exports = ProgramTypes;
