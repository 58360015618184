const {ON_OTT_SELECTIONS_GET} = require('app/www/constants/reducerOtt');

function reducer_Ott(state = {}, action) {
    switch (action.type) {
        case ON_OTT_SELECTIONS_GET:
            state = Object.assign({}, action.payload);
            break;
    }

    return state;
}

module.exports = reducer_Ott;
