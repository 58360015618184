require('./EmptySplash.scss');
require('../EmptySplash/_my/EmptySplash_my.scss');

const b = require('app/www/libs/b')('empty-splash');

const _ = {
    flatten: require('lodash/flatten'),
};

const React = require('react');
const PropTypes = require('prop-types');

const EmptySplash = (props) => {
    const {
        mods,
        logo,
        title,
        description,
        button,
    } = props;

    return (
        <div className={b(mods)}>
            {logo && <div className={b('logo')}>{logo}</div>}
            {title && <div className={b('title')} dangerouslySetInnerHTML={{__html: title}} />}
            {description && (
                <div className={b('description')}>
                    {_.flatten([description]).map((item, index) => (
                        typeof item === 'string' ? <span key={index} dangerouslySetInnerHTML={{__html: item}}/> : item
                    ))}
                </div>
            )}
            {button && <div className={b('button')}>{button}</div>}
        </div>
    );
};

EmptySplash.propTypes = {
    logo: PropTypes.element,
    title: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
    ]),
    description: PropTypes.oneOfType([
        PropTypes.element,
        PropTypes.string,
        PropTypes.array,
    ]),
    button: PropTypes.element,
};

module.exports = EmptySplash;
