const React = require('react');
const PropTypes = require('prop-types');

const transitionEvent = require('app/www/libs/transitionEvent');

class Animate extends React.PureComponent {

    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this._onTransitionEnd = this._onTransitionEnd.bind(this);
    }

    render() {
        return null;
    }

    /**
     * Если animate true,
     * вызываем анимацию появления
     *
     * Если animate false,
     * показываем компонент
     */
    open() {
        const {component, animate} = this.props;

        if (animate) {
            this._subscribeTransition();

            component.setState({
                animating: 'visible',
            });
        } else {
            component.setState({
                visibility: 'visible',
            });
        }
    }

    /**
     * Если animate true,
     * вызываем анимацию исчезновения
     *
     * Если animate false,
     * скрываем компонент
     */
    close() {
        const {component, animate} = this.props;

        if (animate) {
            this._subscribeTransition();

            component.setState({
                animating: 'hidden',
            });
        } else {
            component.setState({
                visibility: 'hidden',
            });
        }
    }

    /**
     * По завершении анимации показываем/скрываем компонент
     * @private
     */
    _onTransitionEnd() {
        const {component} = this.props;

        this._unSubscribeTransition();

        component.setState({
            visibility: component.state.animating,
            animating: false,
        });
    }

    /**
     * Подписываемся на завершение анимации
     * @private
     */
    _subscribeTransition() {
        const {node} = this.props;

        node.addEventListener(transitionEvent, this._onTransitionEnd);
    }

    /**
     * Отписываемся от завершения анимации
     * @private
     */
    _unSubscribeTransition() {
        const {node} = this.props;

        node.removeEventListener(transitionEvent, this._onTransitionEnd);
    }
}

Animate.defaultProps = {
    animate: true,
};

Animate.propTypes = {
    component: PropTypes.object.isRequired,
    animate: PropTypes.bool,
    node: PropTypes.object.isRequired,
};

module.exports = Animate;
