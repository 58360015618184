const {RECOMMENDED_EDITORIAL_GET} = require('app/www/constants/reducer-recommended');

function reducer_RecommendedEditorial(state, action) {
    switch (action.type) {
        case RECOMMENDED_EDITORIAL_GET:
            state = Object.assign({}, action.payload);
            break;
        default:
            state = state || {};
            break;
    }

    return state;
}

module.exports = reducer_RecommendedEditorial;
