/*eslint-disable no-console */
module.exports = (ns) => {
    const functions = {};
    const methods = ['log', 'error', 'info', 'warn'];

    methods.forEach(method => Object.defineProperty(functions, method, {
        value: (data) => console[method](`${ns}: ${data}`),
    }));

    return functions;
};
