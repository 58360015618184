/**
 * Обрезаем строку
 * @param {String} [string]
 * @param {Number} [limit] макс. кол-во символов
 * @param {Number} [restLimit] мин. кол-во обрезанных символов
 * @param {RegExp} [regExp]
 * @returns {String}
 */
function sliceString(string, limit = 125, restLimit = 0, regExp = /[\?.!,:;]*\s[^\s]*$/) {
    string = string || '';

    const slicedString = string.slice(0, limit);
    const restLength = string.length - slicedString.length;

    if (restLength < restLimit) {
        return string;
    }

    return slicedString !== string
        ? `${slicedString.replace(regExp, '')}... `
        : slicedString;
}

module.exports = sliceString;
