const _defaultsDeep = require('lodash/defaultsDeep');
const _unionBy = require('lodash/unionBy');
const _sortBy = require('lodash/sortBy');

/**
 * Склеиваем предыдущее и новое расписание
 * @param prevSchedule
 * @param nextSchedule
 * @returns Object
 */
module.exports = (prevSchedule, nextSchedule = {}) => {
    const days = Object.keys(prevSchedule);

    days.forEach(date => {
        if (prevSchedule[date].isFuture) {
            delete prevSchedule[date];
        }
    });

    const schedule = _defaultsDeep(prevSchedule, nextSchedule);

    days.forEach(date => {
        const sameDay = nextSchedule[date];

        if (sameDay) {
            schedule[date].items = _unionBy(schedule[date].items, sameDay.items, 'id');

            // Нужно для перехода в будущие даты,
            // после склейки сортируем по дате
            // https://st.yandex-team.ru/TVFRONT-3669#1479373885000
            schedule[date].items = _sortBy(schedule[date].items, [
                function(item) {
                    return Date.parse(item.start);
                },
            ]);
        }
    });

    return schedule;
};
