require('./RecommendedTopItemPromo.scss');

const b = require('app/www/libs/b')('recommended-ott-item');

const React = require('react');
const PropTypes = require('prop-types');

const Metrika = require('app/www/libs/client/metrika');

const Image = require('app/www/components/blocks/Image/Image');
const Link = require('app/www/components/blocks/Link/Link');

class RecommendedTopItemPromo extends React.PureComponent {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this._sendMetrika('show');
    }

    render() {
        const {
            url,
            type,
            imageUrl,
        } = this.props;

        const imagesProps = {
            mods: {
                background: true,
            },
            baseUrl: imageUrl,
        };

        const linkProps = {
            url,
            attrs: {
                target: type,
            },
            onClick: () => this._sendMetrika('click'),
        };

        return (
            <div className={b()}>
                <Link {...linkProps}>
                    <div className={b('inner')}>
                        <Image key="image" className={b('image')} {...imagesProps} />
                    </div>
                </Link>
            </div>
        );
    }

    /**
     * Отправляем данные в метрику
     * @param {String} action show|click
     * @private
     */
    _sendMetrika(action) {
        const {campaign} = this.props;

        Metrika.trackParams('ENTRY_POINT', {
            control: 'promoblock',
            campaign,
            action,
        });
    }
}

RecommendedTopItemPromo.propTypes = {
    url: PropTypes.string,
    title: PropTypes.string,
    imageUrl: PropTypes.string,
    campaign: PropTypes.string,
};

module.exports = RecommendedTopItemPromo;
