const fetch = require('app/www/libs/client/fetch');
const routes = require('app/configs/_routes');

module.exports = {

    getEvent(eventId, programCoId) {
        return fetch(routes.event.api.base, {eventId, programCoId}, {
            method: 'get',
        });
    },

    getEvents(eventIds) {
        return fetch(routes.event.api.all, {eventIds}, {
            method: 'get',
        });
    },

};
