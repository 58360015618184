const fetch = require('app/www/libs/client/fetch');
const RESOURCE = require('app/configs/_routes').user.api.scheduleSettings;

/**
 * @param {Object} body тело запроса
 * @returns {Promise}
 */
function saveScheduleSettings(body) {
    return fetch(RESOURCE, null, {
        method: 'put',
        body: JSON.stringify(body),
    });
}

module.exports = {
    saveScheduleSettings,
};
