const plurals = require('app/common/libs/i18n-plural');
const i18nLogger = require('app/common/libs/client/logger')('i18n');

module.exports = {
    get: (key, num, substitutions) => {
        const i18n = window.__I18N__;

        if (!i18n) {
            i18nLogger.error('window.__I18N__ is undefined');
            return '';
        }

        const data = i18n.data;
        const locale = i18n.locale;

        let translated;

        if (typeof num === 'object') {
            substitutions = num;
        }

        if (!Array.isArray(data[key])) {
            if (typeof data[key] === 'undefined') {
                i18nLogger.error(`Key doesn't exist: ${key}`);
                return key;
            }

            translated = data[key];
        } else {
            const pluralForm = plurals[locale](num);
            translated = data[key][pluralForm];
        }

        if (substitutions) {
            Object.keys(substitutions).forEach(placeholder => {
                translated = translated.replace(new RegExp(`{${placeholder}}`, 'g'), substitutions[placeholder]);
            });
        }

        return translated.replace(/\s+/g, ' ').trim();
    },
};
