module.exports = {
    REMINDER_ADD: 'REMINDER_ADD',
    REMINDER_DEL: 'REMINDER_DEL',
    REMINDER_ADD_SESSION: 'REMINDER_ADD_SESSION',
    REMINDER_DEL_SESSION: 'REMINDER_DEL_SESSION',
    REMINDER_OPEN_POPUP: 'REMINDER_OPEN_POPUP',
    REMINDER_CLOSE_POPUP: 'REMINDER_CLOSE_POPUP',
    REMINDERS_UPDATE: 'REMINDERS_UPDATE',
    REMINDER_FUL_FILLED: 'REMINDER_FUL_FILLED',
};
