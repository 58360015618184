require('./RecommendedBottom.scss');

const b = require('app/www/libs/b')('recommended-bottom');

const React = require('react');
const PropTypes = require('prop-types');
const Carousel = require('app/www/components/blocks/Carousel/Carousel');
const RecommendedBottomItem = require('app/www/components/blocks/RecommendedBottomItem/RecommendedBottomItem');
const Spin = require('app/www/components/blocks/Spin/Spin');

const ITEM_WIDTH = 220;
const ITEMS_COUNT_SCROLL = 4;

const RecommendedBottom = (props) => {
    const {
        title,
        events,
        render,
        minItemsShow,
        source,
    } = props;

    const carouselProps = {
        mods: {
            lists: true,
            'recommended-bottom': true,
        },
        hasNext: events.length > minItemsShow,
        scrollWidth: ITEM_WIDTH * ITEMS_COUNT_SCROLL,
    };

    const spinProps = {
        mods: {
            size: 'l',
        },
    };

    if (!render) {
        return (
            <section className={b({empty: true})}>
                <div className={b('loading')}>
                    <Spin {...spinProps} />
                </div>
            </section>
        );
    }

    if (events.length < minItemsShow) {
        return <span />;
    }

    const availableChannels = props.availableChannels || [];

    return (
        <section className={b()}>
            <div className={b('title')}>
                {title}
            </div>
            <Carousel {...carouselProps}>
                {events.map(event => {
                    const RecommendedBottomItemProps = {
                        id: event.id,
                        url: event.url,
                        image: event.program.images[0],
                        isLive: event.live,
                        title: event.title,
                        start: event.start,
                        channel: availableChannels.filter((channel) => channel.id === event.channelId)[0].name,
                        genre: event.program.type.name,
                        source: source,
                    };

                    return <RecommendedBottomItem key={event.id} {...RecommendedBottomItemProps} />;
                })}
            </Carousel>
        </section>
    );
};

Object.defineProperty(RecommendedBottom, 'defaultProps', {
    get: () => ({
        render: true,
    }),
});

RecommendedBottom.propTypes = {
    title: PropTypes.string.isRequired,
    events: PropTypes.arrayOf(
        PropTypes.object
    ).isRequired,
    availableChannels: PropTypes.arrayOf(
        PropTypes.object
    ),
    render: PropTypes.bool,
    minItemsShow: PropTypes.number,
    source: PropTypes.string,
};

module.exports = RecommendedBottom;
