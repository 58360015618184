const _flatten = require('lodash/flatten');
const {
    SCHEDULE_GET,
    SCHEDULE_PROGRAM_ONLINE,
    SCHEDULE_CHUNK_RECEIVED,
    SCHEDULE_PROGRAM_TYPES_CHANGED,
} = require('app/www/constants/reducer-schedule');

function reducer_Schedule(state, action) {
    switch (action.type) {
        case SCHEDULE_GET:
            return action.payload.schedule || state;
        case SCHEDULE_CHUNK_RECEIVED:
            return Object.assign({}, state, {
                currentPage: action.payload.currentPage,
                schedules: _flatten([state.schedules.concat(action.payload.schedules)]),
            });
        case SCHEDULE_PROGRAM_ONLINE:
            return Object.assign({}, state, {
                schedulesProgramOnline: action.payload,
            });
        case SCHEDULE_PROGRAM_TYPES_CHANGED:
            const selectedProgramType = action.payload;
            let selectedProgramTypes = state.selectedProgramTypes.slice();

            if (!selectedProgramTypes.includes(selectedProgramType)) {
                selectedProgramTypes.unshift(selectedProgramType);
            } else {
                selectedProgramTypes = selectedProgramTypes.filter(programType => programType !== selectedProgramType);
            }

            return Object.assign({}, state, {
                selectedProgramTypes,
            });
        default:
            return state || {};
    }
}

module.exports = reducer_Schedule;
