const PREIOD_DEFAULT_EVENTS_LIMIT = 500;
const DEFAULT_PERIOD_ALIAS = 'now';

const PERIODS = {
    now: {
        alias: 'now',
        events: 5,
    },
    morning: {
        alias: 'morning',
        hours: 7,
    },
    afternoon: {
        alias: 'afternoon',
        fromHour: 12,
        hours: 6,
    },
    evening: {
        alias: 'evening',
        fromHour: 18,
    },
    'all-day': {
        alias: 'all-day',
    },
};

/**
 * Получаем объект периода по имени
 * @param {String} name
 * @return {Object|null}
 */
function getPeriod(name) {
    return PERIODS[name] || null;
}

/**
 * @return {Object}
 */
function getDefaultPeriod() {
    return getPeriod(DEFAULT_PERIOD_ALIAS);
}

/**
 * Получаем все объекты периода
 * @returns {Object[]}
 */
function getAllPeriods() {
    return Object.keys(PERIODS)
        .map(key => getPeriod(key))
        .filter(Boolean);
}

/**
 * Проверяем что переданый период `alias`
 * @param {String} compareWithAlias с чем сравниваем
 * @param {String} periodAlias что сравниваем
 * @return {Boolean}
 */
function isPeriod(compareWithAlias, periodAlias) {
    return getPeriod(compareWithAlias).alias === periodAlias;
}

module.exports = {
    PREIOD_DEFAULT_EVENTS_LIMIT,
    getPeriod,
    getDefaultPeriod,
    getAllPeriods,
    isPeriod,
};
