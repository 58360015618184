require('./HeaderMenu.scss');

const b = require('app/www/libs/b')('header-menu');

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');

const Button_WannaSeeCount = require('app/www/components/blocks/Button/_WannaSeeCount/ButtonWannaSeeCount');
const Button = require('app/www/components/blocks/Button/Button');
const Global = require('app/www/components/blocks/Global/Global');
const Popup = require('app/www/components/blocks/Popup/Popup');
const Ua = require('app/www/components/blocks/Ua/Ua');
const User = require('app/www/components/blocks/User/User');
const Link = require('app/www/components/blocks/Link/Link');

const POPUP_TOP_OFFSET = 0;

class HeaderMenu extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this._onClick = this._onClick.bind(this);
        this._onClickLink = this._onClickLink.bind(this);
        this._popupStatus = this._popupStatus.bind(this);
    }

    render() {
        const {
            req,
            i18n,
            userProps,
            superBanner,
        } = this.props;

        const urlBuilder = req.lib('urlBuilder');
        const hasSuperBanner = superBanner && Ua.isTouchPhone;

        const popupProps = {
            directions: 'bottom',
            offset: {
                top: POPUP_TOP_OFFSET,
            },
            mods: {
                theme: 'normal',
                'header-menu': true,
            },
            hasOverlay: true,
            isOpen: this._popupStatus,
            hasSuperBanner,
        };

        const buttonMods = {
            theme: 'clear',
            size: 'm',
            icon: 'dim',
            'header-menu': true,
        };

        const buttonWannaSeeProps = {
            url: urlBuilder.getWannaSeeUrl(),
            mods: buttonMods,
            icon: {
                size: 'm',
                'wanna-see': true,
            },
            tooltipProps: false,
            children: i18n.get('button.wannaSee.button'),
            fetch: true,
        };

        const buttonTuneProps = {
            url: Global.getTuneUrl,
            mods: buttonMods,
            icon: {
                tune: true,
            },
            children: i18n.get('header-menu.tune'),
        };

        const buttonDesktopVersionProps = {
            url: this._changeVersionUrl,
            mods: buttonMods,
            icon: {
                desktop: true,
            },
            children: i18n.get('header-menu.desktopVersion'),
        };

        const buttonHelpProps = {
            url: Global.supportUrl,
            mods: buttonMods,
            icon: {
                help: true,
            },
            children: i18n.get('header-menu.help'),
        };

        return (
            <div
                className={b()}
                ref={ref => (this._node = ref)}
                onClick={this._onClick}
            >
                {this.state.popup && (
                    <Popup
                        ref={ref => (this._popup = ref)}
                        className={b('popup')}
                        owner={this._node}
                        {...popupProps}
                    >
                        <div>
                            {!urlBuilder.isFavoritesUrl && (
                                <Button_WannaSeeCount {...buttonWannaSeeProps} />
                            )}
                            <Button {...buttonTuneProps} />
                            <Button {...buttonDesktopVersionProps} />
                            <Button {...buttonHelpProps} />
                            <div className={b('auth-info')}>
                                <User {...userProps} />
                                {userProps.isLogged && (
                                    <Link className={b('logout')}
                                        url={this._getPassportLogoutUrl()}
                                        onClick={this._onClickLink}
                                    >
                                        {i18n.get('header-menu.logout')}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </Popup>
                )}
            </div>
        );
    }

    /**
     * Урл изменения версии
     * @returns {String}
     * @private
     */
    get _changeVersionUrl() {
        const urlBuilder = this.props.req.lib('urlBuilder');
        const retpath = encodeURIComponent(urlBuilder.appUrls.www);
        const crc = Global.crc;

        return `${Global.yandexUrl}/portal/set/my?block=44&param=1&retpath=${retpath}&sk=${crc}`;
    }

    /**
     * @private
     */
    _onClick() {
        this.setState({
            popup: !this.state.popup,
        });
    }

    /**
     * {Boolean} isOpen попап открыт
     * @private
     */
    _popupStatus(isOpen) {
        this.setState({
            popup: isOpen,
        });
    }

    /**
     * @param {Object} evt
     * @private
     */
    _onClickLink(evt) {
        evt.stopPropagation();
    }

    /**
     * Урл разлогина
     * @returns {String}
     * @private
     */
    _getPassportLogoutUrl() {
        return `${Global.passportUrl}/passport?mode=logout&yu=${Global.yandexuid}&retpath=${Global.retpath}`;
    }
}

HeaderMenu.propTypes = {
    userProps: PropTypes.shape(User.propTypes),
};


function mapStateToProps(state) {
    return {
        superBanner: state.superBanner,
    };
}


module.exports = connect(
    mapStateToProps
)(
    withReq(withI18n(HeaderMenu))
);

