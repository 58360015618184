require('./SuperBanner.scss');
const b = require('app/www/libs/b')('super-banner');

const _ = {
    get: require('lodash/get'),
};
const React = require('react');
const {connect} = require('react-redux');
const {
    openSuperBanner,
} = require('app/www/actions/superBanner');

const {withReq} = require('app/www/components/contexts/Req');
const {withExperiments} = require('app/www/components/contexts/Experiments');

const AdFox = require('app/www/components/blocks/AdFox/AdFox');
const Global = require('app/www/components/blocks/Global/Global');
const Ua = require('app/www/components/blocks/Ua/Ua');

const PageVisibilityAware = require('app/www/components/blocks/PageVisibilityAware/PageVisibilityAware');

const ADV_STAT_ID = 3;

class SuperBanner extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            reload: false,
            isHidden: true,
            showDirectAdv: false,
        };

        this._onLoad = this._onLoad.bind(this);
        this._onError = this._onError.bind(this);
        this._onReload = this._onReload.bind(this);
        this._listenPageVisibility = this._listenPageVisibility.bind(this);
    }

    componentWillUnmount() {
        this._superBanner.removeEventListener('transitionend', this.props.dispatchOpenSuperBanner());
    }

    render() {
        const {
            req,
            channelId,
        } = this.props;

        const {
            isIndexUrl,
            isProgramUrl,
            isChannelUrl,
        } = req.lib('urlBuilder');

        let {
            isHidden,
        } = this.state;

        const creativeParams = Ua.isTouchPhone
            ? Global.adFox.super_banner_touch
            : Global.adFox.super_banner_desktop;

        const params = Object.assign(creativeParams.params, {
            puid1: channelId,
        });

        const AdFoxProps = Object.assign(creativeParams, {
            onLoad: this._onLoad,
            statId: ADV_STAT_ID,
            params,
        });

        const isWrongPage = !isIndexUrl && !isProgramUrl && !isChannelUrl;

        if (isWrongPage) {
            return null;
        }

        return (
            <div className={b({hidden: isHidden})} ref={ref => (this._superBanner = ref)}>
                <AdFox {...AdFoxProps} />
                <PageVisibilityAware callback={this._listenPageVisibility}/>
            </div>
        );
    }

    /**
     * @private
     */
    _onLoad() {
        this.props.dispatchOpenSuperBanner();
    }

    /**
     * @private
     */
    _onError() {
        this.setState({
            isHidden: true,
            showDirectAdv: true,
        });
    }

    /**
     * @private
     */
    _onReload() {
        this.setState({
            reload: !this.state.reload,
        });
    }

    /**
     * Window in focus.
     * @param {Boolean} isHidden вкладка не активна
     * @private
     */
    _listenPageVisibility(isHidden) {
        if (!isHidden) {
            this._onReload();
        }
    }
}

function mapStateToProps(state) {
    const { channel } = state;
    const { channel: currentChannel } = channel;
    let channelId = 0;

    if (currentChannel) {
        channelId = currentChannel.id;
    }

    return {
        channelId: channelId,
        hasBranding: _.get(state, 'channel.branding.status', false),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        dispatchOpenSuperBanner: () => dispatch(openSuperBanner()),
    };
}

module.exports = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withReq(withExperiments(
        SuperBanner
    ))
);
