require('./Popup.scss');
require('./Popup.scss');
require('./_Animate/Popup_Animate.scss');
require('./_Theme/Popup_Theme_Normal.scss');
require('./_To/Popup_To_Bottom.scss');
require('./_To/Popup_To_Left.scss');
require('./_To/Popup_To_Right.scss');
require('./_To/Popup_To_Top.scss');
require('./_Visibility/Popup_Visibility_Hidden.scss');
require('./_Visibility/Popup_Visibility_Visible.scss');
require('./_Suggest/Popup_Suggest.scss');
require('./_HeaderMenu/Popup_HeaderMenu.scss');

const b = require('app/www/libs/b')('popup', true);

const _ = {
    isEqual: require('lodash/isEqual'),
};

const React = require('react');
const ReactDOM = require('react-dom');
const PropTypes = require('prop-types');

const nextTick = require('app/www/libs/nextTick');

const HotkeyListener = require('app/www/components/blocks/HotkeyListener/HotkeyListener');
const ResizeListener = require('app/www/components/blocks/ResizeListener/ResizeListener');
const ClickOutListener = require('app/www/components/blocks/ClickOutListener/ClickOutListener');
const Animate = require('app/www/components/blocks/Animate/Animate');
const Overlay = require('app/www/components/blocks/Overlay/Overlay');

const ESC_KEY_CODE = 27;

class Popup extends React.PureComponent {

    static get defaultProps() {
        return {
            mods: {},
            directions: 'bottom',
            offset: {
                top: 0,
                left: 0,
            },
            clickOut: true,
            isOpen: () => {},
            onMouseLeave: () => {},
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            visibility: 'hidden',
            open: false,
            style: {
                top: 0,
                left: 0,
            },
        };

        const {mods, directions} = this.props;
        this._modAnimate = mods.animate === false ? mods.animate : true;
        this._directions = directions;

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.toggle = this.toggle.bind(this);
        this._update = this._update.bind(this);
    }

    /**
     * Вставляем и показываем попап
     */
    componentDidMount() {
        this._update();
        nextTick(this.open);
    }

    /**
     * Закрываем и удаляем попап
     */
    componentWillUnmount() {
        this.close();
        document.body.removeChild(this._container);
    }

    /**
     * Перерендериваем попап
     * Если изменился вызывающий элемент, направление раскрытия или отсупы, меняем расположение
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const props = this.props;

        if (
            prevProps.directions !== props.directions ||
            !_.isEqual(prevProps.offset, props.offset) ||
            !_.isEqual(prevProps.children, props.children) ||
            !_.isEqual(this._ownerSize, this._getOwnerSize()) ||
            !_.isEqual(this._ownerPosition, this._getOwnerPosition())
        ) {
            this._update();
        }
    }

    /**
     * Рендерим попап в его контейнер
     * @private
     */
    render() {
        const {
            mods,
            className,
            owner,
            tail,
            showClose,
            clickOutExceptions,
            onMouseLeave,
            clickOut,
            children,
            hasOverlay,
            hasSuperBanner,
            isMobileSuggest,
        } = this.props;

        this._insert();

        const clickOutListenerProps = {
            owner: owner,
            exceptions: clickOutExceptions,
            callback: this.close,
        };

        const popupMods = Object.assign({}, mods, {
            to: this._directions.replace(/-.+/, ''),
            animate: this.state.animate || this._modAnimate,
            visibility: this.state.visibility,
            animating: this.state.animating,
        });

        const tailStyle = tail && (this._tailOffset || tail.offset || {}); // @todo to:siauk сделать адаптивный тултип по готовности нового дизайна

        let popupProps = {};

        if (!hasSuperBanner && !isMobileSuggest) {
            popupProps = {
                style: this.state.style,
            };
        }

        const animateProps = {
            component: this,
            animate: this.state.animate,
        };

        const hotkeyListenerProps = {
            keyCode: ESC_KEY_CODE,
            callback: this.close,
        };

        const resizerProps = {
            callback: this._update,
        };

        let content = (
            <div
                ref={ref => (this._popup = this._popup || ref)}
                className={b.mix(className, popupMods)}
                onMouseLeave={onMouseLeave}
                {...popupProps}
            >
                {tail && (
                    <div className={b('tail')} style={tailStyle} />
                )}
                {showClose && (
                    <div className={b('close')} />
                )}
                <div className={b('content')}>
                    {children}
                </div>
                {typeof this.state.animate !== 'undefined' && (
                    <Animate
                        ref={ref => (this._animate = this._animate || ref)}
                        node={this._popup}
                        {...animateProps}
                    />
                )}
                <HotkeyListener {...hotkeyListenerProps} />
                <ResizeListener {...resizerProps} />
            </div>
        );

        const {open: isOpen} = this.state;

        if (hasOverlay && isOpen) {
            document.body.style.overflow = 'hidden';
            document.documentElement.style.overflow = 'hidden';

            content = (
                <Overlay onClick={this.close} hasSuperBanner={hasSuperBanner}>{content}</Overlay>
            );
        }

        if (clickOut) {
            content = (
                <ClickOutListener {...clickOutListenerProps}>{content}</ClickOutListener>
            );
        }

        return ReactDOM.createPortal(content, this._container);
    }

    /**
     * Вставляем и располагаем попап
     */
    _insert() {
        if (!this._container) {
            this._container = document.createElement('div');
            document.body.appendChild(this._container);
        }
    }

    /**
     * Определяем координаты раскрытия попапа
     * @private
     */
    _getDirections() {
        const {isAdaptive, tail} = this.props;

        const popupSize = this._getPopupSize();
        const coords = this._getCoordinates(popupSize);

        if (isAdaptive) {
            this._setDirectionsAdaptive(coords, popupSize);
        }

        switch (this._directions) {
            case 'top':
                if (tail) {
                    this._tailOffset = null;
                }

                return {
                    top: `${coords.to.top}px`,
                    left: `${coords.x.middle}px`,
                };

            case 'top-right':
                if (tail) {
                    this._tailOffset = {
                        left: popupSize.width - this._ownerSize.width / 2,
                    };
                }

                return {
                    top: `${coords.to.top}px`,
                    left: `${coords.x.right}px`,
                };

            case 'top-left':
                if (tail) {
                    this._tailOffset = {
                        left: this._ownerSize.width / 2,
                    };
                }

                return {
                    top: `${coords.to.top}px`,
                    left: `${coords.x.left}px`,
                };

            case 'right':
                return {
                    top: `${coords.y.middle}px`,
                    left: `${coords.to.right}px`,
                };

            case 'right-top':
                return {
                    top: `${coords.y.top}px`,
                    left: `${coords.to.right}px`,
                };

            case 'right-bottom':
                return {
                    top: `${coords.y.bottom}px`,
                    left: `${coords.to.right}px`,
                };

            case 'bottom':
                if (tail) {
                    this._tailOffset = null;
                }

                return {
                    top: `${coords.to.bottom}px`,
                    left: `${coords.x.middle}px`,
                };

            case 'bottom-right':
                if (tail) {
                    this._tailOffset = {
                        left: popupSize.width - this._ownerSize.width / 2,
                    };
                }

                return {
                    top: `${coords.to.bottom}px`,
                    left: `${coords.x.right}px`,
                };

            case 'bottom-left':
                if (tail) {
                    this._tailOffset = {
                        left: this._ownerSize.width / 2,
                    };
                }

                return {
                    top: `${coords.to.bottom}px`,
                    left: `${coords.x.left}px`,
                };

            case 'left':
                return {
                    top: `${coords.y.middle}px`,
                    left: `${coords.to.left}px`,
                };

            case 'left-top':
                return {
                    top: `${coords.y.top}px`,
                    left: `${coords.to.left}px`,
                };

            case 'left-bottom':
                return {
                    top: `${coords.y.bottom}px`,
                    left: `${coords.to.left}px`,
                };

            case 'bottom-window':
                return {
                    bottom: `-${coords.y.top - document.body.clientHeight}px`,
                    left: `${coords.x.middle}px`,
                };
        }

    }

    /**
     * Кординаты раскрытий
     * @param {Object} popupSize размеры попапа
     * @returns {Object}
     * @private
     */
    _getCoordinates(popupSize) {
        const {offset} = this.props;

        this._ownerPosition = this._getOwnerPosition();
        this._ownerSize = this._getOwnerSize();

        return {
            to: {
                top: this._ownerPosition.top - popupSize.height + offset.top,
                right: this._ownerPosition.right + offset.left,
                bottom: this._ownerPosition.bottom + offset.top,
                left: this._ownerPosition.left - popupSize.width + offset.left,
            },
            x: {
                middle: this._ownerPosition.left + (this._ownerSize.width - popupSize.width) / 2 + offset.left,
                right: this._ownerPosition.right - popupSize.width + offset.left,
                left: this._ownerPosition.left + offset.left,
            },
            y: {
                middle: this._ownerPosition.top + (this._ownerSize.height - popupSize.height) / 2 + offset.top,
                top: this._ownerPosition.top + offset.top,
                bottom: this._ownerPosition.bottom - popupSize.height + offset.top,
            },
        };
    }

    /**
     * Направление раскрытия попапа c максимальной видимостью
     * @param {Object} coords координаты раскрытий
     * @param {Object} popupSize размеры попапа
     * @private
     * @return {String}
     */
    _setDirectionsAdaptive(coords, popupSize) {
        const {directions} = this.props;
        const pageWidth = document.documentElement.clientWidth;
        const pageHeight = document.documentElement.clientHeight;
        const pageXOffset = window.pageXOffset;
        const pageYOffset = window.pageYOffset;

        switch (directions) {
            case 'top':
                this._directions = [
                    coords.to.top < pageYOffset ? 'bottom' : 'top',
                    coords.x.middle < pageXOffset ? 'left' :
                        coords.x.middle + popupSize.width > pageWidth + pageXOffset ? 'right' : null,
                ];
                break;
            case 'right':
                this._directions = [
                    coords.to.right + popupSize.width > pageWidth ? 'left' : 'right',
                    coords.y.middle < pageYOffset ? 'top' :
                        coords.y.middle + popupSize.height > pageHeight + pageYOffset ? 'bottom' : null,
                ];
                break;
            case 'bottom':
                this._directions = [
                    coords.to.bottom + popupSize.height > pageHeight + pageYOffset ? 'top' : 'bottom',
                    coords.x.middle < pageXOffset ? 'left' :
                        coords.x.middle + popupSize.width > pageWidth + pageXOffset ? 'right' : null,
                ];
                break;
            case 'left':
                this._directions = [
                    coords.to.left < pageXOffset ? 'right' : 'left',
                    coords.y.middle < pageYOffset ? 'top' :
                        coords.y.middle + popupSize.height > pageHeight + pageYOffset ? 'bottom' : null,
                ];
                break;
        }

        this._directions = this._directions.filter(Boolean).join('-');
    }

    /**
     * Координаты элемента, вызывающего попап
     * @private
     * @return {Object}
     */
    _getOwnerPosition() {
        const owner = this.props.owner.getBoundingClientRect();

        return {
            top: owner.top + pageYOffset,
            right: owner.right + pageXOffset,
            bottom: owner.bottom + pageYOffset,
            left: owner.left + pageXOffset,
        };
    }

    /**
     * Размеры элемента, вызывающего попап
     * @private
     * @return {Object}
     */
    _getOwnerSize() {
        const {owner} = this.props;

        return {
            width: owner.offsetWidth || 0,
            height: owner.offsetHeight || 0,
        };
    }

    /**
     * Размеры попапа
     * @private
     * @return {Object}
     */
    _getPopupSize() {
        const popup = this._popup;

        return {
            width: popup.offsetWidth,
            height: popup.offsetHeight,
        };
    }

    _update() {
        const {mods} = this.props;
        let style = this._getDirections();

        // На всех сервисах использующих "богатый саджест"
        // родительский попап тянется на всю ширину контента,
        // а контент попапа позиционируется относительно input
        if (mods.suggest) {
            style.paddingLeft = `${style.left.replace('px', '') - this._popup.offsetLeft}px`;
            style.left = 0;
        }

        this.setState({
            animate: this._modAnimate,
            style: style,
        });
    }

    open() {
        if (this._animate) {
            this._animate.open();
            this.props.isOpen(true);

            this.setState({
                open: true,
            });
        }
    }

    close() {
        if (this._animate) {
            const {isOpen, hasOverlay} = this.props;
            this._animate.close();

            if (typeof isOpen === 'function') {
                isOpen(false);
            }

            if (hasOverlay) {
                document.body.style.overflow = 'auto';
                document.documentElement.style.overflow = 'visible';
            }

            this.setState({
                open: false,
            });
        }
    }

    toggle() {
        if (this.state.visibility === 'hidden') {
            this.open();
        } else {
            this.close();
        }
    }

}

Popup.propTypes = {
    mods: PropTypes.shape({
        theme: PropTypes.string,
        animate: PropTypes.bool,
    }),
    owner: PropTypes.object.isRequired,
    directions: PropTypes.oneOf([
        'top',
        'top-right',
        'top-left',
        'right',
        'right-top',
        'right-bottom',
        'bottom',
        'bottom-right',
        'bottom-left',
        'left',
        'left-top',
        'left-bottom',
        'bottom-window',
    ]),
    offset: PropTypes.shape({
        top: PropTypes.number,
        left: PropTypes.number,
    }),
    isAdaptive: PropTypes.bool,
    showClose: PropTypes.bool,
    tail: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            offset: PropTypes.shape({
                top: PropTypes.number,
                left: PropTypes.number,
            }),
        }),
    ]),
    clickOut: PropTypes.bool,
    clickOutExceptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    isOpen: PropTypes.func,
    onMouseLeave: PropTypes.func,
    hasOverlay: PropTypes.bool,
};

module.exports = Popup;
