const _ = {
    findIndex: require('lodash/findIndex'),
};

const mergeSchedules = require('app/www/libs/client/program/mergeSchedules');
const {
    SPECIAL_SCHEDULE_GET,
    SPECIAL_SCHEDULE_UPDATE,
} = require('app/specials/constants/special');

module.exports = function reducer_Special(state = null, action) {
    switch (action.type) {
        case SPECIAL_SCHEDULE_GET:
            const schedules = state.schedule.schedules;
            const nextSchedules = action.payload.schedules;

            const indexSchedule = _.findIndex(schedules, schedule => {
                return schedule.channel.id === action.channelId;
            });

            schedules[indexSchedule].schedule = mergeSchedules(
                state.schedule.schedules[indexSchedule].schedule || [],
                nextSchedules[indexSchedule].schedule
            );

            schedules[indexSchedule].optionsNextReq = nextSchedules[indexSchedule].optionsNextReq;

            return Object.assign({}, state, {
                schedule: Object.assign({}, state.schedule, {
                    schedules,
                }),
            });

        case SPECIAL_SCHEDULE_UPDATE:
            return Object.assign({}, state, {
                schedule: Object.assign({}, state.schedule, {
                    schedules: action.payload.schedules,
                }),
            });

        default:
            return state || {};
    }
};
