require('./RecommendedOtt.scss');

const _ = {
    get: require('lodash/get'),
};

const b = require('app/www/libs/b')('recommended-ott');

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const {withReq} = require('app/www/components/contexts/Req');
const Global = require('app/www/components/blocks/Global/Global');

const Carousel = require('app/www/components/blocks/Carousel/Carousel');
const RecommendedOttItem = require('app/www/components/blocks/RecommendedOttItem/RecommendedOttItem');
const RecommendedTopItemPromo = require('app/www/components/blocks/RecommendedTopItemPromo/RecommendedTopItemPromo');

const ITEM_WIDTH = 220;
const ITEMS_COUNT_SCROLL = 4;
const ITEMS_COUNT_SCROLL_TOUCH = 3;

class RecommendedOtt extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        const {entryPoints} = this.props;

        this._promoItem = entryPoints.filter(item => item.enum === 'recommended')[0];
    }

    render() {
        const ottSelection = this.props.ottSelection || [];

        if (!Global.isRussia || !ottSelection.length) {
            return null;
        }

        const {
            title,
            subtitle,
            minItemsShow,
            isLayoutColumnsLarge,
        } = this.props;

        const itemsCountScroll = isLayoutColumnsLarge ? ITEMS_COUNT_SCROLL_TOUCH : ITEMS_COUNT_SCROLL;
        const carouselProps = {
            mods: {
                recommended: true,
                'recommended-top': true,
            },
            scrollWidth: ITEM_WIDTH * ITEMS_COUNT_SCROLL,
        };

        let items;

        carouselProps.hasNext = ottSelection.length >= minItemsShow;
        carouselProps.autoLoad = {
            itemsRendered: itemsCountScroll,
            itemsRemained: itemsCountScroll,
            itemWidth: ITEM_WIDTH,
        };

        items = ottSelection.map((item, i) => {
            return <RecommendedOttItem key={item.filmId || i} {...item} />;
        });

        if (this._promoItem) {
            const promoItemsProps = {
                title: this._promoItem.text,
                url: this._promoItem.url,
                type: this._promoItem.type,
                campaign: this._promoItem.campaign,
                imageUrl: _.get(this._promoItem, 'style.bg.images.ldpi') || _.get(this._promoItem, 'style.bg.images.hdpi', ''),
            };

            const itemPromo = <RecommendedTopItemPromo key='promo' {...promoItemsProps}/>;
            items.unshift(itemPromo);
        }

        return (
            <div className={b()}>
                {title && <h2 className={b('title')}>
                    {title}
                </h2>}
                <Carousel {...carouselProps}>
                    {items}
                </Carousel>
                {subtitle && <span className={b('subtitle')}>
                    {subtitle}
                </span>}
            </div>
        );
    }
}

RecommendedOtt.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    render: PropTypes.bool,
    ottSelection: PropTypes.array,
    minItemsShow: PropTypes.number,
    isLayoutColumnsLarge: PropTypes.bool,
};

function mapStateToProps(state) {
    return {
        entryPoints: state.entryPoints.common,
        ottSelection: state.ott,
        title: (state.schedule || {}).ottSelectionTitle,
        subtitle: (state.schedule || {}).ottSelectionSubtitle,
    };
}

module.exports = connect(
    mapStateToProps,
    null
)(
    withReq(
        RecommendedOtt
    )
);
