require('./Content.scss');

const b = require('app/www/libs/b')('content', true);

const React = require('react');

const Content = (props) => (
    <main className={b.mix(props.className)}>
        {props.children}
    </main>
);

module.exports = Content;
