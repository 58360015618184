const _ = {
    uniq: require('lodash/uniq'),
};
const {
    FAVORITE_PROGRAM_ADD,
    FAVORITE_PROGRAM_DEL,
    FAVORITE_PROGRAMS_UPDATED,
    FAVORITE_PROGRAMS_UPDATED_EXTERNAL,
} = require('app/www/constants/reducerWannaSee');

const initialState = {
    items: [],
    count: {},
};

function getItems(items) {
    return [].concat(items || []);
}

module.exports = function reducer_WannaSee(state = initialState, action) {
    switch (action.type) {
        case FAVORITE_PROGRAM_ADD:
            return !action.payload.success ? state : Object.assign({}, state, {
                items: (() => {
                    let items = getItems(state.items);
                    items.push(action.payload.programId);
                    return items;
                })(),
            });

        case FAVORITE_PROGRAM_DEL:
            return !action.payload.success ? state : Object.assign({}, state, {
                items: (() => {
                    let items = getItems(state.items);
                    items.splice(state.items.indexOf(action.payload.programId), 1);
                    return items;
                })(),
            });

        case FAVORITE_PROGRAMS_UPDATED:
            return Object.assign({}, state, {
                count: action.payload || [],
            });

        case FAVORITE_PROGRAMS_UPDATED_EXTERNAL:
            const items = [].concat(state.items, action.payload.items);

            return Object.assign({}, state, {
                items: _.uniq(items),
            });

        default:
            return state;
    }
};
