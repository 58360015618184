require('./ChannelLogo.scss');
require('./_Size/ChannelLogo_Size_XL.scss');
require('./_Size/ChannelLogo_Size_L.scss');
require('./_Size/ChannelLogo_Size_M.scss');
require('./_Size/ChannelLogo_Size_S.scss');
require('./_Size/ChannelLogo_Size_XS.scss');

const b = require('app/www/libs/b')('channel-logo', true);

const React = require('react');
const PropTypes = require('prop-types');
const Image_Logo = require('app/www/components/blocks/Image/_Logo/Image_Logo');

const ChannelLogo = (props) => {
    const {
        mods,
        logo,
        className,
    } = props;

    if (!logo) {
        return null;
    }

    if (logo.baseUrl) {
        delete logo.baseUrl;
    }

    return <Image_Logo className={b.mix(className, mods)} {...logo} mods={mods} />;
};

ChannelLogo.propTypes = {
    logo: PropTypes.shape(
        Image_Logo.propTypes
    ),
};

module.exports = ChannelLogo;
