const bSelect = require('app/www/libs/b')('select');

const _ = {
    flatten: require('lodash/flatten'),
};

const React = require('react');
const PropTypes = require('prop-types');
const {findDOMNode} = require('react-dom');
const SCROLL_BAR_SIZE = 10;

class ClickOutListener extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onClickOutside = this._onClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this._onClickOutside, true);
        document.addEventListener('scroll', () => (this._isScroll = true));
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this._onClickOutside, true);
        document.removeEventListener('scroll', () => (this._isScroll = true));
    }

    render() {
        return this.props.children;
    }

    _onClickOutside(evt) {
        const domNode = findDOMNode(this);
        const target = evt.target;
        const {owner, exceptions} = this.props;

        if (!owner) {
            this.props.callback();
            return false;
        }

        const isClickOnThis = domNode === target || domNode.contains(target);
        const isClickOnOwner = owner === target || owner.contains(target);
        const isClickOnExceptions = target && Boolean(target.closest)
            ? _.flatten([exceptions, bSelect()]).some(exception => target.closest(`.${exception}`))
            : false;
        const isScrollBar = target.tagName === 'HTML'
            && this._isScroll
            && (evt.clientY >= document.documentElement.clientHeight - SCROLL_BAR_SIZE
            || evt.clientX >= document.documentElement.clientWidth - SCROLL_BAR_SIZE);

        if (!isClickOnThis
            && !isClickOnOwner
            && !isClickOnExceptions
            && !isScrollBar
        ) {
            this.props.callback();
        }
    }
}

ClickOutListener.propTypes = {
    owner: PropTypes.object,
    exceptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.arrayOf(PropTypes.string),
    ]),
    callback: PropTypes.func.isRequired,
};

module.exports = ClickOutListener;
