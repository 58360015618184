require('./Filters.scss');

const b = require('app/www/libs/b')('filters');

const React = require('react');
const Fragment = React.Fragment;
const PropTypes = require('prop-types');

const {withI18n} = require('app/www/components/contexts/I18n');
const {withReq} = require('app/www/components/contexts/Req');

const Button = require('app/www/components/blocks/Button/Button');
const Calendar = require('app/www/components/blocks/Calendar/Calendar');
const Global = require('app/www/components/blocks/Global/Global');
const ProgramTypes = require('app/www/components/blocks/ProgramTypes/ProgramTypes');
const Scroll = require('app/www/components/blocks/Scroll/Scroll');
const Select_Calendar = require('app/www/components/blocks/Select/_Calendar/Select_Calendar');
const Select_ChannelGenres = require('app/www/components/blocks/Select/_ChannelGenres/Select_ChannelGenres');
const Select_Providers = require('app/www/components/blocks/Select/_Providers/Select_Providers');
const Select_ProviderPackages = require('app/www/components/blocks/Select/_ProviderPackages/Select_ProviderPackages');
const Select_ProgramTypes = require('app/www/components/blocks/Select/_ProgramTypes/Select_ProgramTypes');
const Ua = require('app/www/components/blocks/Ua/Ua');

const ALL_PACKAGES_KEY = 'all';
const MY_GENRE_KEY = 'my';

class Filters extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            fixed: false,
        };
        this._isSerpApp = this.props.req.lib('urlBuilder').isSerpApp;

        this._onScroll = this._onScroll.bind(this);
        this._onProviderSelect = this._onProviderSelect.bind(this);
        this._onProviderPackagesSelect = this._onProviderPackagesSelect.bind(this);
    }

    componentDidMount() {
        this._onScroll();
        window.addEventListener('scroll', this._onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this._onScroll);
    }

    render() {
        const {
            i18n,
            mods,
            dndEnabled,
            scheduleSettings,
            channelGenres,
            selectedChannelGenre,
            providers,
            schedules,
            selectedDate,
            selectedPeriod,
            programTypes,
            availableProgramTypes,
            selectedProgramTypes,
            onChangeChannelGenre,
            onClickDnd,
            onChangeCalendar,
            onChangeSelectCalendar,
            onChangeProgramTypes,
            onChangeSelectProgramTypes,
        } = this.props;

        const {fixed} = this.state;

        const locationButtonProps = {
            className: b('location'),
            mods: {
                theme: Ua.isTouchPhone ? 'clear' : 'normal',
                size: 's',
                'only-icon': true,
            },
            icon: {
                location: true,
                size: Ua.isTouchPhone ? 'm' : 'xs',
            },
            url: Global.changeRegionUrl,
            tooltip: true,
        };

        const selectChannelGenresProps = {
            className: b('channels'),
            channelGenres,
            selectedChannelGenre,
            onChange: onChangeChannelGenre,
        };

        const selectCalendar = {
            schedules,
            selectedDate,
            selectedPeriod,
            onChange: onChangeSelectCalendar,
            mods: {
                main: true,
            },
        };

        const calendarProps = {
            schedules,
            selectedDate,
            selectedPeriod,
            onChange: onChangeCalendar,
            mods: {
                main: true,
            },
        };

        const programTypesProps = {
            programTypes,
            availableProgramTypes,
            selectedProgramTypes,
            onChangeProgramTypes,
        };

        const selectProgramTypesProps = {
            className: b('program-types'),
            programTypes,
            selectedProgramTypes,
            availableProgramTypes,
            onChangeSelectProgramTypes,
        };

        const buttonDndProps = {
            className: b('dnd'),
            mods: {
                size: Ua.isTouchPhone ? 'm' : 's',
                theme: mods.dnd ? 'action' : Ua.isTouchPhone ? 'grey' : 'normal',
            },
            onClick: onClickDnd,
        };

        const providerProps = {
            className: b('provider'),
            providers,
            selectedProvider: scheduleSettings.provider || providers[0],
            onChange: this._onProviderSelect,
        };

        const providerPackagesProps = {
            className: b('provider-packages'),
            packages: scheduleSettings.provider && scheduleSettings.provider.packages,
            selectedPackages: scheduleSettings.packages,
            allPackagesKey: ALL_PACKAGES_KEY,
            onChange: this._onProviderPackagesSelect,
        };

        const scrollProps = {
            touch: true,
        };

        return (
            <div className={b()}>
                <div className={b('head-line')}>
                    <div className={b('title-wrapper')}>
                        <h1 className={b('title')}>{i18n.get('filters.title', {region: Global.regionPrepositional})}</h1>
                        {!this._isSerpApp && (
                            <Fragment>
                                <Button {...locationButtonProps} />
                                {!Ua.isTouchPhone && <Select_ChannelGenres {...selectChannelGenresProps} />}
                                {!Ua.isTouchPhone && dndEnabled && (
                                    <Button {...buttonDndProps}>
                                        {mods.dnd
                                            ? i18n.get('button.dnd.done')
                                            : i18n.get('button.dnd.reorder')
                                        }
                                    </Button>
                                )}
                            </Fragment>
                        )}
                    </div>
                    {!this._isSerpApp && !Ua.isTouchPhone && selectedChannelGenre !== MY_GENRE_KEY && providers.length !== 0 && (
                        <div className={b('provider-wrapper')}>
                            <Select_Providers {...providerProps} />
                            {scheduleSettings.provider && (
                                <Select_ProviderPackages {...providerPackagesProps} />
                            )}
                        </div>
                    )}
                </div>
                {!this._isSerpApp && (
                    <div className={b('stick-line', {border: dndEnabled})} ref={(ref) => (this.stickyLine = ref)}>
                        <div className={b('stick-line-wrapper', {fixed})}>
                            <div className={b('stick-line-content')}>
                                {!Ua.isTouchPhone && (
                                    <Fragment>
                                        <Calendar {...calendarProps} />
                                        <ProgramTypes {...programTypesProps} />
                                    </Fragment>
                                )}
                                {Ua.isTouchPhone && (
                                    <Scroll {...scrollProps} >
                                        <Select_ProgramTypes {...selectProgramTypesProps} />
                                        <Select_ChannelGenres {...selectChannelGenresProps} />
                                        <Select_Calendar {...selectCalendar} />
                                        {providers.length !== 0 && (
                                            <Fragment>
                                                <Select_Providers {...providerProps} />
                                                {scheduleSettings.provider && (
                                                    <Select_ProviderPackages {...providerPackagesProps} />
                                                )}
                                            </Fragment>
                                        )}
                                    </Scroll>
                                )}
                            </div>
                        </div>
                    </div>
                )}
                {Ua.isTouchPhone && dndEnabled && (
                    <Button {...buttonDndProps}>
                        {mods.dnd
                            ? i18n.get('button.dnd.done')
                            : i18n.get('button.dnd.reorder')
                        }
                    </Button>
                )}
            </div>
        );
    }

    _onScroll() {
        if (!this._isSerpApp) {
            this.setState({
                fixed: window.pageYOffset >= this.stickyLine.offsetTop,
            });
        }
    }

    /**
     * Сохраняем провайдера
     * @param {String|Number} id
     * @private
     */
    _onProviderSelect(id) {
        const {
            providers,
            onChangeProvider,
        } = this.props;

        const selected = providers.filter(p => p.id === id)[0];

        if (selected) {
            onChangeProvider(selected.packages);
        }
    }

    /**
     * Сохраняем пакеты провайдера
     * @param {String[]|Number[]} packageids
     * @private
     */
    _onProviderPackagesSelect(packageIds) {
        const {
            scheduleSettings,
            onChangeProvider,
        } = this.props;

        const provider = scheduleSettings.provider;
        const packages = provider.packages;
        let newPackages;

        if (packageIds.length === 0 || packageIds[packageIds.length - 1] === ALL_PACKAGES_KEY) {
            newPackages = packages;
        } else {
            newPackages = packages.filter(pkg => packageIds.includes(pkg.id));
        }

        onChangeProvider(newPackages);
    }

}

Filters.propTypes = {
    mods: PropTypes.object,
    scheduleSettings: PropTypes.object.isRequired,
    providers: PropTypes.array,
    channelGenres: PropTypes.array,
    schedules: PropTypes.array.isRequired,
    programTypes: PropTypes.array,
    availableProgramTypes: PropTypes.array,
    dndEnabled: PropTypes.bool.isRequired,
    selectedDate: PropTypes.string,
    selectedPeriod: PropTypes.string,
    selectedChannelGenre: PropTypes.string.isRequired,
    selectedProgramTypes: PropTypes.array,
    onChangeCalendar: PropTypes.func,
    onChangeSelectCalendar: PropTypes.func,
    onChangeSelectProgramTypes: PropTypes.func,
    onChangeProgramTypes: PropTypes.func,
    onChangeChannelGenre: PropTypes.func,
    onChangeProvider: PropTypes.func,
    onClickDnd: PropTypes.func,
};

module.exports = withReq(withI18n(Filters));
