require('./Spin.scss');
require('./_Progress/Spin_Progress.scss');
require('./_Size/Spin_Size_L.scss');
require('./_Size/Spin_Size_M.scss');
require('./_Size/Spin_Size_S.scss');
require('./_Size/Spin_Size_XS.scss');
require('./_Size/Spin_Size_XXS.scss');

const b = require('app/www/libs/b')('spin', true);

const React = require('react');
const PropTypes = require('prop-types');

const Spin = (props) => {
    const mods = Object.assign({progress: true}, props.mods);
    return <span className={b.mix(props.className, mods)} />;
};

Spin.propTypes = {
    mods: PropTypes.shape({
        size: PropTypes.oneOf([
            'xxs',
            'xs',
            's',
            'm',
            'l',
        ]).isRequired,
    }),
};

module.exports = Spin;
