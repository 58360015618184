require('../../Icon/_WannaSee/Icon_WannaSee.scss');
require('../../Icon/_WannaSee/_Size/Icon_WannaSee_Size_S.scss');
require('../../Icon/_WannaSee/_Size/Icon_WannaSee_Size_M.scss');

const b = require('b_').with('button-wrapper');

const _ = {
    isObject: require('lodash/isObject'),
};
const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const Metrika = require('app/www/libs/client/metrika');
const actions = require('app/www/actions/wannaSee');

const {withI18n} = require('app/www/components/contexts/I18n');

const Button = require('app/www/components/blocks/Button/Button');
const ButtonAuth = require('app/www/components/blocks/Button/_Auth/Button_Auth');
const Icon = require('app/www/components/blocks/Icon/Icon');
const Tooltip = require('app/www/components/blocks/Tooltip/Tooltip');
const Ua = require('app/www/components/blocks/Ua/Ua');

const TOOLTIP = require('app/www/constants/tooltip');

class Button_WannaSee extends React.PureComponent {

    static get defaultProps() {
        return {
            tooltipProps: {
                mods: {
                    theme: 'normal',
                    size: 's',
                },
                to: 'top',
                isAdaptive: true,
            },
            icon: {
                'wanna-see': true,
                active: false,
            },
            onClick: () => {},
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            tooltip: false,
            isFavorite: props.isFavorite,
        };

        this._onClick = this._onClick.bind(this);
        this._openTooltip = this._openTooltip.bind(this);
        this._closeTooltip = this._closeTooltip.bind(this);
    }

    static getDerivedStateFromProps(props) {
        return {
            isFavorite: props.isFavorite,
        };
    }

    render() {
        const {
            i18n,
            mods,
            className,
            icon,
            isFavorite,
            userLogged,
            tooltipProps,
        } = this.props;

        if (mods.text) {
            const iconWannaSeeProps = {
                className,
                mods,
            };

            return this.state.isFavorite ? <Icon {...iconWannaSeeProps} /> : null;
        }

        const wrapperMods = {
            'wanna-see': true,
            active: isFavorite,
        };

        const ButtonTag = userLogged ? Button : ButtonAuth;

        const modsExtra = {
            'wanna-see': true,
            active: isFavorite,
        };

        const buttonProps = {
            mods: Object.assign({}, mods, modsExtra),
            icon: Object.assign({}, icon, modsExtra),
            onMouseEnter: this._openTooltip,
            onMouseLeave: this._closeTooltip,
            onClick: this._onClick,
            children: i18n.get('button.wannaSee.button'),
        };

        const tooltipKey = isFavorite ? 'delete' : 'add';

        return (
            <div className={b(wrapperMods)}>
                {(this.state.tooltip && tooltipProps) &&
                    <Tooltip
                        ref={elem => (this.tooltip = elem)}
                        owner={this.button.node}
                        {...tooltipProps}
                    >
                        {i18n.get(`button.wannaSee.tooltip.${tooltipKey}`)}
                    </Tooltip>
                }
                <ButtonTag ref={elem => (this.button = elem)} {...buttonProps} />
            </div>
        );
    }

    /**
     * @private
     */
    _onClick() {
        const {
            store,
            onClick,
            isFavorite,
            userLogged,
        } = this.props;

        if (userLogged) {
            const dispatch = isFavorite
                ? this.props.dispatchDel()
                : this.props.dispatchAdd();

            dispatch.then(() => onClick(isFavorite));
        } else {
            isFavorite // eslint-disable-line no-unused-expressions
                ? this.props.dispatchDelSession()
                : this.props.dispatchAddSession();
        }

        this._closeTooltip();

        if (!isFavorite) {
            Metrika.trackParams(Metrika.PARAMS.WANNA_SEE, {
                balloon: _.isObject(store),
                isLogged: userLogged,
            });
        }
    }

    /**
     * @private
     */
    _openTooltip() {
        if (Ua.isTouch) {
            return;
        }

        this._timeout = setTimeout(() => {
            if (this.state.tooltip) {
                this.tooltip.open();
            } else if (this.props.tooltipProps) {
                this.setState({
                    tooltip: true,
                });
            }
        }, TOOLTIP.DELAY);
    }

    /**
     * @private
     */
    _closeTooltip() {
        if (Ua.isTouch) {
            return;
        }

        clearTimeout(this._timeout);

        if (this.state.tooltip) {
            this.tooltip.close();
        }
    }

}

Button_WannaSee.propTypes = {
    icon: PropTypes.shape({
        'wanna-see': PropTypes.bool,
    }),
    programId: PropTypes.number.isRequired,
    isFavorite: PropTypes.bool,
    tooltipProps: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            to: PropTypes.string,
            isAdaptive: PropTypes.bool,
        }),
    ]),
    onClick: PropTypes.func,
};

function mapStateToProps(state, ownProps) {
    return {
        userLogged: state.user.isLogged,
        isFavorite: state.wannaSee.items.includes(ownProps.programId),
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        dispatchAdd: () => dispatch(actions.add(ownProps.programId)),
        dispatchDel: () => dispatch(actions.del(ownProps.programId)),
        dispatchAddSession: () => dispatch(actions.addSession(ownProps.programId)),
        dispatchDelSession: () => dispatch(actions.delSession(ownProps.programId)),
    };
}

module.exports = connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
        withRef: true,
    }
)(
    withI18n(
        Button_WannaSee
    )
);
