const {TEMPLATE_SCHEDULE_GET} = require('app/specials/constants/template');

module.exports = function reducer_TemplateProject(state = {}, action) {
    switch (action.type) {
        case TEMPLATE_SCHEDULE_GET:
            let schedules = state.schedules.slice();

            if (action.payload.schedule.length) {
                if (action.payload.page) {
                    schedules.push(action.payload.schedule);
                } else {
                    schedules = [action.payload.schedule];
                }
            }

            return Object.assign({}, state, {schedules});

        default:
            return state;
    }
};
