const _isEmpty = require('lodash/isEmpty');
const {SPORT_EVENT_GET} = require('app/www/constants/sport');

function reducer_SportEvent(state, action) {
    switch (action.type) {
        case SPORT_EVENT_GET:
            if (_isEmpty(action.payload)) {
                return null;
            }

            const event = {
                [action.eventId]: action.payload,
            };

            return Object.assign({}, state, {
                events: state ? Object.assign({}, state.events, event) : event,
            });
        default:
            return state || null;
    }
}

module.exports = reducer_SportEvent;
