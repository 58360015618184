const {
    ASYNC_REQUEST_ERROR,
} = require('app/www/constants/async-request-error');

function reducer_ErrorMessage(state, action) {
    switch (action.type) {
        case ASYNC_REQUEST_ERROR:
            return Object.assign({}, state, action.payload);
        default:
            return state || {};
    }
}

module.exports = reducer_ErrorMessage;
