const {RECOMMENDED_ALL_GET} = require('app/www/constants/reducer-recommended');

function reducer_RecommendedAll(state = {}, action) {
    switch (action.type) {
        case RECOMMENDED_ALL_GET:
            state = Object.assign({}, action.payload);
            break;
    }

    return state;
}

module.exports = reducer_RecommendedAll;
