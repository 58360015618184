require('./SearchForm.scss');
require('../Input/_Type/Input_Type_Search.scss');

const b = require('app/www/libs/b')('search-form');

const React = require('react');
const PropTypes = require('prop-types');

const Button = require('app/www/components/blocks/Button/Button');
const Suggest = require('app/www/components/blocks/Suggest/Suggest');

class SearchForm extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };

        this._onClick = this._onClick.bind(this);
        this._onClose = this._onClose.bind(this);
        this._onSelectItem = this._onSelectItem.bind(this);
    }

    render() {
        const {
            action,
            suggestProps,
            buttonName,
            isMobile,
            buttonCloseLabel,
        } = this.props;

        const attrs = {
            action: this.state.action || action,
        };

        suggestProps.focused = this.state.show;

        const buttonProps = {
            mods: {
                theme: 'normal',
                search: true,
                size: 'm',
                pin: 'brick-round',
            },
            attrs: {
                type: 'submit',
            },
            children: buttonName,
        };

        const buttonSearchIconProps = {
            mods: {
                theme: 'ghost',
                icon: 'bright',
                size: 'm',
                'only-icon': true,
            },
            icon: {
                size: 'm',
                search: true,
            },
            onClick: this._onClick,
        };

        return (!isMobile || this.state.show)
            ? (
                <form className={b({active: this.state.show})} {...attrs}>
                    <div className={b('input')}>
                        <Suggest onSelectItem={this._onSelectItem} {...suggestProps} />
                    </div>
                    {!isMobile && (
                        <div className={b('button')}>
                            <Button {...buttonProps} />
                        </div>
                    )}
                    {isMobile && (
                        <div className={b('close')}
                            onClick={this._onClose}
                        >
                            {buttonCloseLabel}
                        </div>
                    )}
                </form>
            )
            : (
                <div className={b('magnifier')}>
                    <Button {...buttonSearchIconProps} />
                </div>
            );
    }

    /**
     * Устанавливаем в адрес формы урл выбранного элемента
     * @param {String} [url] урл
     * @private
     */
    _onSelectItem(url) {
        this.setState({
            action: url,
        });
    }

    /**
     * @private
     */
    _onClick() {
        this.setState({
            show: true,
        });
    }

    /**
     * @private
     */
    _onClose(evt) {
        evt.stopPropagation();

        this.setState({
            show: false,
        });
    }
}

SearchForm.propTypes = {
    action: PropTypes.string,
    buttonName: PropTypes.string,
    buttonCloseLabel: PropTypes.string,
    suggestProps: PropTypes.shape(
        Suggest.PropTypes
    ),
};

module.exports = SearchForm;

