const {REGULAR_SCHEDULE_GET} = require('app/specials/constants/regular');

module.exports = function reducer_Regular(state = {}, action) {
    switch (action.type) {
        case REGULAR_SCHEDULE_GET:
            const schedules = state.schedules.slice();
            schedules.push(action.payload.schedule);

            return Object.assign({}, state, {schedules});

        default:
            return state;
    }
};
