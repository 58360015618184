const _ = {
    get: require('lodash/get'),
};

const React = require('react');
const Button = require('app/www/components/blocks/Button/Button');

const Button_Project = (props) => {
    const {style} = props;
    const icon = _.get(style, 'bg.images.ldpi') || _.get(style, 'bg.images.hdpi', '');
    const iconHover = _.get(style, 'bg.images.ldpihover') || _.get(style, 'bg.images.hdpihover') || icon;

    return [
        <style key="style">
            {`
                .icon_project:before {background-image: url(${icon});}
                .icon_project:after {background-image: url(${iconHover});}
            `}
        </style>,
        <Button key="button" {...props} />,
    ];
};

module.exports = Button_Project;
