require('./ListsCarousel.scss');

const b = require('b_').with('lists-carousel');

const React = require('react');
const PropTypes = require('prop-types');
const Metrika = require('app/www/libs/client/metrika');
const nextTick = require('app/www/libs/nextTick');

const Carousel = require('app/www/components/blocks/Carousel/Carousel');
const Link = require('app/www/components/blocks/Link/Link');
const ResizeListener = require('app/www/components/blocks/ResizeListener/ResizeListener');
const Ua = require('app/www/components/blocks/Ua/Ua');

const NUMBER = 3;
const OFFSET = 32;
const SCROLL_NUMBER = 2;
const ITEM_WIDTH_SMALL = 288;
const ITEM_WIDTH_LARGE = 320;

class ListsCarousel extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};

        this._renderItem = this._renderItem.bind(this);
        this._setOptions = this._setOptions.bind(this);
        this._onClick = this._onClick.bind(this);
    }

    componentDidMount() {
        nextTick(this._setOptions);
    }

    render() {
        const {lists} = this.props;
        const {
            itemWidth,
            scrollWidth,
            hasNext,
        } = this.state;

        const carouselProps = {
            mods: {
                lists: true,
                hidden: !itemWidth,
            },
            scrollWidth,
            hasNext,
        };

        if (this._carousel) {
            carouselProps.width = this._carousel.offsetWidth;
        }

        const resizeListenerProps = {
            callback: this._setOptions,
        };

        return (
            <div ref={ref => (this._carousel = ref)} className={b()}>
                <Carousel {...carouselProps}>
                    {lists.map(this._renderItem)}
                </Carousel>
                <ResizeListener {...resizeListenerProps} />
            </div>
        );
    }

    /**
     * @param {Object} list
     * @param {Number} index
     * @returns {Object}
     * @private
     */
    _renderItem(list, index) {
        const {itemWidth, itemWidthFirst} = this.state;

        const itemStyle = {
            width: `${index % NUMBER ? itemWidthFirst : itemWidth}px`,
        };

        const linkProps = {
            url: list.url,
            onClick: this._onClick(list.metrika, index + 1),
        };

        if (list.bgImage) {
            linkProps.attrs = {
                style: {
                    backgroundImage: `url("${list.bgImage}")`,
                },
            };
        }

        return (
            <div key={list.metrika} className={b('item')} style={itemStyle}>
                <Link className={b('link')} {...linkProps}>
                    <div className={b('title')} dangerouslySetInnerHTML={{__html: list.title}} />
                </Link>
            </div>
        );
    }

    /**
     * Вычисляем ширину элементов и шаг скролла
     * @private
     */
    _setOptions() {
        const {lists} = this.props;
        const carouselWidth = this._carousel.clientWidth - OFFSET;

        let itemWidth;
        let itemWidthFirst;
        let scrollWidth;
        let hasNext;

        if (Ua.isLayoutColumnsSmall) {
            itemWidth = ITEM_WIDTH_SMALL;
            itemWidthFirst = ITEM_WIDTH_SMALL;
            scrollWidth = ITEM_WIDTH_SMALL * SCROLL_NUMBER;
            hasNext = lists.length > 0;
        } else if (Ua.isLayoutColumnsLarge) {
            itemWidth = ITEM_WIDTH_LARGE;
            itemWidthFirst = ITEM_WIDTH_LARGE;
            scrollWidth = ITEM_WIDTH_LARGE * SCROLL_NUMBER;
            hasNext = lists.length > SCROLL_NUMBER;
        } else {
            itemWidth = Math.floor(carouselWidth / NUMBER);
            itemWidthFirst = carouselWidth - itemWidth * (NUMBER - 1);
            scrollWidth = itemWidth * SCROLL_NUMBER;
            hasNext = lists.length > NUMBER;
        }

        this.setState({
            itemWidth,
            itemWidthFirst,
            scrollWidth,
            hasNext,
        });
    }

    /**
     * @param {String} param
     * @param {Number} number
     * @returns {Object}
     * @private
     */
    _onClick(param, number) {
        return () => {
            Metrika.trackParams('LISTS', {param, number});
        };
    }
}

ListsCarousel.propTypes = {
    lists: PropTypes.array.isRequired,
};

module.exports = ListsCarousel;
