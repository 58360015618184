require('./Icon.scss');
require('./_Size/Icon_Size_S.scss');
require('./_Size/Icon_Size_M.scss');
require('./_Size/Icon_Size_L.scss');
require('./_Arrow/Icon_Arrow.scss');
require('./_Arrow/_Size/Icon_Arrow_Size_M.scss');
require('./_Arrow/_Specials/Icon_Arrow_Specials.scss');
require('./_Calendar/Icon_Calendar.scss');
require('./_CalendarCtrl/Icon__CalendarCtrl.scss');
require('./_FavoriteChannel/Icon_FavoriteChannel.scss');
require('./_FavoriteChannel/_Size/Icon_FavoriteChannel_Size_S.scss');
require('./_FavoriteChannel/_Size/Icon_FavoriteChannel_Size_M.scss');
require('./_Live/_Size/Icon_Live_Size_S.scss');
require('./_Live/_Size/Icon_Live_Size_M.scss');
require('./_Onlines/Icon_Onlines.scss');
require('./_RecommendedOnline/Icon_RecommendedOnline.scss');
require('./_Reminder/Icon_Reminder.scss');
require('./_Reminder/_Size/Icon_Reminder_Size_M.scss');
require('./_Search/Icon_Search.scss');
require('./_WannaSee/Icon_WannaSee.scss');
require('./_WannaSee/_Size/Icon_WannaSee_Size_M.scss');
require('./_Arrow/Icon_Arrow.scss');
require('./_Arrow/_Centered/Icon_Arrow_Centered.scss');
require('./_Location/Icon_Location.scss');
require('./_Location/Icon_Location_Size_M.scss');
require('./_Tick/Icon_Tick.scss');
require('./_Close/_Size/Icon_Close_Size_M.scss');
require('./_Close/_Size/Icon_Close_Size_L.scss');
require('./_Play/_Size/Icon_Play_Size_S.scss');
require('./_Play/_Size/Icon_Play_Size_M.scss');
require('./_Play/_Size/Icon_Play_Size_L.scss');

const b = require('app/www/libs/b')('icon', true);

const React = require('react');

const Icon = (props) => {
    const {className, mods} = props;

    return <span className={b.mix(className, mods)} />;
};

module.exports = Icon;
