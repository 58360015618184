/**
 * Хелперы для работы с датой и временем
 *
 * Доступ в коде: req.lib('moment')
 * bh: bh.lib.moment
 *
 * @link http://momentjs.com/
 */

const _ = {
    padStart: require('lodash/padStart'),
    capitalize: require('lodash/capitalize'),
};
const moment = require('moment');

const SOCIAL_DAY_START_HOURS = 5;

const DAYS_WEEK = 7;
const SECONDS_IN_MINUTE = 60;
const SECONDS_IN_HOUR = 60 * SECONDS_IN_MINUTE;
const SECONDS_IN_DAY = 24 * SECONDS_IN_HOUR;
const MUNITES_IN_HOUR = 60;

module.exports = {

    SOCIAL_DAY_START_HOURS,
    SECONDS_IN_MINUTE,
    SECONDS_IN_HOUR,
    SECONDS_IN_DAY,
    MUNITES_IN_HOUR,

    /**
     * Offset in minutes|hours
     * @type {Number|String}
     * @private
     */
    _userOffset: '+03:00',

    /**
     * Устанавливаем пользовательскую локаль и соответствующие настройки
     * @param {Object} params
     * @param {String} params.locale Ex.: ru|uk|by|kz|uz
     * @param {Number|String} params.offset offset из геобазы для выбранного региона
     * @link http://momentjs.com/docs/#/customization/
     * @link http://momentjs.com/docs/#/manipulating/utc-offset/
     */
    setup(params) {
        moment.locale(params.locale);

        if (params.offset) {
            this._userOffset = params.offset / MUNITES_IN_HOUR;
        }
    },

    /**
     * Обертка над `moment()`
     * Возвращаем клонированую дату или новую дату с временной зоной пользователя
     * @param {String|Object|Date|Array} [date] дата
     * @param {String|Number} [offset] смещение
     * @returns {Object} moment. moment(Date.now()) if `date` is empty
     */
    parse(date, offset) {
        offset = offset || this._userOffset;

        return moment.isMoment(date) ? date.clone() : moment(date).utcOffset(offset);
    },

    /**
     * Отдаем наружу настроенный объект `moment`
     * @returns {Object} moment
     */
    lib() {
        return moment;
    },

    /**
     * Добиваем число слева переданными символами
     * Ex.:
     * pad(1) → 01
     * pad(1, 3) → 001
     * @param {Number} number
     * @param {Number} [length=2] желаемое количество знаков
     * @param {String} [sign='0'] символы для добавления
     * @returns {String}
     * @todo tests
     */
    pad(number, length, sign) {
        length = length || 2;
        sign = sign || '0';
        return _.padStart(number, length, sign);
    },

    /**
     * Сравнение двух дат
     * @param {String} action действие before|same|after
     * @param {String|Object|Date|Array} firstDate дата
     * @param {String|Object|Date|Array} [secondDate] дата
     * @returns {Boolean} результат сравнения
     * @link http://momentjs.com/docs/#/query/is-before/
     * @link http://momentjs.com/docs/#/query/is-same/
     * @link http://momentjs.com/docs/#/query/is-after/
     */
    is(action, firstDate, secondDate) {
        const parsedDate = this.parse(firstDate);
        const isAction = `is${_.capitalize(action)}`;

        return parsedDate[isAction](secondDate);
    },

    /**
     * Разница между датами в нужных единицах измерения
     * @param {String} unit единица измерения years|quarters|months|weeks|days|hours|minutes|seconds|milliseconds
     * @param {String|Object|Date|Array} firstDate
     * @param {String|Object|Date|Array} [secondDate]
     * @param {Boolean} [int] для целочисленных значений
     * @returns {Number}
     */
    diff(unit, firstDate, secondDate, int) {
        return moment(secondDate).diff(firstDate, unit, !int);
    },

    /**
     * @param {String|Object|Date|Array} [date]
     * @returns {number}
     */
    unix(date) {
        return this.parse(date).unix();
    },

    /**
     * Является ли дата выходным
     * @param {String|Object|Date|Array} [date]
     * @returns {Boolean}
     */
    isWeekend(date) {
        date = this.getSocialDay(date, true);
        return [6, 7].includes(this.parse(date).isoWeekday());
    },

    /**
     * Определяем, что дата находится в рамках текущей недели
     * @param {String|Object|Date|Array} [date]
     * @returns {Boolean}
     */
    isCurrentWeek(date) {
        const isoWeekday = this.getSocialDayStart().isoWeekday();
        const diff = Math.floor(this.diff('days', this.getSocialDayStart(), date));
        const diffInt = diff >= 0 ? diff + 1 : diff;
        const diffWeeks = isoWeekday + diffInt;

        return diffWeeks >= 0 && diffWeeks <= DAYS_WEEK;
    },

    /**
     * Изменение даты на указанную величину
     * @param {String} action действие add|subtract
     * @param {String|Object|Date|Array} [date]
     * @param {Number} number количество
     * @param {String} unit единица измерения years|quarters|months|weeks|days|hours|minutes|seconds|milliseconds
     * @returns moment измененная дата
     * @link http://momentjs.com/docs/#/manipulating/add/
     * @link http://momentjs.com/docs/#/manipulating/subtract/
     */
    getDeltaDate(action, date, number, unit) {
        const parsedDate = this.parse(date);

        return parsedDate[action](number, unit);
    },

    /**
     * Преобразование даты к началу/концу периода
     * @param {String} action действие start|end
     * @param {String} period период year|quarter|month|week|isoWeek|day|hour|minute|second
     * @param {String|Object|Date|Array} [date]
     * @returns moment преобразованная дата
     * @link http://momentjs.com/docs/#/manipulating/start-of/
     * @link http://momentjs.com/docs/#/manipulating/end-of/
     */
    getRangedDate(action, period, date) {
        const parsedDate = this.parse(date);
        const actionOf = `${action}Of`;

        return parsedDate[actionOf](period);
    },

    /**
     * Получение и нормализация составляющей даты
     * @param {String} component название составляющей даты year|quarter|months|week|month|isoWeekday|weekday|days|dates|hours|minutes|seconds
     * @param {String|Object|Date|Array} [date]
     * @returns {Number|String} значение составляющей даты
     * @link http://momentjs.com/docs/#/get-set/
     */
    getDateComponent(component, date) {
        const parsedDate = this.parse(date);
        const value = parsedDate[component]();

        return this.pad(value);
    },

    /**
     * Получение даты в указанном формате
     * Точка в конце отформатированной даты удаляется
     * @param {String} format формат даты HH:mm|YYYY-MM-DD ...
     * @param {String|Object|Date|Array} [date]
     * @param {Object} [i18n]
     * @param {Boolean} [useUserTimezone] учитывается таймзона из настроек, а не берется из полученной даты
     * @returns {String} дата в указанном формате
     * @link http://momentjs.com/docs/#/displaying/format/
     */
    getFormattedDate(format, date, i18n, useUserTimezone) {
        if (i18n) {
            const diff = this.diff('days', this.getSocialDayStart(), date);

            if (diff >= -1 && diff < 0) {
                return i18n.get('formatted-date.yesterday');
            }

            if (diff >= 0 && diff < 1) {
                return i18n.get('formatted-date.today');
            }

            if (diff >= 1 && diff < 2) {
                return i18n.get('formatted-date.tomorrow');
            }

            date = this.getSocialDay(date, true);
        }

        date = useUserTimezone ? this.parse(date) : moment.parseZone(date);

        return date.format(format).replace(/\.$/, '');
    },

    /**
     * Получение даты в ISO-форматe
     * @param {String|Object|Date|Array} [date]
     * @returns {String} 2015-11-16T05:00:00+03:00
     * @link http://momentjs.com/docs/#/displaying/format/
     */
    toISO(date) {
        return this.parse(date).format();
    },

    /**
     * Прокси-метод для `as-object`
     * @param {String|Object|Date|Array} [date]
     * @returns {Object} {years: {Number}, months: {Number}, date: {Number}, hours: {Number}, minutes: {Number}, seconds: {Number}, milliseconds: {Number}}
     * @link http://momentjs.com/docs/#/displaying/as-object/
     */
    toObject(date) {
        return this.parse(date).toObject();
    },

    /**
     * Получение даты с учетом культурных суток
     * @param {String|Object|Date|Array} [date]
     * @param {Boolean} [notSave] не сохранять год, месяц, дату
     * @returns {Object} moment - 5
     */
    getSocialDay(date, notSave) {
        const socialDay = this.getDeltaDate('subtract', this.parse(date), SOCIAL_DAY_START_HOURS, 'hours');

        if (date && !notSave) {
            const momentDate = moment(date);

            socialDay
                .year(momentDate.year())
                .month(momentDate.month())
                .date(momentDate.date());
        }

        return socialDay;
    },

    /**
     * Начало культурных суток
     * @param {String|Object|Date|Array} [date]
     * @param {Boolean} [notSave] не сохранять год, месяц, дату
     * @returns {Object} moment.withOffset - 5
     */
    getSocialDayStart(date, notSave) {
        return this.getSocialDay(date, notSave)
            .hours(SOCIAL_DAY_START_HOURS)
            .minutes(0)
            .seconds(0)
            .milliseconds(0);
    },

    /**
     * Количество секунд, оставшиеся до конца культурных суток
     * @param {String|Object|Date|Array} [date]
     * @returns {Number}
     */
    getSocialDayDuration(date) {
        const socialDay = this.getSocialDay(date, true);

        return SECONDS_IN_DAY - socialDay.hours() * SECONDS_IN_HOUR - socialDay.minutes() * SECONDS_IN_MINUTE - socialDay.seconds() - 1;
    },

    /**
     * Получение календарной даты (начало для с учетом культурных суток)
     * @param {String|Object|Date|Array} [date]
     * @returns {*|moment}
     */
    getCalendarDate(date) {
        const socialDay = this.getSocialDay(date);

        return this.getRangedDate('start', 'day', socialDay);
    },

    /**
     * Обертка над `moment()`
     * Возвращаем дату с временной зоной из передаваемой даты
     * https://st.yandex-team.ru/TVFRONT-3665#1479374836000
     * @param {String|Object|Date|Array} date дата
     * @returns {Object} moment
     */
    parseWithOffset(date) {
        return this.parse(date, moment.parseZone(date).format('Z'));
    },

};
