const b = require('app/www/libs/b')('adFox');

const React = require('react');
const PropTypes = require('prop-types');

const Global = require('app/www/components/blocks/Global/Global');

const Metrika = require('app/www/libs/client/metrika');

const NEW_LOADER_URL = '//yandex.ru/ads/system/context.js';

function loadAddFoxContext() {
    /**
     * @param {string} src
     * @param {function} onError
     * @param {function} onLoad
     * @param {boolean} isAsync
     * @param {string[]} attributeArr
     * @param {Document} document
     * @returns
     */
    function createScript(src, onError, onLoad, isAsync, attributeArr) {
        const scriptId = Global.advScriptId;
        const lastScript = document.getElementById(scriptId);
        if (lastScript) {
            return;
        }

        const script = document.createElement('script');

        script.type = 'text/javascript';

        script.src = src;
        script.onerror = onError;
        script.onload = onLoad;
        script.async = isAsync;
        script.setAttribute(...attributeArr);
        script.nonce = Global.nonce;
        script.id = scriptId;

        return script;
    }

    /**
     * @param {HTMLScriptElement} script
     */
    function appendScriptToHead(script) {
        const head = document.head;
        head.insertBefore(script, head.firstChild);
    }

    const context = new Promise(
        (resolve, reject) => {
            const adFox = 'yaContextCb';
            window[adFox] = window[adFox] || [];

            if (Ya && Ya.adfoxCode && Ya.adfoxCode.create) {
                return resolve();
            }

            const script = createScript(NEW_LOADER_URL, reject, resolve, true, [
                'crossorigin',
                'anonymous',
            ]);

            appendScriptToHead(script);
        }
    );

    return context;
}

let adFoxLoadPromise = null;
function getAdfoxInstance() {
    if (adFoxLoadPromise) {
        return adFoxLoadPromise;
    }

    return (adFoxLoadPromise = loadAddFoxContext());
}

class AdFox extends React.PureComponent {
    constructor(props) {
        super(props);

        this._onLoad = this._onLoad.bind(this);
        this._onError = this._onError.bind(this);
    }

    componentDidMount() {
        getAdfoxInstance().then(this._onLoad).catch(this._onError);
    }

    render() {
        return (
            <div
                className={b()}
                data-url={NEW_LOADER_URL}
                ref={(ref) => (this.block = ref)}
            >
                <div id={this.props.containerId} />
            </div>
        );
    }

    /**
     * @private
     */
    _onLoad() {
        Metrika.trackParams('ADFOX_ADVMANAGER_RESPONSE', {
            id: this.props.containerId,
            flag: true,
        });

        if (!window || !window.yaContextCb) {
            return;
        }
    
        const { params, ownerId, containerId } = this.props;

        window.yaContextCb.push(() => {
            Ya.adfoxCode.create(
                {
                    params,
                    ownerId,
                    containerId,
                    cspNonce: Global.nonce,
                }
            );
        });
    }

    /**
     * @private
     */
    _onError() {
        Metrika.trackParams('ADFOX_ADVMANAGER_RESPONSE', {
            id: this.props.containerId,
            flag: false,
        });

        this.props.onError();
    }
}

AdFox.defaultProps = {
    onLoad: () => {},
    onError: () => {},
};

AdFox.propTypes = {
    ownerId: PropTypes.number,
    containerId: PropTypes.string,
    statId: PropTypes.number,
    onError: PropTypes.func,
    onLoad: PropTypes.func,
};

module.exports = AdFox;
