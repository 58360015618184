const fetch = require('app/www/libs/client/fetch');
const RESOURCE = require('app/configs/_routes').user.api.wannaSee;

/**
 * @param {String} method post|delete
 * @param {Number} [programId]
 * @returns {Promise.<*|Array>}
 */
function makeRequest(method, programId) {
    return fetch(RESOURCE, null, {
        method: method,
        body: programId && JSON.stringify({
            programId,
        }),
    })
    .then(programsIds => programsIds || []);
}

module.exports = {

    /**
     * @param {Number} programId
     * @returns {Promise.<*|Array>}
     */
    add(programId) {
        return makeRequest('post', programId);
    },

    /**
     * @param {Number} programId
     * @returns {Promise.<*|Array>}
     */
    del(programId) {
        return makeRequest('delete', programId);
    },

    /**
     * @returns {Promise.<Object>}
     */
    update() {
        return makeRequest('get');
    },

};
