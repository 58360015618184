require('./Select_ChannelGenres.scss');

const React = require('react');
const PropTypes = require('prop-types');

const {withI18n} = require('app/www/components/contexts/I18n');

const Select = require('app/www/components/blocks/Select/Select');
const Metrika = require('app/www/libs/client/metrika');
const Ua = require('app/www/components/blocks/Ua/Ua');

class Select_ChannelGenres extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onChange = this._onChange.bind(this);
    }

    render() {
        const {
            i18n,
            className,
            channelGenres,
            selectedChannelGenre,
        } = this.props;

        const options = channelGenres.map(channelGenre => ({
            attrs: {
                id: channelGenre.alias,
                value: channelGenre.alias,
                selected: channelGenre.alias === selectedChannelGenre,
            },
            content: channelGenre.name,
        }));

        options.splice(2, 0, {
            separator: true,
            attrs: {
                value: 'separator',
            },
            content: i18n.get('filters.channel-genres-separator'),
        });

        const selectProps = {
            className,
            mods: {
                'channel-genres': true,
                size: Ua.isTouchPhone ? 'm' : 's',
                theme: 'normal',
            },
            options,
            onChange: this._onChange,
        };

        return (
            <Select {...selectProps} />
        );
    }

    /**
     * @param {String} scheduleTypeAlias алиас жанра сетки (передаётся из селекта)
     * @private
     */
    _onChange(scheduleTypeAlias) {
        this.props.onChange(scheduleTypeAlias);
        Metrika.trackParams('GRID_TYPE', scheduleTypeAlias);
    }

}

Select_ChannelGenres.propTypes = {
    channelGenres: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
    })).isRequired,
    selectedChannelGenre: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

module.exports = withI18n(Select_ChannelGenres);
