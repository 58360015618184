require('./Select_ProviderPackages.scss');

const React = require('react');
const PropTypes = require('prop-types');

const {withI18n} = require('app/www/components/contexts/I18n');

const Select = require('app/www/components/blocks/Select/Select');
const Ua = require('app/www/components/blocks/Ua/Ua');

class Select_ProviderPackages extends React.PureComponent {

    constructor(props) {
        super(props);

        const {
            packages,
            selectedPackages,
        } = this.props;

        this.state = {
            allSelected: selectedPackages.length === packages.length,
        };

        this._onChange = this._onChange.bind(this);
        this._formatTitle = this._formatTitle.bind(this);
    }

    componentDidUpdate(prevProps) {
        const {
            packages,
        } = this.props;

        const prevPackages = prevProps.packages;

        if (packages.length && prevPackages.length && packages[0].id !== prevPackages[0].id) {
            this.setState({
                allSelected: true,
            });
        }
    }

    render() {
        const {
            i18n,
            className,
            packages,
            selectedPackages,
            allPackagesKey,
        } = this.props;

        const {
            allSelected,
        } = this.state;

        const selectOptions = packages.map(pkg => ({
            attrs: {
                id: pkg.id,
                value: pkg.id,
                selected: !allSelected && selectedPackages.some(item => item.id === pkg.id),
            },
            content: pkg.title,
        }));

        const options = Ua.isTouchPhone
            ? selectOptions
            : [{
                attrs: {
                    id: allPackagesKey,
                    value: allPackagesKey,
                    selected: allSelected,
                },
                content: i18n.get('filters.packages-all'),
            }, {
                separator: true,
                attrs: {
                    value: 'separator',
                },
                content: i18n.get('filters.packages-separator'),
            }].concat(selectOptions);

        const selectProps = {
            className,
            mods: {
                packages: true,
                size: Ua.isTouchPhone ? 'm' : 's',
                theme: 'normal',
            },
            format: this._formatTitle(allSelected),
            multiple: true,
            onChange: this._onChange,
            options,
        };

        return (
            <Select {...selectProps} />
        );
    }

    /**
     * Форматирует текст кнопки
     * @param {Boolean} allSelected - все ли пакеты выбраны
     */
    _formatTitle(allSelected) {
        return (selected) => {
            const {i18n} = this.props;

            if (allSelected) {
                return i18n.get('filters.packages-all-button');
            } else if (selected.length === 1) {
                return i18n.get('filters.packages-one-button', {selected});
            }

            return i18n.get('filters.packages-mult-button', {selected: selected.length});
        };
    }

    /**
     * @param {String[]|Number[]} packageids
     * @private
     */
    _onChange(packageIds) {
        var packageIdsLength = packageIds.length;

        this.props.onChange(packageIds);

        this.setState({
            allSelected: !packageIdsLength || packageIds[packageIdsLength - 1] === 'all',
        });
    }
}

Select_ProviderPackages.defaultProps = {
    onChange: () => {},
};

Select_ProviderPackages.propTypes = {
    packages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    })).isRequired,
    selectedPackages: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.number.isRequired,
        title: PropTypes.string.isRequired,
    })),
    allPackagesKey: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

module.exports = withI18n(Select_ProviderPackages);
