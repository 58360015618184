require('./Button_ProgramType.scss');

const React = require('react');
const PropTypes = require('prop-types');
const Button = require('app/www/components/blocks/Button/Button');

const Button_ProgramType = (props) => {
    const {programType, mods, onClick, children} = props;

    const buttonProps = {
        mods: {
            size: 's',
            theme: 'normal',
            disabled: mods.disabled,
            'program-type': mods.programType,
            selected: mods.selected,
        },
        onClick: () => onClick(programType),
    };

    return (
        <Button {...buttonProps}>
            {children}
        </Button>
    );
};

Button_ProgramType.propTypes = {
    programType: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string.isRequired,
        alias: PropTypes.string.isRequired,
    }).isRequired,
    mods: PropTypes.shape({
        programType: PropTypes.string.isRequired,
        selected: PropTypes.bool,
    }),
    onClick: PropTypes.func.isRequired,
};

module.exports = Button_ProgramType;
