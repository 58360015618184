/**
 * @todo to:ixax как-то красивее определять текущие параметры в урле
 * @todo to:ixax убрать копипасту определения текущих `programTypes`
 */

require('./Main.scss');

const b = require('app/www/libs/b')('main-controller');

const _ = {
    get: require('lodash/get'),
    xor: require('lodash/xor'),
    isEqual: require('lodash/isEqual'),
    flatten: require('lodash/flatten'),
};

const React = require('react');
const PropTypes = require('prop-types');
const {connect} = require('react-redux');

const nextTick = require('app/www/libs/nextTick');
const {withReq} = require('app/www/components/contexts/Req');
const {withI18n} = require('app/www/components/contexts/I18n');

const {getSchedule, changeProgramTypes} = require('app/www/actions/schedule');
const {saveScheduleTypeAlias, saveProvider} = require('app/www/actions/scheduleSettings');
const {saveFavoriteChannels, addFavoriteChannel} = require('app/www/actions/favoriteChannels');
const {getEditorial} = require('app/www/actions/recommended');
const {updateExternal: updateWannaSee} = require('app/www/actions/wannaSee');
const {update: updateReminders} = require('app/www/actions/reminders');

const AdvManager = require('app/www/components/blocks/AdvManager/AdvManager');
const Content = require('app/www/components/blocks/Content/Content');
const Dnd = require('app/www/components/blocks/GridDnd/GridDnd');
const Filters = require('app/www/components/blocks/Filters/Filters');
const Grid = require('app/www/components/blocks/Grid/Grid');
const Global = require('app/www/components/blocks/Global/Global');
const ResizeListener = require('app/www/components/blocks/ResizeListener/ResizeListener');
const Metrika = require('app/www/libs/client/metrika');
const RecommendedBottom = require('app/www/components/blocks/RecommendedBottom/RecommendedBottom');
const RecommendedOtt = require('app/www/components/blocks/RecommendedOtt/RecommendedOtt');
const Ua = require('app/www/components/blocks/Ua/Ua');

const CHANNELS_GENRES = {
    ALL: 'all',
    MY: 'my',
};

class MainController extends React.PureComponent {

    static get contextTypes() {
        return {
            router: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);

        this._isSerpApp = this.props.req.lib('urlBuilder').isSerpApp;

        this._onChangeCalendar = this._onChangeCalendar.bind(this);
        this._onChangeSelectCalendar = this._onChangeSelectCalendar.bind(this);
        this._onChangeProgramTypes = this._onChangeProgramTypes.bind(this);
        this._onChangeSelectProgramTypes = this._onChangeSelectProgramTypes.bind(this);
        this._onChangeChannelGenre = this._onChangeChannelGenre.bind(this);
        this._onChangeProvider = this._onChangeProvider.bind(this);
        this._onClickDnd = this._onClickDnd.bind(this);
        this._onResize = this._onResize.bind(this);
        this._saveChannelsOrder = this._saveChannelsOrder.bind(this);

        this._initSchedule = false;

        this.state = {
            loadingSchedule: false,
            dnd: false,
        };
    }

    componentDidMount() {
        nextTick(() => {
            this.setState(this._initialState);
        });
    }

    /**
     * Если изменились избранные каналы, запоминаем, что нужно перегрузить сетку
     * Если сетка обновилась, запоминаем это, скрываем лоадер, проскролливаем страницу (если надо)
     * @param {Object} prevProps
     */
    componentDidUpdate(prevProps) {
        const {
            schedule,
            scheduleSettings,
            favoriteChannels,
            dispatchAddFavoriteChannels,
            dispatchUpdateWannaSee,
            dispatchUpdateReminders,
        } = this.props;

        const {
            schedules,
            providers,
            selectedChannelGenre,
            favoriteChannelIds,
        } = schedule;

        const favoriteChannelsDidUpdated = !_.isEqual(prevProps.favoriteChannels, favoriteChannels);
        const favoriteChannelsInit = !_.isEqual(prevProps.schedule.favoriteChannelIds, favoriteChannelIds);
        const scheduleDidUpdated = !_.isEqual(prevProps.schedule, schedule);

        if (favoriteChannelsDidUpdated) {
            this._needSchedule = true;
        }

        if (scheduleDidUpdated) {
            this._needSchedule = false;

            this.setState({
                loadingSchedule: false,
            });

            if (favoriteChannelsInit && favoriteChannelIds.length) {
                dispatchAddFavoriteChannels(favoriteChannelIds[0]);
            }

            if (!this._initSchedule) {
                this._initSchedule = true;

                const {
                    provider,
                    packages,
                } = scheduleSettings;

                Metrika.trackParams('GRID_PROVIDER', {
                    providersForRegion: providers,
                    providerName: provider
                        ? provider.title
                        : false,
                    packages,
                });

                Metrika.trackParams('CHOOSE_CHANNELS', {
                    type: selectedChannelGenre,
                    favoriteChannelIds,
                });
            }

            let reminders = [];
            let wannaSee = [];

            schedules.forEach(channelSchedule => {
                const events = _.get(channelSchedule, 'events', []);

                events.forEach(event => {
                    if (event.hasReminder) {
                        reminders.push(event.id);
                    }

                    if (event.program.favourite) {
                        wannaSee.push(event.program.id);
                    }
                });
            });

            dispatchUpdateWannaSee(wannaSee);
            dispatchUpdateReminders(reminders);
        }
    }

    render() {
        const {
            isLayoutColumnsLarge,
        } = this.state;

        const {
            i18n,
            store,
            schedule,
            scheduleSettings,
            recommendedEditorial,
        } = this.props;

        const {
            loadingSchedule,
            isLayoutMainLarge,
        } = this.state;

        const dnd = schedule.dndEnabled && this.state.dnd;

        if (
            this._initSchedule &&
            !this._isLastPage &&
            schedule.currentPage + 1 === schedule.totalPages
        ) {
            this._isLastPage = true;

            if (schedule.hasRecommendedEditorial) {
                this.props.dispatchGetEditorial();
            }
        }

        const gridProps = {
            store: store,
            isMain: schedule.isMain,
            isMy: schedule.isMy,
            isAll: schedule.isAll,
            isEmpty: schedule.isEmpty,
            currentPage: schedule.currentPage,
            totalPages: schedule.totalPages,
            selectedDate: schedule.selectedDate,
            selectedPeriod: schedule.selectedPeriod,
            selectedProgramTypes: schedule.selectedProgramTypes || [],
            schedules: schedule.schedules || [],
            scheduleMap: schedule.scheduleMap || [],
            loadingSchedule,
            isLayoutMainLarge,
        };

        const allGenre = schedule.channelGenres.filter(genre => genre.alias === CHANNELS_GENRES.ALL)[0];
        const favoriteGenre = schedule.channelGenres.filter(genre => genre.alias === CHANNELS_GENRES.MY)[0];
        const channelGenres = [allGenre, favoriteGenre].concat(
            schedule.channelGenres.filter(genre => ![CHANNELS_GENRES.MY, CHANNELS_GENRES.ALL].includes(genre.alias))
        );

        const filtersProps = {
            mods: {dnd},
            selectedChannelGenre: schedule.selectedChannelGenre,
            selectedProgramTypes: schedule.selectedProgramTypes || [],
            selectedDate: schedule.selectedDate,
            selectedPeriod: schedule.selectedPeriod,
            channelGenres,
            schedules: schedule.schedules,
            programTypes: schedule.programTypes,
            availableProgramTypes: schedule.availableProgramTypes,
            dndEnabled: schedule.dndEnabled,
            providers: schedule.providers,
            scheduleSettings: scheduleSettings,
            onChangeCalendar: this._onChangeCalendar,
            onChangeSelectCalendar: this._onChangeSelectCalendar,
            onChangeProgramTypes: this._onChangeProgramTypes,
            onChangeSelectProgramTypes: this._onChangeSelectProgramTypes,
            onChangeChannelGenre: this._onChangeChannelGenre,
            onChangeProvider: this._onChangeProvider,
            onClickDnd: this._onClickDnd,
        };

        const dndProps = {
            channels: schedule.schedules.map(data => {
                const channel = data.channel;
                return {
                    id: channel.id,
                    logo: channel.logo,
                    title: channel.title,
                };
            }),
            onDrop: this._saveChannelsOrder,
        };

        const recommendedOttProps = {
            minItemsShow: 4,
            isLayoutColumnsLarge,
        };

        const recommendedEditorialProps = {
            title: i18n.get('recommended-editorial.title'),
            events: recommendedEditorial.events || [],
            availableChannels: recommendedEditorial.availableChannels,
            minItemsShow: 6,
            source: 'editorial',
        };

        const resizeListenerProps = {
            callback: this._onResize,
        };

        const advKey = Ua.isTouchPhone ? '_phone' : '';
        const AdvManagerProps = {
            blockId: Global.adv[`desktop_footer${this._isSerpApp ? '_serp_app' : advKey}`],
        };

        return (
            <Content>
                <div className={b({dnd})}>
                    <div className={b('grid-wrapper')}>
                        {!Ua.isTouchPhone &&
                            <RecommendedOtt {...recommendedOttProps} />
                        }
                        <Filters {...filtersProps} />
                        {schedule.dndEnabled && (
                            <Dnd {...dndProps} />
                        )}
                        <Grid {...gridProps} />
                        {recommendedEditorial.events && (
                            <RecommendedBottom {...recommendedEditorialProps} />
                        )}
                        {this._isLastPage && (
                            <section className={b('adv-bottom')}>
                                <AdvManager {...AdvManagerProps} />
                            </section>
                        )}
                    </div>
                </div>
                <ResizeListener {...resizeListenerProps} />
            </Content>
        );
    }

    /**
     * @param {Event} evt
     * @param {Object} query
     * @private
     */
    _onChangeCalendar(evt, query) {
        evt.stopPropagation();
        evt.preventDefault();

        this._changeLocation(query);
        this.props.dispatchGetSchedule(this._query(query));

        this.setState({
            loadingSchedule: true,
        });

        window.scrollTo(0, 0);
    }

    /**
     * @param {Object} query
     * @private
     */
    _onChangeSelectCalendar(query) {
        this._changeLocation(query);
        this.props.dispatchGetSchedule(this._query(query));

        this.setState({
            loadingSchedule: true,
        });

        window.scrollTo(0, 0);
    }

    /**
     * Колбек по клику на кнопку с `programType`
     * Аргументы поставляются в кнопке
     * @param {Object} programType
     * @param {String} programType.alias
     * @private
     */
    _onChangeProgramTypes(programType) {
        const {selectedProgramTypes} = this.props.schedule;

        let programTypes = selectedProgramTypes.slice();

        if (programTypes.includes(programType.alias)) {
            programTypes = programTypes.filter(type => type !== programType.alias);
        } else {
            programTypes.unshift(programType.alias);

            Metrika.trackParams('GENRE_CHOOSE', {
                master: programType.alias,
                slave: programTypes,
            });
        }

        this._changeLocation({
            genre: programTypes,
        });

        this.props.dispatchChangeProgramTypes(programType.alias);
    }

    /**
     * Колбек при выборе из мульти селекта `programType`
     * @param {Object} programTypesList
     * @private
     */
    _onChangeSelectProgramTypes(programTypesList) {
        const {selectedProgramTypes} = this.props.schedule;

        _.xor(programTypesList, selectedProgramTypes)
            .forEach(item => {
                this.props.dispatchChangeProgramTypes(item);
            });

        this._changeLocation({
            genre: programTypesList,
        });
    }

    /**
     * Сохраняем сетку, меняем урл
     * @param {Object} scheduleTypeAlias алиас жанра канала
     * @private
     */
    _onChangeChannelGenre(scheduleTypeAlias) {
        const query = {
            grid: scheduleTypeAlias,
        };

        this._changeLocation(query);

        this.props.dispatchSaveScheduleTypeAlias(scheduleTypeAlias, this._query(query));

        this.setState({
            loadingSchedule: true,
        });
    }

    /**
     * Сохраняем пакеты провайдера
     * @param {Object[]} selectedPackages пакеты провайдера
     * @private
     */
    _onChangeProvider(selectedPackages) {
        if (!selectedPackages) {
            selectedPackages = [];
        }

        this.props.dispatchSaveProvider(selectedPackages, this._query());

        this.setState({
            loadingSchedule: true,
        });
    }

    /**
     * Меняем урл
     * @param {Object} query
     * @private
     */
    _changeLocation(query) {
        const {req} = this.props;
        const {router} = this.context;
        const {route, history} = router;
        const urlBuilder = req.lib('urlBuilder');
        const location = route.location;

        history.push(`${location.pathname}${urlBuilder.buildQuery(this._query(query))}`);
    }

    /**
     * Получаем объект query
     * @param {Object} [query]
     * @returns {Object}
     * @private
     */
    _query(query) {
        const {req} = this.props;
        return Object.assign({}, req.query, query);
    }

    /**
     * Переходим в режим редактирования списка избранных каналов
     * Сохраняем последовательность каналов при выходе из режима редактирования
     * @private
     */
    _onClickDnd() {
        if (this._needSchedule) {
            this.props.dispatchGetSchedule(this.props.req.query);
        }

        this.setState({
            dnd: !this.state.dnd,
            loadingSchedule: this._needSchedule,
        });
    }

    /**
     * Сохраняем порядок избранных каналов
     * @param {Number[]} channelIds
     * @private
     */
    _saveChannelsOrder(channelIds) {
        this.props.dispatchSaveFavoriteChannels(channelIds);
    }

    /**
     * @returns {Object}
     */
    get _initialState() {
        return {
            isLayoutMainLarge: Ua.isLayoutMainLarge,
        };
    }

    /**
     * @private
     */
    _onResize() {
        this.setState(this._initialState);
    }
}

MainController.propTypes = {
    schedule: PropTypes.shape({
        isMain: PropTypes.bool.isRequired,
        isMy: PropTypes.bool.isRequired,
        isAll: PropTypes.bool.isRequired,
        channelGenres: PropTypes.array.isRequired,
        programTypes: PropTypes.array,
        availableProgramTypes: PropTypes.array,
        providers: PropTypes.array.isRequired,
        selectedDate: PropTypes.string.isRequired,
        selectedPeriod: PropTypes.string.isRequired,
        selectedChannelGenre: PropTypes.string.isRequired,
        selectedProgramTypes: PropTypes.array,
        schedules: PropTypes.array.isRequired,
        scheduleMap: PropTypes.array.isRequired,
        currentPage: PropTypes.number.isRequired,
        totalPages: PropTypes.number,
        expflags: PropTypes.string,
        ottSelectionTitle: PropTypes.string,
        ottSelectionSubtitle: PropTypes.string,
    }),
    scheduleSettings: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        schedule: state.schedule,
        scheduleSettings: state.scheduleSettings,
        favoriteChannels: state.favoriteChannels,
        recommendedEditorial: state.recommendedEditorial,
    };
}

function mapDispatchToProps(dispatch) {
    function _getSchedule(params) {
        return dispatch(getSchedule(params));
    }

    return {
        dispatchSaveScheduleTypeAlias: (scheduleTypeAlias, params) => {
            return dispatch(saveScheduleTypeAlias(scheduleTypeAlias))
                .then(() => _getSchedule(params));
        },
        dispatchSaveProvider: (packages, params) => {
            return dispatch(saveProvider(packages))
                .then(() => _getSchedule(params));
        },
        dispatchChangeProgramTypes: programTypeAlias => dispatch(changeProgramTypes(programTypeAlias)),
        dispatchGetSchedule: params => _getSchedule(params),
        dispatchSaveFavoriteChannels: channelIds => dispatch(saveFavoriteChannels(channelIds)),
        dispatchAddFavoriteChannels: channelId => dispatch(addFavoriteChannel(channelId)),
        dispatchGetEditorial: () => dispatch(getEditorial()),
        dispatchUpdateWannaSee: programIds => dispatch(updateWannaSee(programIds)),
        dispatchUpdateReminders: eventIds => dispatch(updateReminders(eventIds)),
    };
}

module.exports = connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withReq(withI18n(
        MainController
    ))
);
