/**
 * Кроссбраузерное событие transitionend
 * @returns {String}
 * @private
 */
module.exports = (() => {
    const isClient = typeof window !== 'undefined';

    if (isClient) {
        const element = document.createElement('element');
        const transitions = {
            transition: 'transitionend',
            OTransition: 'oTransitionEnd',
            MozTransition: 'transitionend',
            WebkitTransition: 'webkitTransitionEnd',
        };

        for (let key in transitions) {
            if (typeof element.style[key] !== 'undefined') {
                return transitions[key];
            }
        }
    }
})();
