/**
 * @param url
 * @param options
 * @returns {Promise}
 * @link https://github.com/github/fetch
 */
function fetchUrl(url, options) {
    return new Promise((resolve, reject) => {
        fetch(url, options)
            .then(response => {
                if (response.status !== 200) {
                    reject(response.status);
                }

                resolve(response.json());
            })
            .catch(ex => reject(ex));
    });
}

module.exports = fetchUrl;
