const React = require('react');
const PropTypes = require('prop-types');

class HotkeyListener extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onKeyDown = this._onKeyDown.bind(this);
    }

    componentDidMount() {
        document.addEventListener('keydown', this._onKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this._onKeyDown);
    }

    render() {
        return null;
    }

    _onKeyDown(evt) {
        const {keyCode, callback} = this.props;

        (Array.isArray(keyCode) ? keyCode : [keyCode]).forEach((key) => {
            if (evt.keyCode === key) {
                evt.preventDefault();
                callback();
            }
        });
    }
}

HotkeyListener.propTypes = {
    callback: PropTypes.func,
    keyCode: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.array,
    ]).isRequired,
};

module.exports = HotkeyListener;
