const {USER_SETTINGS_FALLBACK} = require('app/www/constants/userSettingsFallback');

function reducer_UserSettingsFallback(state = 0, action) {
    switch (action.type) {
        case USER_SETTINGS_FALLBACK:
            if (action.payload) {
                state++;
            }
            return state;
        default:
            return state;
    }
}

module.exports = reducer_UserSettingsFallback;
