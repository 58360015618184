const fetch = require('app/www/libs/client/fetch');
const RESOURCE = require('app/configs/_routes').user.api.reminders;

/**
 * @param {String} method post|delete
 * @param {Number} eventId
 * @returns {Promise.<*|Array>}
 */
function makeRequest(method, eventId) {
    return fetch(RESOURCE, null, {
        method: method,
        body: JSON.stringify({
            eventId,
        }),
    });
}

module.exports = {

    /**
     * @param {Number} eventId
     * @returns {Promise.<*|Array>}
     */
    add(eventId) {
        return makeRequest('post', eventId);
    },

    /**
     * @param {Number} eventId
     * @returns {Promise.<*|Array>}
     */
    del(eventId) {
        return makeRequest('delete', eventId);
    },

};
