require('./Producers.scss');

const b = require('app/www/libs/b')('producers');

const React = require('react');

const {withI18n} = require('app/www/components/contexts/I18n');

const Global = require('app/www/components/blocks/Global/Global');
const Button = require('app/www/components/blocks/Button/Button');
const Popup = require('app/www/components/blocks/Popup/Popup');
const Link = require('app/www/components/blocks/Link/Link');

class Producers extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {};

        this._onClick = this._onClick.bind(this);
    }

    render() {
        const {i18n} = this.props;

        const buttonProps = {
            mods: {
                size: 'm',
                theme: 'clear',
                producers: true,
            },
        };

        const popupProps = {
            mods: {
                theme: 'normal',
            },
            directions: 'bottom-window',
        };

        const linkProps = {
            attrs: {
                target: '_blank',
            },
        };

        let availableSources = Global.regionInfo.availableSources;
        const regExp = /^(https?:\/\/)?(www.)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;

        availableSources = availableSources.filter(domain => regExp.test(domain.copyrightUrl));

        return (
            <Button
                ref={elem => (this.button = elem)}
                onClick={this._onClick}
                {...buttonProps}
            >
                <div>
                    {i18n.get('contentProviders.title')}
                    {this.state.popup &&
                        <Popup
                            ref={elem => (this.popup = elem)}
                            owner={this.button.node}
                            {...popupProps}
                        >
                            <div className={b()}>
                                <div className={b('title')}>
                                    {i18n.get('contentProviders.title')}
                                </div>
                                <div className={b('label')}>
                                    {i18n.get('contentProviders.program.label')}
                                </div>
                                <div className={b('group-program')}>
                                    <div className={b('item-program', {right: true})}>
                                        {availableSources.map((source) =>
                                            <Link key={source.copyrightUrl}
                                                className={b('provider', {
                                                    name: source.copyrightUrl.match(regExp)[3],
                                                })}
                                                url={source.copyrightUrl}
                                                {...linkProps}
                                            >
                                                <i className={b('icon')} />
                                                <h2 className={b('title-program')}>
                                                    {source.name}
                                                </h2>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Popup>
                    }
                </div>
            </Button>
        );
    }

    /**
     * @private
     */
    _onClick() {
        if (!this.state.popup) {
            this.setState({
                popup: true,
            });
        } else {
            this.popup.toggle();
        }
    }
}

module.exports = withI18n(Producers);
