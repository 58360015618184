/**
 * Инициализация b_
 * Получение модификатора
 * При необходимости добавление метода миксина
 * @param {String} className
 * @param {Boolean} [hasMixin]
 * @returns {Function}
 */
const b_ = require('b_');

module.exports = (className, hasMixin) => {
    const b = b_.with(className);

    b.mod = mod => b(mod).split(' ')[1];

    if (hasMixin) {
        b.mix = (mixClassName, mods = {}) => [b(mods), mixClassName].filter(Boolean).join(' ');
    }

    return b;
};
