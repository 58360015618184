const React = require('react');
const cookies = require('app/www/constants/cookies');
const MetrikaLib = require('app/www/libs/client/metrika');
const secretKeyProvider = require('app/www/dataProviders/client/secretKey');

const SK_REFRESH_TIME = 1000 * 60 * 10; // 10 min

class BaseContainer extends React.PureComponent {

    componentDidMount() {
        this._setUserDisplayCookie();
        this._secretKeyInterval = setInterval(this._updateSecretKey, SK_REFRESH_TIME);
    }

    componentWillUnmount() {
        clearInterval(this._secretKeyInterval);
    }

    constructor(props) {
        super(props);

        this.state = {};

        this._setUserDisplayCookie = this._setUserDisplayCookie.bind(this);
        this._handleMetrikaLoad = this._handleMetrikaLoad.bind(this);
        this._onPageVisibilityChanged = this._onPageVisibilityChanged.bind(this);
    }

    /**
     * Пишем в куку ширину экрана пользователя
     * @private
     */
    _setUserDisplayCookie() {
        const cookieName = cookies['USER_DISPLAY'];
        const windowWidth = window.innerWidth;

        if (this._req.cookies[cookieName] !== String(windowWidth)) {
            document.cookie = `${cookieName}=${windowWidth}; path=/;`;
        }
    }

    /**
     * Обновляем на клиенте sk
     * Если ловим ошибку, перезагружаем страницу
     * @private
     */
    _updateSecretKey() {
        secretKeyProvider.update().catch(() => {
            window.location.reload();
        });
    }

    /**
     * @param {Object} [experiments]
     * @private
     */
    _handleMetrikaLoad(experiments = {}) {
        const {
            user,
        } = this.props.initialState;

        MetrikaLib.trackParams('LOGGED', user.isLogged);

        if (user.isLogged) {
            MetrikaLib.reachGoal('ALARM_NO_WITH_LOGIN_YES');
        }

        if (experiments.has) {
            MetrikaLib.trackParams('EXPERIMENTS', experiments.toMetrika());
        }
    }

    /**
     * @param {Boolean} isHidden
     * @private
     */
    _onPageVisibilityChanged(isHidden) {
        if (isHidden) {
            clearInterval(this._secretKeyInterval);
        } else {
            this._updateSecretKey();
            this._secretKeyInterval = setInterval(this._updateSecretKey, SK_REFRESH_TIME);
        }
    }

}

module.exports = BaseContainer;
