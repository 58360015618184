const {
    REMINDER_OPEN_POPUP,
    REMINDER_CLOSE_POPUP,
} = require('app/www/constants/reducerReminders');

function reducer_RemindersPopupVisible(state, action) {
    switch (action.type) {
        case REMINDER_OPEN_POPUP:
        case REMINDER_CLOSE_POPUP:
            state = action.payload;
            break;
        default:
            state = state || false;
            break;
    }

    return state;
}

module.exports = reducer_RemindersPopupVisible;
