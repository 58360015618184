require('./Teaser_Program.scss');

const b = require('app/www/libs/b')('teaser');

const React = require('react');
const PropTypes = require('prop-types');

const Metrika = require('app/www/libs/client/metrika');
const nextTick = require('app/www/libs/nextTick');

const {withI18n} = require('app/www/components/contexts/I18n');

const Image = require('app/www/components/blocks/Image/Image');
const Link = require('app/www/components/blocks/Link/Link');
const Ua = require('app/www/components/blocks/Ua/Ua');

const COOKIE_NAME = require('app/www/constants/cookies')['TEASER_DISPLAY'];
const COOKIE_EXPIRES = new Date(new Date().getTime() + 3600000 * 12);
const DELAY_OPENING = 100;

class Teaser_Program extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            hidden: false,
        };

        this._onClickClose = this._onClickClose.bind(this);
    }

    componentDidMount() {
        const {
            campaign,
            programId,
        } = this.props;
        const textProgramId = programId ? `_${programId}` : '';
        const cookieUniqueName = `${COOKIE_NAME}_${campaign}${textProgramId}`;
        this._isHidden = document.cookie.includes(cookieUniqueName);

        nextTick(() => {
            if (
                !Ua.isLayoutColumnsLarge &&
                !this._isHidden
            ) {
                this._sendMetrika('show');

                setTimeout(this.setState({
                    visible: true,
                }), DELAY_OPENING);

                document.cookie = `${cookieUniqueName}=1; path=/; expires=${COOKIE_EXPIRES.toUTCString()}`;
            }
        });
    }

    render() {
        if (this._isHidden) {
            return null;
        }

        const {
            url,
            i18n,
            title,
            theme,
            startTime,
            eventTitle,
            programUrl,
            background,
            programTitle,
            hasDescription,
            backgroundFilter,
        } = this.props;

        const {visible, hidden} = this.state;
        const style = {};

        if (backgroundFilter) {
            style.backgroundColor = backgroundFilter;
        }

        const linkProps = {
            url: url.href || programUrl,
            attrs: {
                title: url.title,
            },
            onClick: () => this._sendMetrika('click'),
        };

        const imageProps = {
            baseUrl: background,
            title: url.title,
        };

        return (
            <div className={b({visible, hidden, theme})}>
                {title && <div className={b('title')}>{title}</div>}
                {hasDescription && (
                    <div className={b('column')}>
                        <div>
                            <div className={b('program')}>{programTitle}</div>
                            {eventTitle && (
                                <div className={b('event')}>{eventTitle}</div>
                            )}
                        </div>
                        <div className={b('start')}>
                            {i18n.get('teaser-program.start', {startTime})}
                        </div>
                    </div>
                )}
                <i className={b('close')} onClick={this._onClickClose} />
                <Link className={b('link')} {...linkProps} />
                {backgroundFilter && <div className={b('filter')} style={style}/>}
                <Image className={b('image')} {...imageProps} />
            </div>
        );
    }

    /**
     * Отправляем данные в метрику
     * @param {String} action show|click
     * @private
     */
    _sendMetrika(action) {
        const {campaign} = this.props;

        Metrika.trackParams('TEASER', {
            campaign,
            action,
        });
    }

    /**
     * @private
     */
    _onClickClose() {
        this.setState({
            hidden: true,
        });

        this._sendMetrika('close');
    }
}

Teaser_Program.propTypes = {
    url: PropTypes.object,
    title: PropTypes.string,
    theme: PropTypes.string.isRequired,
    campaign: PropTypes.string.isRequired,
    programId: PropTypes.number,
    startTime: PropTypes.string,
    eventTitle: PropTypes.string,
    programUrl: PropTypes.string,
    background: PropTypes.string.isRequired,
    programTitle: PropTypes.string,
    hasDescription: PropTypes.bool,
};

module.exports = withI18n(Teaser_Program);
