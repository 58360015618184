const dataProvider = require('app/www/dataProviders/client/eventsRecommended');
const {
    RECOMMENDED_NOW_GET,
    RECOMMENDED_EDITORIAL_GET,
    RECOMMENDED_PROVIDER_GET,
} = require('app/www/constants/reducer-recommended');
const {userSettingsFallback} = require('app/www/actions/userSettingsFallback');
const {asyncActionError} = require('app/www/actions/asyncActionError');

function getNow() {
    return dispatch => {
        dataProvider.getNow().then(recommended => {
            dispatch({
                type: RECOMMENDED_NOW_GET,
                payload: recommended,
            });

            dispatch(
                userSettingsFallback(recommended)
            );
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

function getEditorial() {
    return dispatch => {
        dataProvider.getEditorial().then(recommended => {
            dispatch({
                type: RECOMMENDED_EDITORIAL_GET,
                payload: recommended,
            });

            dispatch(
                userSettingsFallback(recommended)
            );
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

function getProvider(programId, programTypeId) {
    return dispatch => {
        dataProvider.getProvider(programId, programTypeId).then(recommended => {
            dispatch({
                type: RECOMMENDED_PROVIDER_GET,
                payload: recommended,
            });

            dispatch(
                userSettingsFallback(recommended)
            );
        })
        .catch(error => {
            dispatch(
                asyncActionError(error)
            );
        });
    };
}

module.exports = {
    getNow,
    getEditorial,
    getProvider,
};
