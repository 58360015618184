require('./Link.scss');
require('./_Hovered/Link_Hovered.scss');
require('./_Theme/Link_Theme_Pseudo.scss');
require('./_External/Link_External_Text.scss');

const b = require('app/www/libs/b')('link', true);

const React = require('react');
const PropTypes = require('prop-types');

class Link extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            hovered: false,
            pressed: false,
        };

        this._onClick = this._onClick.bind(this);
        this._onMouseDown = this._onMouseDown.bind(this);
        this._onMouseUp = this._onMouseUp.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
    }

    render() {
        const {
            mods,
            attrs,
            url,
            children,
            className,
        } = this.props;

        const stateMods = Object.assign({}, mods, this.state);

        attrs.href = url;

        return (
            <a
                ref={elem => (this._node = elem)}
                className={b.mix(className, stateMods)}
                onMouseEnter={this._onMouseEnter}
                onMouseLeave={this._onMouseLeave}
                onMouseDown={this._onMouseDown}
                onMouseUp={this._onMouseUp}
                onClick={this._onClick}
                {...attrs}
            >
                {mods.theme === 'pseudo'
                    ? <span className={b('inner')}>{children}</span>
                    : children
                }
            </a>
        );
    }

    get node() {
        return this._node;
    }

    /**
     * @protected
     */
    _onMouseEnter(evt) {
        this.setState({
            hovered: true,
        });

        this.props.onMouseEnter(evt);
    }

    /**
     * @protected
     */
    _onMouseLeave(evt) {
        this.setState({
            hovered: false,
        });

        this.props.onMouseLeave(evt);
    }

    /**
     * @protected
     */
    _onMouseDown() {
        this.setState({
            pressed: true,
        });
    }

    /**
     * @protected
     */
    _onMouseUp() {
        this.setState({
            pressed: false,
        });
    }

    /**
     * @protected
     */
    _onClick(evt) {
        this.props.onClick(evt);
    }
}

Link.defaultProps = {
    mods: {},
    attrs: {},
    url: '/',
    onMouseEnter: () => {},
    onMouseLeave: () => {},
    onClick: () => {},
};

Link.propTypes = {
    mods: PropTypes.shape({
        theme: PropTypes.oneOf(['pseudo']),
    }),
    attrs: PropTypes.shape({
        tabIndex: PropTypes.number,
    }),
    url: PropTypes.string,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
    onClick: PropTypes.func,
};

module.exports = Link;

