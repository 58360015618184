const _ = {
    set: require('lodash/set'),
};

const React = require('react');
const PropTypes = require('prop-types');

const {YANDEX_ENV} = require('app/common/libs/env');

const Global = require('app/www/components/blocks/Global/Global');
const MetrikaManager = require('app/www/components/blocks/MetrikaManager/MetrikaManager');
const MetrikaLoader = require('app/www/components/blocks/MetrikaLoader/MetrikaLoader');
const MetrikaLib = require('app/www/libs/client/metrika');

class Metrika extends React.PureComponent {

    static get contextTypes() {
        return {
            router: PropTypes.object,
        };
    }

    constructor(props) {
        super(props);

        this.state = {
            loaded: false,
        };

        this._screenSizes = [];
        this.unlisten = () => {};


        this._onLoad = this._onLoad.bind(this);
        this._onResize = this._onResize.bind(this);
        this._onOrientationChange = this._onOrientationChange.bind(this);
    }

    componentDidMount() {
        //@todo to:antipovs Убрать после реализации роутинга через BrowserRouter на всем проекте
        if (this.context.router) {
            const {route, history} = this.context.router;

            this._currentPath = history.createHref(route.location);

            this.unlisten = history.listen(location => {
                const url = history.createHref(location);

                MetrikaLib.hit(url, {
                    referer: this._currentPath,
                });

                this._currentPath = url;
            });
        }

        window.addEventListener('resize', this._onResize);
        window.addEventListener('orientationchange', this._onOrientationChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this._onResize);
        window.removeEventListener('orientationchange', this._onOrientationChange);
        this.unlisten();
    }

    render() {
        const metrikaProps = {
            loaded: this.state.loaded,
            log: YANDEX_ENV !== 'production',
        };

        const metrikaMainProps = Object.assign({}, metrikaProps, {
            onLoad: this.props.onLoad,
            trackInitialHashParams: true,
            params: Global.metrika,
        });

        const metrikaCommonProps = Object.assign({}, metrikaProps, {
            isExecutable: false,
            params: Global.metrikaCommon,
        });

        return (
            <MetrikaLoader onLoad={this._onLoad}>
                <MetrikaManager {...metrikaMainProps} />
                <MetrikaManager {...metrikaCommonProps} />
            </MetrikaLoader>
        );
    }

    /**
     * @private
     */
    _onLoad() {
        this.setState({
            loaded: true,
        });
    }

    /**
     * @see https://st.yandex-team.ru/TVFRONT-3443
     * @private
     */
    _onResize() {
        const screenHeight = document.body.clientHeight;
        const screenWidth = document.body.clientWidth;
        const screenSize = `${screenWidth}х${screenHeight}`;

        this._screenSizes.push(screenSize);

        clearTimeout(this._resizeProcess);

        this._resizeProcess = setTimeout(() => {
            if (screenHeight === document.body.clientHeight
                && screenWidth === document.body.clientWidth) {
                const start = this._screenSizes[0];
                const finish = this._screenSizes[this._screenSizes.length - 1];

                MetrikaLib.params({browser_window: _.set({}, ['resize', start], finish)});
                this._screenSizes = [];
            }
        }, 3000);
    }

    /**
     * @see https://st.yandex-team.ru/TVFRONT-3443
     * @private
     */
    _onOrientationChange() {
        const screenHeight = document.body.clientHeight;
        const screenWidth = document.body.clientWidth;

        setTimeout(() => {
            if (screenHeight > screenWidth) {
                MetrikaLib.params({browser_window: {orientation: 'portrait'}});
            } else {
                MetrikaLib.params({browser_window: {orientation: 'landscape'}});
            }
        }, 500);
    }

}

MetrikaLoader.propTypes = {
    onLoad: PropTypes.func,
};

module.exports = Metrika;
