const _ = {
    findIndex: require('lodash/findIndex'),
};

const mergeSchedules = require('app/www/libs/client/program/mergeSchedules');
const {
    PROGRAM_SCHEDULE_GET,
    PROGRAM_SCHEDULE_UPDATE,
} = require('app/www/constants/reducerProgram');

function reducer_Program(state, action) {
    switch (action.type) {
        case PROGRAM_SCHEDULE_GET:
            const schedules = state.schedules;
            const nextSchedules = action.payload.schedules;

            const indexSchedule = _.findIndex(schedules, schedule => {
                return schedule.channel.id === action.channelId;
            });

            schedules[indexSchedule].schedule = mergeSchedules(
                state.schedules[indexSchedule].schedule || [],
                nextSchedules[indexSchedule].schedule
            );

            schedules[indexSchedule].optionsNextReq = nextSchedules[indexSchedule].optionsNextReq;

            return Object.assign({}, state, {
                schedules,
            });

        case PROGRAM_SCHEDULE_UPDATE:
            return Object.assign({}, state, {
                schedules: action.payload.schedules,
            });

        default:
            return state || {};
    }
}

module.exports = reducer_Program;
