const _union = require('lodash/union');
const {
    REMINDER_ADD,
    REMINDER_DEL,
    REMINDERS_UPDATE,
    REMINDER_FUL_FILLED,
} = require('app/www/constants/reducerReminders');

const initialState = {
    items: [],
};

function getItems(items) {
    return [].concat(items || []);
}

module.exports = function reducer_Reminders(state = initialState, action) {
    switch (action.type) {
        case REMINDER_ADD:
            return !action.payload.success ? state : Object.assign({}, state, {
                items: (() => {
                    let items = getItems(state.items);
                    items.push(action.payload.eventId);
                    return items;
                })(),
            });

        case REMINDER_DEL:
            return !action.payload.success ? state : Object.assign({}, state, {
                items: (() => {
                    let items = getItems(state.items);
                    items.splice(state.items.indexOf(action.payload.eventId), 1);
                    return items;
                })(),
            });

        case REMINDERS_UPDATE:
            return Object.assign({}, state, {
                items: _union(state.items, action.payload.eventIds),
            });

        case REMINDER_FUL_FILLED:
            const eventIds = action.payload || [];

            return Object.assign({}, state, {
                items: _union(
                    state.items,
                    eventIds.filter(event => event.hasReminder).map(event => event.id)
                ),
            });

        default:
            return state;
    }
};
