const fetch = require('app/www/libs/client/fetch');
const req = require('app/www/libs/client/req');
const RESOURCES = require('app/configs/_routes').recommended.api;

module.exports = {

    getNow() {
        return fetch(RESOURCES.now);
    },

    getEditorial() {
        return fetch(RESOURCES.editorial);
    },

    getProvider(programId, programTypeId) {
        const urlBuilder = req.lib('urlBuilder');
        const query = urlBuilder.completeParams({programId, programTypeId});

        return fetch(RESOURCES.provider, query);
    },

};
