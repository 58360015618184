const {USER_SETTINGS_FALLBACK} = require('app/www/constants/userSettingsFallback');

function userSettingsFallback(data) {
    return {
        type: USER_SETTINGS_FALLBACK,
        payload: data.userSettingsFallback,
    };
}

module.exports = {
    userSettingsFallback,
};
