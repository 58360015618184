/**
 * Plural slavic rule
 * @see plurals
 */
function pluralSlavic(num) {
    if (num % 10 === 1 && num % 100 !== 11) {
        return 0;
    }

    if (num % 10 >= 2 && num % 10 <= 4 && (num % 100 < 10 || num % 100 >= 20)) {
        return 1;
    }

    return 2;
}

/**
 * Plural germanic rule
 * @see plurals
 */
function pluralGermanic(num) {
    return (num === 1) ? 0 : 1;
}

/**
 * Plurals helpers
 * @param {Number} num
 * @return {Number} plural form of locale
 */
module.exports = {
    ru: pluralSlavic,
    uk: pluralSlavic,
    uz: pluralSlavic,
    kk: pluralSlavic,
    en: pluralGermanic,
};
