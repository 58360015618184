const {EVENT_GET, EVENTS_GET} = require('app/www/constants/event');

function reducer_Event(state, action) {
    switch (action.type) {
        case EVENT_GET:
            return Object.assign({}, state, {
                item: action.payload,
            });
        case EVENTS_GET:
            return Object.assign({}, state, {
                items: (state && state.items ? state.items : []).concat(action.payload),
            });
        default:
            return state || null;
    }
}

module.exports = reducer_Event;
