const _isEmpty = require('lodash/isEmpty');
const _isObject = require('lodash/isObject');
const _merge = require('lodash/merge');
const req = require('app/www/libs/client/req');
const {stringify} = require('querystring');
const secretKeyStore = require('app/www/libs/client/secretKeyStore');

class FetchError extends Error {

    constructor(status, message, details) {
        super();
        this.message = message;
        this.status = status;
        this.details = details;
    }

}

/**
 * @param url
 * @param query
 * @param options
 * @param noRegion не регионозависимый запрос
 * @returns {Promise}
 * @link https://github.com/github/fetch
 * @todo query передавать отдельным параметром
 */
function fetchUrl(url, query, options, noRegion) {
    if (!noRegion) {
        const regionId = req.region.id ? `/${req.region.id}` : '';
        url = url.replace(/\/:regionId\(\\d\+\)\??/gi, regionId) || '/';
    }

    if (!_isEmpty(query) && _isObject(query)) {
        url += `?${stringify(query)}`;
    }

    const requestOptions = _merge({
        method: 'get',
        credentials: 'same-origin',
        redirect: 'follow',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            'X-TV-SK': secretKeyStore.key,
            'X-User-Session-Id': window.__USER_SESSION_ID__,
        },
    }, options);

    return fetch(url, requestOptions).then(response => {

        if (!(response.status >= 200 && response.status < 400)) {
            throw new FetchError(response.status);
        }

        return response.json();
    }, ex => {
        throw new FetchError(500, 'Fetch error', ex);
    });
}

module.exports = fetchUrl;
