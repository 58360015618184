require('./GridDnd.scss');

const b = require('app/www/libs/b')('grid-dnd');

const React = require('react');
const PropTypes = require('prop-types');

const GridDndChannel = require('app/www/components/blocks/GridDndChannel/GridDndChannel');
const Ua = require('app/www/components/blocks/Ua/Ua');
const {SortableContainer, SortableElement, arrayMove, SortableHandle} = require('react-sortable-hoc');

const DragHandle = SortableHandle(() => <span className={b('item-handle')}>&nbsp;</span>);

const SortableItem = SortableElement(({item}) => {
    const {logo, title} = item;
    const dndChannelProps = {logo, title};

    return (
        <div className={b('item', {mobile: Ua.isTouchPhone})}>
            <GridDndChannel {...dndChannelProps} />
            <DragHandle />
        </div>
    );
});

const SortableList = SortableContainer(({items}) => {
    return (
        <section className={b()}>
            {items.map((item, index) => <SortableItem key={index} index={index} item={item} />)}
        </section>
    );
});

class GridDnd extends React.PureComponent {

    constructor(props) {
        super(props);

        this._onSortEnd = this._onSortEnd.bind(this);

        this.state = {
            channels: props.channels,
        };
    }

    static getDerivedStateFromProps(props, state) {
        return props.channels.length !== state.channels.length ? {
            channels: props.channels,
        } : null;
    }

    render() {
        const sortableListProps = {
            helperClass: b('item', {active: true}),
            axis: Ua.isTouchPhone ? 'y' : 'xy',
            items: this.state.channels,
            onSortEnd: this._onSortEnd,
            transitionDuration: Ua.isTouchPhone ? 300 : 700,
            useDragHandle: Ua.isTouchPhone,
        };

        return <SortableList {...sortableListProps} />;
    }

    /**
     * Сохраняем новый порядок каналов
     * @param {Object}
     */
    _onSortEnd({oldIndex, newIndex}) {
        this.setState({
            channels: arrayMove(this.state.channels, oldIndex, newIndex),
        });

        const channelIds = this.state.channels.map(item => item.id);
        this.props.onDrop(channelIds);
    }
}

GridDnd.propTypes = {
    channels: PropTypes.arrayOf(
        PropTypes.shape(GridDndChannel.propTypes)
    ),
    onDrop: PropTypes.func,
};

module.exports = GridDnd;
