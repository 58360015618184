const querystring = require('querystring');
const _get = require('lodash/get');

let req = Object.create(null);

if (typeof window !== 'undefined') {
    const location = document.location;

    const libs = {
        urlBuilder: require('app/common/libs/urlBuilder'),
        moment: require('app/common/libs/moment'),
    };

    function getLib(name) {
        return Object.defineProperty(libs[name], 'req', req);
    }

    function parseQuery() {
        try {
            const query = location.search.split('?')[1];
            return querystring.parse(query || '');
        } catch (ex) {
            return {};
        }
    }

    const regionId = Number(_get(location.pathname.match(/^\/(\d+)/), '1')) || _get(window, 'LEGO_GLOBAL.regionId', null);

    Object.defineProperties(req, {
        cookies: {
            value: (() => {
                let cookies = {};
                document.cookie.split('; ').forEach(cookie => {
                    let arr = cookie.split('=');
                    cookies[arr[0]] = arr[1];
                });

                return cookies;
            })(),
            enumerable: false,
            writable: false,
            configurable: false,
        },

        lib: {
            value: name => getLib(name),
            enumerable: false,
            writable: false,
            configurable: false,
        },
        hostname: {
            value: `new.${location.hostname.replace('new.', '')}`,
            enumerable: true,
            writable: false,
            configurable: false,
        },
        appHost: {
            value: location.hostname,
            enumerable: true,
            writable: false,
            configurable: false,
        },
        path: {
            value: location.pathname,
            enumerable: true,
        },
        query: {
            get: parseQuery,
            enumerable: true,
            configurable: false,
        },
        originalUrl: {
            value: `${location.pathname}${location.search}`,
            enumerable: true,
            configurable: false,
        },
        region: {
            value: {
                id: regionId,
            },
            enumerable: true,
            writable: false,
            configurable: false,
        },
        regionId: {
            value: regionId,
            enumerable: true,
            writable: false,
            configurable: false,
        },
        isAdaptive: {
            value: window.process.env.IS_ADAPTIVE,
            enumerable: false,
            writable: false,
            configurable: false,
        },
    });

    Object.defineProperty(libs.urlBuilder, 'req', {
        value: req,
        enumerable: true,
        writable: false,
        configurable: false,
    });

    // Нельзя проинициализировать сразу, потому что `window` не готов
    document.addEventListener('DOMContentLoaded', () => {
        Object.defineProperty(req, 'user', {
            value: window.__INITIAL_STATE__.user,
            enumerable: true,
        });

        req.lib('moment').setup(window.__INITIAL_STATE__.moment);
    });

}

module.exports = req;
