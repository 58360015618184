const React = require('react');

const {getAdv} = require('app/common/libs/config');

const { withReq } = require('app/www/components/contexts/Req');
const { withExperiments } = require('app/www/components/contexts/Experiments');

const AdvManager = require('app/www/components/blocks/AdvManager/AdvManager');
const Global = require('app/www/components/blocks/Global/Global');
const Ua = require('app/www/components/blocks/Ua/Ua');
const COOKIE_NAME = require('app/www/constants/cookies')['FULLSCREEN_AD'];
const { ADV_TYPE_DEFAULT } = require('app/www/constants/adv-type');

// https://st.yandex-team.ru/OTT-19056
const EXPERIMENT_NAME = 'fullscreen_ad';

class FullScreenAd extends React.PureComponent {
    constructor(props) {
        super(props);
        this._onLoad = this._onLoad.bind(this);
        this._getFullScreenCookieExpires = this._getFullScreenCookieExpires.bind(this);
    }

    render() {
        const { experiments, req } = this.props;

        const isFullScreenCookie = req.cookies[COOKIE_NAME];
        const experimentGroup = experiments.get(EXPERIMENT_NAME);
        const isDefaultExperimentGroup = experimentGroup === ADV_TYPE_DEFAULT;

        if (!Ua.isTouchPhone || isFullScreenCookie || isDefaultExperimentGroup) {
            return null;
        }

        const AdvManagerProps = {
            blockId: getAdv(
                'desktop_fullscreen_touch',
                experimentGroup,
                Global.adv
            ),
            onLoad: this._onLoad,
        };

        return (
            <AdvManager {...AdvManagerProps} />
        );
    }

    _onLoad() {
        const COOKIE_EXPIRES = this._getFullScreenCookieExpires();
        document.cookie = `${COOKIE_NAME}=1; path=/; expires=${COOKIE_EXPIRES.toUTCString()}`;
    }

    _getFullScreenCookieExpires() {
        const {experiments} = this.props;
        const experiment = experiments.get(EXPERIMENT_NAME);
        const dayInMs = 1000 * 3600 * 24;

        const transformMsToDate = (ms, dayCount = 1) => new Date(new Date().getTime() + ms * dayCount);

        switch (experiment) {
            case 'experiment1':
                // 2 дня
                return transformMsToDate(dayInMs, 2);
            case 'experiment2':
                // 4 дня
                return transformMsToDate(dayInMs, 4);
            case 'experiment3':
                // 6 дней
                return transformMsToDate(dayInMs, 6);
            default:
                return transformMsToDate(0);
        }
    }
}

module.exports = withReq(withExperiments(FullScreenAd));
