const _ = {
    pickBy: require('lodash/pickBy'),
    mapValues: require('lodash/mapValues'),
};
const serializeJavascript = require('serialize-javascript');

const React = require('react');
const PropTypes = require('prop-types');
const Global = require('app/www/components/blocks/Global/Global');

const {
    LAYOUT_COLUMNS_LARGE,
    LAYOUT_COLUMNS_MEDIUM,
} = require('app/www/components/blocks/Content/Content.const');

const displayCookie = require('app/www/constants/cookies')['USER_DISPLAY'];

const LAYOUT_LARGE = 1024;
const LAYOUT_MIDDLE = 768;
const LAYOUT_SMALL = 500;
const LAYOUT_MAIN = 1200;

class Ua extends React.PureComponent {

    render() {
        if (!this.props.uatraits) {
            return null;
        }

        Ua.uatraits = this._normalizeUatraits();

        const inlineLegoUatraits = {
            __html: `window.LEGO_UATRAITS = ${serializeJavascript(Ua.uatraits, { isJSON: true })};`,
        };
        const props = _.pickBy({
            dangerouslySetInnerHTML: inlineLegoUatraits,
            nonce: Global.nonce,
        }, Boolean);

        return (
            <script {...props} />
        );
    }

    /**
     * @returns {Object}
     */
    static get platform() {
        let platform = {};
        let match;

        if (Ua.uatraits) {
            platform = Ua._uatraitsPick('OS');
        } else if (Ua._isClient && (match = navigator.userAgent.match(/Windows\sPhone[^\d]*\s([\d.]+)/))) {
            platform.wp = match[1];
        } else if (Ua._isClient && (match = navigator.userAgent.match(/Android\s+([\d.]+)/))) {
            platform.android = match[1];
        } else if (Ua._isClient && navigator.userAgent.match(/\sHTC[\s_].*AppleWebKit/)) {
            platform.android = '2.3';
        } else if (Ua._isClient && (match = navigator.userAgent.match(/iPhone\sOS\s([\d_]+)/))) {
            platform.ios = match[1].replace(/_/g, '.');
        } else if (Ua._isClient && (match = navigator.userAgent.match(/iPad.*OS\s([\d_]+)/))) {
            platform.ios = match[1].replace(/_/g, '.');
        } else if (Ua._isClient && (match = navigator.userAgent.match(/Bada\/([\d.]+)/))) {
            platform.bada = match[1];
        } else if (Ua._isClient && navigator.userAgent.match(/MSIE\s9/)) {
            platform.wp = '7.5';
        } else {
            platform.other = true;
        }

        return platform;
    }

    /**
     * @returns {Object}
     */
    static get browser() {
        let browser = {};

        if (Ua.uatraits) {
            browser = Ua._uatraitsPick('Browser');
        } else if (Ua._isClient && /msie|trident/i.test(navigator.userAgent)) {
            browser.ie = parseInt(navigator.userAgent.split(/msie|rv:/i)[1], 10);
        } else if (Ua._isClient && window.opera) {
            browser.opera = parseInt(window.opera.version(), 10);
        }

        return browser;
    }

    /**
     * @returns {Object}
     */
    static get device() {
        let device = {};

        if (Ua.uatraits) {
            device = Ua._uatraitsPick('Device');
        } else if (Ua._isClient && navigator.userAgent.match(/iPhone\sOS\s([\d_]+)/)) {
            device.iphone = true;
        } else if (Ua._isClient && navigator.userAgent.match(/iPad.*OS\s([\d_]+)/)) {
            device.ipad = true;
        }

        return device;
    }

    /**
     * @returns {Number}
     */
    static get devicePixelRatio() {
        if (Ua._isClient) {
            return (
                'deviceXDPI' in screen && 'logicalXDPI' in screen ? screen.deviceXDPI / screen.logicalXDPI :
                'devicePixelRatio' in window ? window.devicePixelRatio : 1
            );
        }
    }

    /**
     * @returns {Boolean}
     */
    static get isHiDpi() {
        if (Ua._isClient) {
            return typeof window.matchMedia === 'function'
                ? window.matchMedia([
                    'only screen and (-webkit-min-device-pixel-ratio: 1.3)',
                    'only screen and (min-resolution: 1.3dppx)',
                    'only screen and (min-resolution: 124dpi)',
                ].join(', ')).matches
                : Ua.devicePixelRatio >= 1.3;
        }
    }

    /**
     * @returns {Boolean}
     */
    static get isMobile() {
        return Ua.uatraits ? Ua.uatraits.isMobile : Ua.device.iphone || false;
    }

    /**
     * @returns {Boolean}
     */
    static get isTouch() {
        return Ua.uatraits ? Ua.uatraits.isTouch : Ua.device.iphone || Ua.device.ipad || false;
    }

    /**
     * Нормализуем данные из uatraits
     * @private
     */
    _normalizeUatraits() {
        const {uatraits} = this.props;

        _.mapValues(uatraits, value => {
            if (value === 'false') {
                value = false;
            }

            if (value === 'true') {
                value = true;
            }

            if (!isNaN(parseFloat(value))) {
                value = parseFloat(value);
            }

            return value;
        });

        return uatraits;
    }

    /**
     * Определяем клиента
     * @returns {Boolean}
     * @private
     */
    static get _isClient() {
        return typeof window !== 'undefined';
    }

    /**
     * Выбираем свойства из uatraits, ключи которых содержат заданую строку
     * @param {String} string
     * @returns {Object}
     * @private
     */
    static _uatraitsPick(string) {
        return Ua.uatraits && _.pickBy(Ua.uatraits, (value, key) => key.includes(string));
    }

    /**
     * @returns {Number}
     */
    static get LAYOUT_LARGE() {
        return LAYOUT_LARGE;
    }

    /**
     * @returns {Number}
     */
    static get LAYOUT_MIDDLE() {
        return LAYOUT_MIDDLE;
    }

    /**
     * @returns {Number}
     */
    static get LAYOUT_SMALL() {
        return LAYOUT_SMALL;
    }

    /**
     * Ширина экрана пользователя
     * На клиенте берем ширину экрана
     * На сервере куку user_display
     * @returns {Number}
     */
    static get displayWidth() {
        if (Global.isClient && document.cookie.includes(displayCookie)) {
            return window.innerWidth;
        }

        if (!Global.isClient && Ua.uatraits.displayCookie) {
            return Ua.uatraits.displayCookie;
        }
    }

    /**
     * Ширина экрана
     * @returns {Number}
     */
    static get windowWidth() {
        return process.env.IS_ADAPTIVE ? window.innerWidth : LAYOUT_LARGE + 1;
    }

    /**
     * Широкий экран
     * @returns {Boolean}
     */
    static get isLayoutLarge() {
        return Ua.windowWidth > LAYOUT_LARGE;
    }

    /**
     * Средний экран
     * @returns {Boolean}
     */
    static get isLayoutMiddle() {
        return Ua.windowWidth <= LAYOUT_LARGE && Ua.windowWidth >= LAYOUT_MIDDLE;
    }

    /**
     * Узкий экран
     * @returns {Boolean}
     */
    static get isLayoutSmall() {
        return Ua.windowWidth <= LAYOUT_SMALL;
    }

    /**
     * Широкий экран для главной
     * @returns {Boolean}
     */
    static get isLayoutMainLarge() {
        return document.documentElement.clientWidth > LAYOUT_MAIN;
    }

    /**
     * Узкий экран для главной
     * @returns {Boolean}
     */
    static get isLayoutMainSmall() {
        return document.documentElement.clientWidth <= LAYOUT_MAIN;
    }

    /**
     * Широкий экран для страницы с колонками
     * @returns {Boolean}
     */
    static get isLayoutColumnsLarge() {
        return Ua.displayWidth < LAYOUT_COLUMNS_LARGE;
    }

    /**
     * Узкий экран для страницы с колонками
     * @returns {Boolean}
     */
    static get isLayoutColumnsSmall() {
        return Ua.displayWidth < LAYOUT_COLUMNS_MEDIUM;
    }

    /**
     * @returns {Boolean}
     */
    static get isDesktop() {
        return Ua.uatraits.isDesktop;
    }

    /**
     * @returns {Boolean}
     */
    static get isTouchPhone() {
        return Ua.uatraits.isTouchPhone;
    }

    /**
     * @returns {Boolean}
     */
    static get isPhoneHasViewDesktop() {
        return Ua.uatraits.isPhoneHasViewDesktop;
    }

    /**
     * @returns {Boolean}
     */
    static get isOldOpera() {
        return Ua.browser.BrowserName === 'Opera' && Ua.browser.BrowserVersion <= 13;
    }

    /**
     * @returns {Boolean}
     */
    static get isOldIE() {
        return Ua.browser.BrowserName === 'MSIE' && Ua.browser.BrowserVersion <= 8;
    }

    /**
     * Показывать ли мордовый плеер
     * @returns {Boolean}
     */
    static get isStreamEnabled() {
        return Ua.uatraits.isStreamEnabled;
    }

    /**
     * Показывать ли тачевые кнопки
     * @returns {Boolean}
     */
    static get isTouchButtons() {
        return Ua.isTouch || Ua.isLayoutColumnsLarge;
    }

    /**
     * Проверка на бота
     * @returns {Boolean}
     */
    static get isRobot() {
        return Ua.uatraits.isRobot;
    }
}

Ua.defaultProps = {
    uatraits: Ua._isClient && window.LEGO_UATRAITS,
};

Ua.propTypes = {
    uatraits: PropTypes.object,
};

module.exports = Ua;
