require('./Logo.scss');
require('./_Service/Logo_Service.scss');

const b = require('app/www/libs/b')('logo');

const PropTypes = require('prop-types');

const React = require('react');
const Global = require('app/www/components/blocks/Global/Global');
const Link = require('app/www/components/blocks/Link/Link');

class Logo extends React.PureComponent {

    render() {
        const {children, mods} = this.props;

        const linkProps = {
            url: Global.newYandexUrl,
        };

        return (
            <div className={b(mods)}>
                {children}
                <span ref={elem => (this._main = elem)} className={b('main')}>
                    <Link className={b('link')} {...linkProps} />
                </span>
            </div>
        );
    }

    /**
     * Элемент главной Яндекса
     * @returns {Object}
     */
    get main() {
        return this._main;
    }
}

Logo.propTypes = {
    mods: PropTypes.shape({
        service: PropTypes.string,
        lang: PropTypes.string,
    }),
};

module.exports = Logo;
