require('./MetrikaLoader.scss');

const b = require('app/www/libs/b')('metrika-loader');

const React = require('react');
const PropTypes = require('prop-types');

class MetrikaLoader extends React.PureComponent {

    constructor(props) {
        super(props);

        if (typeof window !== 'undefined') {
            this._loadScript();
        }
    }

    render() {
        const {children} = this.props;

        return (
            <div className={b()}>
                {children}
            </div>
        );
    }

    /**
     * Грузим скрипт метрики
     * @private
     */
    _loadScript() {
        const {onLoad, onError} = this.props;

        const script = Object.assign(document.createElement('script'), {
            src: '//mc.yandex.ru/metrika/watch.js',
            type: 'text/javascript',
            async: true,
            onload: onLoad,
            onerror: onError,
        });

        document.head.appendChild(script);
    }
}

MetrikaLoader.defaultProps = {
    onLoad: () => {},
    onError: () => {},
};

MetrikaLoader.propTypes = {
    onLoad: PropTypes.func,
    onError: PropTypes.func,
};

module.exports = MetrikaLoader;
