const _mapKeys = require('lodash/mapKeys');
const BaseModel = require('app/common/libs/model');
const {moscow} = require('app/common/libs/config');
const DEFAULT_REGION = require('app/common/libs/mocks/region');

const MAX_DISCHARGE_REGION_ID = 10;

/**
 * Данные по регионам пользователя: портальному и из урла
 */
class RegionModel extends BaseModel {

    /**
     * @param {Object} attributes
     * @param {Object} [attributes.portalId]
     * @param {Object} [attributes.pathId]
     * @param {Object} attributes.region
     * @param {Object} [attributes.timezone]
     * @param {Object} [attributes.linguistics]
     */
    constructor(attributes) {
        attributes.linguistics = _mapKeys(attributes.linguistics, (value, key) => key.replace('_case', ''));

        super(attributes);
    }

    /**
     * Получаем regionId
     * @returns {Number|null}
     */
    get id() {
        return this.region.id;
    }

    /**
     * Регион из урла
     * @returns {Number}
     */
    get pathId() {
        return this._pickNumber('pathId');
    }

    /**
     * Портальный регион
     * @returns {Number}
     */
    get portalId() {
        return this._pickNumber('portalId');
    }

    /**
     * @returns {Object}
     */
    get region() {
        return this._pick('region');
    }

    /**
     * @returns {Number}
     */
    get countryId() {
        return this._pickNumber('countryId');
    }

    /**
     * @returns {Boolean}
     */
    get isRussia() {
        return this.countryId === 225;
    }

    /**
     * @returns {Boolean}
     */
    get isMoscow() {
        return this.id === moscow;
    }

    /**
     * Смещение в регионе из урла
     * @returns {Number}
     */
    get timezoneOffset() {
        return this._pickNumber('timezone.offset');
    }

    /**
     * Регион для мета
     * @returns {Number}
     */
    get regionIdForMeta() {
        return this._pickNumber('regionIdForMeta');
    }

    /**
     * Родители региона
     * @returns {Number[]}
     */
    get parents() {
        return this._pick('parents', []);
    }

    /**
     * Форма имени основного региона
     * @param {String} [declension=nominative] склонение
     * @returns {String|null}
     */
    name(declension) {
        declension = declension || 'nominative';
        return this._pickString(`linguistics.${declension}`);
    }

    /**
     * Широта местоположения региона, к которому принадлежат посетители сайта
     * @returns {Number}
     */
    get latitude() {
        return this._pickNumber('latitude');
    }

    /**
     * Долгота местоположения региона, к которому принадлежат посетители сайта
     * @returns {Number}
     */
    get longitude() {
        return this._pickNumber('latitude');
    }

    /**
     * id региона не должен быть больше 10 разрядов
     * @param {Number|String} regionId
     * @returns {Number}
     */
    static normalizeId(regionId) {
        if (String(regionId).length > MAX_DISCHARGE_REGION_ID) {
            regionId = DEFAULT_REGION.id;
        }

        return Number(regionId);
    }

}

module.exports = RegionModel;
