require('./Input.scss');
require('./_Disabled/Input_Disabled.scss');
require('./_Pin/Input_Pin_RoundClear.scss');
require('./_Size/Input_Size_M.scss');
require('./_Size/Input_Size_S.scss');
require('./_Size/Input_Size_XS.scss');
require('./_Theme/Input_Theme_Normal.scss');

const _ = {
    get: require('lodash/get'),
};

const b = require('app/www/libs/b')('input', true);

const React = require('react');
const PropTypes = require('prop-types');

class Input extends React.PureComponent {

    constructor(props) {
        super(props);

        const {attrs, autofocus} = this.props;
        const value = attrs.value || '';

        this.state = {
            value,
            clear: value.length > 0,
            focused: autofocus,
        };

        this._onFocus = this._onFocus.bind(this);
        this._onBlur = this._onBlur.bind(this);
        this._onKeyDown = this._onKeyDown.bind(this);
        this._onKeyUp = this._onKeyUp.bind(this);
        this._onInput = this._onInput.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this.focusInput = this.focusInput.bind(this);
        this.val = this.val.bind(this);
    }

    /**
     * Передаем полю фоткус если надо
     */
    componentDidMount() {
        if (this.state.focused || this.props.focused) {
            this.focusInput();
        }
    }

    static getDerivedStateFromProps(props) {
        const {attrs} = props;

        return attrs.value ? {
            value: props.attrs.value,
        } : null;
    }

    render() {
        const {
            mods,
            attrs,
            className,
        } = this.props;

        const stateMods = Object.assign({}, mods, {
            hovered: !mods.disabled && this.state.hovered,
            focused: !mods.disabled && this.state.focused,
        });

        const clearMods = {
            visible: this.state.clear,
        };

        const inputAttrs = Object.assign({}, attrs, {
            disabled: mods.disabled,
            value: this.state.value,
        });

        return (
            <span className={b.mix(className, stateMods)}>
                <span className={b('box')}>
                    <span
                        className={b('clear', clearMods)}
                        onClick={this.clearInput}
                    >&nbsp;</span>
                    <input
                        ref={elem => (this._node = elem)}
                        className={b('control')}
                        onKeyDown={this._onKeyDown}
                        onKeyUp={this._onKeyUp}
                        onMouseEnter={this._onMouseEnter}
                        onMouseLeave={this._onMouseLeave}
                        onFocus={this._onFocus}
                        onBlur={this._onBlur}
                        onInput={this._onInput}
                        onChange={() => {}}
                        {...inputAttrs}
                    />
                </span>
            </span>
        );
    }

    get node() {
        return this._node;
    }

    /**
     * @protected
     */
    _onMouseEnter() {
        this.setState({
            hovered: true,
        });
    }

    /**
     * @protected
     */
    _onMouseLeave() {
        this.setState({
            hovered: false,
        });
    }

    /**
     * @protected
     */
    _onFocus() {
        this.setState({
            focused: true,
        });

        this.props.onFocus();
    }

    /**
     * @protected
     */
    _onBlur() {
        this.setState({
            focused: false,
        });

        this.props.onBlur();
    }

    /**
     * @param {Object} evt объект события
     * @private
     */
    _onInput(evt) {
        this._value = _.get(evt, 'target.value', '');

        this.setState({
            value: this._value,
            clear: this._value.length > 0,
        });

        this.props.onInput(this._value);
    }

    /**
     * @param {Object} evt объект события
     * @private
     */
    _onKeyDown(evt) {
        this.props.onKeyDown(evt);
    }

    /**
     * @param {Object} evt объект события
     * @private
     */
    _onKeyUp(evt) {
        this.props.onKeyUp(evt);
    }

    /**
     * Очищаем значение
     */
    clearInput() {
        this.setState(
            {value: ''},
            this._onInput,
            this.focusInput()
        );
    }

    /**
     * Передаем фокус
     */
    focusInput() {
        this.node.focus();
    }

    /**
     * Возвращает или устанавливает значение атрибута value
     * @param {String} value
     */
    val(value) {
        this.node.value = value || this.node.value;
        return this.node.value;
    }
}

Input.defaultProps = {
    onBlur: () => {},
    onFocus: () => {},
    onInput: () => {},
    onKeyDown: () => {},
    onKeyUp: () => {},
};

Input.propTypes = {
    mods: PropTypes.shape({
        disabled: PropTypes.bool,
        pin: PropTypes.string,
        size: PropTypes.string.isRequired,
        theme: PropTypes.string.isRequired,
    }).isRequired,
    attrs: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.string,
        placeholder: PropTypes.string,
        value: PropTypes.string,
        tabIndex: PropTypes.number,
    }),
    autofocus: PropTypes.bool,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    onInput: PropTypes.func,
    onKeyDown: PropTypes.func,
    onKeyUp: PropTypes.func,
};

module.exports = Input;
