require('./SuggestItem.scss');
require('./_Hovered/SuggestItem_Hovered.scss');
require('./_WithInfo/SuggestItem_WithInfo.scss');

const b = require('app/www/libs/b')('suggest-item');

const React = require('react');
const PropTypes = require('prop-types');

const Link = require('app/www/components/blocks/Link/Link');
const Image = require('app/www/components/blocks/ImageSuggest/ImageSuggest');

class SuggestItem extends React.PureComponent {

    constructor(props) {
        super(props);

        const {hovered} = this.props.mods;

        this.state = {hovered};

        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
    }

    static getDerivedStateFromProps(props) {
        return {
            hovered: props.mods.hovered,
        };
    }

    render() {
        const {
            title,
            url,
            text,
            img,
            mods,
            onClick,
        } = this.props;

        const {hovered} = this.state;

        const linkProps = {
            url: url,
        };

        const imageProps = {
            attrs: {
                src: img,
                title: title,
            },
        };

        Object.assign(mods, {hovered});

        return (
            <li className={b(mods)}
                onMouseEnter={this._onMouseEnter}
                onMouseLeave={this._onMouseLeave}
            >
                <Link
                    className={b('link')}
                    onClick={onClick}
                    {...linkProps}
                >
                    {img &&
                        <Image className={b('image')} {...imageProps} />
                    }
                    <h4 className={b('title')}>{title}</h4>
                    {text &&
                        <p className={b('info')}>{text}</p>
                    }
                </Link>
            </li>
        );
    }

    /**
     * @protected
     */
    _onMouseEnter() {
        this.setState({
            hovered: true,
        });
    }

    /**
     * @protected
     */
    _onMouseLeave() {
        this.setState({
            hovered: false,
        });
    }
}

SuggestItem.defaultProps = {
    onClick: () => {},
};

SuggestItem.propTypes = {
    mods: PropTypes.shape({
        hovered: PropTypes.bool,
        'with-info': PropTypes.bool,
    }),
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    text: PropTypes.string,
    img: PropTypes.string,
};

module.exports = SuggestItem;
