require('./User.scss');

const b = require('app/www/libs/b')('user', true);

const React = require('react');
const PropTypes = require('prop-types');

const {withI18nRef} = require('app/www/components/contexts/I18n');

const ButtonAuth = require('app/www/components/blocks/Button/_Auth/Button_Auth');
const Global = require('app/www/components/blocks/Global/Global');

class User extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {
            i18n,
            isLogged,
            avatarId,
            userName,
            onClick,
            buttonProps,
            children,
            className,
        } = this.props;

        const userAttrs = {
            href: `${Global.passportUrl}?retpath=${Global.retpath}`,
        };

        const buttonAuthProps = Object.assign({
            hasModal: false,
            mods: {
                size: 'm',
                theme: 'normal',
                'user-header': true,
            },
            children: i18n.get('lego.user.button'),
        }, buttonProps);

        return isLogged
            ? (
                <a
                    className={b.mix(className)}
                    onClick={onClick}
                    {...userAttrs}
                >
                    {this.getUserBlock(avatarId, userName)}
                    {children}
                </a>
            ) : (
                <ButtonAuth {...buttonAuthProps} />
            );
    }

    /**
     * Элемент аватара
     * @returns {Object}
     */
    get icon() {
        return this._icon;
    }

    /**
     * Строим блок, состоящий из аватара и логина пользователя
     * @param {String} [avatarId] id аватара
     * @param {String} [userName] имя и фамилия
     * @returns {Object[]}
     */
    getUserBlock(avatarId, userName) {
        const iconAttrs = {
            style: {
                backgroundImage: `url('${this._getAvatarUrl(avatarId)}')`,
            },
        };

        return [
            <span
                key="icon"
                ref={ref => (this._icon = ref)}
                className={b('icon')}
                {...iconAttrs}
            />,
            userName && (
                <span key="name" className={b('name')}>
                    {userName}
                </span>
            ),
        ];
    }

    /**
     * Урл аватара
     * @param {String} [avatarId] id аватара
     * @returns {String}
     * @private
     */
    _getAvatarUrl(avatarId) {
        avatarId = avatarId || User.defaultProps.avatarId;
        const avatarsHost = Global.avatarsHost;

        return `${avatarsHost}/get-yapic/${avatarId}/islands-middle`;
    }

}

User.defaultProps = {
    avatarId: '0/0-0',
    maxLength: 16,
    onClick: () => {},
};

User.propTypes = {
    isLogged: PropTypes.bool,
    avatarId: PropTypes.string,
    userName: PropTypes.string,
    onClick: PropTypes.func,
};

module.exports = withI18nRef(User);
