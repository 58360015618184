require('./balloon.scss');

const b_ = require('b_');

const sliceString = require('app/common/libs/sliceString');

const React = require('react');
const PropTypes = require('prop-types');
const b = b_.with('balloon');

const Metrika = require('app/www/libs/client/metrika');
const nextTick = require('app/www/libs/nextTick');

const {withI18nRef} = require('app/www/components/contexts/I18n');

const Button = require('app/www/components/blocks/Button/Button');
const Button_WannaSee = require('app/www/components/blocks/Button/_WannaSee/Button_WannaSee');
const Image = require('app/www/components/blocks/Image/Image');
const Icon = require('app/www/components/blocks/Icon/Icon');
const ProgramMeta = require('app/www/components/blocks/ProgramMeta/ProgramMeta');
const Popup = require('app/www/components/blocks/Popup/Popup');
const Link = require('app/www/components/blocks/Link/Link');
const Ua = require('app/www/components/blocks/Ua/Ua');

const DESCRIPTION_LENGTH = 170;
const DELAY_OPEN = 400;
const DELAY_REOPEN = 300;
const DELAY_CLOSE = 1000;

class Balloon extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            hovered: false,
        };

        this._events = {};

        this.onMouseEnterEvent = this.onMouseEnterEvent.bind(this);
        this.onMouseLeaveEvent = this.onMouseLeaveEvent.bind(this);
        this.onClickEvent = this.onClickEvent.bind(this);
        this._onMouseEnter = this._onMouseEnter.bind(this);
        this._onMouseLeave = this._onMouseLeave.bind(this);
        this._onClick = this._onClick.bind(this);
    }

    componentDidUpdate() {
        const {event} = this.props;

        if (event && !this._events[event.id]) {
            this._events[event.id] = event;
            this._openSavedEventPopup(event);
        }
    }

    render() {
        const {i18n, store} = this.props;
        const {event} = this.state;

        if (!event) {
            return <span/>;
        }

        const {
            id: programId,
            favourite: isFavorite,
            ageRestriction: age,
            description,
        } = event.program;

        const image = event.program.images[0];
        const type = event.program.type.name;
        const hasOnlines = event.program.onlines.length > 0;

        const popupProps = {
            mods: {
                theme: 'normal',
            },
            owner: this._ownerBalloon,
            clickOutExceptions: b_('domik'),
            directions: 'right',
            isAdaptive: true,
        };

        const linkProps = {
            url: event.url,
        };

        const imageProps = {
            mods: {
                size: 'xl',
                background: true,
            },
        };

        const iconLiveProps = {
            mods: {
                size: 'm',
                live: true,
            },
        };

        const programMetaProps = {
            type: type,
            age: age,
        };

        const buttonWannaSeeProps = {
            mods: {
                theme: 'normal',
                size: 'm',
                'only-icon': hasOnlines,
            },
            store,
            programId,
            isFavorite,
            tooltipProps: false,
        };

        const buttonOnlinesProps = {
            mods: {
                theme: 'normal',
                size: 'm',
            },
            icon: {
                onlines: true,
            },
            url: `${event.url}#embedded`,
            onClick: () => {this._metrikaParam = 'online';},
        };

        const elemTrailerProps = {
            onClick: () => {this._metrikaParam = 'trailer';},
        };

        return (
            <Popup
                ref={elem => (this.popup = elem)}
                className={b()}
                {...popupProps}
            >
                <div
                    onMouseEnter={this._onMouseEnter}
                    onMouseLeave={this._onMouseLeave}
                >
                    <Link
                        className={b('link')}
                        onClick={this._onClick(type, programId)}
                        {...linkProps}
                    >
                        {image &&
                            <div className={b('image')}>
                                <Image
                                    sizes={image.sizes}
                                    {...imageProps}
                                />
                                {event.program.hasTrailers && (
                                    <div className={b('trailer')} {...elemTrailerProps}>
                                        {i18n.get('balloon.trailer')}
                                    </div>
                                )}
                            </div>
                        }
                        <div className={b('content')}>
                            {type || age ? (
                                <div className={b('program-meta')}>
                                    <ProgramMeta {...programMetaProps} />
                                </div>
                            ) : null}
                            <div className={b('title')}>
                                {event.title}
                                {event.live && <Icon className={b('live')} {...iconLiveProps} />}
                            </div>
                            {description && (
                                <div className={b('description')}>
                                    {sliceString(description, DESCRIPTION_LENGTH)}
                                </div>
                            )}
                        </div>
                    </Link>
                    <div className={b('buttons')}>
                        <Button_WannaSee {...buttonWannaSeeProps} />
                        {hasOnlines && (
                            <Button {...buttonOnlinesProps}>
                                {i18n.get('button.onlines')}
                            </Button>
                        )}
                    </div>
                </div>
            </Popup>
        );
    }

    /**
     * Если информации о передаче нет, делаем запрос
     * Если есть, берем сохраненную информацию
     * @param {Object} evt
     * @param {Number} eventId
     * @param {Number} programCoId
     * @private
     */
    onMouseEnterEvent(evt, eventId, programCoId) {
        if (Ua.isTouch || !Ua.isLayoutLarge) {
            return;
        }

        const target = evt.currentTarget;

        clearTimeout(this._timeoutCloseBalloon);

        setTimeout(() => {
            this._closePopup(target);
        }, DELAY_REOPEN);

        this._timeoutOpenBalloon = setTimeout(() => {
            if (this._clickedLink) {
                return;
            }

            this._ownerBalloon = target;
            const event = this._events[eventId];

            if (event) {
                this._openSavedEventPopup(event);
            } else {
                this._openNewEventPopup(eventId, programCoId);
            }
        }, DELAY_OPEN);
    }

    /**
     * @param {Object} evt
     * @private
     */
    onMouseLeaveEvent(evt) {
        if (Ua.isTouch || !Ua.isLayoutLarge) {
            return;
        }

        const target = evt.relatedTarget;

        clearTimeout(this._timeoutOpenBalloon);

        this._timeoutCloseBalloon = setTimeout(() => {
            this._closePopup(target);
        }, DELAY_CLOSE);
    }

    /**
     * Кладем событие в state
     * Показываем попап
     * @param {Object} event
     * @private
     */
    _openSavedEventPopup(event) {
        this.setState({event});

        nextTick(() => {
            clearTimeout(this._timeoutClose);
            this.popup.open();

            const {hasTrailers, onlines, type} = event.program;
            const hasOnlines = onlines.length > 0;
            const status = hasOnlines && hasTrailers ? 'trailer_online' :
                hasOnlines ? 'online' :
                hasTrailers ? 'trailer' : 'none';

            this._metrikaStatus = `status_${status}`;

            Metrika.trackParams('BALLOON_SHOW', this._metrikaStatus);
            Metrika.trackParams('BALLOON', type.name);
        });
    }

    /**
     * Делаем запрос по id события и coId программы
     * @param {Number} eventId
     * @param {Number} programCoId
     * @private
     */
    _openNewEventPopup(eventId, programCoId) {
        this.props.dispatchGetEvent(eventId, programCoId);
    }

    /**
     * @private
     */
    onClickEvent() {
        this._clickedLink = true;
    }

    /**
     * Закрываем балун, если на нем нет курсора
     * @param {Object} target
     */
    _closePopup(target) {
        if (!this.popup) {
            return;
        }

        const hasClosest = Boolean(target.closest);
        const isHovered = this.state.hovered;
        const isBalloon = hasClosest && target.closest(`.${b()}`);
        const isTooltip = hasClosest && target.closest('.tooltip');

        if (!hasClosest || !isHovered && !isBalloon && !isTooltip) {
            this.popup.close();
        }
    }

    /**
     * Очищаем таймер закрытия
     * @private
     */
    _onMouseEnter() {
        clearTimeout(this._timeoutClose);

        this.setState({
            hovered: true,
        });
    }

    /**
     * Закрываем при потере курсора
     * @param {Object} evt
     * @private
     */
    _onMouseLeave(evt) {
        const target = evt.relatedTarget;

        this.setState({
            hovered: false,
        });

        this._timeoutClose = setTimeout(() => {
            this._closePopup(target);
        }, DELAY_CLOSE);
    }

    /**
     * @param {String} programType
     * @param {Number} programId
     * @returns {Object}
     * @private
     */
    _onClick(programType, programId) {
        return () => {
            Metrika.params({
                balloon_click: {
                    [this._metrikaParam || 'other']: this._metrikaStatus,
                },
            });

            this._metrikaParam = null;

            Metrika.trackParams('TV_EVENT', {
                mods: {
                    balloon: true,
                },
                genre: programType,
                programId,
            });
        };
    }

}

Balloon.propTypes = {
    event: PropTypes.object,
};

module.exports = withI18nRef(Balloon);
