require('./GridChannel.scss');

const b = require('app/www/libs/b')('grid-channel');

const React = require('react');
const PropTypes = require('prop-types');

const Metrika = require('app/www/libs/client/metrika');

const Button_FavoriteChannel = require('app/www/components/blocks/Button/_FavoriteChannel/Button_FavoriteChannel');
const ChannelLogo = require('app/www/components/blocks/ChannelLogo/ChannelLogo');
const Link = require('app/www/components/blocks/Link/Link');
const Ua = require('app/www/components/blocks/Ua/Ua');

const GridChannel = (props) => {
    const {
        id,
        url,
        logo,
        title,
        isSerpApp,
        isFavorite,
        hasAnyMatchEvent,
        onChangeFavorite,
    } = props;

    const mods = {
        match: hasAnyMatchEvent,
    };

    const buttonFavoriteChannelProps = {
        channelId: id,
        mods: {
            pin: 'circle',
            theme: Ua.isTouchPhone ? 'clear' : 'ghost',
            size: 'xs',
            'only-icon': true,
            active: isFavorite,
        },
        icon: {
            size: 's',
        },
        isFavorite,
        onChange: onChangeFavorite,
    };

    const linkProps = {
        url,
        onClick: () => Metrika.trackParams('CHANNEL_TITLE', title),
    };

    const channelLogoProps = {
        className: b('logo'),
        mods: {
            size: 'm',
        },
        logo,
    };

    return (
        <div className={b(mods)}>
            <Link className={b('link')} {...linkProps}>
                <ChannelLogo {...channelLogoProps} />
                <h2 className={b('title')}>{title}</h2>
            </Link>
            <div className={b('buttons')}>
                <span className={b('icons-divider')} />
                {!isSerpApp &&
                    <Button_FavoriteChannel {...buttonFavoriteChannelProps} />
                }
            </div>
        </div>
    );
};

GridChannel.propTypes = {
    hasAnyMatchEvent: PropTypes.bool,
    id: PropTypes.number.isRequired,
    url: PropTypes.string.isRequired,
    logo: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool,
    onChangeFavorite: PropTypes.func,
    onClick: PropTypes.func,
};

module.exports = GridChannel;
